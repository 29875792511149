import React, {useState} from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { useTrans } from "@core/Hook/trans";
import Box from "@mui/material/Box";
import ModalTypography from "../Partial/ModalTypography";
import FormInputPasscode from "@core/Form/FormInputPasscode";
import FadeInUp from "../../../Animate/FadeInUp";
import FormControlWrapper from "@core/Form/FormControlWrapper";
import InputPasscodeForm, {IInputPassCodeData} from "../Form/InputPasscodeForm";
import {IValidateOtpResponse, useManageOtp} from "../../../Hook/auth";

interface IRegisterStepOtp {
    otpToken: string;
    onSubmit: (data: IValidateOtpResponse) => void;
}

const RegisterStepOtpStyled = styled(Stack)(({ theme }) => ({
    '&.x-register-step-otp': {
        '.-description-wrapper': {
            marginTop: theme.spacing(3),
        },
        '.-form-wrapper': {
            margin: theme.spacing(3.5, 0, 1),
        },
    }
}))

const RegisterStepOtp: React.FC<IRegisterStepOtp> = ({ onSubmit, otpToken }) => {
    const { trans } = useTrans();
    const [payload, setPayload] = useState('')
    const {submitOtp, apiOtpState} = useManageOtp();

    const handleSubmitOtp = async (data: IInputPassCodeData) => {
        const formData = {
            otpCode: data.otpCode
        }

        const res = await submitOtp(formData, otpToken);
        if (res && res.validateToken) {
            return onSubmit({
                validateToken: res.validateToken
            });
        }
    }

    return (
        <RegisterStepOtpStyled className={'x-register-step-otp'}>
            <FadeInUp>
                <Box className={'-description-wrapper'}>
                    <ModalTypography renderType={'title'} align={'center'}>{trans('modal.register_otp.enter_otp')}</ModalTypography>
                    <ModalTypography align={'center'}>{trans('modal.register_otp.otp_will_sent_to_your_mobile')}</ModalTypography>
                </Box>
                <Box className={'-form-wrapper'}>
                    <InputPasscodeForm
                        labelSubmit={trans('modal.register_otp.submit_password_otp')}
                        inputName={'otpCode'}
                        onSubmit={(data) => handleSubmitOtp(data)}
                        payload={payload}
                        isLoading={apiOtpState.isLoading}
                    >
                        <FormControlWrapper
                            required={true}
                            className={'-form-control-wrapper'}
                        >
                            <FormInputPasscode
                                isOtp
                                value={payload}
                                onChange={(val) => setPayload(val)}
                            />
                        </FormControlWrapper>
                    </InputPasscodeForm>
                </Box>
            </FadeInUp>
        </RegisterStepOtpStyled>
    )
}

export default RegisterStepOtp
