import React from "react";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {Image} from "@core/Utils/image";


const ServiceImagePreviewStyled = styled(Box)(({ theme }) => ({
    '&.x-service-preview-image': {
        width: '250px',
        margin: theme.spacing(2, 'auto'),
        [theme.breakpoints.between('xxs', 380)]: {
            width: '220px',
        },
    }
}))

export interface IServiceImagePreview {
    code: string;
}

const ServiceImagePreview: React.FC<IServiceImagePreview> = ({code}) => {
    const serviceImagePreview = `wow-boost-service-${code}-preview.gif`

    return (
        <ServiceImagePreviewStyled className="x-service-preview-image">
            <Image
                extraClass={'-service-preview-image'}
                src={serviceImagePreview}
                width={450}
                height={600}
                webp={false}
            />
        </ServiceImagePreviewStyled>
    )
}

export default ServiceImagePreview
