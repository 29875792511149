import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { parse, stringify } from "qs";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { PaginationProps } from "@mui/material/Pagination/Pagination";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";

export type ApiResponse<T> = {
    code: string;
    message: string;
    current_page: number;
    limit: number;
    count: number;
    items: Array<T>;
}

export interface IApiPagination {
    page: number;
    count: number;
    limit: number;
    items: Array<any>;
}

export interface IPagination<T> {
    nextPage: () => void;
    prevPage: () => void;
    jumpPage: (page: number) => void;
    currentData: () => Array<T>,
    itemsPerPage: number;
    defaultPage: number;
    page: number;
    maxPage: number;
    count: number;
}

export interface IPager<T> extends PaginationProps {
    pager: IPagination<T>;
    justifyContent?: 'center' | 'flex-start' | 'flex-end';
}

export function usePagination<T>(paginate: IApiPagination | object): { pagination: IPager<T>, shouldRender: boolean } {
    let _paginate: IApiPagination = {
        count: 0,
        limit: 1,
        page: 1,
        items: [],
    };

    if (!isEmpty(paginate)) {
        _paginate = paginate as IApiPagination;
    }

    const location = useLocation();
    const qs = parse(trim(location.search, '?'));
    const page = parseInt(qs?.page as string || '1');
    const maxPage = Math.ceil(_paginate.count / _paginate.limit);
    const count = _paginate.count;
    const data = _paginate.items;

    const navigate = useNavigate();

    function getData(): Array<T> {
        return data;
    }

    function nextPage() {
        jumpPage(page)
    }

    function prevPage() {
        jumpPage(page)
    }

    function jumpPage(page: number) {
        const pageNumber = Math.max(1, page);

        navigate(`${location.pathname}?${stringify({
            ...parse(trim(location.search, '?')),
            page: pageNumber,
        })}`);
    }

    const pagination: IPager<T> = {
        // eslint-disable-next-line no-undef
        onChange: (_event: React.ChangeEvent<unknown>, page: number) => {
            jumpPage(page)
        },
        pager: {
            nextPage,
            prevPage,
            jumpPage,
            currentData: getData,
            itemsPerPage: _paginate.limit,
            page,
            defaultPage: page,
            maxPage,
            count,
        }
    }

    return { pagination, shouldRender: maxPage > 1};
}

const BasicPaginationStyled = styled(Box)(({theme}) => ({
    '&.x-basic-pagination-container': {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(2.5),
        },
        'button': {
            minWidth: 28,
            height: 28,
            color: theme.baseVariables.pagination.color,
            background: theme.baseVariables.pagination.bg,
            '&.Mui-selected': {
                color: theme.baseVariables.pagination.activeColor,
                background: theme.baseVariables.pagination.activeBg
            }
        },
        '.MuiPagination-root': {
            display: 'flex',
        }
    }
}))

const BasicPagination: React.FC<IPager<any>> = (props) => {
    const {
        pager,
        justifyContent = 'center',
        ...paginationProps
    } = props;

    return (
        <BasicPaginationStyled className={'x-basic-pagination-container'}>
            <Pagination
                shape="rounded"
                count={pager.maxPage}
                page={pager.page}
                boundaryCount={1}
                siblingCount={0}
                sx={{justifyContent: justifyContent}}
                {...paginationProps}
            />
        </BasicPaginationStyled>
    )
}

export default BasicPagination
