import * as process from 'process';

export const LOCAL_STORAGE_CURRENT_LOCALE_CODE = 'current_locale_code';

export const LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE = 'ApiToken';

export const MAIN_WEB_URL = 'https://dev.wowboost.me';
export const BASE_IMAGE_PATH = 'https://dev.wowboost.me/asset/images';

export const DEFAULT_PAYMENT_METHOD = process.env['REACT_APP_PAYMENT_METHOD'];

export const IS_PAYMENT_VIA_BANK_TRANSFER = DEFAULT_PAYMENT_METHOD === 'bank-transfer';

export const AUTH_MODE_AVAILABLE = ['email', 'phone-number'];

export const SOCIAL_LINE_URL = 'https://page.line.me/843wkvii';

export enum PaymentMethodEnum {
    PROMPTPAY = 'promptpay',
    WOWBOOST_CREDIT = 'wowboost_credit',
    CREDIT_CARD = 'credit_card',
    BANK_TRANSFER = 'bank_transfer',
}

export enum AccountGroupTypeEnum {
    INTERNAL = 1,
    EXTERNAL = 2,
    GOLD = 3,
    VIP = 4,
}

export const ACCOUNT_GROUP_INTERNAL_LIST = [
    AccountGroupTypeEnum.INTERNAL,
    AccountGroupTypeEnum.GOLD,
    AccountGroupTypeEnum.VIP,
]
