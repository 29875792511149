import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleResponseFormError, resolveClientApiState, useClientApiState } from '@core/Hook/api';
import { useTrans } from '@core/Hook/trans';
import { Asset } from '@core/Utils/image';
import { useResponsive } from '@core/Hook/responsiveHook';
import OrderCardLayout from '../Component/Order/OrderCardLayout';
import Layout from '../Component/Common/Layout';
import MetaSeo from '../Component/Common/MetaSeo';
import CreditBalance from './Credit/CreditBalance';
import PlainButton from '../Component/Button/PlainButton';
import { styled } from '@mui/material/styles';
import { loadCreditPrice } from '../Api/credit';
import { OrderTypeEnum } from '../Constants/order';
import ProductGroup, { IServiceProductChoice } from './Product/ProductGroup';

const OrderCardLayoutStyled = styled(OrderCardLayout)(({ theme }) => ({
    '&.x-credit-layout-container': {
        marginTop: theme.spacing(2),
        [theme.breakpoints.between('xxs', 380)]: {
            marginTop: theme.spacing(1.5),
        },
        '.-form-control': {
            paddingTop: theme.spacing(2),
            '&.-hide': {
                display: 'none',
            },
            '.-input-label': {
                '.-product-group-label-required': {
                    marginRight: theme.spacing(1),
                    color: theme.baseVariables.serviceProductForm.requireColor,
                },
                '.-product-group-label': {
                    fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                    color: theme.baseVariables.serviceProductForm.labelColor,
                },
                '.-product-group-sub-label': {
                    fontWeight: theme.baseVariables.fonts.fontWeightNormal,
                    color: theme.baseVariables.serviceProductForm.buttonDownload,
                },
                '&.-upload': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                },
            },
            '.-btn-download-example': {
                marginLeft: theme.spacing(2),
                padding: 0,
                display: 'block',
                float: 'right',
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                fontWeight: theme.baseVariables.fonts.fontWeightNormal,
                color: theme.baseVariables.serviceProductForm.btnDownload,
                background: 'none',
                textDecoration: 'underline',
                '&.-full': {
                    display: 'flex',
                    justifyContent: 'center',
                    margin: theme.spacing(2, 0, 0),
                    float: 'none',
                },
            },
        },
        '.-summary-wrapper': {
            marginBottom: theme.spacing(3),
        },
        '.-button-action-container': {
            background: theme.baseVariables.serviceProductForm.buttonActionContainerBg,
            position: 'fixed',
            bottom: 0,
            left: 0,
            margin: 0,
            zIndex: 3,
            transition: theme.baseVariables.constants.transition.normal,
            width: '100%',
            padding: theme.spacing(2, 4),
            boxShadow: theme.baseVariables.serviceProductForm.buttonActionContainerBoxShadow,
            [theme.breakpoints.up('sm')]: {
                position: 'relative',
                padding: theme.spacing(2, 0),
                boxShadow: 'none',
            },
            '.-button-wrapper': {
                [theme.breakpoints.up('sm')]: {
                    width: '300px',
                    margin: `${theme.spacing(5)} auto`,
                },
                [theme.breakpoints.down('sm')]: {
                    marginTop: theme.spacing(2),
                },
            },
        },
    },
}));

export interface IOrderInfo {
    data: string;
    customerInfo: string;
}
export interface IReceiptFormData {
    type: string;
    personalName?: string | undefined;
    corporateName?: string | undefined;
    phoneNumber?: string | undefined;
    address?: string | undefined;
    taxNumber?: string | undefined;
}
export interface IOrderMetadata {
    [key: string]: string;
}

export interface IOrderCheckOut {
    productItemFullCode: string;
    link: string;
    socialAccountName: string;
    metadata?: IOrderMetadata;
    purchaseOrderRefId?: string;
}

export interface IOrderCustomerInfo {
    infoName: string;
    isCorporate: boolean;
    address?: string;
    phoneNumber?: string;
    taxpayerId?: string;
}

const Credit: React.FC = () => {
    const { trans } = useTrans();
    const { isTabletOrMobile } = useResponsive();
    const [apiState, setApiState] = useClientApiState({ isLoading: false });
    const navigate = useNavigate();
    const bgImage = isTabletOrMobile ? Asset('wow-boost-history-purchase-bg-mobile.jpg') : Asset('wow-boost-history-purchase-bg.jpg');
    const [apiLoadCreditState, setApiLoadCreditState] = useClientApiState({
        isLoading: false,
    });
    const [creditPriceChoices, setCreditPriceChoices] = useState<IServiceProductChoice[]>([]);
    const [chooseAmountInfo, setChooseAmountInfo] = useState<IServiceProductChoice>();
    const location = useLocation();
    const purchaseOrderProduct = location.state?.purchaseOrderProduct;

    useEffect(() => {
        loadCreditPriceData();
    }, []);

    const creditPriceData: IServiceProductChoice[] = [];
    const loadCreditPriceData = async () => {
        setApiLoadCreditState({ ...apiLoadCreditState, isLoading: true });
        const response = resolveClientApiState(await loadCreditPrice(), true);
        if (response.isOk) {
            setApiLoadCreditState({ ...apiLoadCreditState, isLoading: false });
            response.data.items.map((item: any) => {
                creditPriceData.push({
                    code: item.code,
                    amount: item.creditValue,
                    price: item.price,
                });
            });
            setCreditPriceChoices(creditPriceData);
            if (purchaseOrderProduct) {
                setChooseAmountInfo(purchaseOrderProduct);
                return;
            }
            setChooseAmountInfo(creditPriceData[0]);

            return;
        }
        handleResponseFormError(response);
        setApiState({ ...apiLoadCreditState, isLoading: false });
    };

    const handleProductChoose = (choice: IServiceProductChoice) => {
        setChooseAmountInfo({ ...choice });
    };

    const submitOrder = () => {
        navigate('/account/order-summary', {
            state: {
                orderType: OrderTypeEnum.CREDIT,
                productInfoFormData: {
                    ...chooseAmountInfo,
                },
            },
        });
    };

    return (
        <Layout contentAvoidHeader contentBg={bgImage}>
            <MetaSeo title={trans('meta_seo.history.title')} description={trans('meta_seo.history.description')} />
            <OrderCardLayoutStyled title={trans('credit.title')}>
                <CreditBalance />
                <ProductGroup
                    useLabelItem
                    labelGroup={trans('credit.credit_amount')}
                    prefix=""
                    choices={creditPriceChoices}
                    choiceSelected={chooseAmountInfo}
                    choiceType={'creditPriceChoices'}
                    onChoose={(choice) => handleProductChoose(choice)}
                />
                <PlainButton type={'submit'} color={'primary'} label={trans('credit.button.confirm')} onClick={submitOrder} />
            </OrderCardLayoutStyled>
        </Layout>
    );
};

export default Credit;
