import React, { ReactElement } from 'react';
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import split from "lodash/split";
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import { makeStyles } from "@mui/styles";
import isUndefined from "lodash/isUndefined";
import { useTrans } from "@core/Hook/trans";

export interface ImageType {
    src: string;
    alt?: string;
    width: string | number;
    height: string | number;
    classes?: string;
    extraClass?: string;
    webp?: boolean;
    lazy?: boolean;
    lazySrc?: string;
    isRemote?: boolean;
}

const useStyles = makeStyles({
    root: {
        '&.-img': {
            maxWidth: '100%',
            height: 'auto',
        },
    }
});

export const Asset = (path: string): string => {
    return `${process.env.PUBLIC_URL}/asset/images/${path}`
}

const mediaGetExt = (fixPath: string): string | undefined => {
    const pathSplit = split(fixPath, '.');

    return last(pathSplit);
}

const mediaConvertExt = (fixPath: string, expected: string): string => {
    const pathSplit =  split(fixPath, '.');

    return fixPath.replace(pathSplit[pathSplit.length - 1], expected);
}

export const Image = ({src, classes, alt, width, height, extraClass, isRemote, lazy, lazySrc, webp = true}: ImageType): ReactElement => {
    type imgSrcType = {src: string, 'data-src'?: string};
    const { trans } = useTrans();
    const _alt = isUndefined(alt) ? trans('general.alt_prefix') : alt;
    const classStyles = useStyles();
    const srcResolved = isRemote ? src : Asset(src);
    const initClass = `${classStyles.root} -img`;
    const placeholderImage = (lazySrc && Asset(lazySrc)) || `https://via.placeholder.com/${width}x${height}?text=...`;

    let srcAttrs: imgSrcType = {
        src: srcResolved,
    }

    let classResolved = !isEmpty(classes)
        ? classes
        : (!isEmpty(extraClass)
            ? `${initClass} ${extraClass}`
            : initClass)
    ;

    if (lazy) {
        classResolved += ' lazyload';
        srcAttrs = {
            src: placeholderImage,
            'data-src': srcResolved
        }
    }

    if (webp) {
        type pictureSrcType = {srcSet?: string, 'data-srcset'?: string}

        let defaultSrcAttrs: pictureSrcType = {
            srcSet: srcResolved
        }

        let webpSrcAttrs: pictureSrcType = {
            srcSet: mediaConvertExt(srcResolved, 'webp')
        }

        if (lazy) {
            defaultSrcAttrs = {'data-srcset': srcResolved}
            webpSrcAttrs = {'data-srcset': mediaConvertExt(srcResolved, 'webp')}
        }

        return (
            <picture>
                <source
                    {...webpSrcAttrs}
                    type="image/webp"
                />
                <source
                    {...defaultSrcAttrs}
                    type={`image/${mediaGetExt(srcResolved)}`}
                />
                <img
                    {...srcAttrs}
                    className={classResolved}
                    alt={_alt}
                    width={width}
                    height={height}
                />
            </picture>
        )
    }

    return <img
        {...srcAttrs}
        className={classResolved}
        alt={_alt}
        width={width}
        height={height}
    />;
}
