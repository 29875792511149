import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useResponsive } from "@core/Hook/responsiveHook";
import { useTrans } from "@core/Hook/trans";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";

interface IOrderHistoryTab {
    currentState: string;
}

const OrderHistoryTabStyled = styled(Stack)(({theme}) => ({
    '&.x-history-tab-container': {
        width: '100%',
        '.-btn-tab': {
            padding: theme.spacing(.75, 0),
            width: 'calc(25% - .25rem)',
            color: theme.baseVariables.history.tabColor,
            background: theme.baseVariables.history.tabBg,
            fontSize: theme.baseVariables.fonts.fontSizeSmaller,
            textAlign: 'center',
            lineHeight: 1.25,
            border: theme.baseVariables.history.tabBorder,
            borderRadius: 8,
            transition: 'filter .2s',
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(1.5, 0),
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
            },
            '&.-active, &:active': {
                color: theme.baseVariables.history.tabActiveColor,
                background: theme.baseVariables.history.tabActiveBg,
                border: theme.baseVariables.history.tabActiveBorder
            },
            '&:not(.-active)': {
                [theme.breakpoints.up('md')]: {
                    '&:hover': {
                        filter: 'brightness(.95)',
                    }
                }
            }
        }
    }
}))

const OrderHistoryTab: React.FC<IOrderHistoryTab> = ({ currentState }) => {
    const navigate = useNavigate();
    const { trans } = useTrans();
    const { isTabletOrMobile } = useResponsive();

    const buttons = [
        { code: 'pending', title: trans('history_state.pending.title'), title_mobile: trans('history_state.pending.title_mobile') },
        { code: 'process', title: trans('history_state.process.title'), title_mobile: trans('history_state.process.title_mobile') },
        { code: 'success', title: trans('history_state.success.title'), title_mobile: trans('history_state.success.title_mobile') },
        { code: 'cancel', title: trans('history_state.cancel.title'), title_mobile: trans('history_state.cancel.title_mobile') }
    ];

    return (
        <OrderHistoryTabStyled className={'x-history-tab-container'} direction={'row'} justifyContent={'space-between'}>
            {buttons.map((item, key) => {
                const active = item.code === currentState ? '-active' : '';

                return (
                    <Button
                        key={`history-tab-${key}-${item.code}`}
                        className={`-btn-tab ${active}`}
                        onClick={() => navigate(`/account/order-history/${item.code}`)}
                    >
                        {isTabletOrMobile ? item.title_mobile : item.title}
                    </Button>
                )
            })}
        </OrderHistoryTabStyled>
    )
}

export default OrderHistoryTab
