import React from "react";
import ServiceProductIcon, {IServiceProductIcon} from "./ServiceIcon";

const TikTokIcon: React.FC<IServiceProductIcon> = (props) => {
    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 10 10" fill="none">
                <g clipPath="url(#clip0_1338_7319)">
                    <path d="M9.12721 3.14746C8.61001 3.14746 8.13281 2.97612 7.7496 2.68708C7.31011 2.35573 6.99434 1.86968 6.88283 1.30947C6.85521 1.17106 6.84035 1.02822 6.83893 0.881836H5.36149V4.9189L5.35972 7.13018C5.35972 7.72137 4.97474 8.22264 4.44108 8.39893C4.28621 8.45009 4.11894 8.47434 3.94477 8.46478C3.72246 8.45256 3.51412 8.38548 3.33305 8.27716C2.94772 8.0467 2.68646 7.62862 2.67938 7.15036C2.66823 6.40288 3.27252 5.79346 4.01947 5.79346C4.16691 5.79346 4.30851 5.81754 4.44108 5.86126V4.75783V4.36116C4.30125 4.34045 4.15894 4.32966 4.01504 4.32966C3.19747 4.32966 2.43282 4.6695 1.88624 5.28175C1.47311 5.74443 1.22531 6.33474 1.18708 6.95371C1.13699 7.76686 1.43453 8.53983 2.01155 9.11013C2.09634 9.19385 2.18537 9.27155 2.27847 9.34324C2.77319 9.72397 3.37801 9.93035 4.01504 9.93035C4.15894 9.93035 4.30125 9.91973 4.44108 9.89902C5.03616 9.81088 5.58522 9.53847 6.01853 9.11013C6.55095 8.5839 6.84512 7.88527 6.84831 7.14169L6.8407 3.83954C7.0947 4.03548 7.37241 4.19761 7.67048 4.32346C8.13405 4.51905 8.62559 4.61817 9.13146 4.61799V3.54518V3.14711C9.13181 3.14746 9.12756 3.14746 9.12721 3.14746Z" fill="#FAFAFA"/>
                </g>
                <defs>
                    <clipPath id="clip0_1338_7319">
                        <rect width="9.0625" height="9.0625" fill="white" transform="translate(0.625 0.875)"/>
                    </clipPath>
                </defs>
            </svg>
        </ServiceProductIcon>
    )
}

export default TikTokIcon
