import React from 'react';
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Image } from "@core/Utils/image";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTrans } from "@core/Hook/trans";
import PlainButton from "../../Button/PlainButton";
import {ValidationStateProps} from "../RegisterEmailValidationModal";

interface IEmailValidationSuccess {
    onClick(): void;
    validationState: ValidationStateProps;
}

const EmailValidationSuccessStyled = styled(Stack)(({ theme }) => ({
    '&.x-email-validation-success': {
        '.-img': {
            width: 120,
        },
        '.-time': {
            fontSize: theme.baseVariables.fonts.fontSizeNormal,
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            textAlign: 'center',
            color: theme.palette.primary.main,
        },
        '.-btn': {
            minWidth: 220,
        }
    },
}));

const EmailValidationSuccess: React.FC<IEmailValidationSuccess> = ({ onClick, validationState }) => {
    const { trans } = useTrans();
    const isResetPasswordSuccess = 'complete-reset-password' === validationState;
    const icon = isResetPasswordSuccess ? 'complete-reset-password' : 'complete';
    const description = isResetPasswordSuccess ? trans('modal.email_validation.success_reset_password_description') : trans('modal.email_validation.success_verified_description');
    const textButton = isResetPasswordSuccess ? trans('modal.email_validation.success_reset_password_btn') : trans('modal.email_validation.success_verified_btn');

    return (
        <EmailValidationSuccessStyled className={'x-email-validation-success'}>
            <Box className={'-icon-wrapper'} textAlign={"center"}>
                <Image
                    src={`ic-email-validation-${icon}.png`}
                    width={60}
                    height={60}
                />
            </Box>
            <Box textAlign={"center"} marginTop={2}>
                <Typography variant={"subtitle2"} whiteSpace={"pre-wrap"}>
                    {description}
                </Typography>
            </Box>
            <Stack
                justifyContent={"center"}
                alignItems={"center"}
                marginTop={2}
                textAlign={"center"}
                spacing={2}
            >
                <PlainButton
                    color={'primary'}
                    extraClass={'-btn -submit'}
                    label={textButton}
                    onClick={onClick}
                />
            </Stack>
        </EmailValidationSuccessStyled>
    );
};

export default EmailValidationSuccess;
