import { AxiosResponse } from 'axios';
import ApiClient from '../Utils/api_client';

export const getOrderHistory = (page: number, limit: number, stateId: number): Promise<AxiosResponse> => {
    return ApiClient.get(`/purchase-order/loadPurchaseOrder/${page}`, {
        limit: limit,
        orderStatus: stateId,
    });
};

export const getOrderCreditHistory = (page: number, limit?: number): Promise<AxiosResponse> => {
    return ApiClient.get(`/purchase-order-credit/loadPurchaseOrder/${page}`, {
        limit: limit,
    });
};

export const getOrderByPurchaseOrderId = (purchaseOrderRefId: string): Promise<AxiosResponse> => {
    return ApiClient.get(`/purchase-order/loadPurchaseOrderById/${purchaseOrderRefId}`);
};

export const getOrderCreditByPurchaseOrderId = (purchaseOrderRefId: string): Promise<AxiosResponse> => {
    return ApiClient.get(`/purchase-order-credit/loadPurchaseOrderById/${purchaseOrderRefId}`);
};
