import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTrans } from '@core/Hook/trans';
import DefaultModal, { IDefaultModal } from '../../Component/Modal/DefaultModal';
import Typography from '@mui/material/Typography';
import { Image } from '@core/Utils/image';

const PaymentCreditFailModalStyled = styled(Box)(({ theme }) => ({
    '&.x-payment-credit-fail-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        height: '180px',
        whiteSpace: 'break-spaces',
        '.-image': {
            marginBottom: theme.spacing(2),
        },
        '.-description-text': {
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
        },
    },
}));

const PaymentCreditFailModal: React.FC<IDefaultModal> = ({ open, handleClose, ...rest }) => {
    const { trans } = useTrans();

    if (!open) return <></>;

    return (
        <DefaultModal
            {...rest}
            isHalfSize
            showCloseIcon
            open={open}
            title={trans('order_summary.payment_credit_fail_modal.title')}
            titleColor={'primary'}
            handleClose={handleClose}
        >
            <PaymentCreditFailModalStyled className={'x-payment-credit-fail-container'}>
                <Image src={'ic-alert-failed.png'} extraClass={'-image'} width={80} height={80} />
                <Typography variant={'body1'} className={'-description-text'}>
                    {trans('order_summary.payment_credit_fail_modal.description')}
                </Typography>
            </PaymentCreditFailModalStyled>
        </DefaultModal>
    );
};

export default PaymentCreditFailModal;
