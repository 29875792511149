import React, { memo, ReactElement, ReactNode } from "react"
import { Button } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import { useTrans } from "@core/Hook/trans";

export interface IBasicButton extends ButtonProps {
    value?: string | number;
    label?: string | ReactNode;
    labelElement?: ReactElement | boolean;
    isLoading?: boolean;
    loadingText?: string;
}

export const renderLoading = (loadingText: string) => {
    return (
        <div className={`-ic-loading-wrapper`}>
            <i className="fas fa-circle-notch fa-spin" /> {loadingText}
        </div>
    )
};

const BasicButton = (props: IBasicButton) => {
    const {labelElement, label, disabled, isLoading, className, loadingText, ...buttonProps} = props;
    const {trans} = useTrans();
    const isDisabled = disabled || isLoading;
    let classNameResolved = className;

    if (isDisabled) {
        classNameResolved += ' -disabled';
    }

    return (
        <Button
            {...buttonProps}
            onClick={props.onClick}
            className={classNameResolved}
            disabled={disabled || isLoading}
        >
            {isLoading && renderLoading(loadingText || trans('button.loading'))}
            {!isLoading && (labelElement ? labelElement : label)}
        </Button>
    )
}

export default  memo(BasicButton);
