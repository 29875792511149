import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const ThaiUserIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 27 18" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.744354H26.25V3.74985H0.75V0.744354ZM0.75 6.74985V11.2498H26.25V6.74985H0.75ZM0.75 14.2498H26.25V17.2556H0.75V14.2498Z" fill={color}/>
            </svg>
        </ServiceProductIcon>
    )
}

export default ThaiUserIcon
