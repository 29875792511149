import React from 'react';
import { useTrans } from '@core/Hook/trans';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { matchPath, useLocation } from 'react-router-dom';
import { IOrder, IOrderSummary } from '../../Recoil/Order/atom';
import useOrderHook from '../../Hook/orderHook';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { Skeleton } from '@mui/material';
import { OrderTypeEnum } from '../../Constants/order';
import { NumericFormat } from 'react-number-format';

interface IOrderDetailContent {
    order: IOrder | IOrderSummary;
    orderType: string;
    isLoading?: boolean;
}

const OrderDetailContentStyled = styled(Box)(({ theme }) => ({
    '&.x-order-detail-content': {
        '&.-spacer': {
            marginTop: theme.spacing(2),
        },
        '.-heading-title': {
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            color: '#34406b',
        },
        '.-row-content': {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginTop: theme.spacing(0.75),
            '.-column-left': {
                display: 'flex',
                justifyContent: 'space-between',
                width: '30%',
                minWidth: 100,
            },
            '.-column-right': {
                flex: 1,
                paddingLeft: theme.spacing(2),
                wordBreak: 'break-all',
            },
        },
        '.-label, .-text, .-description': {
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
        },
        '.-label': {
            color: theme.baseVariables.orderDetail.labelColor,
        },
        '.-text, .-description': {
            color: theme.baseVariables.orderDetail.textColor,
        },
    },
}));

const OrderDetailContent: React.FC<IOrderDetailContent> = ({ order, orderType, isLoading }) => {
    const { trans } = useTrans();
    const location = useLocation();
    const { getOrderDescription, getOrderNationalityTargetLabelByCode, getProductOptionsTranslation } = useOrderHook();

    const orderProduct = order.purchaseOrderProduct;
    const orderProgress = order.purchaseOrderProgress;
    const orderReceipt = order.purchaseOrderReceipt;
    const orderOption = !isUndefined(orderProgress?.serviceOption)
        ? orderProgress?.serviceOption
        : getProductOptionsTranslation(order as IOrder);

    let data = [
        { label: trans('order.payment_method'), value: orderReceipt?.paymentMethod ? trans(`order_detail.payment_method.${orderReceipt?.paymentMethod}`) : ''},
        { label: trans('order.platform'), value: orderProduct.platformName },
        { label: trans('order.type'), value: orderProduct.serviceName },
        { label: trans('order.group'), value: getOrderNationalityTargetLabelByCode(orderProduct.accountType) },
        { label: trans('order.packet'), value: getOrderDescription(order) },
        { label: trans('order.option'), value: orderOption },
        { label: trans('order.name'), value: orderProgress?.socialAccountName },
        { label: trans('order.link'), value: orderProgress?.links?.join('\n') || orderProgress?.link },
    ];

    if (orderType === OrderTypeEnum.CREDIT) {
        data = [
            {
                label: trans('credit.amount'),
                value: (
                    <NumericFormat
                        thousandSeparator
                        fixedDecimalScale
                        decimalScale={2}
                        value={orderProduct.amount}
                        displayType={'text'}
                    />
                ),
            },
            {
                label: trans('credit.price'),
                value: (
                    <NumericFormat
                        thousandSeparator
                        fixedDecimalScale
                        decimalScale={2}
                        prefix="฿"
                        value={orderProduct.price}
                        displayType={'text'}
                    />
                ),
            },
        ];
    }

    const isOrderDetailPage = matchPath('/account/order-detail/:orderId', location.pathname);

    return (
        <OrderDetailContentStyled className={`x-order-detail-content ${isOrderDetailPage ? '' : '-spacer'}`}>
            <Typography component={'strong'} className={'-heading-title'}>
                {trans('order.detail')}
            </Typography>
            {data
                .filter((row) => !isEmpty(row.value))
                .map((row, key) => {
                    return (
                        <Box key={`detail-content-${key}`} className={'-row-content'}>
                            <Box className={'-column-left'}>
                                <Typography className={'-label'}>{row.label}</Typography>
                                <Typography className={'-label'}>{':'}</Typography>
                            </Box>
                            <Box className={'-column-right'}>
                                {isLoading
                                    ? <Skeleton height={21} variant="rounded" />
                                    : <Typography className={'-text'}>{row.value}</Typography>
                                }
                            </Box>
                        </Box>
                    );
                })}
        </OrderDetailContentStyled>
    );
};

export default OrderDetailContent;
