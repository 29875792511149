import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTrans } from '@core/Hook/trans';
import { useResponsive } from '@core/Hook/responsiveHook';

interface IStateTitle {
    state: string;
    isIndexPage?: boolean;
}

const StateTitleStyled = styled(Typography)(({ theme }) => ({
    '&.x-state-title-container': {
        fontSize: theme.baseVariables.fonts.fontSizeSmaller,
        fontWeight: theme.baseVariables.fonts.fontWeightMedium,
        textWrap: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
        },
        '&.-pending': {
            color: theme.baseVariables.stateTitle.statePendingColor,
        },
        '&.-process': {
            color: theme.baseVariables.stateTitle.stateProcessColor,
        },
        '&.-success': {
            color: theme.baseVariables.stateTitle.stateSuccessColor,
        },
        '&.-cancel': {
            color: theme.baseVariables.stateTitle.stateCancelColor,
        },
        '&.-customerStrictInfo': {
            color: theme.baseVariables.stateTitle.stateCustomerStrictInfoColor,
        },
        '.fas': {
            marginRight: theme.spacing(0.5),
        },
    },
}));

const StateTitle: React.FC<IStateTitle> = ({ state, isIndexPage = true }) => {
    const { trans } = useTrans();
    const { isTabletOrMobile } = useResponsive();

    const detailState = {
        pending: isTabletOrMobile ? trans('history_state.pending.detail_state_mobile') : trans('history_state.pending.detail_state'),
        process: isTabletOrMobile ? trans('history_state.process.detail_state_mobile') : trans('history_state.process.detail_state'),
        success: isTabletOrMobile ? trans('history_state.success.detail_state_mobile') : trans('history_state.success.detail_state'),
        cancel: isTabletOrMobile ? trans('history_state.cancel.detail_state_mobile') : trans('history_state.cancel.detail_state'),
        customerStrictInfo: isTabletOrMobile
            ? trans('history_state.customer-strict-info.detail_state_mobile')
            : trans('history_state.customer-strict-info.detail_state'),
    };

    const indexState = {
        pending: isTabletOrMobile ? trans('history_state.pending.title_mobile') : trans('history_state.pending.title'),
        process: isTabletOrMobile ? trans('history_state.process.title_mobile') : trans('history_state.process.title'),
        success: isTabletOrMobile ? trans('history_state.success.title_mobile') : trans('history_state.success.title'),
        cancel: isTabletOrMobile ? trans('history_state.cancel.title_mobile') : trans('history_state.cancel.title'),
        customerStrictInfo: isTabletOrMobile
            ? trans('history_state.customer-strict-info.detail_state_mobile')
            : trans('history_state.customer-strict-info.detail_state'),
    };

    const stateTitle = isIndexPage ? indexState : detailState;

    return (
        <StateTitleStyled className={`x-state-title-container -${state}`}>
            {'process' === state && <i className="fas fa-spinner fa-spin" />}
            {stateTitle[state]}
        </StateTitleStyled>
    );
};

export default StateTitle;
