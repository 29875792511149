import React from "react";
import ServiceProductIcon, {IServiceProductIcon} from "./ServiceIcon";

const FacebookIcon: React.FC<IServiceProductIcon> = (props) => {
    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 5 10" fill="none">
                <path d="M4.64043 5.81949L4.88977 4.23527H3.35383V3.20553C3.35383 2.77234 3.56826 2.34906 4.25395 2.34906H4.96208V0.999995C4.5497 0.934235 4.13301 0.898659 3.71537 0.893555C2.45122 0.893555 1.6259 1.65349 1.6259 3.0273V4.23527H0.224609V5.81949H1.6259V9.65133H3.35383V5.81949H4.64043Z" fill="#FAFAFA"/>
            </svg>
        </ServiceProductIcon>
    )
}

export default FacebookIcon
