import Pusher, {Channel} from "pusher-js";

const pusher = new Pusher(process.env['REACT_APP_PUSHER_AUTH_KEY'] as string, {
    cluster: 'ap1',
});
const channel = pusher.subscribe('default');

export interface ChannelWrapper {
    bind: (event: string, callback: (data?: any) => void, name: string, onReconnect?: () => void) => void,
    unbind: (name: string) => void,
}

export const wrappedChannelFactory = (p: Pusher, c: Channel): ChannelWrapper => {
    const wrappedChannel = {
        initializedConnection: false,
        callbacks: {},
        bind (event: string, callback: (data?: any) => void, name: string, onReconnect?: () => void) {
            this.callbacks[name] = {
                event,
                callback,
                onReconnect,
            };
        },
        unbind (name: string) {
            delete this.callbacks[name];
        },
        invoke() {
            p.connection.bind("state_change", (states: any) => {
                if ('connected' !== states.current) {
                    return;
                }

                if (!this.initializedConnection) {
                    this.initializedConnection = true;

                    return;
                }

                // on reconnect
                console.log('Welcome back..');
                for (const key in this.callbacks) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!this.callbacks.hasOwnProperty(key)) {
                        continue;
                    }

                    if (!this.callbacks[key]?.onReconnect) return;

                    this.callbacks[key]?.onReconnect.call(null);
                }
            });

            c.bind_global( (e: string, data: any) => {
                for (const key in this.callbacks) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!this.callbacks.hasOwnProperty(key)) {
                        continue;
                    }

                    const {event, callback} = this.callbacks[key];

                    if (event !== e) {
                        continue;
                    }

                    callback.call(null, data);
                }
            });
        }
    };

    wrappedChannel.invoke();

    return wrappedChannel;
}


export default wrappedChannelFactory(pusher, channel);
