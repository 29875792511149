import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { parse } from 'qs';
import trim from 'lodash/trim';
import { handleResponseFormError, resolveClientApiState, useClientApiState } from '@core/Hook/api';
import { useTrans } from '@core/Hook/trans';
import { Asset } from '@core/Utils/image';
import { useResponsive } from '@core/Hook/responsiveHook';
import CreditHistoryList from './CreditHistory/CreditHistoryList';
import OrderCardLayout from '../Component/Order/OrderCardLayout';
import Layout from '../Component/Common/Layout';
import MetaSeo from '../Component/Common/MetaSeo';
import BasicPagination, { IApiPagination, usePagination } from '../Component/BasicPagination';
import { IOrder } from '../Recoil/Order/atom';
import useOrderHook from '../Hook/orderHook';
import isUndefined from 'lodash/isUndefined';
import { useAlert } from '../Hook/alertHook';
import { getOrderCreditHistory } from '../Api/order';
import LoadingList from '../Component/Loading/LoadingList';

const CreditHistory: React.FC = () => {
    const location = useLocation();
    const qs = parse(trim(location.search, '?'));
    const page = parseInt((qs?.page as string) || '1');
    const limit = 8;
    const { trans } = useTrans();
    const { isTabletOrMobile } = useResponsive();
    const [apiState, setApiState] = useClientApiState({ isLoading: false });
    const { orderPaginate } = useOrderHook();
    const { pagination, shouldRender } = usePagination<IOrder>(orderPaginate as IApiPagination);
    const { renderStateAlert } = useAlert();
    const [searchParams, setSearchParams] = useSearchParams();
    const [creditHistory, setCreditHistory] = useState<IOrder[]>([]);

    const bgImage = isTabletOrMobile ? Asset('wow-boost-history-purchase-bg-mobile.jpg') : Asset('wow-boost-history-purchase-bg.jpg');

    const getCreditHistory = async () => {
        setApiState({ ...apiState, isLoading: true });
        const response = resolveClientApiState(await getOrderCreditHistory(page), true);
        if (response.isOk) {
            setApiState({ ...apiState, isLoading: false });
            setCreditHistory(response.data.items);
            return;
        }
        handleResponseFormError(response);
        setApiState({ ...apiState, isLoading: false });
    };

    useEffect(() => {
        getCreditHistory();
    }, []);

    return (
        <Layout contentAvoidHeader contentBg={bgImage}>
            <MetaSeo title={trans('meta_seo.history.title')} description={trans('meta_seo.history.description')} />
            <OrderCardLayout title={trans('credit.credit_history_title')}>
                {apiState.isLoading && <LoadingList amount={limit} />}
                {!apiState.isLoading && (
                    <>
                        <CreditHistoryList lists={creditHistory} />
                        {shouldRender && <BasicPagination {...pagination} />}
                        {(!isUndefined(location.state?.isPaymentSuccess) || !isUndefined(qs.noti)) &&
                            renderStateAlert(searchParams, setSearchParams)}
                    </>
                )}
            </OrderCardLayout>
        </Layout>
    );
};

export default CreditHistory;
