export const useManageLocalStorage = () => {
    const setToLocalStorage = (key: string, value: string) => window.localStorage.setItem(key, value)
    const getItemLocalStorage = (key: string) => window.localStorage.getItem(key)
    const removeItemLocalStorage = (key: string) => window.localStorage.removeItem(key)

    return {
        setToLocalStorage,
        getItemLocalStorage,
        removeItemLocalStorage,
    }
}
