import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

interface IFormInputPasscode {
    value: string;
    onChange(val: string): void;
    passwordType?: boolean;
    digit?: number;
    validationPattern?: RegExp;
    isOtp?: boolean;
}

const FormInputPasscodeStyled = styled(Box)(({ theme }) => ({
    '&.x-passcode': {
        '.MuiTextField-root': {
            width: 'calc((100% / 6) - .5rem)',
        },
        '.MuiOutlinedInput-root': {
            borderRadius: 10,
            '.MuiOutlinedInput-input': {
                textAlign: 'center',
                fontSize: theme.baseVariables.fonts.fontSizeBig,
                fontWeight: theme.baseVariables.fonts.fontWeightBold,
            },
        },
        '&.-is-otp-input': {
            '.MuiOutlinedInput-root': {
                borderRadius: 0,
                background: 'transparent',
                '&.Mui-focused': {
                    '.MuiOutlinedInput-notchedOutline': {
                        borderWidth: '2px',
                    }
                }
            },
            '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
                borderBottom: `2px solid ${theme.baseVariables.input.borderColor}`,
            },
        }
    }
}))

const FormInputPasscode: React.FC<IFormInputPasscode> = ({
    digit = 6,
    value,
    onChange,
    passwordType,
    validationPattern= /[0-9]{1}/,
    isOtp = false
}) => {
    const inputRefArray = useRef<any>([]);
    const isOtpClass = isOtp ? '-is-otp-input' : '';

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const val = e.target.value;

        if (!validationPattern.test(val) && val !== "") return;

        const valueArr = value.split("");
        valueArr[index] = val;

        const newVal = valueArr.join("").slice(0, digit);
        onChange(newVal);

        if (!val) return;

        inputRefArray.current[index + 1]?.focus();
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
        if (e.key === "ArrowLeft" || e.key === "Backspace") {
            inputRefArray.current[index - 1]?.focus();
            inputRefArray.current[index - 1]?.setSelectionRange(0, 1);

            return;
        }

        if (e.key === "ArrowRight") {
            inputRefArray.current[index + 1]?.focus();
            inputRefArray.current[index + 1]?.setSelectionRange(0, 1);

            return;
        }
    };

    return (
        <FormInputPasscodeStyled className={`x-passcode ${isOtpClass}`}>
            <Stack direction={'row'} spacing={1} justifyContent={'center'}>
                {Array.from(Array(digit), (val, index) => {
                    return (
                        <TextField
                            key={`passcode-index-${index}`}
                            type={passwordType ? "password" : "text"}
                            value={value.at(index) ?? ""}
                            autoFocus={index === 0}
                            onFocus={e => e.target.select()}
                            onChange={(e) => handleInputChange(e, index)}
                            onKeyUp={(e) => handleKeyUp(e, index)}
                            inputRef={ref => inputRefArray.current[index] = ref}
                            inputProps={{
                                inputMode: 'numeric',
                                autoComplete: "one-time-code",
                                maxLength: digit,
                                // pattern: validationPattern,
                            }}
                        />
                    )
                })}
            </Stack>
        </FormInputPasscodeStyled>
    )
}

export default FormInputPasscode
