import { useRecoilState, useRecoilValue } from 'recoil';
import { IProductInfo, ProductChooseInfo } from '../Recoil/Product/atom';

import { InitialProductInfoByPlatformCodeSelector } from '../Recoil/Product/selector';
import isUndefined from 'lodash/isUndefined';
import { mapClientProductCodeToServerProductCode, mapServerProductCodeToClientProductCode } from '../Constants/product';
import { useTrans } from '@core/Hook/trans';
import { IServiceProductChoice } from 'Page/Product/ProductGroup';

export const useManageProductInfo = () => {
    const [productChooseInfo, setProductChooseInfo] = useRecoilState(ProductChooseInfo);
    const initialProduct = useRecoilValue(
        InitialProductInfoByPlatformCodeSelector(isUndefined(productChooseInfo.platform) ? undefined : productChooseInfo.platform?.code),
    );

    const splitProductItemFullCode = (productItemFullCode: string): { [p: string]: string } | undefined => {
        const productInfoKey = ['platformCode', 'serviceCode', 'targetCode', 'packageCode'];
        const serverProductItemFullCodeArr: Array<string> = (productItemFullCode as string).split('_');
        if (serverProductItemFullCodeArr.length !== 4) return undefined;

        return productInfoKey.reduce(
            (a, key, index) => Object.assign(a, { [key]: mapServerProductCodeToClientProductCode(serverProductItemFullCodeArr[index]) }),
            {},
        );
    };

    const generateServerProductItemFullCode = (): string => {
        const platformCode = mapClientProductCodeToServerProductCode(productChooseInfo.platform?.code as string);
        const service = mapClientProductCodeToServerProductCode(productChooseInfo.service?.code as string);
        const target = mapClientProductCodeToServerProductCode(productChooseInfo.target?.code as string);
        const packageCode = productChooseInfo.packageInfo?.code as string;

        return [platformCode, service, target, packageCode].join('_');
    };

    const initialProductInfo = (initProduct?: IProductInfo | undefined) => {
        const initialProductInfo = isUndefined(initProduct)
            ? {
                  platform: initialProduct.platform,
                  service: initialProduct.service,
                  target: initialProduct.target,
                  packageInfo: initialProduct.packageInfo,
                  serviceOptionType: undefined,
                  serviceOptionTypeValue: undefined,
              }
            : initProduct;

        setProductChooseInfo(initialProductInfo);
    };

    const initialProductInfoByPlatform = (platformChoice: IServiceProductChoice) => {
        const initialProductInfo = {
            platform: platformChoice,
            service: initialProduct.service,
            target: initialProduct.target,
            packageInfo: initialProduct.packageInfo,
            serviceOptionType: undefined,
            serviceOptionTypeValue: undefined,
        };

        setProductChooseInfo(initialProductInfo);
    };

    const saveProductPlatform = (platformChoice: IServiceProductChoice) => {
        initialProductInfoByPlatform(platformChoice);
    };

    const saveProductService = (serviceChoice: IServiceProductChoice) => {
        setProductChooseInfo((prev: IProductInfo) => {
            return {
                ...prev,
                service: serviceChoice,
            };
        });
    };

    const saveProductTarget = (targetChoice: IServiceProductChoice) => {
        setProductChooseInfo((prev: IProductInfo) => {
            return {
                ...prev,
                target: targetChoice,
            };
        });
    };

    const saveProductPackage = (packageChoice: IServiceProductChoice) => {
        setProductChooseInfo((prev: IProductInfo) => {
            return {
                ...prev,
                packageInfo: packageChoice,
            };
        });
    };

    const saveProductServiceOptionType = (serviceOptionTypeChoice: IServiceProductChoice) => {
        setProductChooseInfo((prev: IProductInfo) => {
            return {
                ...prev,
                serviceOptionType: serviceOptionTypeChoice,
            };
        });
    };

    const saveProductServiceOptionTypeValue = (serviceOptionTypeValueChoice: IServiceProductChoice) => {
        setProductChooseInfo((prev: IProductInfo) => {
            return {
                ...prev,
                serviceOptionTypeValue: serviceOptionTypeValueChoice,
            };
        });
    };

    const saveProductAccountSocialName = (socialName: string) => {
        setProductChooseInfo((prev: IProductInfo) => {
            return {
                ...prev,
                accountSocialName: socialName,
            };
        });
    };

    const saveProductAccountSocialLink = (socialLink: string) => {
        setProductChooseInfo((prev: IProductInfo) => {
            return {
                ...prev,
                accountSocialLink: socialLink,
            };
        });
    };

    const clearProductInfoByKey = (key: string) => {
        const newProductInfo = {
            ...productChooseInfo,
            [key]: undefined,
        };

        setProductChooseInfo(newProductInfo);
    };

    const clearProductInfo = () => {
        const initProductInfo = {
            platform: undefined,
            service: undefined,
            target: undefined,
            packageInfo: undefined,
            serviceOptionType: undefined,
            serviceOptionTypeValue: undefined,
        };

        setProductChooseInfo(initProductInfo);
    };

    return {
        productChooseInfo,
        saveProductPlatform,
        saveProductService,
        saveProductTarget,
        saveProductPackage,
        saveProductServiceOptionType,
        saveProductServiceOptionTypeValue,
        saveProductAccountSocialName,
        saveProductAccountSocialLink,
        clearProductInfo,
        clearProductInfoByKey,
        initialProductInfo,
        generateServerProductItemFullCode,
        splitProductItemFullCode,
    };
};

export const useProductTranslation = () => {
    const { trans } = useTrans();

    const platformLabelByCode = (platformCode: string) => {
        return trans(`service.product.platform.${platformCode}`);
    };

    const serviceLabelByCode = (serviceCode: string) => {
        return trans(`service.product.service.${serviceCode}`);
    };

    const targetAccountLabelByCode = (accountCode: string) => {
        return trans(`service.product.target.${accountCode}`);
    };

    const packageUnitLabelByCode = (serviceCode: string) => {
        return trans(`service.product.service.unit.${serviceCode}`);
    };

    return {
        platformLabelByCode,
        serviceLabelByCode,
        targetAccountLabelByCode,
        packageUnitLabelByCode,
    };
};
