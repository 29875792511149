import React, { useEffect, useRef } from "react";
import { CountUp } from "countup.js";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { NumericFormat } from "react-number-format";

interface ICountingUp {
    number: number;
    extraClass?: string;
}

const CountingUpStyled = styled(NumericFormat)(() => ({
    '&.x-counting-up': {}
}))

const CountingUp: React.FC<ICountingUp> = ({ extraClass , number}) => {
    let countUpAnim;
    const countUpRef = useRef<any>(null)

    async function initCountUp() {
        countUpAnim = new CountUp(countUpRef?.current, number, {
            duration: 3,
            useEasing: true,
            smartEasingThreshold: 5000
        });

        if (!countUpAnim.error) {
            countUpAnim.start();
        } else {
            console.error(countUpAnim.error);
        }
    }

    useEffect(() => {
        initCountUp()
    }, [])

    return (
        <CountingUpStyled
            thousandSeparator
            getInputRef={countUpRef}
            value={number}
            displayType={'text'}
            className={`x-counting-up ${extraClass}`}
        />
    )
}

export default CountingUp
