import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useTrans } from "@core/Hook/trans";
import ModalTypography from "../Partial/ModalTypography";
import FormControlWrapper from "@core/Form/FormControlWrapper";
import TextWithLink from "../../TextWithLink";
import FormInputPasscode from "@core/Form/FormInputPasscode";
import FadeInUp from "../../../Animate/FadeInUp";
import InputPasscodeForm, {IInputPassCodeData} from "../Form/InputPasscodeForm";

interface ILoginStepPassword {
    onSubmit: (data: IInputPassCodeData) => void;
    onClickForgetPassword?(): void;
    isLoading?: boolean;
}

const LoginStepPasswordStyled = styled(Stack)(({ theme }) => ({
    '&.x-login-step-password': {
        '.-icon-wrapper': {
            margin: theme.spacing(2, 0),
            textAlign: 'center',
            '.-img': {
                display: 'inline-block',
                width: 100,
            }
        },
        '.-description-wrapper': {
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(3),
            }
        },
        '.-form-wrapper': {
            margin: theme.spacing(3.5, 0, 1),
        },
        '.-text-with-link-wrapper': {
            marginTop: theme.spacing(3),
        }
    }
}))

const LoginStepPassword: React.FC<ILoginStepPassword> = ({ onSubmit, onClickForgetPassword, isLoading }) => {
    const { trans } = useTrans();
    const [payload, setPayload] = useState('')
    const submitPassword = (data: IInputPassCodeData) => onSubmit(data);

    return (
        <LoginStepPasswordStyled className={'x-login-step-password'}>
            <FadeInUp>
                <Box className={'-description-wrapper'}>
                    <ModalTypography
                        renderType={'title'}
                        align={'center'}
                    >
                        {trans('modal.login_password.enter_password')}
                    </ModalTypography>
                </Box>
                <Box className={'-form-wrapper'}>
                    <InputPasscodeForm
                        inputName={'password'}
                        isLoading={isLoading}
                        labelSubmit={trans('modal.login_password.submit_password')}
                        payload={payload}
                        onSubmit={(data) => submitPassword(data)}
                    >
                        <FormControlWrapper
                            required={true}
                            className={'-form-control-wrapper'}
                        >
                            <FormInputPasscode
                                passwordType
                                value={payload}
                                onChange={(val) => setPayload(val)}
                            />
                        </FormControlWrapper>
                    </InputPasscodeForm>
                </Box>
                <Box className={'-text-with-link-wrapper'}>
                    <TextWithLink
                        linkTitle={trans('modal.login.forget_password')}
                        linkOnClick={onClickForgetPassword}
                    />
                </Box>
            </FadeInUp>
        </LoginStepPasswordStyled>
    )
}

export default LoginStepPassword
