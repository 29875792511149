import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useTrans } from "@core/Hook/trans";
import Title from "../../Component/Title";
import {List, ListItem} from "@mui/material";

const PolicyRefundStyled = styled(Box)(({theme}) => ({
    '&.x-policy-refund-container': {
        '.-list-wrapper': {
            listStyleType: 'decimal',
            listStylePosition: 'outside',
            padding: theme.spacing(0, 0, 0, 1.75),
            '.-list-item-wrapper': {
                display: 'list-item',
                '&::marker': {
                    color: theme.baseVariables.colors.textPrimary,
                    fontSize: theme.baseVariables.fonts.fontSizeSmall,
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.baseVariables.fonts.fontSizeNormal,
                    },
                }
            }
        }
    }
}))

const PolicyRefund: React.FC = () => {
    const { trans } = useTrans();

    return (
        <PolicyRefundStyled className="x-policy-refund-container">
            <List className={'-list-wrapper'}>
                {Array.from(Array(5)).map((i, index) => {
                    return (
                        <ListItem
                            dense
                            disablePadding
                            className={'-list-item-wrapper'}
                            key={`policy_refund_no_${index}`}
                        >
                            <Title
                                titlePosition={'left'}
                                subTitlePosition={'justify'}
                                subTitle={trans(`policy.refund.refund_of_service.no_${index + 1}`)}
                                extraClass={'-in-policy'}
                            />
                        </ListItem>
                    )
                })}
            </List>
        </PolicyRefundStyled>
    )
}

export default PolicyRefund
