import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { capitalize } from "lodash";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";

interface ISelectOption {
    value: string | number;
    label: string;
}

export interface IFormSelect {
    name: string;
    control: any;
    options: ISelectOption[];
    isDisabled?: boolean;
    isHideLabel?: boolean;
    extraClass?: string;
    defaultValue?: string | number;
    label?: string;
    value?: string;
    required?: boolean;
    placeholder?: string;
}

const selectStyles = {
    control: (base: any) => ({
        ...base,
        height: 44,
        color: '#34406b',
        background: '#ffffff',
        fontSize: '.9375rem',
        borderColor: '#a1b8db',
        borderRadius: 30
    }),
    option: (base: any, state: any) => ({
        ...base,
        backgroundColor: state.isSelected ? '#5a80ee' : '#ffffff'
    }),
    valueContainer: (base: any) => ({
        ...base,
        padding: '0 14px'
    }),
    clearIndicator: (base: any) => ({
        ...base,
        display: 'none'
    }),
    indicatorSeparator: (base: any) => ({
        ...base,
        display: 'none'
    }),
    input: (base: any) => ({
        ...base,
        color: '#34406b'
    }),
    placeholder: (base: any) => ({
        ...base,
        color: '#a1b8db'
    }),
    menuPortal: (base: any) => ({
        ...base, zIndex: 9999
    })
}

const FormSelect: React.FC<IFormSelect> = ({
    control,
    label,
    name,
    options,
    required,
    isDisabled,
    isHideLabel,
    extraClass,
    defaultValue,
    placeholder,
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({
                field: { value, onChange, ...field  },
                fieldState: { error }
            }) => {
                return (
                    <FormControl
                        fullWidth
                        className={extraClass}
                        required={required}
                        error={!!error}
                    >
                        {!isHideLabel && (
                            <FormLabel sx={{marginBottom: '.25rem', fontSize: '.9375rem'}}>
                                {label || capitalize(name)}
                            </FormLabel>
                        )}
                        <Select
                            isClearable
                            options={options}
                            isDisabled={isDisabled}
                            styles={selectStyles}
                            placeholder={placeholder}
                            menuPortalTarget={document.body}
                            onChange={(val) => onChange(val?.value)}
                            value={options?.find((o) => o.value === value)}
                            {...field}
                        />
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                )
            }}
        />
    )
}

export default FormSelect
