import { useTrans } from '@core/Hook/trans';
import { IOrder, IOrderSummary, OrderHistoryAtom, OrderSingleAtom } from '../Recoil/Order/atom';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { IApiState, resolveClientApiState } from '@core/Hook/api';
import { getOrderCreditHistory, getOrderHistory } from '../Api/order';
import { useRecoilState, useRecoilValue } from 'recoil';
import find from 'lodash/find';
import { mapServerProductCodeToClientProductCode, TARGET_FOREIGN_USER, TARGET_NONE_USER, TARGET_THAI_USER } from '../Constants/product';
import isEmpty from 'lodash/isEmpty';
import { IApiPagination } from '../Component/BasicPagination';
import { useManageProductInfo, useProductTranslation } from './product';
import { hasDecimal } from '../Utils/helper';

const useOrderHook = () => {
    const { trans } = useTrans();
    const [orderHistory, setOrderHistory] = useRecoilState(OrderHistoryAtom);
    const [orderPaginate, setOrderPaginate] = useState<IApiPagination>();
    const orderSingle = useRecoilValue(OrderSingleAtom);
    const { splitProductItemFullCode } = useManageProductInfo();
    const { packageUnitLabelByCode } = useProductTranslation();

    const getProductOptionsTranslation = (order: IOrder): string => {
        const purchaseOrderProgress = order?.purchaseOrderProgress;
        const optionCode = mapServerProductCodeToClientProductCode(purchaseOrderProgress?.metadata?.selectCode as string);
        const optionValueCode = mapServerProductCodeToClientProductCode(purchaseOrderProgress?.metadata?.data as string);
        const serviceOptionLabel = !isEmpty(optionCode) ? trans(`service.product.service_option_type.${optionCode}`) : '';
        const serviceOptionValueLabel = !isEmpty(optionValueCode)
            ? trans(`service.product.service_option_type_value.${optionValueCode}`)
            : '';

        return !isEmpty(optionCode) ? `${serviceOptionLabel} ${serviceOptionValueLabel}` : '';
    };

    const useFetchOrders = (apiState: IApiState, setApiState: (...args: any[]) => void) => {
        const fetchOrders = async (page: number, limit: number, orderStateCode: number) => {
            setApiState({ ...apiState, isLoading: true });

            // Paginate
            const res = resolveClientApiState(await getOrderHistory(page, limit, orderStateCode), true, {
                paginateKey: '',
                itemKey: 'items',
            });

            setOrderHistory(res.data.items);
            setOrderPaginate(res.paginate as IApiPagination);

            setApiState({ ...apiState, isLoading: false });
        };

        return {
            fetchOrders,
        };
    };

    const findOrderFromState = (orderId: string) => {
        return find(orderHistory, { purchaseOrderRefId: orderId }) as IOrder | undefined;
    };

    const getOrderDescription = (order: IOrder | IOrderSummary) => {
        const orderProduct = order.purchaseOrderProduct;
        const productInfoObject: { [p: string]: string } | undefined = splitProductItemFullCode(orderProduct.productItemFullCode as string);
        const isDecimalPrice = hasDecimal(orderProduct.price);

        return (
            <>
                <NumericFormat displayType={'text'} thousandSeparator value={orderProduct.amount} />
                <Typography component={'span'} className={'-description'}>{` ${packageUnitLabelByCode(
                    productInfoObject?.serviceCode as string,
                )} `}</Typography>
                <NumericFormat
                    thousandSeparator
                    displayType={'text'}
                    value={orderProduct.price}
                    decimalScale={2}
                    fixedDecimalScale={isDecimalPrice}
                />
                <Typography component={'span'} className={'-description'}>{` ${trans('currency.thb')} `}</Typography>
            </>
        );
    };

    const getOrderNationalityTargetLabelByCode = (code: number) => {
        switch (code) {
            case 0:
                return trans('order_nationality.none');
            case 1:
                return trans('order_nationality.thai');
            case 2:
                return trans('order_nationality.national');
            default:
                return 'unknown';
        }
    };

    const getOrderNationalityTargetLabelByClientTargetCode = (targetCode: string): number => {
        switch (targetCode) {
            case TARGET_NONE_USER:
                return 0;
            case TARGET_THAI_USER:
                return 1;
            case TARGET_FOREIGN_USER:
                return 2;
            default:
                return 0;
        }
    };

    return {
        orderSingle,
        orderHistory,
        useFetchOrders,
        findOrderFromState,
        getOrderDescription,
        getOrderNationalityTargetLabelByCode,
        getOrderNationalityTargetLabelByClientTargetCode,
        getProductOptionsTranslation,
        orderPaginate,
    };
};

export default useOrderHook;
