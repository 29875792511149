import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const BoostViewIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 31 30" fill="none">
                <g clipPath="url(#clip0_1338_2146)">
                    <path d="M30.401 14.4138C30.1333 14.1151 23.6923 7.08984 15.625 7.08984C7.55772 7.08984 1.11687 14.1151 0.849033 14.4138C0.550322 14.7476 0.550322 15.2524 0.849033 15.5862C1.11687 15.8849 7.55784 22.9102 15.625 22.9102C23.6922 22.9102 30.1332 15.8849 30.401 15.5862C30.6997 15.2524 30.6997 14.7476 30.401 14.4138ZM15.625 21.1523C12.233 21.1523 9.47269 18.3921 9.47269 15C9.47269 11.6079 12.233 8.84766 15.625 8.84766C19.0171 8.84766 21.7774 11.6079 21.7774 15C21.7774 18.3921 19.0171 21.1523 15.625 21.1523Z" fill={color}/>
                    <path d="M16.5039 13.2422C16.5039 12.358 16.9428 11.5802 17.6106 11.1018C17.0114 10.795 16.3431 10.6055 15.625 10.6055C13.202 10.6055 11.2305 12.577 11.2305 15C11.2305 17.423 13.202 19.3945 15.625 19.3945C17.7944 19.3945 19.5899 17.8105 19.9446 15.7407C18.1748 16.3106 16.5039 14.972 16.5039 13.2422Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_1338_2146">
                        <rect width="30" height="30" fill="white" transform="translate(0.625)"/>
                    </clipPath>
                </defs>
            </svg>
        </ServiceProductIcon>
    )
}

export default BoostViewIcon
