import React, {useEffect} from "react";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import { parse } from "qs";
import trim from "lodash/trim";
import find from "lodash/find";
import { useClientApiState } from "@core/Hook/api";
import { useTrans } from "@core/Hook/trans";
import { Asset } from "@core/Utils/image";
import { useResponsive } from "@core/Hook/responsiveHook";
import OrderHistoryTab from "./OrderHistory/OrderHistoryTab";
import OrderHistoryList from "./OrderHistory/OrderHistoryList";
import OrderCardLayout from "../Component/Order/OrderCardLayout";
import Layout from "../Component/Common/Layout";
import MetaSeo from "../Component/Common/MetaSeo";
import BasicPagination, {IApiPagination, usePagination} from "../Component/BasicPagination";
import { IOrder } from "../Recoil/Order/atom";
import { ORDER_STATUS_AVAILABLE_LIST } from "../Constants/order";
import LoadingList from "../Component/Loading/LoadingList";
import useOrderHook from "../Hook/orderHook";
import isUndefined from "lodash/isUndefined";
import {useAlert} from "../Hook/alertHook";

const OrderHistory: React.FC = () => {
    const location = useLocation();
    const qs = parse(trim(location.search, '?'));
    const page = parseInt(qs?.page as string || '1');
    const limit = 8;
    const { trans } = useTrans();
    const { state } = useParams();
    const { isTabletOrMobile } = useResponsive();
    const [apiState, setApiState] = useClientApiState({isLoading: false});
    const {useFetchOrders, orderHistory, orderPaginate} = useOrderHook();
    const { pagination, shouldRender } = usePagination<IOrder>(orderPaginate as IApiPagination);
    const {fetchOrders} = useFetchOrders(apiState, setApiState);
    const {renderStateAlert} = useAlert();
    const [searchParams, setSearchParams] = useSearchParams();

    const bgImage = isTabletOrMobile
        ? Asset('wow-boost-history-purchase-bg-mobile.jpg')
        : Asset('wow-boost-history-purchase-bg.jpg');

    useEffect(() => {
        const _orderStatusCode = find(ORDER_STATUS_AVAILABLE_LIST, {state})?.code || 0;

        fetchOrders(page, limit, _orderStatusCode);
    }, [state, page])

    return (
        <Layout contentAvoidHeader contentBg={bgImage}>
            <MetaSeo
                title={trans('meta_seo.history.title')}
                description={trans('meta_seo.history.description')}
            />
            <OrderCardLayout title={trans('history.title')}>
                <OrderHistoryTab currentState={state || 'pending'} />
                {apiState.isLoading && <LoadingList amount={limit} />}
                {!apiState.isLoading && (
                    <>
                        <OrderHistoryList lists={orderHistory} />
                        {shouldRender && <BasicPagination {...pagination} />}
                        {(!isUndefined(location.state?.isPaymentSuccess) || !isUndefined(qs.noti)) && renderStateAlert(searchParams, setSearchParams)}
                    </>
                )}
            </OrderCardLayout>
        </Layout>
    )
}

export default OrderHistory
