import { alpha } from '@mui/material';

const FONT_SIZE_BASE = 16;

const constants = {
    transition: {
        fast: 'all .2s',
        normal: 'all .4s',
        slow: 'all .6s',
    },
};

const colors = {
    primary: '#5a80ee',
    secondary: '#02734a',
    info: '#335a7d',
    success: '#27ae60',
    warning: '#dab122',
    error: '#eb5757',
    white: '#ffffff',
    black: '#000000',
    bodyBg: '#f9ffff',
    bodyColor: '#030303',
    textPrimary: '#646f9B',
    // textDisabled: 'rgba(0 0 0 / .26)',
    placeholderColor: '#4f4f4f',
    inputBorderColor: '#3d3d3d',
    inputFocusBorderColor: '#ffe598',
    facebookColor: '#0571ed',
    tiktokColor: '#cf0b52',
};

const fonts = {
    fontFamily: 'FC Iconic Text, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSizeBase: FONT_SIZE_BASE,
    fontSizeSmallest: '.625rem',
    fontSizeSmaller: '.75rem',
    fontSizeSmallerLarge: '.8125rem',
    fontSizeSmall: '.875rem',
    fontSizeSmallLarge: '.9375rem',
    fontSizeNormal: '1rem',
    fontSizeNormalLarge: '1.125rem',
    fontSizeBig: '1.25rem',
    fontSizeBigLarge: '1.375rem',
    fontSizeBigger: '1.5rem',
    fontSizeBiggerLarge: '1.75rem',
    fontSizeBiggest: '2rem',
    fontSizeH1: '2.25rem',
    fontWeightLight: 300,
    fontWeightNormal: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontSizeSmallestFixed: '.625rem',
    fontSizeSmallerFixed: '.75rem',
    fontSizeSmallFixed: '.875rem',
    fontSizeSmallLargeFixed: '.9375rem',
    fontSizeNormalFixed: '1rem',
    fontSizeNormalLargeFixed: '1.125rem',
    fontSizeBigFixed: '1.25rem',
    fontSizeBigLargeFixed: '1.375rem',
    fontSizeBiggerFixed: '1.5rem',
    fontSizeBiggerLargeFixed: '1.75rem',
    fontSizeBiggestFixed: '2rem',
};

const alert = {
    color: '#34406b',
    errorProgressBg: '#f86565',
    errorBg: '#ffeaea',
    successProgressBg: '#3bc073',
    successBg: '#f1ffed',
};

const title = {
    color: '#34406b',
    facebookColor: colors.facebookColor,
    tiktokColor: colors.tiktokColor,
};

const cover = {
    titleColor: '#5a80ee',
    iconBg: '#e2efff',
    iconColor: '#9fc7ff',
    numberColor: '#5a80ee',
};

const formLabelControl = {
    requireColor: '#eb5757',
    labelColor: '#34406b',
    subLabelColor: '#646f9b',
};

const serviceCover = {
    titleColor: '#5a80ee',
    boxBorder: '2px solid #e2efff',
};

const serviceProduct = {
    bg: '#ffffff',
    border: '2px solid #e2efff',
};

const serviceProductForm = {
    requireColor: '#eb5757',
    labelColor: '#34406b',
    subLabelColor: '#646f9b',
    buttonDownload: '#5a80ee',
    buttonActionContainerBg: '#ffffff',
    buttonActionContainerBoxShadow: '0px -1px 8px 0px rgba(34, 40, 88, 0.10)',
    buttonUploadColor: '#a1b8db',
    buttonUploadHighlightColor: '#5a80ee',
};

const serviceProductButton = {
    bg: 'radial-gradient(173.75% 173.75% at 50% -0.00%, #ffffff 0%, #ecf4ff 100%)',
    border: '2px solid #e2efff',
    color: '#646f9b',

    bgHover: '#e2efff',
    borderHover: '2px solid #9fd7ff',
    colorHover: '#5a80ee',

    packageColor: '#34406B',
    prizeColor: '#5a80ee',
    internalColor: '#8b5cf6',
    internalBg: '#eae6fe',
};

const serviceProductIcon = {
    bg: '#a1b8db',
    bgHover: '#5a80ee',
};

const header = {
    height: 50,
    bg: 'transparent',
    boxShadow: 'none',
    desktopBg: alpha(colors.white, 0.3),
    desktopBorder: '1px solid #e2efff',

    activeBg: alpha('#f9fcff', 0.8),
    activeBoxShadow: `0px 3px 5px 0px ${alpha('#455da4', 0.1)}`,
    activeBackdropFilter: 'blur(5px)',
    activeDesktopBg: '#f9fcff',
    activeDesktopBoxShadow: 'none',
};

const headerAnon = {
    btnLoginBg: 'linear-gradient(180deg, #a1c1ff 0%, #5a80ee 100%)',
    btnLoginColor: colors.white,
    btnLoginActiveFilter: 'brightness(.95)',
};

const headerLogged = {
    btnLoggedBg: '#eef4fc',
    btnLoggedColor: '#5a80ee',
    btnLoggedBorder: '1px solid #9fc7ff',
    btnLoggedActiveBg: '#deedff',
};

const headerHamburger = {
    color: '#5a80ee',
};

const headerMenuMobileDialog = {
    bg: alpha('#ecf4ff', 0.8),
    closeIconColor: '#97afd6',
    paperBg: 'transparent',
};

const localeSwitcher = {
    btnBg: '#eef4fc',
    btnColor: '#9fc7ff',
    btnBorder: '1px solid #9fc7ff',
    btnActiveFilter: 'brightness(.9)',
    listItemActiveBg: '#e2efff',
};

const menus = {
    mobileBg: 'radial-gradient(174% 174% at 50% 0%, #fff 0%, #ecf4ff 100%)',
    mobileBorder: '1px solid #e2efff',
    mobileActiveFilter: 'brightness(.9)',

    desktopColor: '#34406b',
    desktopActiveBg: 'transparent',
    desktopActiveColor: colors.primary,
};

const modal = {
    bg: 'linear-gradient(180deg, #fafafa 0%, #e3f0ff 100%)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#e2efff',
    backdropColor: alpha('#141c46', 0.3),

    titleColor: '#34406b',
    titleFontSize: fonts.fontSizeNormal,
    titleFontSizeXs: fonts.fontSizeSmall,
    titleFontWeight: fonts.fontWeightBold,
    titleFontSizeDesktop: fonts.fontSizeBig,
    subTitleColor: '#646f9b',

    iconCloseSize: fonts.fontSizeNormal,
    iconCloseColor: '#a1b8db',

    contentFontSize: fonts.fontSizeSmall,
    contentFontSizeDesktop: fonts.fontSizeNormal,
};

const input = {
    height: 44,
    bg: colors.white,
    color: '#34406b',
    adornmentColor: '#a1b8db',
    borderColor: '#a1b8db',
    borderFocusColor: colors.primary,
    placeholderColor: '#a1b8db',
};

const loggedMenu = {
    btnCloseColor: '#a1b8db',
    userBg: '#e2efff',
    userBorder: '1px solid #9fc7ff',
    userIconColor: '#5a80ee',
    usernameColor: '#646f9b',
    dropdownBtnBg: colors.white,
    dropdownBtnActiveBg: '#eef4fc',
    dropdownIconColor: '#646f9b',
    dropdownTextColor: '#646f9b',
    drawerBtnBg: 'radial-gradient(175% 175% at 50% 0, #ffffff 0, #ecf4ff 100%)',
    drawerBtnBorder: '1px solid #e2efff',
    drawerBtnIconColor: '#a1b8db',
    drawerBtnTextColor: '#646f9b',
};

const pagination = {
    color: '#646f9b',
    bg: '#f2f2f2',
    activeColor: '#eef4fc',
    activeBg: '#5a80ee',
};

const ourService = {
    cardBg: 'radial-gradient(173.75% 173.75% at 50% -0.00%, #ffffff 0%, #ecf4ff 100%)',
};

const review = {
    swiperBulletBg: '#e1e4e8',
    swiperBulletBgActive: '#5a80ee',
    cardContentColor: '#34406b',
    cardActionColor: '#34406b',
    cardActionBg: '#e2efff',
};

const howToUse = {
    numberBg: '#646f9b',
    numberColor: '#e2efff',
    titleColor: '#646f9b',
    swiperBulletBg: '#e1e4e8',
    swiperBulletBgActive: '#5a80ee',
    cardContentColor: '#34406b',
    cardActionColor: '#34406b',
    cardActionBg: '#e2efff',

    numberColorDesktop: '#556089',
    numberBgDesktop: '#e2efff',
    numberLinerBgDesktop: '#e2efff',
    numberColorDesktopActive: '#e2efff',
    numberBgDesktopActive: '#646f9b',
    numberLinerBgDesktopActive: '#646f9b',
    bulletColorDesktop: '#646f9b',
    bulletColorDesktopActive: '#fafafa',
    swiperBulletBgDesktop: 'transparent',
    swiperBulletBgDesktopActive: '#646f9b',
};

const faq = {
    questionSymbolColor: '#34406b',
    questionIconColor: '#34406b',
    questionColor: '#34406b',
    answerSymbolColor: '#646f9b',
    answerColor: '#646f9b',
    answerBg: '#e2efff',
};

const buttonPlain = {
    color: '#fafafa',
    facebookBg: colors.facebookColor,
    facebookColor: '#fafafa',
    tiktokBg: colors.tiktokColor,
    tiktokColor: '#fafafa',

    receiptColor: '#5a80ee',

    questionBg: alpha('#ffffff', 0.8),

    primaryBg: 'linear-gradient(180deg, #a1c1ff 0%, #5a80ee 100%)',
    primaryColor: '#fafafa',

    secondaryBg: 'radial-gradient(174% 174% at 50% 0%, #fff 0%, #ecf4ff 100%)',
    secondaryColor: '#646f9b',
    secondaryBorder: '1px solid #e2efff',
};

const history = {
    bg: colors.white,
    border: '1px solid #e2efff',

    tabBg: 'radial-gradient(174% 174% at 50% 0, #fff 0, #ecf4ff 100%)',
    tabColor: '#646f9b',
    tabBorder: '1px solid #e2efff',
    tabActiveBg: '#e2efff',
    tabActiveColor: '#5a80ee',
    tabActiveBorder: '1px solid #9fc7ff',

    listItemBg: 'rgba(238, 244, 252, .60)',
    listItemTitleColor: '#34406b',
    listItemDescriptionColor: '#34406b',
    listItemDateColor: '#646f9b',
    listItemPriceColor: '#646f9b',
};

const orderDetail = {
    titleColor: '#34406b',
    labelColor: '#646f9b',
    textColor: '#34406b',
    descriptionColor: '#5a80ee',
    descriptionBg: '#eef4fc',
};

const paymentDetail = {
    qrcodeBorder: '1px solid #a1b8db',
    nameColor: '#34406b',
    accountColor: '#34406b',
    refnoColor: '#646f9b',
};

const paymentRemark = {
    color: '#34406b',
    btnColor: '#5a80ee',
};

const paymentAlert = {
    bg: '#ffeded',
    border: '1px solid #eb5757',
    timerColor: '#eb5757',
    titleColor: '#eb5757',
    subTitleColor: '#eb5757',
};

const stateTitle = {
    stateWaitingBg: '#e2efff',
    statePendingColor: '#46a6ff',
    stateProcessBg: '#fff8de',
    stateProcessColor: '#f3bd00',
    stateSuccessBg: '#e1f8ea',
    stateSuccessColor: '#3bc073',
    stateCancelBg: '#ffe9e9',
    stateCancelColor: '#f86565',
    stateCustomerStrictInfoBg: '#FDF2E9',
    stateCustomerStrictInfoColor: '#AF601A',
};

const noResult = {
    color: '#A1B8DB',
};

const progressBar = {
    bg: '#fafafa',
    border: '1px solid #e2efff',
    trackBg: '#fafafa',
    progressBg: '#9fc7ff',
    labelColor: '#5a80ee',
};

const summary = {
    bg: '#eef4fc',
    titleColor: '#34406b',
    priceColor: '#5a80ee',
    priceDecimalColor: '#eb5757',
};

const fileUpload = {
    bg: '#eef4fc',
    border: '1px dashed #a1b8db',
    btnBg: 'radial-gradient(174% 174% at 50% 0, #ffffff 0, #ecf4ff 100%)',
    btnColor: '#646f9b',
    btnBorder: '1px solid #e2efff',
    btnIconColor: '#a1b8db',
    btnClearColor: '#5a80ee',
    iconViewColor: alpha('#646f9b', 0.5),
    activeBg: '#d9e8fc',
    errorColor: '#eb5757',
    fileNameColor: '#34406b',
    fileSizeColor: '#a1b8db',
};

const footer = {
    bg: alpha('#e4e7ff', 0.5),
    descriptionColor: colors.textPrimary,
};

const tagSeo = {
    bg: '#a1b8db',
    color: '#fafafa',
};

const copyright = {
    bg: '#e2efff',
    color: colors.textPrimary,
};

export const Variables = {
    constants,
    colors,
    fonts,
    alert,
    title,
    cover,
    serviceCover,
    serviceProduct,
    formLabelControl,
    serviceProductForm,
    serviceProductIcon,
    serviceProductButton,
    header,
    headerAnon,
    headerHamburger,
    headerLogged,
    headerMenuMobileDialog,
    input,
    localeSwitcher,
    menus,
    modal,
    loggedMenu,
    pagination,
    ourService,
    buttonPlain,
    howToUse,
    faq,
    review,
    history,
    orderDetail,
    paymentDetail,
    paymentRemark,
    paymentAlert,
    stateTitle,
    noResult,
    progressBar,
    summary,
    fileUpload,
    footer,
    tagSeo,
    copyright,
};

export default Variables;
