import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const BoostCommentFileIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" fill="none">
                <g clipPath="url(#clip0_1981_1764)">
                    <path d="M21.4988 10.2559C21.1683 9.7229 20.5842 9.4043 19.9364 9.4043H5.51158C4.79655 9.4043 4.14623 9.80736 3.85434 10.4315L0.0390625 18.4309C0.183098 19.0167 0.7302 19.4543 1.38184 19.4543H16.7288C17.3849 19.4543 17.9846 19.0832 18.2776 18.4961L21.5753 11.8864C21.8357 11.3634 21.8068 10.7539 21.4988 10.2559Z" fill={color}/>
                    <path d="M3.07569 9.20122C3.36724 8.57729 4.01772 8.17406 4.73292 8.17406H18.3162V6.43667C18.3162 5.72048 17.712 5.1377 16.9695 5.1377H9.04917C9.03756 5.1377 9.02959 5.13471 9.02661 5.13255L7.60899 3.15041C7.35792 2.79878 6.94324 2.58887 6.50018 2.58887H1.3471C0.604185 2.58887 0 3.17165 0 3.88784V15.6496L3.07569 9.20122Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_1981_1764">
                        <rect width="21.75" height="21.75" fill="white" transform="translate(0 0.125)"/>
                    </clipPath>
                </defs>
            </svg>
        </ServiceProductIcon>
    )
}

export default BoostCommentFileIcon
