import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react";
import isUndefined from "lodash/isUndefined";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import Typography from "@mui/material/Typography";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTrans } from "@core/Hook/trans";
import { Image } from "@core/Utils/image";

interface IFileUpload {
    onChange: (file?: File|undefined) => void;
    setFile: Dispatch<SetStateAction<File | null>>;
    title?: string;
    titleClear?: string;
    customButton?: ReactElement;
    showButtonClear?: boolean;
    acceptedFile?: string[];
    maxFileSize?: number;
    textError?: string | null;
}

const FileUploadStyled = styled(Box)(({ theme }) => ({
    '&.x-file-upload-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        '.-file-upload-wrapper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            padding: theme.spacing(1),
            width: '100%',
            height: 100,
            background: theme.baseVariables.fileUpload.bg,
            border: theme.baseVariables.fileUpload.border,
            borderRadius: 15,
            transition: 'all .2s ease',
            '&.-active': {
                background: theme.baseVariables.fileUpload.activeBg
            },
            '.-btn-upload': {
                padding: theme.spacing(1, 2.5),
                color: theme.baseVariables.fileUpload.btnColor,
                background: theme.baseVariables.fileUpload.btnBg,
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                fontWeight: theme.baseVariables.fonts.fontWeightNormal,
                border: theme.baseVariables.fileUpload.btnBorder,
                borderRadius: 8,
                boxShadow: 'none',
                textTransform: 'unset',
                '.-highlight': {
                    color: theme.baseVariables.serviceProductForm.buttonUploadHighlightColor,
                    fontSize: theme.baseVariables.fonts.fontSizeSmall,
                    textDecoration: 'underline',
                    transform: 'translateY(-1px)',
                },
                '.-icon': {
                    marginRight: theme.spacing(1),
                    color: theme.baseVariables.fileUpload.btnIconColor,
                    fontSize: theme.baseVariables.fonts.fontSizeNormalLarge,
                    transform: 'translateY(-1px)',
                },
                '.-name': {
                    color: theme.baseVariables.fileUpload.fileNameColor,
                    fontSize: theme.baseVariables.fonts.fontSizeSmall
                },
                '.-size': {
                    marginLeft: theme.spacing(1),
                    color: theme.baseVariables.fileUpload.filrSizeColor,
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    transform: 'translateY(1px)'
                }
            },
            '.-btn-preview': {
                position: 'relative',
                padding: 0,
                width: 'auto',
                height: '100%',
                '.-image': {
                    width: 'auto',
                    height: '100%'
                },
                '.-icon': {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    color: theme.baseVariables.fileUpload.iconViewColor,
                    fontSize: theme.baseVariables.fonts.fontSizeBiggest,
                    transform: 'translate(-50%, -50%)'
                }
            }
        },
        '.-text-error': {
            margin: theme.spacing(.5, 1.75, 0),
            color: theme.baseVariables.fileUpload.errorColor,
            fontSize: theme.baseVariables.fonts.fontSizeSmall
        },
        '.-btn-clear': {
            marginTop: theme.spacing(1),
            color: theme.baseVariables.fileUpload.btnClearColor,
            background: 'none',
            fontSize: theme.baseVariables.fonts.fontSizeSmaller,
            fontWeight: theme.baseVariables.fonts.fontWeightNormal,
            textDecoration: 'underline'
        }
    }
}))

const FilePreviewStyled = styled(Dialog)(({ theme }) => ({
    '&.x-file-preview-container': {
        '.MuiDialog-paper': {
            margin: theme.spacing(2),
            width: '100%',
            '.-image': {
                width: '100%',
                height: 'auto'
            }
        }
    }
}))

const FileUpload: React.FC<IFileUpload> = ({
    onChange,
    setFile,
    title,
    titleClear,
    customButton,
    showButtonClear = true,
    acceptedFile,
    maxFileSize = 0,
    textError
}) => {
    const { trans } = useTrans();
    const [drag, setDrag] = useState<boolean>(false);
    const [view, setView] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const [fileSize, setFileSize] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);
    const imageFileTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp'];

    const isValid = (file: File) => {
        if (acceptedFile && !acceptedFile.includes(file.name.split('.').pop() as string)) {
            setError(`The file type must be "${acceptedFile.join(', ')}"`);

            return false;
        }

        if (maxFileSize && file.size > maxFileSize * 1000) {
            setError(`Max allowed file size is ${maxFileSize}KB`);

            return false;
        }

        if (!isImageType(file)) {
            setError(`The file type must be "${imageFileTypes.join(', ')}"`);

            return false;
        }

        return true;
    };

    const isImageType = (file: File) => {
        return imageFileTypes.includes(file.type);
    }

    const getImageFile = (file: File) => {
        if (!isImageType(file)) {
            return;
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            setImageUrl(reader.result as string);
        };

        reader.readAsDataURL(file);
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        setError(null);

        const file = event.target.files[0];

        if (!file || !isValid(file)) {
            return;
        }

        onChange(file);
        setFile(file);
        setFileName(file.name);
        setFileSize(file.size);
        getImageFile(file);
    };

    const handleClearFile = () => {
        onChange(undefined);
        setFile(null);
        setFileName(null);
        setFileSize(0);
        setImageUrl(null);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        if ('dragenter' === event.type || 'dragover' === event.type) {
            setDrag(true);

            return;
        }

        setDrag(false);
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        setDrag(false);
        setError(null);

        if (!event.dataTransfer.files || !event.dataTransfer.files[0]) {
            return;
        }

        const file = event.dataTransfer.files[0];

        if (!file || !isValid(file)) {
            return;
        }

        setFile(file);
        setFileName(file.name);
        setFileSize(file.size);
        getImageFile(file);
    };

    useEffect(() => {
        if (!textError) {
            return;
        }

        setError(textError);
    }, [textError]);

    return (
        <>
            <FileUploadStyled className={'x-file-upload-container'}>
                <Box
                    className={`-file-upload-wrapper ${drag ? '-active' : ''}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragOver}
                    onDrop={handleDrop}
                >
                    {imageUrl && (
                        <Button className={'-btn-preview'} variant='text' onClick={() => setView(true)}>
                            <Image
                                isRemote
                                src={imageUrl}
                                extraClass={'-image'}
                                width={100}
                                height={100}
                                webp={false}
                            />
                            <SearchIcon className={'-icon'} />
                        </Button>
                    )}
                    {!imageUrl && fileName && (
                        <Button className={'-btn-upload'} variant={'contained'} component='label'>
                            <UploadFileIcon className={'-icon'} />
                            <Typography className={'-name'} component={'span'}>{fileName}</Typography>
                            <Typography className={'-size'} component={'span'}>{`(${fileSize / 1000} kB)`}</Typography>
                            <input hidden type='file' name='file' id='file' onChange={handleFileUpload} />
                        </Button>
                    )}
                    {!imageUrl && !fileName && (
                        <Button className={'-btn-upload'} variant={'contained'} component={'label'}>
                            {isUndefined(customButton)
                                ? <><CloudUploadIcon className={'-icon'} /> {title ? title : trans('file_upload.btn_upload')}</>
                                : customButton
                            }
                            <input hidden type='file' name='file' id='file' onChange={handleFileUpload} />
                        </Button>
                    )}
                </Box>
                {error && <Typography className={'-text-error'}>{error}</Typography>}
                {showButtonClear && (
                    <Button className={'-btn-clear'} variant='text' onClick={handleClearFile}>
                        {titleClear ? titleClear : trans('file_upload.btn_clear')}
                    </Button>
                )}
            </FileUploadStyled>
            <FilePreviewStyled className={'x-file-preview-container'} onClose={() => setView(false)} open={view}>
                {imageUrl && (
                    <Image
                        isRemote
                        src={imageUrl}
                        extraClass={'-image'}
                        width={300}
                        height={300}
                        webp={false}
                    />
                )}
            </FilePreviewStyled>
        </>
    )
}

export default FileUpload
