import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Page from '../Page';
import { useCustomerContext } from '../Hook/auth';

export const webRoutes = [
    {
        name: 'home',
        path: '/',
        component: <Page.Homepage />,
    },
    {
        name: 'policy',
        path: '/policy/:topic',
        component: <Page.Policy />,
    },
    {
        name: 'services',
        path: '/services',
        component: <Page.Services />,
    },
    {
        name: 'auth',
        path: '/auth/redirect',
        component: <Page.Authentication />,
    },
    {
        name: 'email-verification',
        path: '/email-verification/:token',
        component: <Page.EmailVerification />,
    },
    {
        name: 'email-verification-reset-password',
        path: '/reset-password/:token',
        component: <Page.EmailVerification />,
    },
    {
        name: 'credit',
        path: '/account/credit/amount',
        component: <Page.Credit />,
    },
    {
        name: 'credit-history',
        path: '/account/credit/credit-history',
        component: <Page.CreditHistory />,
    },
    {
        name: 'order-history',
        path: '/account/order-history/:state',
        component: <Page.OrderHistory />,
    },
    {
        name: 'order-detail',
        path: '/account/order-detail/:orderId',
        component: <Page.OrderDetail />,
    },
    {
        name: 'order',
        path: '/account/order-summary',
        component: <Page.OrderSummary />,
    },
    {
        name: 'order-payment',
        path: '/account/order-payment/:refId',
        component: <Page.OrderPayment />,
    },
    {
        name: 'order-payment-method',
        path: '/account/payment-method',
        component: <Page.PaymentMethod />,
    },
    {
        name: '404',
        path: '*',
        component: <Page.NotFound />,
    },
];

function RequireAuth({ childrenComponent, routePath }: { childrenComponent: JSX.Element; routePath: string }) {
    const { isLogged } = useCustomerContext();
    const location = useLocation();
    const isLoggedRoute = routePath.includes('/account');

    if (!isLogged && isLoggedRoute) {
        return <Navigate to="/" state={{ from: location }} />;
    }

    return childrenComponent;
}

const Routers = () => {
    return (
        <Routes>
            {webRoutes.map((route) => {
                return (
                    <Route
                        key={`route-${route.name}`}
                        path={route.path}
                        element={<RequireAuth routePath={route.path} childrenComponent={route.component} />}
                    />
                );
            })}
        </Routes>
    );
};

export default Routers;
