import React, { BaseSyntheticEvent, useState } from "react";
import capitalize from "lodash/capitalize";
import TextField, { BaseTextFieldProps } from "@mui/material/TextField";
import { FormLabel, IconButton, InputBaseComponentProps } from "@mui/material";
import FormControlWrapper from "@core/Form/FormControlWrapper";
import { StandardTextFieldProps } from "@mui/material/TextField/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface IFormInput extends StandardTextFieldProps {
    name: string;
    label?: string;
    type?: string;
    register?: any;
    errors?: any;
    wrapperClass?: string;
    helperText?: string;
}

export const inputClickSelectAll = (e: BaseSyntheticEvent): void => {
    return e.target.select()
};

export const onInputAcceptOnlyNumber = (e: BaseSyntheticEvent): void => {
    e.target.value = e.target.value
        .replace(/[^0-9.]/g, '')
        .replace(/(\..*)\./g, '$1')
};

export const onInputIntegerAndEnterSubmit = (e: React.KeyboardEvent<HTMLInputElement>, handleSubmit: any) => {
    const target = e.target as HTMLInputElement;

    if (target.value && 'Enter' === e.key) {
        return handleSubmit;
    }

    if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
    }
}

export const basicInputPropsNumber: InputBaseComponentProps = {
    inputMode: 'numeric',
    pattern: '[0-9]*',
};

export const basicInputPropsFloat: InputBaseComponentProps = {
    inputMode: 'numeric',
    pattern: '[0-9.]*',
};

const FormInput: React.FC<IFormInput> = (props) => {
    const {
        type = 'text',
        required,
        register,
        errors,
        name,
        label,
        helperText,
        InputProps,
        ...otherProps
    } = props
    const isPasswordType = type === 'password'
    const [showPassword, setShowPassword] = useState(false)

    return (
        <FormControlWrapper required={required}>
            {label && <FormLabel sx={{marginBottom: '.25rem', fontSize: '.9375rem'}}>{label || capitalize(name)}</FormLabel>}
            <TextField
                {...otherProps}
                {...register(name)}
                type={isPasswordType ? (showPassword ? 'text' : 'password') : type}
                id={name}
                size={'small'}
                variant={"outlined"}
                error={!!errors[name]}
                helperText={errors[name] ? errors[name]?.message : helperText}
                InputProps={isPasswordType
                    ? {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        ...InputProps
                    } : InputProps
                }
            />
        </FormControlWrapper>
    )
}

export default FormInput
