import React from 'react';
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Layout from "../Component/Common/Layout";
import Box from "@mui/material/Box";
import {Image} from "@core/Utils/image";
import Typography from "@mui/material/Typography";
import PlainButton from "../Component/Button/PlainButton";
import {useTrans} from "@core/Hook/trans";
import {useNavigate} from "react-router-dom";

const NotFoundStyled = styled(Container)(({ theme }) => ({
    '&.x-not-found-container': {
        height: '100dvh',
        '.-not-found-wrapper': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
            '.-image-logo': {
                width: '300px',
                [theme.breakpoints.up('sm')]: {
                    width: '350px',
                },
            },
            '.-button-wrapper': {
                marginTop: theme.spacing(4),
            }
        }
    }
}))

const NotFound: React.FC = () => {
    const {trans} = useTrans();
    const navigate = useNavigate();

    return (
        <Layout isDisableFooter isDisableHeader>
            <NotFoundStyled className="x-not-found-container">
                <Box className="-not-found-wrapper">
                    <Box className={'-image-logo-wrapper'}>
                        <Image
                            extraClass={'-image-logo'}
                            src={'logo.png'}
                            width={350}
                            height={120}
                        />
                    </Box>
                    <Typography variant={'h3'} className={'-title'}>
                        {'404 NOT FOUND'}
                    </Typography>
                    <Box className={'-button-wrapper'}>
                        <PlainButton
                            color={'primary'}
                            label={trans('general.homepage')}
                            onClick={() => navigate('/')}
                        />
                    </Box>
                </Box>
            </NotFoundStyled>
        </Layout>
    )
}

export default NotFound;
