import React, {useEffect} from "react";
import { useTrans } from "@core/Hook/trans";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Image} from "@core/Utils/image";
import isEmpty from "lodash/isEmpty";
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {BeatLoader} from "react-spinners";
import Typography from "@mui/material/Typography";
import {resolveClientApiState} from "@core/Hook/api";
import {checkValidateTokenIsExpire, validateEmailWithValidateToken} from "../Api/user";
import {useCustomerContext} from "../Hook/auth";

const EmailVerificationStyled = styled(Container)(({ theme }) => ({
    '&.x-email-verification-container': {
        height: '100dvh',
        '.-email-verification-wrapper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            whiteSpace: 'break-spaces',
            '.-image-loading-wrapper': {
                width: '200px',
            },
            '.-loader-wrapper': {
                margin: theme.spacing(2, 0),
            },
            '.-description-text': {
                textAlign: 'center',
                fontSize: theme.baseVariables.fonts.fontSizeSmall
            }
        }
    }
}))

const EmailVerification: React.FC = () => {
    const navigate = useNavigate();
    const {token} = useParams();
    const location = useLocation();
    const { trans } = useTrans();
    const isResetPasswordVerification = location.pathname.includes('/reset-password');
    const {isLogged} = useCustomerContext();

    const verifyToken = async () => {
        const method = isResetPasswordVerification ? await checkValidateTokenIsExpire({validateToken: token}) : await validateEmailWithValidateToken({validateToken: token});
        const resVerifyEmailToken = resolveClientApiState(method);

        if (resVerifyEmailToken.isOk) {
            const state = isResetPasswordVerification ? {
                isValidResetPasswordToken: true,
                resetPasswordToken: token,
            } : {
                isVerifyEmailSuccess: true,
                email: resVerifyEmailToken.data.email,
            }

            return navigate('/', {state: state});
        }

        const state = isResetPasswordVerification ? {
            isValidResetPasswordToken: false,
        } : {
            isVerifyEmailSuccess: false,
            email: resVerifyEmailToken.data?.email
        }

        return navigate('/', {state: state});
    }

    useEffect(() => {
        if (isEmpty(token) || isLogged) {
            return navigate('/');
        }

        verifyToken();
    }, [token])

    return (
        <EmailVerificationStyled className={'x-email-verification-container'}>
            <Box className="-email-verification-wrapper">
                <Box className={'-image-loading-wrapper'}>
                    <Image
                        extraClass={'-image-logo-loading'}
                        src={'logo.png'}
                        width={387}
                        height={133}
                    />
                </Box>
                <Box className={'-loader-wrapper'}>
                    <BeatLoader color={'#5a80ee'} />
                </Box>
                <Typography variant={'body1'} className={'-description-text'}>{trans('email_verification.verify.description')}</Typography>
            </Box>
        </EmailVerificationStyled>
    )
}

export default EmailVerification
