import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useTrans } from "@core/Hook/trans";
import ModalTypography from "../Partial/ModalTypography";
import TextWithLink from "../../TextWithLink";
import FadeInUp from "../../../Animate/FadeInUp";
import LoginByEmailForm, {ILoginByEmailFormData} from "../Form/LoginByEmailForm";
import SSOButtonGroup from "../../SSOButtonGroup";

interface ILoginByEmail {
    onSubmit: (data: ILoginByEmailFormData) => void;
    onClickRegister?(): void;
    onClickForgetPassword?(): void;
    isLoading?: boolean;
}

const LoginByEmailStyled = styled(Stack)(({ theme }) => ({
    '&.x-login-email': {
        '.-icon-wrapper': {
            margin: theme.spacing(2, 0),
            textAlign: 'center',
            '.-img': {
                display: 'inline-block',
                width: 100,
            }
        },
        '.-description-wrapper': {
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(3),
            }
        },
        '.-form-wrapper': {
            margin: theme.spacing(2, 0, 1),
            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(3.5, 0, 1),
            }
        },
        '.-text-with-link-wrapper': {
            marginTop: theme.spacing(3),
        }
    }
}))

const LoginByEmail: React.FC<ILoginByEmail> = ({ onSubmit, onClickRegister, onClickForgetPassword, isLoading }) => {
    const { trans } = useTrans();
    const submitLogin = (data: ILoginByEmailFormData) => onSubmit(data);

    return (
        <LoginByEmailStyled className={'x-login-email'}>
            <FadeInUp>
                <Box className={'-description-wrapper'}>
                    <ModalTypography renderType={'title'} align={'center'}>
                        {trans('modal.login_email.title')}
                    </ModalTypography>
                    <ModalTypography renderType={'description'} align={'center'}>
                        {trans('modal.login_email.description')}
                    </ModalTypography>
                </Box>
                <Box className={'-form-wrapper'}>
                    <LoginByEmailForm onSubmit={(data) => submitLogin(data)} isLoading={isLoading} onClickForgetPassword={onClickForgetPassword} />
                    <SSOButtonGroup />
                    <Box className={'-text-with-link-wrapper'}>
                        <TextWithLink
                            title={trans('modal.login.do_not_have_an_account')}
                            linkTitle={trans('modal.login.register')}
                            linkOnClick={onClickRegister}
                        />
                    </Box>
                </Box>
            </FadeInUp>
        </LoginByEmailStyled>
    )
}

export default LoginByEmail
