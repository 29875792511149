import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import StateTitle from '../../Component/StateTitle';
import CopyToClipboard from '../../Component/CopyToClipboard';
import Stack from '@mui/material/Stack';
import { getOrderStateLabelByCode } from '../../Utils/helper';
import { OrderTypeEnum } from '../../Constants/order';
import { IOrder } from 'Recoil/Order/atom';
import { useTrans } from '@core/Hook/trans';

interface ICreditHistoryListItem {
    order: IOrder;
}

const CreditHistoryListItemStyled = styled(Box)(({ theme }) => ({
    '&.x-history-list-item-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        width: '100%',
        background: theme.baseVariables.history.listItemBg,
        borderRadius: 10,
        transition: 'filter .2s',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1.5, 4),
            '&:hover': {
                filter: 'brightness(.95)',
            },
        },
        '.-content-wrapper': {
            flex: 1,
            '.-content-row': {
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                '.-order-id .MuiTypography-root': {
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.baseVariables.fonts.fontSizeSmall,
                    },
                },
                '&.-description': {
                    marginTop: theme.spacing(0.5),
                },
                '&.-mt': {
                    marginTop: theme.spacing(1),
                    [theme.breakpoints.up('sm')]: {
                        marginTop: theme.spacing(2),
                    },
                },
                '.-title, .-description': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'pre-warp',
                    [theme.breakpoints.up('sm')]: {
                        whiteSpace: 'nowrap',
                    },
                },
                '.-title': {
                    maxWidth: '90%',
                    color: theme.baseVariables.history.listItemTitleColor,
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.baseVariables.fonts.fontSizeSmallLarge,
                    },
                },
                '.-description': {
                    maxWidth: '55%',
                    color: theme.baseVariables.history.listItemDescriptionColor,
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.baseVariables.fonts.fontSizeSmall,
                    },
                    [theme.breakpoints.between('xxs', 380)]: {
                        fontSize: theme.baseVariables.fonts.fontSizeSmallest,
                    },
                },
                '.-date': {
                    color: theme.baseVariables.history.listItemDateColor,
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    textAlign: 'right',
                    [theme.breakpoints.up('sm')]: {
                        whiteSpace: 'nowrap',
                        fontSize: theme.baseVariables.fonts.fontSizeSmall,
                    },
                },
                '.-price': {
                    color: theme.baseVariables.history.listItemPriceColor,
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.baseVariables.fonts.fontSizeNormal,
                    },
                },
            },
        },
    },
}));

const CreditHistoryListItem: React.FC<ICreditHistoryListItem> = ({ order }) => {
    const { trans } = useTrans();
    const navigate = useNavigate();

    const orderProduct = order.purchaseOrderProduct;
    const handleOnClick = () =>
        navigate(`/account/order-detail/${order.purchaseOrderRefId}`, {
            state: {
                orderType: OrderTypeEnum.CREDIT,
            },
        });

    return (
        <CreditHistoryListItemStyled className={'x-history-list-item-container'} onClick={() => handleOnClick()}>
            <Box className={'-content-wrapper'}>
                <Box className={'-content-row'}>
                    <Typography className={'-title'}>
                        {trans('credit.credit_history_topic')}
                        <NumericFormat displayType={'text'} thousandSeparator value={orderProduct?.amount} suffix={` บาท`} />
                    </Typography>
                    <StateTitle state={getOrderStateLabelByCode(order.orderStatus)} />
                </Box>
                <Box className={'-content-row -mt'}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1} flexWrap={'wrap'} justifyContent={'flex-start'}>
                        <Typography className={'-date'}>{`${moment(order.createdAt).format('DD/MM/YY HH:mm')}`}</Typography>
                        <CopyToClipboard
                            extraClass={'-order-id'}
                            copyMessage={`#${order.purchaseOrderRefId}`}
                            label={`#${order.purchaseOrderRefId}`}
                            textSize={'small'}
                        />
                    </Stack>
                    <NumericFormat prefix={'฿'} value={orderProduct?.price} thousandSeparator displayType={'text'} className={'-price'} />
                </Box>
            </Box>
        </CreditHistoryListItemStyled>
    );
};

export default CreditHistoryListItem;
