import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Menus from "../Menus";
import Copyright from "../../Copyright";

interface IMenuMobileDialog extends DialogProps {
    onClose: () => void;
    extraClass?: string;
}

const MenuMobileDialogStyled = styled(Dialog)(({ theme }) => ({
    '&.x-menu-mobile-dialog': {
        background: theme.baseVariables.headerMenuMobileDialog.bg,
        backdropFilter: 'blur(5px)',
        '.-container-wrapper': {
            margin: 'auto',
        },
        '.-paper': {
            margin: theme.spacing(0),
            background: theme.baseVariables.headerMenuMobileDialog.paperBg,
        },
        '.-ic-close': {
            position: 'fixed',
            top: theme.spacing(1),
            right: theme.spacing(1),
            color: theme.baseVariables.headerMenuMobileDialog.closeIconColor,
        },
    }
}))

const MenuMobileDialog: React.FC<IMenuMobileDialog> = ({
    onClose,
    ...rest
}) => {
    return (
        <MenuMobileDialogStyled
            {...rest}
            hideBackdrop
            className={'x-menu-mobile-dialog'}
            PaperProps={{className: '-paper', elevation: 0}}
            onClose={onClose}
        >
            <IconButton className={'-ic-close'} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box className={'-container-wrapper'}>
                    <Menus onClick={onClose} />
                </Box>
            </DialogContent>
            <Copyright renderLocation={'menu-modal-dialog'} />
        </MenuMobileDialogStyled>
    )
}

export default MenuMobileDialog
