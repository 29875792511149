import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTrans } from '@core/Hook/trans';
import { Image } from '@core/Utils/image';
import isUndefined from 'lodash/isUndefined';
import CopyToClipboard from '../../Component/CopyToClipboard';

interface IBankInfo {
    name: string;
    account: string;
    bankCode: string;
    ref_no: string;
    qrcodeUrl?: string;
    paymentCancelledAt?: string;
}

interface IPaymentDetail {
    bankInfo: IBankInfo;
}

const PaymentDetailStyled = styled(Box)(({ theme }) => ({
    '&.x-payment-detail-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        '.-qrcode-wrapper': {
            margin: 'auto',
            width: 220,
            border: theme.baseVariables.paymentDetail.qrcodeBorder,
            borderRadius: theme.spacing(1),
            '.-image': {
                display: 'block',
                width: '100%',
                height: 'auto',
                borderRadius: theme.spacing(1),
            },
        },
        '.-detail-wrapper': {
            justifyContent: 'center',
            margin: theme.spacing(3, 0),
            width: '100%',
            textAlign: 'center',
            '&::before, &::after': {
                display: 'none',
            },
            '.-name': {
                color: theme.baseVariables.paymentDetail.nameColor,
                fontSize: theme.baseVariables.fonts.fontSizeNormal,
                fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            },
            '.-account': {
                color: theme.baseVariables.paymentDetail.accountColor,
                fontSize: theme.baseVariables.fonts.fontSizeNormal,
                fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            },
            '.-ref-no': {
                color: theme.baseVariables.paymentDetail.refnoColor,
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                fontWeight: theme.baseVariables.fonts.fontWeightMedium,
            },
        },
        '.-divider': {
            marginBottom: theme.spacing(3),
        },
    },
}));

const PaymentDetail: React.FC<IPaymentDetail> = ({ bankInfo }) => {
    const { trans } = useTrans();
    return (
        <PaymentDetailStyled className={'x-payment-detail-container'}>
            {!isUndefined(bankInfo.qrcodeUrl) && (
                <Box className={'-qrcode-wrapper'}>
                    <Image extraClass={'-image'} src={bankInfo.qrcodeUrl} width={250} height={250} webp={false} isRemote />
                </Box>
            )}
            <Stack className={'-detail-wrapper'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                <Image extraClass={'-image'} src={`${bankInfo.bankCode}_logo.png`} width={200} height={50} webp />
                <Typography className={'-name'}>{trans('payment.title_name', { name: bankInfo.name })}</Typography>
                {bankInfo.account && (
                    <CopyToClipboard
                        copyMessage={`${bankInfo.account}`}
                        textSize={'normal'}
                        label={
                            <Typography component={'span'} display={'flex'} alignItems={'center'} gap={1}>
                                <Typography className={'-account'}>
                                    {trans('payment.title_account', {
                                        account: bankInfo.account,
                                    })}
                                </Typography>
                                <i className="fa-regular fa-copy" />
                            </Typography>
                        }
                    />
                )}
                <Typography className={'-ref-no'}>{trans('payment.title_ref_no', { ref_no: bankInfo.ref_no })}</Typography>
            </Stack>
            <Divider className={'-divider'} />
        </PaymentDetailStyled>
    );
};

export default PaymentDetail;
