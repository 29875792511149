import React from 'react';
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { useTrans } from "@core/Hook/trans";
import DefaultModal, { IDefaultModal } from "./DefaultModal";
import EmailValidationResend from "./EmailValidation/EmailValidationResend";
import EmailValidationSuccess from "./EmailValidation/EmailValidationSuccess";
import EmailValidationFail from "./EmailValidation/EmailValidationFail";
import {IModalDialogHook} from "../../Hook/modalDialogHook";

export type ValidationStateProps = 'sent' | 'complete' | 'in-complete' | 'invalid' | 'reset-password' | 'invalid-reset-password' | 'complete-reset-password'

interface IRegisterEmailValidationModal extends IDefaultModal {
    email?: string
    validationState: ValidationStateProps;
    handleRegisterModal?: IModalDialogHook;
    handleLoginModal?: IModalDialogHook;
    handleEmailVerificationModal?: IModalDialogHook;
    handleClose: () => void,
    setEmailVerifyState?: (state: ValidationStateProps) => void;
}

const RegisterEmailValidationModalStyled = styled(Stack)(({ theme }) => ({
    '&.x-register-email-validation': {
        '.-img': {
            width: 120,
        },
        '.-time': {
            fontSize: theme.baseVariables.fonts.fontSizeNormal,
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            textAlign: 'center',
            color: theme.palette.primary.main,
        },
        '.-btn': {
            minWidth: 220,
        }
    },
}));

const RegisterEmailValidationModal: React.FC<IRegisterEmailValidationModal> = (props) => {
    const { trans } = useTrans()
    const {
        email,
        open,
        validationState,
        handleRegisterModal,
        handleLoginModal,
        handleEmailVerificationModal,
        setEmailVerifyState,
        handleClose,
        ...rest
    } = props

    let modalTitle = validationState === 'complete'
        ? trans('modal.email_validation.success_verified')
        : trans('modal.email_validation.confirm_email')

    if (validationState === 'invalid') {
        modalTitle = trans('modal.email_validation.fail_verified');
    }

    if (validationState === 'invalid-reset-password') {
        modalTitle = trans('modal.email_validation.fail_reset_password');
    }

    if (validationState === 'complete-reset-password') {
        modalTitle = trans('modal.email_validation.success_reset_password');
    }

    const getComponentByValidationState = () => {
        switch (validationState) {
            case 'sent':
                return <EmailValidationResend initialCanSentState={false} email={email} validationState={"sent"} />;
            case 'complete':
                return <EmailValidationSuccess validationState={'complete'} onClick={() => {
                    handleClose();
                    handleLoginModal?.handleClickOpen();
                }} />
            case 'complete-reset-password':
                return <EmailValidationSuccess validationState={'complete-reset-password'} onClick={() => {
                    handleClose();
                    handleLoginModal?.handleClickOpen();
                }} />
            case 'invalid':
                return <EmailValidationFail validationState={'invalid'} onClick={() => {
                    handleClose();
                    handleRegisterModal?.handleClickOpen()
                }} />
            case 'invalid-reset-password':
                return <EmailValidationFail validationState={'invalid-reset-password'} onClick={() => {
                    handleClose();
                    handleLoginModal?.handleClickOpen()
                }} />
            case "in-complete":
                return <EmailValidationResend initialCanSentState={true} email={email} validationState={"in-complete"} onClick={() => {
                    handleClose();
                    setEmailVerifyState && setEmailVerifyState('sent');
                    handleEmailVerificationModal && handleEmailVerificationModal.handleClickOpen();
                }} />
            case 'reset-password':
                return <EmailValidationResend initialCanSentState={false} isAutoSentFirstTime email={email} validationState={"reset-password"} />
        }
    }

    return (
        <DefaultModal
            {...rest}
            handleClose={handleClose}
            showCloseIcon
            isHalfSize
            open={open}
            title={modalTitle}
            titleColor={'primary'}
            extraClass={'-email-validation-modal'}
        >
            <RegisterEmailValidationModalStyled className={'x-register-email-validation'}>
                {getComponentByValidationState()}
            </RegisterEmailValidationModalStyled>
        </DefaultModal>
    );
};

export default RegisterEmailValidationModal;
