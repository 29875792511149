import React from 'react';
import BasicButton, { IBasicButton } from '@core/Component/BasicButton';
import { styled } from '@mui/material/styles';

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        line: true;
        gray: true;
        print: true;
        transparent: true;
        ssoFacebook: true;
        ssoGmail: true;
    }
}

interface IPlainButton extends IBasicButton {
    displayMode?: 'center' | 'between';
    extraClass?: string;
    disabledRounded?: boolean;
}

const PlainButtonStyled = styled(BasicButton)(({ theme }) => ({
    '&.x-btn-plain': {
        display: 'flex',
        alignItems: 'center',
        minHeight: 44,
        padding: theme.spacing(1, 4),
        color: theme.baseVariables.buttonPlain.color,
        fontSize: theme.baseVariables.fonts.fontSizeSmall,
        fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
        borderRadius: theme.spacing(3),
        transition: 'all .2s',
        // [theme.breakpoints.up('sm')]: {
        //     minHeight: 50,
        //     fontSize: theme.baseVariables.fonts.fontSizeNormal,
        // },
        '&.-primary': {
            // width: '50%',
            //   marginTop: "14px",
            color: theme.baseVariables.buttonPlain.primaryColor,
            background: theme.baseVariables.buttonPlain.primaryBg,
        },
        '&.-secondary': {
            color: theme.baseVariables.buttonPlain.secondaryColor,
            background: theme.baseVariables.buttonPlain.secondaryBg,
            border: theme.baseVariables.buttonPlain.secondaryBorder,
            '&:active, &:hover': {
                filter: 'brightness(.975)',
            },
        },
        // '&.-info': {
        //     color: theme.baseVariables.buttonPlain.infoColor,
        //     background: theme.baseVariables.buttonPlain.infoBg,
        // },
        // '&.-line': {
        //     color: theme.baseVariables.buttonPlain.lineColor,
        //     background: theme.baseVariables.buttonPlain.lineBg,
        // },
        // '&.-center': {
        //     justifyContent: 'center',
        // },
        // '&.-between': {
        //     justifyContent: 'space-between',
        // },
        '&.-facebook': {
            color: theme.baseVariables.buttonPlain.facebookColor,
            background: theme.baseVariables.buttonPlain.facebookBg,
        },
        '&.-tiktok': {
            color: theme.baseVariables.buttonPlain.tiktokColor,
            background: theme.baseVariables.buttonPlain.tiktokBg,
        },
        '&.-question': {
            padding: theme.spacing(1.5),
            borderRadius: theme.spacing(1),
            background: theme.baseVariables.buttonPlain.questionBg,
            justifyContent: 'flex-start',
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(2),
            },
        },
        '&.-text-underline': {
            border: 'none',
            background: 'transparent',
            boxShadow: 'none',
            padding: 0,
            width: '100%',
            textDecoration: 'underline',
            color: theme.baseVariables.buttonPlain.receiptColor,
            minHeight: 'auto',
        },
        '&.-receipt': {
            marginTop: theme.spacing(0.5),
            justifyContent: 'flex-end',
        },
        '&.-invite-to-use-button': {
            padding: theme.spacing(1, 5),
        },
        '&.-cancel-order': {
            marginTop: theme.spacing(3),
        },
        // '&.-transparent': {
        //     background: 'transparent',
        //     boxShadow: 'none',
        // },
        // '&.-no-rounded': {
        //     [theme.breakpoints.down('sm')]: {
        //         borderRadius: 0,
        //     },
        // },
        // '&.-back-home': {
        //     width: '120px',
        //     marginRight: theme.spacing(1),
        // },
        '&.-btn-sso': {
            justifyContent: 'center',
            fontWeight: theme.baseVariables.fonts.fontWeightMedium,
            textTransform: 'none',
            '.-ic': {
                marginRight: theme.spacing(1.5),
            },
            '.-text': {
                minWidth: 154,
            },
            '&:active, &:hover': {
                filter: 'brightness(.9)',
            },
        },
        '&.-ssoFacebook': {
            background: 'linear-gradient(180deg, #00b2ff -37%, #006aff 100%), linear-gradient(180deg, #a1c1ff -54%, #5a80ee 100%)',
            '.-text': {
                color: 'white',
            },
        },
        '&.-ssoGmail': {
            color: '#646F9B',
            background: 'radial-gradient(173% 173% at 50% 0%, #fff 0%, #ecf4ff 100%)',
            border: '1px solid #9fc7ff',
        },
        '&.-disabled': {
            filter: 'contrast(.4) brightness(1.2)',
            // color: theme.baseVariables.buttonPlain.disabledColor,
            // background: theme.baseVariables.buttonPlain.disabledBg,
            // boxShadow: theme.baseVariables.buttonPlain.disabledBoxShadow
        },
        '&:active, &:hover': {
            filter: 'brightness(1.2)',
        },
    },
}));

const PlainButton: React.FC<IPlainButton> = ({
    color = 'primary',
    extraClass,
    displayMode = 'center',
    disabled,
    disabledRounded,
    ...rest
}) => {
    let classResolved = `x-btn-plain -${color} -${displayMode}`;

    if (extraClass) {
        classResolved += ` ${extraClass}`;
    }

    if (disabledRounded) {
        classResolved += ' -no-rounded';
    }

    if (disabled) {
        classResolved += ' -disabled';
    }

    return <PlainButtonStyled disabled={disabled} className={classResolved} {...rest} />;
};

export default PlainButton;
