import React, { createElement, FormHTMLAttributes, ReactNode } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export type childrenType = ReactNode;

const FormWrapperStyled = styled('form')(({ theme }) => ({
    '&.x-form-input-container': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        '&.-table-page': {
            '.-form-input-inner-wrapper': {
                display: 'flex',
                flex: 1,
            },
        },
        '.-form-input-inner-wrapper': {
            '.-input-wrapper': {
                margin: theme.spacing(2.5, 0, 1),
            },
        },
    },
}));

export interface IFormProps extends FormHTMLAttributes<any> {
    defaultValues?: any;
    children?: childrenType;
    buttonLabel?: string;
    buttonComponent?: childrenType;
    onSubmit?: any;
    register?: any;
    extraClass?: string;
}

const Form: React.FC<IFormProps> = ({ buttonLabel = 'Submit', children, buttonComponent, onSubmit, register, extraClass, ...rest }) => {
    return (
        <FormWrapperStyled className={`x-form-input-container ${extraClass || ''}`} onSubmit={onSubmit} {...rest}>
            <div className={'-form-input-inner-wrapper'}>
                {Array.isArray(children)
                    ? children.map((child) => {
                          return child.props.name
                              ? createElement(child.type, {
                                    ...{
                                        ...child.props,
                                        register,
                                        key: child.props.name,
                                    },
                                })
                              : child;
                      })
                    : children}
            </div>
            {buttonComponent ? (
                buttonComponent
            ) : (
                <Button type={'submit'} onClick={onSubmit} variant={'contained'} sx={{ mt: 2 }} size={'large'}>
                    {buttonLabel}
                </Button>
            )}
        </FormWrapperStyled>
    );
};

export default Form;
