import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { useTrans } from "@core/Hook/trans";
import Box from "@mui/material/Box";
import ModalTypography from "../Partial/ModalTypography";
import FormInputPasscode from "@core/Form/FormInputPasscode";
import FormControlWrapper from "@core/Form/FormControlWrapper";
import FadeInUp from "../../../Animate/FadeInUp";
import InputPasscodeForm, {IInputPassCodeData} from "../Form/InputPasscodeForm";
import {IValidateOtpResponse, useManageOtp} from "../../../Hook/auth";

interface IForgetPasswordStepOtp {
    onSubmit: (data: IValidateOtpResponse) => void;
    otpToken: string;
    phoneNumber?: string;
}

const ForgetPasswordStepOtpStyled = styled(Stack)(({ theme }) => ({
    '&.x-forget-password-step-otp': {
        '.-description-wrapper': {
            marginTop: theme.spacing(3),
        },
        '.-form-wrapper': {
            margin: theme.spacing(3.5, 0, 1),
        },
    }
}))

const ForgetPasswordStepOtp: React.FC<IForgetPasswordStepOtp> = ({ onSubmit, otpToken, phoneNumber }) => {
    const { trans } = useTrans();
    const [payload, setPayload] = useState('')
    const {submitOtp, apiOtpState} = useManageOtp();

    const handleSubmitOtp = (data: IInputPassCodeData) => {
        const formData = {
            otpCode: data.forgetPasswordOtp
        }

        return submitOtp(formData, otpToken).then((res) => {
            if (res && res.validateToken) {
                return onSubmit({
                    validateToken: res.validateToken
                })
            }
        })
    }

    return (
        <ForgetPasswordStepOtpStyled className={'x-forget-password-step-otp'}>
            <FadeInUp>
                <Box className={'-description-wrapper'}>
                    <ModalTypography renderType={'title'} align={'center'}>{trans('modal.forget_password_otp.description')}</ModalTypography>
                    <ModalTypography align={'center'}>{trans('modal.forget_password_otp.sub_description', {
                        'phoneNumber': phoneNumber
                    })}</ModalTypography>
                </Box>
                <Box className={'-form-wrapper'}>
                    <InputPasscodeForm
                        labelSubmit={trans('modal.forget_password_otp.submit_password_otp')}
                        inputName={'forgetPasswordOtp'}
                        isLoading={apiOtpState.isLoading}
                        payload={payload}
                        onSubmit={(data) => handleSubmitOtp(data)}
                    >
                        <FormControlWrapper
                            required={true}
                            className={'-form-control-wrapper'}
                        >
                            <FormInputPasscode
                                isOtp
                                value={payload}
                                onChange={(val) => setPayload(val)}
                            />
                        </FormControlWrapper>
                    </InputPasscodeForm>
                </Box>
            </FadeInUp>
        </ForgetPasswordStepOtpStyled>
    )
}

export default ForgetPasswordStepOtp
