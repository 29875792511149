import React, {useEffect} from "react";
import { useTrans } from "@core/Hook/trans";
import {useLocation, useNavigate} from "react-router-dom";
import {Image} from "@core/Utils/image";
import isEmpty from "lodash/isEmpty";
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {BeatLoader} from "react-spinners";
import Typography from "@mui/material/Typography";
import {parse, stringify} from "qs";
import trim from "lodash/trim";

const AuthenticationStyled = styled(Container)(({ theme }) => ({
    '&.x-authentication-container': {
        height: '100dvh',
        '.-authentication-wrapper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            whiteSpace: 'break-spaces',
            '.-image-loading-wrapper': {
                width: '200px',
            },
            '.-loader-wrapper': {
                margin: theme.spacing(2, 0),
            },
            '.-description-text': {
                textAlign: 'center',
                fontSize: theme.baseVariables.fonts.fontSizeSmall
            }
        }
    }
}))

const Authentication: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryString = parse(trim(location.search, '?'));
    const qs = queryString?.qs as string;
    const { trans } = useTrans();

    const handleAuth = async () => {
        const decodeQs = JSON.parse(atob(qs));

        if (isEmpty(decodeQs)) return navigate('/');

        return navigate(`/?serverErrorCode=${decodeQs.serverErrorCode}`);
    }

    useEffect(() => {
        if (isEmpty(qs)) {
            return navigate('/');
        }

        handleAuth();
    }, [])

    return (
        <AuthenticationStyled className={'x-authentication-container'}>
            <Box className="-authentication-wrapper">
                <Box className={'-image-loading-wrapper'}>
                    <Image
                        extraClass={'-image-logo-loading'}
                        src={'logo.png'}
                        width={387}
                        height={133}
                    />
                </Box>
                <Box className={'-loader-wrapper'}>
                    <BeatLoader color={'#5a80ee'} />
                </Box>
                <Typography variant={'body1'} className={'-description-text'}>{trans('authentication.verify.description')}</Typography>
            </Box>
        </AuthenticationStyled>
    )
}

export default Authentication
