import React, {ReactElement} from "react";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {Image} from "@core/Utils/image";
import {BarLoader} from "react-spinners";

const LoadingFullPageStyled = styled(Box)(({theme}) => ({
    '&.x-loading-full-page-wrapper': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        '.-image-loading-wrapper': {
            width: '200px',
        },
        '.-loading-wrapper': {
            marginTop: theme.spacing(2),
        }
    }
}))


const LoadingFullPage = (): ReactElement => {
    return (
        <LoadingFullPageStyled className="x-loading-full-page-wrapper">
            <Box className={'-image-loading-wrapper'}>
                <Image
                    extraClass={'-image-logo-loading'}
                    src={'logo.png'}
                    width={387}
                    height={133}
                />
            </Box>
            <Box className={'-loading-wrapper'}>
                <BarLoader width={190} color={'#5a80ee'} speedMultiplier={1} />
            </Box>
        </LoadingFullPageStyled>
    )
};

export default LoadingFullPage
