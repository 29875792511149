import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import { setRecoilExternalState } from "../Recoil/RecoilExternalStatePartial";
import { AlertAtom } from "../Recoil/Ui/atom";
import forOwn from "lodash/forOwn";
import isEmpty from "lodash/isEmpty";
import { parseTemplate } from "url-template";
import {LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE} from "../Constants/common";
import {IOmiseWrapper} from "./omise";
import FormData from "form-data";

interface IClientVar {
    PUSHER_AUTH_KEY: string,
    API_URL: string,
    TOKEN?: string
}

declare global {
    interface Window {
        CLIENT_VAR: IClientVar;
        OMISE_CARD_WRAPPER: IOmiseWrapper | undefined;
        OmiseCard: any;
    }
}

export class ApiClient {
    private readonly _apiEndpoint: string;
    private _ignoreFormDataKey: string[];
    private _client: AxiosInstance;
    private readonly _debug: boolean;
    public _cancel: () => void;

    constructor(apiEndpoint: string, debug: boolean) {
        this._apiEndpoint = apiEndpoint;
        this._ignoreFormDataKey = [];
        this._debug = debug;
        this._client = axios.create({
            timeout: 100000,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'api-key': process.env['REACT_APP_API_KEY'],
            },
            cancelToken: new axios.CancelToken((c) => {
                this._cancel = c;
            }),
        });

        this._cancel = () => {
            // on cancel
        };
    }

    set _accessToken(accessToken: string | undefined) {
        if (isEmpty(accessToken)) {
            delete this._client.defaults.headers.common['Authorization'];

            return;
        }

        this._client.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    }

    responseHandler(promise: Promise<AxiosResponse>): Promise<AxiosResponse> {
        // @ts-ignore
        return promise
            .then((response) => {
                this._debug && console.log("%cRESPONSE => ", "color: green; font-weight: bold;", response);

                return response;
            })
            .catch((err) => {
                if (401 === err.response.status) {
                    setRecoilExternalState(AlertAtom, {
                        show: true,
                        title: '401',
                        content: 'Session is expired.',
                    })
                    window.localStorage.removeItem(LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE)
                }

                return err.response;
            });
    }

    parseUrl(path: string): string {
        const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)/;
        if (regex.test(path)) {
            return path;
        }

        return `${this._apiEndpoint}${path}`;
    }

    requestHandler(formData: object): string {
        const transformedFormData = formData;

        forOwn(formData, (v: any, k: string) => {
            if (null !== v && 'undefined' !== typeof v && -1 !== this._ignoreFormDataKey.indexOf(k)) {
                // @ts-ignore
                delete transformedFormData[k];
            }
        });

        this._debug && console.log("%cFORM_DATA => ", "color: blue; font-weight: bold;", transformedFormData);

        return JSON.stringify(transformedFormData);
    }

    async get(_path: string, _params: object = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
        const url = parseTemplate(`${this.parseUrl(_path)}{?_params*}`).expand({
            _params: {
                ..._params
            }
        });

        this._debug && console.log('API[GET] ==>', url);

        return this.responseHandler(this._client.get(url, config));
    }

    async post(_path: string, _formData: object = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
        const url = this.parseUrl(_path);

        this._debug && console.log('API[POST] ==>', url);

        return this.responseHandler(
            this._client.post(url, this.requestHandler(_formData), config)
        );
    }

    async postForm(_path: string, _formData: object = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
        const url = this.parseUrl(_path);

        const formData = new FormData();
        for (const key in _formData) {
            formData.append(key, formData[key]);
        }

        this._debug && console.log('API[POST] ==>', url);

        return this.responseHandler(this._client.post(url, _formData, config));
    }

    async put(_path: string, _formData: object = {}): Promise<AxiosResponse> {
        const url = this.parseUrl(_path);

        this._debug && console.log('API[PUT] ==>', url);

        return this.responseHandler(
            this._client.put(url, this.requestHandler(_formData))
        );
    }

    async patch(_path: string, _formData: object = {}): Promise<AxiosResponse> {
        const url = this.parseUrl(_path);

        this._debug && console.log('API[PATCH] ==>', url);

        return this.responseHandler(
            this._client.patch(url, this.requestHandler(_formData))
        );
    }

    async delete(_path: string, _params: object = {}): Promise<AxiosResponse> {
        const url = parseTemplate(`${this._apiEndpoint}${_path}{?_params*}`).expand({
            _params: {
                ..._params
            }
        });

        this._debug && console.log('API[DELETE] ==>', url);

        return this.responseHandler(
            this._client.delete(url)
        );
    }
}

const client = new ApiClient(`${process.env['REACT_APP_API_BASE_URL']}/${process.env['REACT_APP_API_VERSION']}`, false);

export default client;
