import React from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useTrans } from "@core/Hook/trans";
import ModalTypography from "../Partial/ModalTypography";
import Box from "@mui/material/Box";
import TextWithLink from "../../TextWithLink";
import FadeInUp from "../../../Animate/FadeInUp";
import PhoneNumberForm, {IPhoneNumberFormData} from "../Form/PhoneNumberForm";
import SSOButtonGroup from "../../SSOButtonGroup";

interface ILoginStepPhoneNumber {
    onSubmit: (data: IPhoneNumberFormData) => void;
    onClickRegister?(): void;
    isLoading?: boolean;
}

const LoginStepPhoneNumberStyled = styled(Stack)(({ theme }) => ({
    '&.x-login-step-phone-number': {
        '.-icon-wrapper': {
            margin: theme.spacing(2, 0),
            textAlign: 'center',
            '.-img': {
                display: 'inline-block',
                width: 80,
            }
        },
        '.-description-wrapper': {
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(3),
            }
        },
        '.-form-wrapper': {
            margin: theme.spacing(3.5, 0, 1),
        },
        '.-text-with-link-wrapper': {
            marginTop: theme.spacing(3),
        }
    }
}))

const LoginStepPhoneNumber: React.FC<ILoginStepPhoneNumber> = ({ onSubmit, onClickRegister, isLoading }) => {
    const { trans } = useTrans();

    const submitPhoneNumber = (data: IPhoneNumberFormData) => onSubmit(data);

    return (
        <LoginStepPhoneNumberStyled className={'x-login-step-phone-number'}>
            <FadeInUp>
                {/*<Box className={'-icon-wrapper'}>*/}
                {/*    <Image*/}
                {/*        src={'ic-chat.png'}*/}
                {/*        width={60}*/}
                {/*        height={60}*/}
                {/*    />*/}
                {/*</Box>*/}
                <Box className={'-description-wrapper'}>
                    <ModalTypography renderType={'title'} align={'center'}>{trans('modal.login_phone_number.enter_phone_number')}</ModalTypography>
                    <ModalTypography align={'center'}>{trans('modal.login_phone_number.phone_number_as_registered')}</ModalTypography>
                </Box>
                <PhoneNumberForm onSubmit={(data) => submitPhoneNumber(data)} isLoading={isLoading} />
                <SSOButtonGroup />
                <Box className={'-text-with-link-wrapper'}>
                    <TextWithLink
                        title={trans('modal.login.do_not_have_an_account')}
                        linkTitle={trans('modal.login.register')}
                        linkOnClick={onClickRegister}
                    />
                </Box>
            </FadeInUp>
        </LoginStepPhoneNumberStyled>
    )
}

export default LoginStepPhoneNumber
