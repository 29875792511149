import React from 'react';
import { useTrans } from '@core/Hook/trans';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Image } from '@core/Utils/image';
import { styled } from '@mui/material/styles';
import { useScrollspy } from '../../Hook/scrollspyHook';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import isUndefined from 'lodash/isUndefined';

interface IMenus {
    renderLocation?: 'header' | 'mobile-dialog' | 'footer';
    onClick?(): void;
}

interface IMenuItem {
    code: string;
    title: string;
    icon: string;
    targetId?: string | undefined;
    path?: string | undefined;
    isEnable: boolean;
}

const MenusStyled = styled(Box)(({ theme }) => ({
    '&.x-menu': {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        '&.-in-header, &.-in-footer': {
            flex: 1,
            justifyContent: 'space-evenly',
            flexWrap: 'nowrap',
            maxWidth: 850,
        },
        '&.-in-mobile-dialog': {
            columnGap: theme.spacing(1.25),
            rowGap: theme.spacing(1.25),
        },
        '&.-in-footer': {
            flexWrap: 'wrap',
        },
    },
    '.x-menu-item': {
        textTransform: 'none',
        '&.-in-mobile-dialog': {
            flexDirection: 'column',
            width: 'calc(50% - 5px)',
            padding: theme.spacing(1.5, 0.5),
            fontWeight: theme.baseVariables.fonts.fontWeightMedium,
            background: theme.baseVariables.menus.mobileBg,
            border: theme.baseVariables.menus.mobileBorder,
            borderRadius: 6,
            transition: 'filter .2s',
            '.-img': {
                marginBottom: theme.spacing(0.75),
            },
            '.-text': {
                fontSize: theme.baseVariables.fonts.fontSizeSmallLarge,
                [theme.breakpoints.up('md')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeNormal,
                },
            },
            '&:active': {
                filter: theme.baseVariables.menus.mobileActiveFilter,
            },
        },
        '&.-in-header, &.-in-footer': {
            background: 'transparent',
            padding: theme.spacing(0.75),
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(0, 2),
            },
            '.-text': {
                fontSize: theme.baseVariables.fonts.fontSizeSmallerLarge,
                fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                color: theme.baseVariables.menus.desktopColor,
                transition: 'color .2s',
                [theme.breakpoints.up('lg')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeSmall,
                },
            },
        },
        '&.-in-header': {
            '.-text': {
                [theme.breakpoints.only('md')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                },
            },
            '&.-active': {
                background: theme.baseVariables.menus.desktopActiveBg,
                '.-text': {
                    color: theme.baseVariables.menus.desktopActiveColor,
                },
            },
            '&:hover': {
                background: theme.baseVariables.menus.desktopActiveBg,
                '.-text': {
                    color: theme.baseVariables.menus.desktopActiveColor,
                },
            },
        },
        '&.-in-footer': {
            width: '50%',
            [theme.breakpoints.up('sm')]: {
                width: '33%',
            },
            [theme.breakpoints.up('md')]: {
                width: 'auto',
                padding: theme.spacing(1, 2),
            },
            '.-text': {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                fontWeight: theme.baseVariables.fonts.fontWeightMedium,
            },
        },
    },
}));

const Menus: React.FC<IMenus> = ({ renderLocation = 'mobile-dialog', onClick }) => {
    const { trans } = useTrans();
    const location = useLocation();
    const navigate = useNavigate();

    const isHomepage = matchPath('/', location.pathname);
    const renderAllLocation = ['header', 'mobile-dialog', 'footer'];

    const menus: IMenuItem[] = [
        {
            code: 'our-service',
            title: trans('menu.our_service'),
            icon: 'ic-menu-our-service.png',
            targetId: 'homepageSectionOurService',
            isEnable: renderAllLocation.includes(renderLocation),
        },
        {
            code: 'why-us',
            title: trans('menu.why_us'),
            icon: 'ic-menu-why-us.png',
            targetId: 'homepageSectionWhyChooseUs',
            isEnable: renderAllLocation.includes(renderLocation),
        },
        {
            code: 'usage-step',
            title: trans('menu.usage_step'),
            icon: 'ic-menu-usage-step.png',
            targetId: 'homepageSectionHowToUse',
            isEnable: renderAllLocation.includes(renderLocation),
        },
        {
            code: 'review',
            title: trans('menu.review'),
            icon: 'ic-menu-review.png',
            targetId: 'homepageSectionReview',
            isEnable: renderAllLocation.includes(renderLocation),
        },
        {
            code: 'faq',
            title: trans('menu.faq'),
            icon: 'ic-menu-faq.png',
            targetId: 'homepageSectionFAQ',
            isEnable: renderAllLocation.includes(renderLocation),
        },
        {
            code: 'contact-us',
            title: trans('menu.contact_us'),
            icon: 'ic-menu-contact-us.png',
            targetId: 'homepageSectionContactUs',
            isEnable: renderAllLocation.includes(renderLocation),
        },
        {
            code: 'term-and-condition',
            title: trans('menu.term_and_condition'),
            icon: 'ic-menu-contact-us.png',
            targetId: undefined,
            path: '/policy/term-and-condition',
            isEnable: ['footer'].includes(renderLocation),
        },
        {
            code: 'privacy-policy',
            title: trans('menu.privacy_policy'),
            icon: 'ic-menu-contact-us.png',
            targetId: undefined,
            path: '/policy/privacy',
            isEnable: ['footer'].includes(renderLocation),
        },
        {
            code: 'refund-policy',
            title: trans('menu.refund_policy'),
            icon: 'ic-menu-contact-us.png',
            targetId: undefined,
            path: '/policy/refund',
            isEnable: ['footer'].includes(renderLocation),
        },
    ].filter((menu) => menu.isEnable);

    const activeId = useScrollspy(
        menus.map((menu) => menu.targetId as string),
        50,
    );

    const handleClickMenu = (menu: IMenuItem) => {
        if (!isUndefined(menu.path)) return navigate(menu.path);
        if (isUndefined(menu.targetId)) return;

        const id = menu.targetId;

        if (!isHomepage) {
            navigate('/');

            setTimeout(() => {
                const element = document.getElementById(id);
                _scrollToView(element, 50);
            }, 375);

            return;
        }

        const element = document.getElementById(id);
        if (onClick) onClick();

        setTimeout(
            () => {
                _scrollToView(element, 40);
            },
            renderLocation !== 'mobile-dialog' ? 0 : 375,
        );
    };

    const _scrollToView = (element: HTMLElement | null, offset: number) => {
        if (!element) return;

        window.scrollTo({
            behavior: 'smooth',
            top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
        });
    };

    return (
        <MenusStyled className={`x-menu -in-${renderLocation}`}>
            {menus.map((menu, index) => {
                return (
                    <Button
                        key={`menu-item-${index}-${menu.code}`}
                        onClick={() => handleClickMenu(menu)}
                        className={`x-menu-item -in-${renderLocation} ${activeId === menu.targetId ? '-active' : ''}`}
                    >
                        {renderLocation === 'mobile-dialog' && <Image src={menu.icon} width={25} height={25} />}
                        <Typography className={'-text'}>{menu.title}</Typography>
                    </Button>
                );
            })}
        </MenusStyled>
    );
};

export default Menus;
