import React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { useTrans } from "@core/Hook/trans";
import Box from "@mui/material/Box";
import ModalTypography from "../Partial/ModalTypography";
import FadeInUp from "../../../Animate/FadeInUp";
import RegisterByEmailForm, {IRegisterByEmailFormData} from "../Form/RegisterByEmailForm";
import SSOButtonGroup from "../../SSOButtonGroup";

interface IRegisterByEmailBody {
    onSubmit: (data: { password: string; email: string }) => void;
    isLoading: boolean;
}

const RegisterByEmailStyled = styled(Stack)(({ theme }) => ({
    '&.x-register-by-email': {
        paddingBottom: theme.spacing(1.5),
        '.-icon-wrapper': {
            margin: theme.spacing(2, 0),
            textAlign: 'center',
            '.-img': {
                display: 'inline-block',
                width: 80,
            }
        },
        '.-description-wrapper': {
            marginTop: theme.spacing(1.5),
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(3),
            }
        },
        '.-form-wrapper': {
            margin: theme.spacing(3.5, 0, 1),
        },
    }
}))

const RegisterByEmail: React.FC<IRegisterByEmailBody> = ({ onSubmit, isLoading }) => {
    const { trans } = useTrans();

    const submitEmail = async (data: IRegisterByEmailFormData) => {
        onSubmit({
            email: data.email,
            password: data.password,
        });
    }

    return (
        <RegisterByEmailStyled className={'x-register-by-email'}>
            <FadeInUp>
                <Box className={'-description-wrapper'}>
                    <ModalTypography renderType={'title'} align={'center'}>{trans('modal.register_email.enter_email')}</ModalTypography>
                </Box>
                <RegisterByEmailForm onSubmit={(data) => submitEmail(data)} isLoading={isLoading} />
                <SSOButtonGroup />
            </FadeInUp>
        </RegisterByEmailStyled>
    )
}

export default RegisterByEmail
