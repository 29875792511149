import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const BoostShareIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_1338_2155)">
                    <path d="M19.0615 15.0762C17.7155 15.0762 16.5294 15.7383 15.7844 16.7452L9.3433 13.447C9.45024 13.0825 9.52539 12.7048 9.52539 12.3062C9.52539 11.7655 9.41454 11.2515 9.22305 10.7787L15.964 6.72234C16.7141 7.60275 17.8168 8.17383 19.0615 8.17383C21.3152 8.17383 23.1484 6.34057 23.1484 4.08691C23.1484 1.83325 21.3152 0 19.0615 0C16.8079 0 14.9746 1.83325 14.9746 4.08691C14.9746 4.60627 15.0815 5.09893 15.259 5.5563L8.49799 9.62459C7.74845 8.77034 6.66169 8.21924 5.43848 8.21924C3.18482 8.21924 1.35156 10.0525 1.35156 12.3062C1.35156 14.5598 3.18482 16.3931 5.43848 16.3931C6.80668 16.3931 8.01282 15.7115 8.75501 14.6763L15.1749 17.9638C15.0567 18.3455 14.9746 18.743 14.9746 19.1631C14.9746 21.4167 16.8079 23.25 19.0615 23.25C21.3152 23.25 23.1484 21.4167 23.1484 19.1631C23.1484 16.9094 21.3152 15.0762 19.0615 15.0762Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_1338_2155">
                        <rect width="23.25" height="23.25" fill="white" transform="translate(0.625)"/>
                    </clipPath>
                </defs>
            </svg>
        </ServiceProductIcon>
    )
}

export default BoostShareIcon
