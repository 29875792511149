import React, { memo, ReactElement, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fade from '@mui/material/Fade';
import { styled } from "@mui/material/styles";
import { Breakpoint, DialogActions, IconButton, PaperProps } from "@mui/material";
import classNames from "classnames";

export interface IModalComponentNeeded {
    title?: null | string | ReactElement,
    titleCustom?: ReactNode,
    titleColor?: 'primary' | 'normal',
    subTitle?: string,
    open: boolean,
    handleClose: () => void,
    showCloseIcon?: boolean;
    actions?: ReactNode;
    data?: any;
    customStyle?: PaperProps;
    isFixed?: boolean;
    hideBackdrop?: boolean;
    hasTabHeading?: boolean;
}

export interface IDefaultModal extends IModalComponentNeeded {
    children?: ReactNode;
    dialogSize?: false | Breakpoint | undefined;
    extraClass?: string;
    isHalfSize?: boolean;
}

const DefaultModalStyled = styled(Dialog)(({ theme }) => {
    const { breakpoints, baseVariables, spacing } = theme;

    return ({
        '&.x-modal': {
            '.MuiPaper-root': {
                margin: '1rem',
                width: '100%',
                borderRadius: '15px',
                background: baseVariables.modal.bg,
                borderWidth: baseVariables.modal.borderWidth,
                borderStyle: baseVariables.modal.borderStyle,
                borderColor: baseVariables.modal.borderColor,
            },
            '&.-has-tab': {
                '.MuiPaper-root': {
                    [theme.breakpoints.up('sm')]: {
                        overflow: 'unset',
                    },
                },
                '.-ic-close-wrapper': {
                    top: '3.125rem',
                    [theme.breakpoints.up('sm')]: {
                        top: '-1.75rem',
                        right: '-2.25rem',
                        'i.fa-xmark': {
                            color: 'white',
                        },
                    },
                }
            },
            '.-ic-close-wrapper': {
                position: 'absolute',
                top: spacing(0),
                right: spacing(0),
                zIndex: 1,
                width: 40,
                height: 40,
                fontSize: baseVariables.fonts.fontSizeBig,
                'i.fa-xmark': {
                    color: baseVariables.modal.iconCloseColor
                },
            },
            '& .-modal-title': {
                textAlign: 'center',
                fontSize: baseVariables.fonts.fontSizeNormal,
                fontWeight: baseVariables.modal.titleFontWeight,
                color: baseVariables.modal.titleColor,
                [breakpoints.up('sm')]: {
                    fontSize: baseVariables.fonts.fontSizeNormalLarge,
                },
                '&.-primary': {
                    padding: theme.spacing(4, 2, 1),
                    color: theme.palette.primary.main,
                },
            },
            '.-modal-sub-title': {
                fontSize: baseVariables.fonts.fontSizeSmall,
                fontWeight: baseVariables.fonts.fontWeightNormal,
                color: baseVariables.modal.subTitleColor,
                margin: theme.spacing(1, 0),
                [breakpoints.up('sm')]: {
                    fontSize: baseVariables.fonts.fontSizeNormal,
                },
            },
            '& .-modal-content-wrapper': {
                fontSize: baseVariables.fonts.fontSizeSmall,
                [breakpoints.up('sm')]: {
                    padding: spacing(0, 6, 3),
                },
                [breakpoints.up('md')]: {
                    fontSize: baseVariables.fonts.fontSizeNormal,
                },
                '&.-fixed-content': {
                    overflow: 'hidden',
                },
            },
            '.-modal-action-wrapper': {
                justifyContent: 'center',
                padding: spacing(0, 0, 2.5),
            },
            '&.-order-cancel-modal': {
                '.-modal-title': {
                    paddingTop: spacing(3),
                },
                '.-modal-content-wrapper': {
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: spacing(0),
                        paddingRight: spacing(0),
                    }
                }
            },
            '.MuiDialogActions-root': {
                '& > *': {
                    minWidth: 130,
                },
                '& > :not(:first-of-type)': {
                    marginLeft: theme.spacing(2),
                }
            },
            '&.-half-size': {
                '.MuiPaper-root': {
                    minHeight: 360,
                    [theme.breakpoints.up('sm')]: {
                        minHeight: 330,
                    }
                }
            },
            '&.-email-validation-modal': {
                '.-modal-content-wrapper': {
                    [theme.breakpoints.up('sm')]: {
                        padding: theme.spacing(0, 5, 3),
                    }
                }
            }
        },
    })
});

const DefaultModal: React.FC<IDefaultModal> = (props) => {
    const {
        open,
        title,
        titleCustom,
        titleColor = 'normal',
        subTitle,
        handleClose,
        children,
        actions,
        showCloseIcon,
        dialogSize = 'xs',
        customStyle,
        isFixed,
        isHalfSize,
        extraClass,
        hasTabHeading ,
        hideBackdrop = false,
    } = props

    const cls = classNames(
        'x-modal',
        isHalfSize && '-half-size',
        hasTabHeading && '-has-tab',
        extraClass
    )

    const closeModal = () => {
        handleClose();
    };

    return (
        <DefaultModalStyled
            fullWidth
            keepMounted
            hideBackdrop={hideBackdrop}
            open={open}
            className={cls}
            scroll={'paper'}
            maxWidth={dialogSize}
            onClose={closeModal}
            TransitionComponent={Fade}
            PaperProps={customStyle}
        >
            {showCloseIcon && (
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    className={classNames('-ic-close-wrapper', hasTabHeading && '-has-tab')}
                >
                    <i className="fa-solid fa-xmark" />
                </IconButton>
            )}
            {titleCustom
                ? titleCustom
                : (
                    <DialogTitle className={`-modal-title -${titleColor}`}>
                        {title && title}
                        {subTitle && (
                            <div className={'-modal-sub-title'}>{subTitle}</div>
                        )}
                    </DialogTitle>
                )
            }
            {children && (
                <DialogContent className={`-modal-content-wrapper ${isFixed ? '-fixed-content' : ''}`}>
                    {children}
                </DialogContent>
            )}
            {actions && (
                <DialogActions className={'-modal-action-wrapper'}>
                    {actions}
                </DialogActions>
            )}
        </DefaultModalStyled>
    );
}

export default memo(DefaultModal);
