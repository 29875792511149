import React, {useState} from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { useTrans } from "@core/Hook/trans";
import Box from "@mui/material/Box";
import ModalTypography from "../Partial/ModalTypography";
import FormInputPasscode from "@core/Form/FormInputPasscode";
import FormControlWrapper from "@core/Form/FormControlWrapper";
import FadeInUp from "../../../Animate/FadeInUp";
import * as Yup from "yup";
import {ISetPassword} from "../RegisterModal";
import InputPasscodeForm, {IInputPassCodeData} from "../Form/InputPasscodeForm";

interface IRegisterStepConfirmPassword {
    onSubmit: (data: ISetPassword) => void;
    firstPassword: string;
    isLoading?: boolean;
}

const RegisterStepConfirmPasswordStyled = styled(Stack)(({ theme }) => ({
    '&.x-register-step-password': {
        '.-description-wrapper': {
            marginTop: theme.spacing(3),
        },
        '.-form-wrapper': {
            margin: theme.spacing(3.5, 0, 1),
        },
    }
}))

const RegisterStepConfirmPassword: React.FC<IRegisterStepConfirmPassword> = ({ onSubmit, firstPassword, isLoading = false }) => {
    const { trans } = useTrans();
    const [payload, setPayload] = useState('')
    const validationSchema = Yup.object().shape({
        password: Yup.string().required(trans('modal.register_confirm_password.form.not_blank')).min(6, trans('modal.register_confirm_password.form.min', {min: 6})),
        confirmPassword: Yup.string()
            .required(trans('modal.register_confirm_password.form.not_blank'))
            .min(6, trans('modal.register_confirm_password.form.min', {min: 6}))
            .oneOf([Yup.ref('password')], trans('modal.register_confirm_password.form.password_not_match'))
    });

    const submitConfirmPassword = (data: IInputPassCodeData) => {
        onSubmit({
            password: data.confirmPassword
        });
    }

    return (
        <RegisterStepConfirmPasswordStyled className={'x-register-step-password'}>
            <FadeInUp>
                <Box className={'-description-wrapper'}>
                    <ModalTypography renderType={'title'} align={'center'}>{trans('modal.register_confirm_password.confirm_your_password')}</ModalTypography>
                </Box>
                <Box className={'-form-wrapper'}>
                    <InputPasscodeForm
                        labelSubmit={trans('modal.register_confirm_password.submit_password')}
                        inputName={'confirmPassword'}
                        extraFormData={{password: firstPassword}}
                        objectSchema={validationSchema}
                        onSubmit={(data) => submitConfirmPassword(data)}
                        payload={payload}
                        isLoading={isLoading}
                    >
                        <FormControlWrapper
                            required={true}
                            className={'-form-control-wrapper'}
                        >
                            <FormInputPasscode
                                passwordType
                                value={payload}
                                onChange={(val) => setPayload(val)}
                            />
                        </FormControlWrapper>
                    </InputPasscodeForm>
                </Box>
            </FadeInUp>
        </RegisterStepConfirmPasswordStyled>
    )
}

export default RegisterStepConfirmPassword
