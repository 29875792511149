import React, { ReactNode } from "react";
import { Waypoint } from "react-waypoint";
import { animated, useSpring } from 'react-spring';

export interface IAnimate extends IAnimateTransition {
    onEnter: () => void;
    transitionTo: any;
}

export interface IAnimateTransition {
    delay?: number;
    duration?: number;
    tension?: number;
    friction?: number;
    mass?: number;
    children?: ReactNode;
    extraClass?: string;
}

const Animate: React.FC<IAnimate> = ({
    children,
    onEnter,
    transitionTo,
    extraClass= '',
    delay = 150,
    duration = 300,
    mass = 1,
    tension = 170,
    friction = 26,
}) => {
    const transition = useSpring({
        delay,
        to: transitionTo,
        config: {
            mass,
            duration,
            tension,
            friction,
        }
    })

    return (
        <Waypoint onEnter={onEnter}>
            <animated.div className={`x-animate-waypoint ${extraClass}`} style={transition}>
                {children}
            </animated.div>
        </Waypoint>
    )
}

export default Animate;
