import React from "react";
import DefaultModal, { IDefaultModal } from "./DefaultModal";

const ConfirmModal:React.FC<IDefaultModal> = ({handleClose, ...rest}) => {
    return (
        <DefaultModal handleClose={handleClose} {...rest} />
    )
}

export default ConfirmModal;
