import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Image } from "@core/Utils/image";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTrans } from "@core/Hook/trans";
import Stack from "@mui/material/Stack";

interface IPolicyCover {
    title: string;
}

const CoverStyled = styled(Box)(({theme}) => ({
    '&.x-policy-cover-container': {
        padding: theme.spacing(7, 0, 0),
        '.-cover-container-wrapper': {
            padding: theme.spacing(4, 0, 0),
        },
        '.-image-wrapper': {
            width: '170px',
        },
        '.-title': {
            fontSize: theme.baseVariables.fonts.fontSizeNormalLarge,
            fontWeight: theme.baseVariables.fonts.fontWeightMedium,
        }
    }
}))

const PolicyCover: React.FC<IPolicyCover> = ({title}) => {
    const { trans } = useTrans();

    return (
        <CoverStyled className="x-policy-cover-container">
            <Container className={'-cover-container-wrapper'}>
                <Stack display={'flex'} direction={'column'} spacing={1} alignItems={'center'} justifyContent={'center'}>
                    <Box className={'-image-wrapper'}>
                        <Image
                            extraClass={'-img-logo'}
                            src={'wow-boost-logo.png'}
                            width={170}
                            height={58}
                        />
                    </Box>
                    <Typography variant={'h1'} className={'-title'}>
                        {trans(`policy.cover.title.${title}`)}
                    </Typography>
                </Stack>
            </Container>
        </CoverStyled>
    )
}

export default PolicyCover
