import React from "react";
import { useTrans } from "@core/Hook/trans";
import ConfirmModal from "../../Component/Modal/ConfirmModal";
import PlainButton from "../../Component/Button/PlainButton";
import PolicyRefund from "../Policy/PolicyRefund";

interface IRefundPolicyModal {
    open: boolean;
    handleClose: () => void;
}

const RefundPolicyModal: React.FC<IRefundPolicyModal> = ({open, handleClose}) => {
    const { trans } = useTrans();

    const confirmModalActions = () => {
        return (
            <>
                <PlainButton
                    color={'primary'}
                    label={trans('general.close')}
                    onClick={handleClose}
                />
            </>
        )
    }

    return (
        <ConfirmModal
            showCloseIcon
            open={open}
            title={trans('order_summary.refund_policy.button_text')}
            handleClose={handleClose}
            actions={confirmModalActions()}
            dialogSize={'sm'}
        >
            <PolicyRefund />
        </ConfirmModal>
    )
}

export default RefundPolicyModal
