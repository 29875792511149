import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Header from './Header';
import Footer from './Footer';
import Stack from '@mui/material/Stack';

interface ILayout {
    children: ReactNode;
    contentBg?: string;
    contentAvoidHeader?: boolean;
    isDisableFooter?: boolean;
    isDisableHeader?: boolean;
}

const LayoutStyled = styled(Stack)(({ theme }) => ({
    '&.x-layout': {
        height: '100%',
        '.-content-wrapper': {
            flex: 1,
            '&.-avoid-header': {
                paddingTop: theme.baseVariables.header.height,
            },
            '&.-bg-image': {
                backgroundSize: 'cover',
                backgroundPosition: 'top right',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
            },
            '&.-is-disable-footer': {
                paddingBottom: theme.spacing(20),
            },
        },
    },
}));

const Layout: React.FC<ILayout> = ({ children, contentBg, contentAvoidHeader, isDisableHeader = false, isDisableFooter = false }) => {
    const paddingBottomDisableFooterClass = isDisableFooter ? '-is-disable-footer' : '';
    const contentAttrs = {
        className: `-content-wrapper ${contentAvoidHeader ? '-avoid-header' : ''} ${contentBg ? '-bg-image' : ''} ${paddingBottomDisableFooterClass}`,
        style: contentBg ? { backgroundImage: `url(${contentBg})` } : {},
    };

    return (
        <LayoutStyled className={'x-layout'}>
            {!isDisableHeader && <Header />}
            <Box id={'mainContent'} {...contentAttrs}>
                {children}
            </Box>
            {!isDisableFooter && <Footer />}
        </LayoutStyled>
    );
};

export default Layout;
