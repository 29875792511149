import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTrans } from '@core/Hook/trans';
import PlainButton from '../Button/PlainButton';
import ConfirmModal from '../Modal/ConfirmModal';
import { useHandleDialog } from '../../Hook/modalDialogHook';
import isUndefined from 'lodash/isUndefined';
import { OrderTypeEnum } from '../../Constants/order';
import { IServiceProductChoice } from 'Page/Product/ProductGroup';

interface IOrderAction {
    currentState?: string;
    orderId?: string;
    orderType?: string;
    purchaseOrderProduct?: IServiceProductChoice;
}

const OrderActionStyled = styled(Box)(({ theme }) => ({
    '&.x-order-action-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: theme.spacing(3),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(6, 0, 3),
        },
        '.-btn': {
            width: 'calc(50% - 4px)',
            [theme.breakpoints.up('sm')]: {
                width: 'calc(50% - 6px)',
            },
        },
    },
}));

const OrderAction: React.FC<IOrderAction> = ({ currentState, orderId, orderType, purchaseOrderProduct }) => {
    const navigate = useNavigate();
    const { trans } = useTrans();
    const { open, handleClickOpen, handleClose } = useHandleDialog();

    const getButtonLabel = () => {
        if (isUndefined(currentState) || 'pending' === currentState) return trans('order.button.payment');
        if (['success', 'cancel'].includes(currentState)) return trans('order.button.purchase');

        return trans('order.button.home');
    };

    const handleClickedBack = () => {
        if (orderType === OrderTypeEnum.CREDIT) {
            navigate(`/account/credit/credit-history`);
            return;
        }
        navigate(`/account/order-history/${currentState}`);
    };

    const handleClicked = () => {
        if (isUndefined(currentState)) {
            handleClickOpen();
            return;
        }

        if ('pending' === currentState)
            return navigate('/account/order-summary', {
                state: {
                    type: 'product',
                    orderType: orderType,
                    isRePayment: true,
                },
            });

        if (['success', 'cancel'].includes(currentState)) {
            if (orderType === OrderTypeEnum.CREDIT) {
                return navigate(`/account/credit/amount`, {
                    state: {
                        orderId: orderId,
                        purchaseOrderProduct: purchaseOrderProduct,
                    },
                });
            }
            return navigate(`/services`, {
                state: {
                    orderId: orderId,
                },
            });
        }

        navigate('/');
    };

    const _confirmModalActions = () => (
        <>
            <PlainButton
                color={'secondary'}
                label={trans('general.cancel')}
                onClick={() => {
                    handleClose();
                }}
            />
            <PlainButton
                color={'primary'}
                label={trans('general.confirm')}
                onClick={() => navigate('/account/order-payment', { state: { orderType: orderType } })}
            />
        </>
    );

    return (
        <OrderActionStyled className={'x-order-action-container'}>
            <PlainButton label={trans('order.button.back')} color={'secondary'} extraClass={'-btn'} onClick={() => handleClickedBack()} />
            <PlainButton label={getButtonLabel()} extraClass={'-btn'} onClick={() => handleClicked()} />
            <ConfirmModal
                showCloseIcon
                open={open}
                title={trans('modal.checkout_confirm.title')}
                subTitle={trans('modal.checkout_confirm.sub_title')}
                handleClose={handleClose}
                actions={_confirmModalActions()}
            />
        </OrderActionStyled>
    );
};

export default OrderAction;
