import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useResponsive } from '@core/Hook/responsiveHook';
import { Asset } from '@core/Utils/image';
import { useTrans } from '@core/Hook/trans';
import PaymentDetail from './Payment/PaymentDetail';
import Layout from '../Component/Common/Layout';
import MetaSeo from '../Component/Common/MetaSeo';
import OrderSummary from '../Component/Order/OrderSummary';
import { useHandleDialog } from '../Hook/modalDialogHook';
import OrderCardLayout from '../Component/Order/OrderCardLayout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import usePaymentHook from '../Hook/paymentHook';
import TextWithLink from '../Component/TextWithLink';
import UploadSlipModal from './Payment/UploadSlipModal';
import { hasDecimal } from '../Utils/helper';
import Stack from '@mui/material/Stack';
import { SOCIAL_LINE_URL } from '../Constants/common';
import { IApiState, resolveClientApiState, useClientApiState } from '@core/Hook/api';
import { getOrderByPurchaseOrderId, getOrderCreditByPurchaseOrderId } from '../Api/order';
import { useRecoilState } from 'recoil';
import { OrderSingleAtom } from '../Recoil/Order/atom';
import moment from 'moment';
import { OrderTypeEnum, PaidStatusEnum } from '../Constants/order';
import isUndefined from 'lodash/isUndefined';
import { useManageProductInfo, useProductTranslation } from '../Hook/product';
import { NumericFormat } from 'react-number-format';

const PaymentRemarkStyled = styled(Box)(({ theme }) => ({
    '.MuiBox-root.x-text-with-link': {
        '.-text': {
            color: theme.baseVariables.paymentRemark.color,
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            textAlign: 'center',
        },
        '.-link': {
            marginLeft: theme.spacing(0.5),
            padding: 0,
            background: 'none',
            color: theme.baseVariables.paymentRemark.btnColor,
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
        },
        '&.-highlight': {
            '.-text': {
                color: theme.baseVariables.summary.priceDecimalColor,
            },
        },
    },
}));

const OrderPayment: React.FC = () => {
    const location = useLocation();
    const { isTabletOrMobile } = useResponsive();
    const { trans } = useTrans();
    const params = useParams();
    const uploadSlipModal = useHandleDialog();
    const navigate = useNavigate();
    const { orderPaymentPromptPay } = usePaymentHook();
    const [orderSingle, setOrderSingle] = useRecoilState(OrderSingleAtom);
    const [apiState, setApiState] = useClientApiState({ isLoading: false });
    const orderType = location.state?.orderType;
    const { platformLabelByCode, serviceLabelByCode, targetAccountLabelByCode, packageUnitLabelByCode } = useProductTranslation();
    const { splitProductItemFullCode } = useManageProductInfo();
    const isCreditOrder = orderType === OrderTypeEnum.CREDIT;
    const productItemFullCode = orderSingle?.purchaseOrderProduct?.productItemFullCode;

    const fetchOrderSingle = async () => {
        if (!params.refId) return;

        setApiState((prev: IApiState) => {
            return { ...prev, isLoading: true };
        });

        let res;
        if (orderType === OrderTypeEnum.CREDIT) {
            res = resolveClientApiState(await getOrderCreditByPurchaseOrderId(params.refId), true);
        } else {
            res = resolveClientApiState(await getOrderByPurchaseOrderId(params.refId), true);
        }

        setApiState({ ...res, isLoading: false });

        if (res.isOk) {
            setOrderSingle(res.data);

            if (res.data.paidStatus === PaidStatusEnum.PENDING) return;

            navigate(`/account/order-detail/${res.data.purchaseOrderRefId}`, {
                state: {
                    orderType: orderType,
                },
            });

            return;
        }
    };

    useEffect(() => {
        fetchOrderSingle();

        return () => {
            setOrderSingle(undefined);
        };
    }, []);

    const renderOrderCreditTitle = () => {
        return (
            <Box textAlign={'center'} color={'#5A80EE'} fontWeight={'bold'}>
                {trans('credit.credit_history_topic')}
                <NumericFormat displayType={'text'} thousandSeparator value={orderSingle?.purchaseOrderProduct?.amount} suffix={` บาท`} />
            </Box>
        );
    };

    const renderOrderTitle = () => {
        if (isUndefined(productItemFullCode)) return;

        const productInfoObject: { [p: string]: string } | undefined = splitProductItemFullCode(productItemFullCode as string);
        const producttitle = `${platformLabelByCode(productInfoObject?.platformCode as string)} ${serviceLabelByCode(
            productInfoObject?.serviceCode as string,
        )} ${targetAccountLabelByCode(productInfoObject?.targetCode as string)} `;

        return (
            <Box textAlign={'center'} color={'#5A80EE'} fontWeight={'bold'}>
                {producttitle}
            </Box>
        );
    };

    useEffect(() => {
        if (!window['ws_channel']) return;

        window['ws_channel'].bind(
            'order_promptpay_sucess',
            (data: { purchaseOrderRefId: string; returnUri: string }) => {
                return navigate(`/account/order-detail/${data.purchaseOrderRefId}`, {
                    state: {
                        isPaymentSuccess: true,
                    },
                });
            },
            'order_payment_success',
        );

        window['ws_channel'].bind(
            'order_promptpay_fail',
            (data: { purchaseOrderRefId: string; returnUri: string }) => {
                return navigate(`/account/order-detail/${data.purchaseOrderRefId}`, {
                    state: {
                        isPaymentSuccess: false,
                    },
                });
            },
            'order_payment_fail',
        );

        return () => {
            if (!window['ws_channel']) return;

            window['ws_channel'].unbind('order_payment_success');
            window['ws_channel'].unbind('order_payment_fail');
        };
    }, []);

    const bgImage = isTabletOrMobile ? Asset('wow-boost-history-purchase-bg-mobile.jpg') : Asset('wow-boost-history-purchase-bg.jpg');

    const isPriceDecimal = hasDecimal(Number(orderPaymentPromptPay?.totalPrice));
    const bankInfo = {
        name: orderPaymentPromptPay?.companyName || (orderSingle?.companyInfo.companyName as string),
        account: orderPaymentPromptPay?.companyAccount || (orderSingle?.companyInfo.companyAccount as string),
        bankCode: orderPaymentPromptPay?.bankCode || (orderSingle?.companyInfo.bankCode as string),
        ref_no: orderPaymentPromptPay?.refId || (orderSingle?.purchaseOrderRefId as string),
        qrcodeUrl: orderPaymentPromptPay?.qrcodeUrl || (orderPaymentPromptPay?.qrcodeUrl as string),
        paymentCancelledAt: orderPaymentPromptPay?.paidExpireAt || moment(orderSingle?.paidExpireAt).format('lll'),
    };

    if (!orderSingle) return <></>;
    if (orderSingle && orderSingle.paidStatus !== PaidStatusEnum.PENDING) return <></>;
    return (
        <Layout contentAvoidHeader contentBg={bgImage}>
            <MetaSeo title={trans('meta_seo.payment.title')} description={trans('meta_seo.payment.description')} />
            {/*{isTabletOrMobile && <PaymentAlert isOutside paymentCancelledAt={bankInfo.paymentCancelledAt} />}*/}

            <OrderCardLayout title={trans('payment.title')}>
                {orderPaymentPromptPay || orderSingle ? (
                    <>
                        {isCreditOrder ? renderOrderCreditTitle() : renderOrderTitle()}
                        <PaymentDetail bankInfo={bankInfo} />
                        <OrderSummary price={Number(orderSingle?.purchaseOrderReceipt.transactionPrice)} />
                        {isPriceDecimal && (
                            <PaymentRemarkStyled marginTop={1}>
                                <TextWithLink
                                    extraClass={'-highlight'}
                                    title={'** กรุณาโอนพร้อมเลขทศนิยม เพื่อความถูกต้องและรวดเร็วในการตรวจสอบค่ะ **'}
                                />
                            </PaymentRemarkStyled>
                        )}
                        <PaymentRemarkStyled marginTop={3}>
                            <TextWithLink title={trans(`payment.remark_1${isPriceDecimal ? '_decimal' : ''}`)} />
                            <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} flexWrap={'wrap'} gap={0.5}>
                                <TextWithLink
                                    title={trans(`payment.remark_2${isPriceDecimal ? '_decimal' : ''}`)}
                                    linkTitle={trans('payment.upload')}
                                    linkOnClick={uploadSlipModal.handleClickOpen}
                                />
                                {isPriceDecimal && (
                                    <TextWithLink
                                        title={trans('general.or')}
                                        linkTitle={trans('payment.contact_support')}
                                        linkOnClick={() => window.open(SOCIAL_LINE_URL, '_blank')}
                                    />
                                )}
                            </Stack>
                        </PaymentRemarkStyled>
                        <UploadSlipModal open={uploadSlipModal.open} handleClose={uploadSlipModal.handleClose} orderType={orderType} />
                    </>
                ) : (
                    <></>
                )}
            </OrderCardLayout>
        </Layout>
    );
};

export default OrderPayment;
