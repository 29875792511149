import React from "react";
import PlainButton from "../../Button/PlainButton";
import { useTrans } from "@core/Hook/trans";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import FormInput from "@core/Form/FormInput";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Form from "@core/Form/Form";
import * as Yup from "yup";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from '@mui/icons-material/Lock';

interface IRegisterByEmailForm {
    onSubmit: (data: IRegisterByEmailFormData) => void;
    isLoading?: boolean;
}

export interface IRegisterByEmailFormData {
    email: string;
    password: string;
    confirmPassword: string;
}

const RegisterByEmailForm: React.FC<IRegisterByEmailForm> = ({ onSubmit, isLoading = false }) => {
    const { trans } = useTrans();
    const validationSchema = Yup.object().shape({
        email: Yup.string().required(trans('modal.register_email.form.not_blank')).email(trans('modal.register_email.form.invalid_email')),
        password: Yup.string().required(trans('modal.register_email.form.password.not_blank'))
            .min(8, trans('modal.register_email.form.password.min', {min: 8})).max(12, trans('modal.register_email.form.password.max', {max: 12})),
        confirmPassword: Yup.string()
            .required(trans('modal.register_email.form.password.not_blank'))
            .min(8, trans('modal.register_email.form.password.min', {min: 8}))
            .oneOf([Yup.ref('password')], trans('modal.register_email.form.password.not_match'))
    });
    const { register, handleSubmit, formState: { errors } } = useForm<IRegisterByEmailFormData>({
        resolver: yupResolver(validationSchema)
    });

    const submitRegisterByEmail = (data: IRegisterByEmailFormData) => onSubmit(data);

    const renderButtonAction = () => {
        return (
            <PlainButton
                type={'submit'}
                fullWidth
                color={'primary'}
                label={trans('general.confirm')}
                isLoading={isLoading}
                disabled={isLoading}
            />
        )
    }

    const inputProps = {
        name: 'email',
        id: 'email',
        className: "-form-control",
        register,
        placeholder: trans('modal.register_email.form.email_placeholder'),
        errors: errors,
        type: 'text',
        fullWidth: true,
        autoFocus: true,
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <PersonIcon />
                </InputAdornment>
            ),
        },
    }

    const inputPasswordProps = {
        name: 'password',
        id: 'password',
        className: "-form-control",
        register,
        placeholder: trans('modal.register_email.form.password_placeholder'),
        errors: errors,
        type: 'password',
        fullWidth: true,
        helperText: trans('modal.register_email.form.password_helper'),
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <LockIcon />
                </InputAdornment>
            ),
        },
    }

    const inputConfirmPasswordProps = {
        name: 'confirmPassword',
        id: 'confirmPassword',
        className: "-form-control",
        register,
        placeholder: trans('modal.register_email.form.confirm_password_placeholder'),
        errors: errors,
        type: 'password',
        fullWidth: true,
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <LockIcon />
                </InputAdornment>
            ),
        },
    }

    return (
        <Form register={register} onSubmit={handleSubmit(submitRegisterByEmail)} buttonComponent={renderButtonAction()}>
            <Box className={'-input-wrapper'}>
                <FormInput {...inputProps} />
                <FormInput {...inputPasswordProps} />
                <FormInput {...inputConfirmPasswordProps} />
            </Box>
        </Form>
    )
}

export default RegisterByEmailForm
