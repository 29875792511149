import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { useTrans } from "@core/Hook/trans";
import Box from "@mui/material/Box";
import ModalTypography from "../Partial/ModalTypography";
import FormInputPasscode from "@core/Form/FormInputPasscode";
import FormControlWrapper from "@core/Form/FormControlWrapper";
import FadeInUp from "../../../Animate/FadeInUp";
import InputPasscodeForm, {IInputPassCodeData} from "../Form/InputPasscodeForm";
import * as Yup from "yup";
import {ISetPassword} from "../RegisterModal";

interface IForgetPasswordStepConfirmPassword {
    onSubmit: (data: ISetPassword) => void;
    newPassword: string;
    isLoading?: boolean;
}

const ForgetPasswordStepConfirmPasswordStyled = styled(Stack)(({ theme }) => ({
    '&.x-forget-password-step-confirm-password': {
        '.-description-wrapper': {
            marginTop: theme.spacing(3),
        },
        '.-form-wrapper': {
            margin: theme.spacing(3.5, 0, 1),
        },
    }
}))

const ForgetPasswordStepConfirmPassword: React.FC<IForgetPasswordStepConfirmPassword> = ({ onSubmit, newPassword, isLoading = false }) => {
    const { trans } = useTrans();
    const [payload, setPayload] = useState('')
    const validationSchema = Yup.object().shape({
        newPassword: Yup.string().required(trans('modal.forget_password_confirmed.form.not_blank')).min(6, trans('modal.forget_password_confirmed.form.min', {min: 6})),
        forgetPasswordConfirmPassword: Yup.string()
            .required(trans('modal.forget_password_confirmed.form.not_blank'))
            .min(6, trans('modal.forget_password_confirmed.form.min', {min: 6}))
            .oneOf([Yup.ref('newPassword')], trans('modal.forget_password_confirmed.form.password_not_match'))
    });

    const submitConfirmPassword = (data: IInputPassCodeData) => {
        onSubmit({
            password: data.forgetPasswordConfirmPassword
        });
    }

    return (
        <ForgetPasswordStepConfirmPasswordStyled className={'x-forget-password-step-confirm-password'}>
            <FadeInUp>
                <Box className={'-description-wrapper'}>
                    <ModalTypography renderType={'title'} align={'center'}>{trans('modal.forget_password_confirmed.description')}</ModalTypography>
                </Box>
                <Box className={'-form-wrapper'}>
                    <InputPasscodeForm
                        labelSubmit={trans('modal.forget_password_entered.submit_password')}
                        inputName={'forgetPasswordConfirmPassword'}
                        extraFormData={{newPassword: newPassword}}
                        objectSchema={validationSchema}
                        onSubmit={(data) => submitConfirmPassword(data)}
                        payload={payload}
                        isLoading={isLoading}
                    >
                        <FormControlWrapper
                            required={true}
                            className={'-form-control-wrapper'}
                        >
                            <FormInputPasscode
                                passwordType
                                value={payload}
                                onChange={(val) => setPayload(val)}
                            />
                        </FormControlWrapper>
                    </InputPasscodeForm>
                </Box>
            </FadeInUp>
        </ForgetPasswordStepConfirmPasswordStyled>
    )
}

export default ForgetPasswordStepConfirmPassword
