import Authentication from './Authentication';
import Homepage from './Homepage';
import Policy from './Policy';
import OrderHistory from './OrderHistory';
import OrderDetail from './OrderDetail';
import OrderPayment from './OrderPayment';
import OrderSummary from './OrderSummary';
import Services from './Services';
import Splash from './Splash';
import NotFound from './NotFound';
import EmailVerification from './EmailVerification';
import Credit from './Credit';
import PaymentMethod from './PaymentMethod';
import CreditHistory from './CreditHistory';

export default {
    Authentication,
    Homepage,
    Policy,
    Services,
    EmailVerification,
    OrderDetail,
    OrderSummary,
    OrderHistory,
    OrderPayment,
    Splash,
    NotFound,
    Credit,
    PaymentMethod,
    CreditHistory,
};
