import React from 'react';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OrderState from '../../Component/Order/OrderState';
import OrderProgress from '../../Component/Order/OrderProgress';
import { IOrder } from '../../Recoil/Order/atom';
import CopyToClipboard from '../../Component/CopyToClipboard';
import { ORDER_STATUS_PROGRESS, ORDER_STATUS_SUCCESS, OrderTypeEnum } from '../../Constants/order';
import { resolveClientApiState, useClientApiState } from '@core/Hook/api';
import { getPurchaseReceipt, getPurchaseReceiptCredit } from '../../Api/purchase';
import PlainButton from '../../Component/Button/PlainButton';
import { ToastAlert } from '@core/Component/AlertFadeInRight';
import { useTrans } from '@core/Hook/trans';
import { Skeleton } from '@mui/material';
import { getOrderStateLabelByCode, isOrderProcessStatus } from '../../Utils/helper';
import { useLocation } from 'react-router-dom';

interface IOrderDetailHeading {
    order: IOrder;
    isLoading?: boolean;
    isIndexPage?: boolean;
}

const OrderDetailHeadingStyled = styled(Box)(({ theme }) => ({
    '&.x-order-detail-heading': {
        '.-heading-description-wrapper': {
            width: '100%',
            background: theme.baseVariables.orderDetail.descriptionBg,
            textAlign: 'center',
            padding: theme.spacing(0.75),
            borderRadius: theme.spacing(0.5),
            '.-heading-description-text': {
                fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                color: theme.baseVariables.orderDetail.descriptionColor,
                [theme.breakpoints.up('sm')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeSmall,
                },
            },
        },
        '.-date-order-wrapper': {
            marginTop: theme.spacing(2),
            '.MuiTypography-root': {
                color: '#646f9b',
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                [theme.breakpoints.up('sm')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeSmall,
                },
            },
        },
        '.-loading-wrapper': {
            '.-loading-skeleton:first-of-type': {
                marginBottom: theme.spacing(2),
            },
        },
    },
}));

const OrderDetailHeading: React.FC<IOrderDetailHeading> = ({ order, isIndexPage, isLoading = true }) => {
    const { trans } = useTrans();
    const location = useLocation();
    const purchaseOrderProgress = order?.purchaseOrderProgress;
    const percent = (purchaseOrderProgress?.progressCount / purchaseOrderProgress?.progressTotal) * 100;
    const percentMustBeHundred = percent >= 100 ? 100 : percent;
    const orderState = getOrderStateLabelByCode(order.orderStatus);
    const shouldRenderReceipt = [ORDER_STATUS_PROGRESS, ORDER_STATUS_SUCCESS].includes(orderState);
    const [apiState, setApiState] = useClientApiState({
        isLoading: false,
    });
    const orderType = location.state?.orderType;

    const onClickReceipt = async () => {
        setApiState({ ...apiState, isLoading: true });

        let receiptRes;
        if (orderType === OrderTypeEnum.CREDIT) {
            receiptRes = resolveClientApiState(await getPurchaseReceiptCredit(order?.purchaseOrderRefId));
        } else {
            receiptRes = resolveClientApiState(await getPurchaseReceipt(order?.purchaseOrderRefId));
        }

        if (receiptRes.isOk) {
            const file = new Blob([receiptRes.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open() as Window;
            pdfWindow.location.href = fileURL;

            ToastAlert('success', trans('alert.success.title'));
            setApiState({ ...apiState, isLoading: false });

            return;
        }

        ToastAlert('error', trans('alert.error.title'));
        setApiState({ ...apiState, isLoading: false });
    };

    const renderOrderDetailDescription = () => {
        return (
            <Box className={'-heading-description-wrapper'}>
                <Typography component={'span'} className={'-heading-description-text'}>
                    {trans('order_detail.title_description')}
                </Typography>
            </Box>
        );
    };

    return (
        <OrderDetailHeadingStyled className={'x-order-detail-heading'}>
            {isLoading ? (
                <Box className={'-loading-wrapper'}>
                    <Skeleton height={37} className={'-loading-skeleton'} variant="rounded" />
                    <Skeleton height={24} className={'-loading-skeleton'} variant="rounded" />
                </Box>
            ) : (
                <>
                    {orderState && <OrderState state={orderState} isIndexPage={isIndexPage} />}
                    {isOrderProcessStatus(order?.orderStatus) && (
                        <OrderProgress
                            count={purchaseOrderProgress?.progressCount}
                            total={purchaseOrderProgress.progressTotal}
                            value={percentMustBeHundred}
                        />
                    )}
                    {isOrderProcessStatus(order?.orderStatus) && renderOrderDetailDescription()}
                    <Stack direction={'row'} justifyContent={'space-between'} className={'-date-order-wrapper'}>
                        <Typography>{moment(order.createdAt).format('DD/MM/YY HH:mm')}</Typography>
                        <Box>
                            <CopyToClipboard
                                copyMessage={`#${order.purchaseOrderRefId}`}
                                label={`#${order.purchaseOrderRefId}`}
                                textSize={'small'}
                                extraClass={'-order-id'}
                            />
                            {shouldRenderReceipt && (
                                <Stack display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                    <PlainButton
                                        color={'secondary'}
                                        label={trans('order_detail.show_receipt')}
                                        extraClass={'-text-underline -receipt'}
                                        onClick={onClickReceipt}
                                        isLoading={apiState.isLoading}
                                        disabled={apiState.isLoading}
                                    />
                                </Stack>
                            )}
                        </Box>
                    </Stack>
                </>
            )}
        </OrderDetailHeadingStyled>
    );
};

export default OrderDetailHeading;
