import React, { ReactNode } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Title from "../Title";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material";

interface OrderCardLayout {
    title?: string;
    children: ReactNode;
}

const OrderCardLayoutStyled = styled(Box)(({ theme }) => ({
    '&.x-order-card-layout': {
        padding: theme.spacing(2, 0),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4, 0),
        },
        '.-paper': {
            padding: theme.spacing(4, 2),
            background: 'white',
            border: '1px solid #e2efff',
            borderRadius: 15,
        },
        '.-stack': {
            '& > *': {
                width: '100%'
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: 550,
                margin: 'auto',
            },
            '.-title-custom': {
                [theme.breakpoints.up('md')]: {
                    marginBottom: theme.spacing(2),
                }
            },
        },
        '.MuiDivider-root': {
            margin: theme.spacing(2, 0),
            borderColor: alpha('#a1b8db', .5),
        }
    }
}))

const OrderCardLayout: React.FC<OrderCardLayout> = ({ title , children}) => {
    return (
        <OrderCardLayoutStyled className={'x-order-card-layout'}>
            <Container className={'-container'}>
                <Paper className={'-paper'} elevation={0}>
                    <Stack className={'-stack'} alignItems={"center"}>
                        {title && <Title variantTitle={'h1'} title={title} extraClass={'-title-custom'} />}
                        {children}
                    </Stack>
                </Paper>
            </Container>
        </OrderCardLayoutStyled>
    )
}

export default OrderCardLayout
