import React from "react";
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";
import {useTrans} from "@core/Hook/trans";
import {muiBreakpoints, useResponsive} from "@core/Hook/responsiveHook";
import HomepageSectionLayout from "./HomepageSectionLayout";
import Title from "../../Component/Title";
import {Asset, Image} from "@core/Utils/image";
import Box from "@mui/material/Box";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination, Scrollbar} from "swiper";
import {Card, CardActions, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import FadeInUp from "../../Animate/FadeInUp";

const ReviewStyled = styled(Container)(({theme}) => ({
    '&.x-homepage-review-container': {
        '.-swiper-slide': {
            marginTop: theme.spacing(3),
            '.-swiper-slide-item': {
                width: '90%',
                height: 'auto',
                display: 'grid',
                [theme.breakpoints.up('sm')]: {
                    width: '100%',
                },
            },
            '.swiper-pagination': {
                marginTop: theme.spacing(3),
                position: 'relative',
                bottom: 0,
                '.swiper-pagination-bullet': {
                    width: '10px',
                    height: '10px',
                    borderRadius: '8px',
                    transition: theme.baseVariables.constants.transition.normal,
                    background: theme.baseVariables.review.swiperBulletBg,
                    opacity: 1,
                    '&.swiper-pagination-bullet-active': {
                        width: '28px',
                        background: theme.baseVariables.review.swiperBulletBgActive,
                    }
                }
            },
            '.-card-wrapper': {
                display: 'flex',
                flexDirection: 'column',
                borderRadius: theme.spacing(2),
                borderWidth: '2px',
                '.-card-content-wrapper': {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    padding: theme.spacing(4, 3),
                    '.-review-text': {
                        marginTop: theme.spacing(1),
                        color: theme.baseVariables.review.cardContentColor,
                        fontSize: theme.baseVariables.fonts.fontSizeSmall,
                        [theme.breakpoints.up('sm')]: {
                            fontSize: theme.baseVariables.fonts.fontSizeNormal,
                        },
                    },
                },
                '.-card-action-wrapper': {
                    background: theme.baseVariables.review.cardActionBg,
                    padding: theme.spacing(2, 3),
                    '.-profile-image': {
                        display: 'flex',
                        borderRadius: '50%',
                        width: '50px',
                        height: '50px',
                        marginRight: theme.spacing(2),
                    },
                    '.-reviewer-text': {
                        color: theme.baseVariables.review.cardActionColor,
                        fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                    }
                }
            }
        }
    }
}))

const HomepageOurService: React.FC = () => {
    const {trans} = useTrans();
    const {isTabletOrMobile} = useResponsive();
    const bg = isTabletOrMobile ? Asset('wow-boost-homepage-review-bg-mobile.png') : Asset('wow-boost-homepage-review-bg.png')
    const contents = [
        {
            reviewer: 'ผู้ใช้ kom***',
            review: 'ระบบดำเนินการให้รวดเร็วมาก โอนเงินปุ๊บ ยอดขึ้นปั๊บ ใช้เวลาแค่ 1 ชั่วโมงกว่าๆก็ได้ยอดครบแล้ว มีต่อรอบหน้าแน่นอนค่าาา',
            profileImage: 'wow-boost-homepage-reviewer-1.png',
        },
        {
            reviewer: 'ผู้ใช้ gar****',
            review: 'ชอบตรงที่มีบริการรายงานสแปม ก่อนหน้านี้โดนมิจฉาชีพใช้รูปเราไปสร้างบัญชีปลอม แต่พอใช้บริการรายงานสแปมของที่นี่ ตอนนี้บัญชีปลอมหายไปแล้ว สบายใจมากเลยค่ะ แนะนำเลย',
            profileImage: 'wow-boost-homepage-reviewer-2.png',
        },
        {
            reviewer: 'ผู้ใช้ tan*****',
            review: 'ระบบใช้งานง่ายมาก แค่ก็อปลิ๊ง จ่ายเงิน รอแป๊ปเดียวยอดก็เพิ่มขึ้นแล้ว ประทับใจมากค้าบบบ',
            profileImage: 'wow-boost-homepage-reviewer-3.png',
        },
        {
            reviewer: 'ผู้ใช้ sao***',
            review: 'เว็บนี้เริสมากกก ซื้อยอด 1000 แต่ได้ยอดจริง 1300 กว่าๆ คือมีการแถมให้ด้วย ปังสุด อะไรสุด ชอบมากกกก ใครลังเล แนะนำเลยค่ะ',
            profileImage: 'wow-boost-homepage-reviewer-1.png',
        },
    ]

    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
            return '<span class="' + className + '">' + '</span>';
        },
    };

    return (
        <HomepageSectionLayout sectionId={'homepageSectionReview'} imageBg={bg}>
            <ReviewStyled className="x-homepage-review-container">
                <FadeInUp>
                    <Title title={trans('homepage.section.review.title')} subTitle={trans('homepage.section.review.sub_title')} />
                </FadeInUp>
                <FadeInUp>
                    <Swiper
                        slidesOffsetAfter={0}
                        slidesOffsetBefore={0}
                        spaceBetween={16}
                        slidesPerView={'auto'}
                        pagination={pagination}
                        modules={[Pagination, Scrollbar, Autoplay]}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: true,
                        }}
                        className={'-swiper-slide'}
                        breakpoints={{
                            [muiBreakpoints.md]: {
                                slidesPerView: 3,
                                slidesOffsetAfter: 0,
                                slidesOffsetBefore: 0,
                            },
                            [muiBreakpoints.sm]: {
                                slidesPerView: 2,
                                slidesOffsetAfter: 0,
                                slidesOffsetBefore: 0,
                            },
                        }}
                    >
                        {contents.map((item, index) => {
                            return (
                                <SwiperSlide
                                    key={`review-${item.reviewer}-${index}`}
                                    className={'-swiper-slide-item'}
                                >
                                    <Card variant="outlined" className={'-card-wrapper'}>
                                        <CardContent className={'-card-content-wrapper'}>
                                            <Box>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="39" height="36" viewBox="0 0 39 36" fill="none">
                                                    <path d="M29.1198 35.9995C26.1252 35.9995 23.9567 34.8457 22.6143 32.538C21.2719 30.2303 20.6007 27.5764 20.6007 24.5764C20.6007 22.8457 20.8072 20.9418 21.2203 18.8649C21.6333 16.788 22.4078 14.7111 23.5437 12.6341C24.7828 10.4418 26.4866 8.30721 28.6551 6.23028C30.8236 4.03798 33.715 1.96105 37.3291 -0.000488281L38.2585 1.5572C36.1932 3.74951 34.5411 5.65336 33.3019 7.26874C32.166 8.88413 31.2883 10.3841 30.6687 11.7687C30.0492 13.1534 29.6361 14.4226 29.4296 15.5764C29.3263 16.7303 29.2747 17.9418 29.2747 19.2111C29.2747 20.1341 29.5329 20.8264 30.0492 21.288C30.5655 21.6341 31.1334 22.0957 31.753 22.6726C32.6823 23.3649 33.5084 24.0572 34.2313 24.7495C35.0574 25.3264 35.4704 26.5957 35.4704 28.5572C35.4704 30.6341 34.8508 32.4226 33.6117 33.9226C32.3726 35.3072 30.8753 35.9995 29.1198 35.9995ZM8.67399 35.9995C5.6794 35.9995 3.45927 34.8457 2.0136 32.538C0.671201 30.2303 0 27.5764 0 24.5764C0 22.8457 0.206523 20.9995 0.61957 19.038C1.03262 16.9611 1.85871 14.8264 3.09785 12.6341C4.33699 10.4418 6.04081 8.30721 8.20931 6.23028C10.3778 4.03798 13.2691 1.96105 16.8833 -0.000488281L17.8127 1.5572C15.7474 3.74951 14.0952 5.65336 12.8561 7.26874C11.7202 8.88413 10.8425 10.3841 10.2229 11.7687C9.60334 13.1534 9.1903 14.4226 8.98377 15.5764C8.88051 16.7303 8.82888 17.9418 8.82888 19.2111C8.82888 20.1341 9.0354 20.8264 9.44845 21.288C9.96476 21.7495 10.5327 22.1534 11.1523 22.4995C11.9784 23.0764 12.8045 23.7687 13.6306 24.5764C14.5599 25.2687 15.0246 26.5957 15.0246 28.5572C15.0246 30.6341 14.405 32.4226 13.1659 33.9226C12.03 35.3072 10.5327 35.9995 8.67399 35.9995Z" fill="#A1B8DB"/>
                                                </svg>
                                                <Typography align={'left'} variant={'body1'} className={'-review-text'}>{item.review}</Typography>
                                            </Box>
                                        </CardContent>
                                        <CardActions className={'-card-action-wrapper'}>
                                            <Image
                                                src={item.profileImage}
                                                width={50}
                                                height={50}
                                                extraClass={'-profile-image'}
                                            />
                                            <Typography align={'left'} variant={'body1'} className={'-reviewer-text'}>{item.reviewer}</Typography>
                                        </CardActions>
                                    </Card>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </FadeInUp>
            </ReviewStyled>
        </HomepageSectionLayout>
    )
}

export default HomepageOurService
