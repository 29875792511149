import React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { useTrans } from "@core/Hook/trans";
import Box from "@mui/material/Box";
import { Image } from "@core/Utils/image";
import ModalTypography from "../Partial/ModalTypography";
import CountdownTimer from "../../CountdownTimer";
import moment from "moment";
import FadeInUp from "../../../Animate/FadeInUp";

interface IForgetPasswordSuccess {
    onEnd(): void;
}

const ForgetPasswordStepSuccessStyled = styled(Stack)(({ theme }) => ({
    '&.x-forget-password-step-success': {
        '.-icon-wrapper': {
            margin: theme.spacing(1, 0),
            textAlign: 'center',
            '.-img': {
                display: 'inline-block',
                width: 120,
            }
        },
        '.-form-wrapper': {
            margin: theme.spacing(2.5, 0, 1),
        },
        '.x-countdown-timer-wrapper': {
            display: 'inline-block',
            minWidth: 20,
            fontSize: 'inherit',
        }
    }
}))

const ForgetPasswordStepSuccess: React.FC<IForgetPasswordSuccess> = ({ onEnd }) => {
    const { trans } = useTrans();

    return (
        <ForgetPasswordStepSuccessStyled className={'x-forget-password-step-success'}>
            <FadeInUp>
                <Box className={'-icon-wrapper'}>
                    <Image
                        src={'ic-success.png'}
                        width={100}
                        height={100}
                    />
                </Box>
                <ModalTypography renderType={'title'} align={'center'}>{trans('modal.forget_password_success.description')}</ModalTypography>
                <ModalTypography align={'center'}>
                    {trans('modal.forget_password_success.sub_description')}
                    <CountdownTimer
                        pattern={'second'}
                        endedAt={moment().add('6', 'second').toDate()}
                        onEnd={onEnd}
                    />
                    {trans('unit.second')}
                </ModalTypography>
            </FadeInUp>
        </ForgetPasswordStepSuccessStyled>
    )
}

export default ForgetPasswordStepSuccess
