import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, Skeleton } from '@mui/material';
import { IServiceProductIcon } from '../../Component/Icon/ServiceIcon';
import isUndefined from 'lodash/isUndefined';
import FormLabelControl from '../../Component/Form/FormLabelControl';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import PaymentButton from './PaymentButton';

export interface IPaymentChoice {
    icon?: any;
    iconProps?: IServiceProductIcon;
    price?: number;
    amount?: number;
    code: string;
    label: string | ReactElement;
    isInternal?: boolean | undefined;
    description?: string;
    iconDetail?: any;
}

interface IPaymentGroup {
    labelGroup?: string;
    useLabelItem?: boolean;
    isRequired?: boolean;
    choices: Array<IPaymentChoice> | undefined;
    choiceType: string;
    onChoose: (choice: IPaymentChoice) => void;
    choiceSelected: IPaymentChoice | undefined;
    dynamicIcon?: any;
    isIconVertical?: boolean;
    imgPreview?: any;
    isLoading?: boolean;
    errorMessage?: string;
}

const PaymentGroupStyled = styled(Box)(({ theme }) => ({
    '&.x-payment-group': {
        margin: '2rem 0rem',
        '.-payment-choice-wrapper': {
            marginTop: 0,
            display: 'flex',
            '.-loading-payment-group-wrapper': {
                height: '62px',
                '.-loading-payment-group': {
                    height: '100%',
                },
            },
            '.-error-message': {
                color: theme.baseVariables.colors.error,
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                margin: theme.spacing(0.5, 1.5, 0),
            },
        },
    },
}));

const PaymentGroup: React.FC<IPaymentGroup> = ({
    labelGroup,
    choices,
    onChoose,
    choiceSelected,
    dynamicIcon,
    choiceType,
    errorMessage,
    imgPreview,
    isLoading = false,
    isIconVertical = false,
    isRequired = true,
    useLabelItem = false,
}) => {
    const ImagePreviewIcon = imgPreview;

    return (
        <PaymentGroupStyled className="x-payment-group">
            {labelGroup && <FormLabelControl label={labelGroup} isRequired={isRequired} />}
            {imgPreview && choiceSelected?.code && <ImagePreviewIcon code={choiceSelected.code} />}
            {isLoading ? (
                <Grid container spacing={{ xxs: 1, sm: 1.25 }} columns={12} className={'-payment-choice-wrapper'}>
                    {Array.from(Array(4)).map((i, index) => {
                        return (
                            <Grid
                                item
                                sm={3}
                                xxs={6}
                                className={'-loading-payment-group-wrapper'}
                                key={`-loading-payment-${choiceType}-${index}`}
                            >
                                <Skeleton className={'-loading-payment-group'} variant="rounded" />
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Grid container spacing={{ xxs: 1, sm: 1.25 }} columns={12} className={'-payment-choice-wrapper'}>
                    {choices &&
                        choices.map((choice, index) => {
                            const isSelected = choiceSelected?.code === choice.code;
                            const isInternal = choice.isInternal;
                            const _icon = isUndefined(dynamicIcon) ? choice.icon : dynamicIcon;

                            return (
                                <Grid item sm={12} xxs={12} className={'-payment-choice-item'} key={`${choice.code}-${index}`}>
                                    {useLabelItem && <FormLabelControl subLabel={choice.label as string} />}

                                    <PaymentButton
                                        label={useLabelItem ? '' : choice.label}
                                        icon={_icon}
                                        iconProps={choice.iconProps}
                                        extraClass={classNames(`choice-label-with-icon -${choiceType}`, isInternal && '-internal')}
                                        isActive={isSelected}
                                        onClick={() => onChoose(choice)}
                                        isIconVertical={isIconVertical}
                                        price={choice.price}
                                        iconDetail={choice.iconDetail}
                                    />
                                    {errorMessage && (
                                        <Typography className={'-error-message'} variant={'body1'}>
                                            {errorMessage}
                                        </Typography>
                                    )}
                                </Grid>
                            );
                        })}
                </Grid>
            )}
        </PaymentGroupStyled>
    );
};

export default PaymentGroup;
