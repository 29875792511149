import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useTrans} from "@core/Hook/trans";
import {Image} from "@core/Utils/image";
import ImageButton from "./Button/ImageButton";
import Stack from "@mui/material/Stack";
import { SOCIAL_LINE_URL } from "../Constants/common";

const ContactUsFooterStyled = styled(Box)(({ theme }) => ({
    '&.x-contact-us-footer': {
        marginTop: theme.spacing(2),
        '.-text': {
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            fontWeight: theme.baseVariables.fonts.fontWeightMedium,
            color: theme.baseVariables.menus.desktopColor,
        },
        '.-line-contact-wrapper': {
            width: '60px',
        }
    },
}));

const ContactUsFooter: React.FC = () => {
    const {trans} = useTrans();

    return (
        <ContactUsFooterStyled id={'homepageSectionContactUs'} className={'x-contact-us-footer'}>
            <Stack direction={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                <Typography className={'-text'}>{trans('contact_us_footer.title')}</Typography>
                <Box className={'-line-contact-wrapper'}>
                    <ImageButton
                        href={SOCIAL_LINE_URL}
                        target={'_blank'}
                        labelElement={<Image
                            extraClass={'-contact-button-img'}
                            src={'wow-boost-homepage-contact-us-footer-line-button.png'}
                            width={35}
                            height={35}
                        />}
                    />
                </Box>
            </Stack>
        </ContactUsFooterStyled>
    )
}

export default ContactUsFooter
