import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTrans } from "@core/Hook/trans";
import { Image } from "@core/Utils/image";

interface INoResult {
    title?: string;
    icon?: string;
}

const NoResultStyled = styled(Box)(({theme}) => ({
    '&.x-no-result-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '.-icon': {
            width: 40,
            height: 'auto'
        },
        '.-title': {
            marginTop: theme.spacing(2),
            color: theme.baseVariables.noResult.color,
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            fontWeight: theme.baseVariables.fonts.fontWeightMedium
        }
    }
}))

const NoResult: React.FC<INoResult> = ({ title, icon }) => {
    const { trans } = useTrans();

    return (
        <NoResultStyled className={'x-no-result-container'}>
            <Image
                extraClass={'-icon'}
                src={icon || 'ic-no-result.png'}
                width={40}
                height={40}
            />
            <Typography className={'-title'}>{title || trans('no_result.title')}</Typography>
        </NoResultStyled>
    )
}

export default NoResult
