import React from "react";
import Layout from "../Component/Common/Layout";
import MetaSeo from "../Component/Common/MetaSeo";
import { useTrans } from "@core/Hook/trans";
import {useParams} from "react-router-dom";
import PolicyBodyLayout from "./Policy/PolicyLayout";
import PolicyCover from "./Policy/PolicyCover";
import {useResponsive} from "@core/Hook/responsiveHook";
import {Asset} from "@core/Utils/image";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import PolicyTerm from "./Policy/PolicyTermAndCondition";
import PolicyRefund from "./Policy/PolicyRefund";
import PolicyPrivacy from "./Policy/PolicyPrivacy";

const PolicyStyled = styled(Box)(({theme}) => ({
    '&.x-policy-wrapper': {
        backgroundSize: '100%',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('md')]: {
            backgroundSize: 'cover',
        },
    }
}))

const Policy: React.FC = () => {
    const { trans } = useTrans();
    const { topic } = useParams();
    const { isTabletOrMobile } = useResponsive();
    const coverBg =isTabletOrMobile ? Asset('wow-boost-service-cover-bg-mobile.png') : Asset('wow-boost-service-cover-bg.png');

    const renderPolicyContent = () => {
        const isPolicyTerm = 'term-and-condition' === topic;
        const isPolicyRefund = 'refund' === topic;
        const isPolicyPrivacy = 'privacy' === topic;

        if (isPolicyTerm) return <PolicyTerm />
        if (isPolicyRefund) return <PolicyRefund />
        if (isPolicyPrivacy) return <PolicyPrivacy />

        return <></>
    }

    return (
        <Layout>
            <MetaSeo
                title={trans('meta_seo.homepage.title')}
                description={trans('meta_seo.homepage.description')}
            />

            <PolicyStyled className={'x-policy-wrapper'} style={{backgroundImage: `url(${coverBg})`}}>
                <PolicyCover title={topic as string} />
                <PolicyBodyLayout>
                    {renderPolicyContent()}
                </PolicyBodyLayout>
            </PolicyStyled>
        </Layout>
    )
}

export default Policy
