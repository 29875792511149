import React, {ReactNode} from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import isUndefined from "lodash/isUndefined";
import {useResponsive} from "@core/Hook/responsiveHook";
import {Asset} from "@core/Utils/image";

interface ISectionLayout {
    sectionId?: string;
    imageBg?: string;
    children: ReactNode;
}

const SectionLayoutStyled = styled(Box)(({theme}) => ({
    '&.x-homepage-section-layout-container': {
        backgroundSize: 'cover',
        backgroundPosition: '100% 100%',
        backgroundRepeat: 'no-repeat',
        padding: theme.spacing(3, 0),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4, 0),
        },
    }
}))

const HomepageSectionLayout: React.FC<ISectionLayout> = ({imageBg, children, sectionId}) => {
    const {isTabletOrMobile} = useResponsive();
    const defaultBg = isTabletOrMobile ? Asset('wow-boost-homepage-section-default-bg-mobile.png') : Asset('wow-boost-homepage-section-default-bg.png')
    const bg = isUndefined(imageBg) ? defaultBg : imageBg;

    const attrs: {[key: string]: any} = {
        id: sectionId,
        className: 'x-homepage-section-layout-container',
        style: {backgroundImage: `url(${bg})`},
    }

    return (
        <SectionLayoutStyled {...attrs}>
            {children}
        </SectionLayoutStyled>
    )
}

export default HomepageSectionLayout
