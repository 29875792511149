import React, { memo, useState } from 'react';
import { styled } from '@mui/material/styles';
import BasicButton, { IBasicButton } from '@core/Component/BasicButton';
import isUndefined from 'lodash/isUndefined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IServiceProductIcon } from '../../Component/Icon/ServiceIcon';
import { NumericFormat } from 'react-number-format';
import { alpha } from '@mui/material';

interface IPaymentButton extends IBasicButton {
    extraClass?: string;
    icon?: any;
    iconProps?: IServiceProductIcon;
    price?: number;
    paymentMethod?: string;
    isActive: boolean;
    isIconVertical: boolean;
    iconDetail?: any;
}

const PaymentButtonWrapperStyled = styled(BasicButton)(({ theme }) => ({
    '&.x-service-payment-button': {
        background: theme.baseVariables.serviceProductButton.bg,
        border: theme.baseVariables.serviceProductButton.border,
        borderRadius: theme.spacing(1.5),
        color: theme.baseVariables.serviceProductButton.color,
        transition: theme.baseVariables.constants.transition.normal,
        padding: theme.spacing(1.5, 1, 1.5, 3.5),
        width: '100%',
        [theme.breakpoints.between(360, 395)]: {
            padding: theme.spacing(1.25, 1.75),
        },
        [theme.breakpoints.between('xxs', 359)]: {
            padding: theme.spacing(1.25, 1),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1.5, 1),
        },
        '&:hover, &.-active': {
            background: theme.baseVariables.serviceProductButton.bgHover,
            border: theme.baseVariables.serviceProductButton.borderHover,
            '&.-choice-label-with-icon': {
                '.-payment-choice-label-wrapper': {
                    '.-payment-choice-label': {
                        color: theme.baseVariables.serviceProductButton.colorHover,
                    },
                },
            },
        },
        '&.-choice-label-with-icon': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                // justifyContent: 'center',
            },
            '&.-serviceOptionTypeValue': {
                justifyContent: 'center',
                padding: theme.spacing(1),
                [theme.breakpoints.up('sm')]: {
                    padding: theme.spacing(1.5, 1),
                },
                '.-payment-choice-label-wrapper': {
                    '.-payment-choice-icon-wrapper': {
                        marginRight: theme.spacing(0),
                    },
                },
            },
            '&.-package': {
                justifyContent: 'center',
                padding: theme.spacing(1.5, 1),
                '.-payment-choice-label-wrapper': {
                    '.-payment-choice-label': {
                        color: theme.baseVariables.serviceProductButton.packageColor,
                    },
                },
            },
            '.-payment-choice-label-wrapper': {
                '.-payment-choice-label': {
                    textTransform: 'capitalize',
                    fontSize: theme.baseVariables.fonts.fontSizeSmall,
                    fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                    [theme.breakpoints.between('xxs', 380)]: {
                        fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    },
                },
                '.-payment-choice-price': {
                    fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                    float: 'right',
                },
                '.-payment-choice-detail': {
                    marginTop: theme.spacing(1),
                    color: theme.baseVariables.serviceProductButton.prizeColor,
                    fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                    float: 'right',
                },
                '&.-is-vertical': {
                    flexDirection: 'column',
                    '.-payment-choice-icon-wrapper': {
                        marginRight: 0,
                        marginBottom: theme.spacing(1),
                    },
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                margin: '0rem 1rem',
                '.-payment-choice-icon-wrapper': {
                    marginRight: theme.spacing(1),
                    marginTop: theme.spacing(1),
                },
            },
            '&.-internal': {
                color: theme.baseVariables.serviceProductButton.internalColor,
                background: 'radial-gradient(173.75% 173.75% at 50% -0.00%, #ffffff 0%, #eae6fe 100%)',
                borderColor: alpha('#8b5cf6', 0.25),
                overflow: 'hidden',
                '&::before': {
                    content: '"ใช้ภายใน"',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: '.125rem .5rem',
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    color: theme.palette.common.white,
                    borderRadius: '0 0 0 8px',
                    background: alpha('#8b5cf6', 0.25),
                    transition: 'all .2s',
                },
                '&:hover, &.-active': {
                    background: alpha('#8b5cf6', 0.25),
                    borderColor: '#8b5cf6',
                    path: {
                        fill: '#8b5cf6',
                    },
                    '&::before': {
                        background: '#8b5cf6',
                    },
                },
            },
        },
    },
}));

const PaymentButton = (props: IPaymentButton) => {
    const { extraClass, label, price, paymentMethod, icon, iconProps, isActive, isIconVertical, iconDetail, ...paymentButtonProps } = props;
    const [isHovered, setIsHovered] = useState(false);
    const ServiceIcon = icon;
    const activeClass = isActive ? '-active' : '';

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const renderLabelWithIcon = () => {
        const iconVerticalClass = isIconVertical ? '-is-vertical' : '';

        return (
            <>
                <Box className={`-payment-choice-label-wrapper ${iconVerticalClass}`}>
                    {icon && (
                        <Box className={'-payment-choice-icon-wrapper'}>
                            <img src={icon} style={{ width: '80%' }} />
                        </Box>
                    )}
                    <Typography component={'span'} className={'-payment-choice-label'}>
                        {label}
                    </Typography>

                    {/* {iconDetail && (
                    <Typography component={'div'} className={'-payment-choice-detail'}>
                        {iconDetail.map((item: any, index: number) => (
                            <img key={index} src={item} style={{ width: '25%' }} />
                        ))}
                    </Typography>
                )} */}
                </Box>
                <Box className={`-payment-choice-label-wrapper ${iconVerticalClass}`}>
                    {!isUndefined(price) && (
                        <Typography component={'span'} className={'-payment-choice-price'}>
                            <NumericFormat
                                thousandSeparator
                                fixedDecimalScale
                                decimalScale={2}
                                displayType={'text'}
                                className={'-number-text'}
                                value={price}
                            />
                        </Typography>
                    )}
                </Box>
            </>
        );
    };

    return (
        <PaymentButtonWrapperStyled
            className={`x-service-payment-button ${activeClass} ${!isUndefined(extraClass) ? `-${extraClass}` : ''}`}
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
            labelElement={renderLabelWithIcon()}
            {...paymentButtonProps}
        />
    );
};

export default memo(PaymentButton);
