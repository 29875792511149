import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const BoostCommentEmojiIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 23 24" fill="none">
                <g clipPath="url(#clip0_1981_20036)">
                    <path d="M11.5 0.75C9.27497 0.75 7.09989 1.4098 5.24984 2.64597C3.39979 3.88213 1.95785 5.63914 1.10636 7.69481C0.254874 9.75048 0.0320869 12.0125 0.466171 14.1948C0.900254 16.3771 1.97171 18.3816 3.54505 19.955C5.11839 21.5283 7.12295 22.5998 9.30524 23.0338C11.4875 23.4679 13.7495 23.2451 15.8052 22.3936C17.8609 21.5422 19.6179 20.1002 20.854 18.2502C22.0902 16.4001 22.75 14.225 22.75 12C22.75 9.01631 21.5647 6.15483 19.455 4.04505C17.3452 1.93526 14.4837 0.75 11.5 0.75ZM15.6181 7.9574C15.9037 7.95711 16.1829 8.04154 16.4205 8.20002C16.6581 8.35849 16.8433 8.58388 16.9527 8.84766C17.0621 9.11144 17.0908 9.40176 17.0352 9.68186C16.9795 9.96196 16.842 10.2193 16.6401 10.4212C16.4382 10.6231 16.1809 10.7606 15.9008 10.8163C15.6207 10.8719 15.3304 10.8432 15.0666 10.7338C14.8028 10.6243 14.5774 10.4391 14.4189 10.2016C14.2605 9.964 14.176 9.68475 14.1763 9.39918C14.1763 9.0168 14.3282 8.65007 14.5986 8.37969C14.869 8.1093 15.2357 7.9574 15.6181 7.9574ZM7.38191 7.9574C7.66749 7.95711 7.94673 8.04154 8.1843 8.20002C8.42187 8.35849 8.60708 8.58388 8.71651 8.84766C8.82592 9.11144 8.85463 9.40176 8.79899 9.68186C8.74335 9.96196 8.60586 10.2193 8.40393 10.4212C8.202 10.6231 7.9447 10.7606 7.6646 10.8163C7.38449 10.8719 7.09418 10.8432 6.8304 10.7338C6.56662 10.6243 6.34123 10.4391 6.18275 10.2016C6.02428 9.964 5.93984 9.68475 5.94014 9.39918C5.94053 9.01692 6.09256 8.65042 6.36286 8.38012C6.63316 8.10982 6.99965 7.95779 7.38191 7.9574ZM17.8355 14.8687C17.3633 17.5643 14.7063 19.4339 11.5 19.4339C8.29375 19.4339 5.63816 17.5643 5.16596 14.8687C5.16089 14.8414 5.15792 14.8137 5.15708 14.7859C5.15708 14.3965 5.63076 14.1449 6.04524 14.287C7.64392 14.8391 9.50905 15.1204 11.5015 15.1204C13.4939 15.1204 15.3591 14.8391 16.9577 14.287C17.3707 14.139 17.8459 14.3951 17.8459 14.7859C17.8446 14.8137 17.8411 14.8414 17.8355 14.8687Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_1981_20036">
                        <rect width="22.5" height="22.5" fill="white" transform="translate(0.25 0.75)"/>
                    </clipPath>
                </defs>
            </svg>
        </ServiceProductIcon>
    )
}

export default BoostCommentEmojiIcon
