import React, { useEffect } from "react";
import Layout from "../Component/Common/Layout";
import MetaSeo from "../Component/Common/MetaSeo";
import { useTrans } from "@core/Hook/trans";
import ServiceCover from "./Service/ServiceCover";
import Box from "@mui/material/Box";
import {Asset} from "@core/Utils/image";
import {styled} from "@mui/material/styles";
import Product from "./Service/Product";
import {useResponsive} from "@core/Hook/responsiveHook";
import { getUserCredit } from "../Api/credit";
import { CustomerInfoAtom, ICustomerInfo } from "../Recoil/Common/atom";
import { useRecoilState } from "recoil";
import { useCustomerContext } from "../Hook/auth";

const ServiceStyled = styled(Box)(({theme}) => ({
    '&.x-service-wrapper': {
        backgroundSize: '100%',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('md')]: {
            backgroundSize: 'cover',
        },
    }
}))

const Services: React.FC = () => {
    const { trans } = useTrans();
    const { isTabletOrMobile } = useResponsive();
    const coverBg =isTabletOrMobile ? Asset('wow-boost-service-cover-bg-mobile.png') : Asset('wow-boost-service-cover-bg.png');
    const [customerInfo, setCustomerInfo] = useRecoilState(CustomerInfoAtom);
    const { isLogged } = useCustomerContext();

    const setLastedCreditValue = async () => {
        const userCreditRes = await getUserCredit()
        
        if (200 === userCreditRes.status) {
            setCustomerInfo({
                ...customerInfo as ICustomerInfo,
                creditValue: userCreditRes.data.creditValue,
            })
        }
    }

    useEffect(()=>{
        if (!isLogged) return;
        
        setLastedCreditValue();
    }, [])
    
    return (
        <Layout isDisableFooter={isTabletOrMobile}>
            <MetaSeo
                title={trans('meta_seo.homepage.title')}
                description={trans('meta_seo.homepage.description')}
            />
            <ServiceStyled className={'x-service-wrapper'} style={{backgroundImage: `url(${coverBg})`}}>
                <ServiceCover />
                <Product />
            </ServiceStyled>
        </Layout>
    )
}

export default Services
