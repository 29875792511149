import React, {Fragment, useEffect, useState} from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import DefaultModal, { IDefaultModal } from "./DefaultModal";
import { useTrans } from "@core/Hook/trans";
import RegisterStepPhoneNumber from "./Register/RegisterStepPhoneNumber";
import RegisterStepOtp from "./Register/RegisterStepOtp";
import RegisterStepPassword from "./Register/RegisterStepPassword";
import RegisterStepConfirmPassword from "./Register/RegisterStepConfirmPassword";
import RegisterStepSuccess from "./Register/RegisterStepSuccess";
import {handleResponseFormError, resolveClientApiState, useClientApiState} from "@core/Hook/api";
import {createCustomerByEmail, createCustomerByPhoneWithValidateToken} from "../../Api/user";
import {loginByPhone} from "../../Api/auth";
import {useManageLocalStorage} from "@core/Utils/localStorage";
import {IValidateOtpResponse} from "../../Hook/auth";
import {LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE} from "../../Constants/common";
import RegisterByEmail from "./Register/RegisterByEmail";
import AuthModeSelectModalTabHeader from "./AuthModeSelectModalTabHeader";
import {IModalDialogHook} from "../../Hook/modalDialogHook";
import {useLocation, useNavigate} from "react-router-dom";
import isUndefined from "lodash/isUndefined";
import {ValidationStateProps} from "./RegisterEmailValidationModal";

const RegisterBoxStyled = styled(Box)(() => ({
    '&.x-register-box-container': {
    },
}));

export interface IRegisterByEmail {
    email: string;
    password: string;
}

export interface IRegisterRequestOtp extends IRequestOtp {
    phoneNumber: string;
}

export interface IRequestOtp {
    otpToken: string;
    refCode?: string;
}

export interface ISetPassword {
    password: string;
}

interface IPreRegisterInfo {
    phoneNumber: string;
    password: string;
    validateToken: string;
    firstName: string;
}

interface IRegisterModal extends IDefaultModal {
    setCurrentTab: (currentTab: string) => void;
    currentTab: string;
    handleEmailVerificationModal?: IModalDialogHook;
    setEmailVerify?: (email: string) => void;
    setEmailVerifyState?: (state: ValidationStateProps) => void;
}

const RegisterModal: React.FC<IRegisterModal> = ({open, handleClose, setCurrentTab, currentTab, handleEmailVerificationModal,setEmailVerifyState, setEmailVerify, ...rest}) => {
    const { trans } = useTrans();
    const location = useLocation();
    const [currentState, setCurrentState] = useState('phone_number');
    const [otpToken, setOtpToken] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const initialPreRegister = {
        phoneNumber: '',
        password: '',
        validateToken: '',
        firstName: '',
    }
    const [preRegisterInfo, setPreRegisterInfo] = useState<IPreRegisterInfo>(initialPreRegister);
    const [apiCreateCustomerState, setApiCreateCustomerState] = useClientApiState({
        isLoading: false,
    });
    const {setToLocalStorage} = useManageLocalStorage();
    const navigate = useNavigate();

    const createCustomer = async (data: IPreRegisterInfo) => {
        setApiCreateCustomerState({ ...apiCreateCustomerState, isLoading: true });

        const createCustomerRes = resolveClientApiState(await createCustomerByPhoneWithValidateToken(data), true);

        if (createCustomerRes.isOk) {
            setApiCreateCustomerState({ ...apiCreateCustomerState, isLoading: false });

            const loginData = {
                phoneNumber: data.phoneNumber,
                pin: data.password
            }

            const loginRes = resolveClientApiState(await loginByPhone(loginData), true);

            if (loginRes.isOk) {
                setToLocalStorage(LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE, loginRes.data.accessToken);

                return {success: true};
            }

            handleResponseFormError(loginRes);

            return;
        }

        handleResponseFormError(createCustomerRes);
        setApiCreateCustomerState({ ...apiCreateCustomerState, isLoading: false });
    }

    const getModalTitle = () => {
        return trans('modal.register_phone_number.title')
    }

    const handleRegisterByEmail = async (state: string, data: IRegisterByEmail) => {
        setApiCreateCustomerState({ ...apiCreateCustomerState, isLoading: true });

        const resRegisterByEmail = resolveClientApiState(await createCustomerByEmail(data), true, {
            errorMessageWithCode: {
                3: trans('modal.register_email.already_exists')
            }
        });

        if (resRegisterByEmail.isOk) {
            handleClose();
            handleEmailVerificationModal?.handleClickOpen();
            setEmailVerify?.(data.email)
            setEmailVerifyState?.('sent');
        }

        handleResponseFormError(resRegisterByEmail);
        setApiCreateCustomerState({ ...apiCreateCustomerState, isLoading: false });
    }

    const handleRegisterStepPhoneNumber = (state: string, data: IRegisterRequestOtp) => {
        setOtpToken(data.otpToken);
        setPreRegisterInfo((prev) => {
            return {...prev, phoneNumber: data.phoneNumber, firstName: data.phoneNumber}
        })
        setCurrentState(state);
    }

    const handleRegisterStepOtp = (state: string, data: IValidateOtpResponse) => {
        setPreRegisterInfo((prev) => {
            return {...prev, validateToken: data.validateToken}
        })
        setCurrentState(state);
    }

    const handleRegisterStepPassword = (state: string, data: ISetPassword) => {
        setPassword(data.password);
        setCurrentState(state);
    }

    const handleRegisterConfirmPassword = (state: string, data: ISetPassword) => {
        const preRegisterInfoData = {
            ...preRegisterInfo,
            password: data.password,
        }

        createCustomer(preRegisterInfoData).then((res) => {
            if (res && res.success) return setCurrentState('success');

            handleClose();
        });
    }

    const handleRegisterSuccess = () => {
        handleClose();
        window.location.reload();
    }

    const getModalBodyRenderComponent = () => {
        if ('email' === currentTab) {
            return <RegisterByEmail onSubmit={(data: IRegisterByEmail) => handleRegisterByEmail('verify_email', data)} isLoading={apiCreateCustomerState.isLoading} />
        }

        if ('phone-number' === currentTab) {
            switch (currentState) {
                case 'phone_number':
                    return <RegisterStepPhoneNumber onSubmit={(data: IRegisterRequestOtp) => handleRegisterStepPhoneNumber( 'otp', data)} />
                case 'otp':
                    return otpToken && <RegisterStepOtp onSubmit={(data: IValidateOtpResponse) => handleRegisterStepOtp('password', data)} otpToken={otpToken} />
                case 'password':
                    return <RegisterStepPassword onSubmit={(data: ISetPassword) => handleRegisterStepPassword('confirm_password', data)} />
                case 'confirm_password':
                    return <RegisterStepConfirmPassword onSubmit={(data: ISetPassword) => handleRegisterConfirmPassword('success', data)} firstPassword={password} isLoading={apiCreateCustomerState.isLoading} />
                case 'success':
                    return <RegisterStepSuccess onEnd={() => handleRegisterSuccess()} />
            }
        }
    }

    const renderEmailVerification = () => {
        const verifyState  = location.state?.isVerifyEmailSuccess ? 'complete' : 'invalid';
        handleEmailVerificationModal?.handleClickOpen();
        setEmailVerify && setEmailVerify(location.state?.email);
        setEmailVerifyState && setEmailVerifyState(verifyState)
        navigate(location.pathname, { replace: true });
    }

    useEffect(() => {
        if (isUndefined(location.state?.isVerifyEmailSuccess)) return;

        renderEmailVerification();
    }, [])

    if (!open) return <></>;

    return (
        <DefaultModal
            {...rest}
            showCloseIcon
            hasTabHeading
            open={open}
            isHalfSize={'success' !== currentState}
            title={getModalTitle()}
            titleCustom={<AuthModeSelectModalTabHeader setCurrentTab={(currentTab) => setCurrentTab(currentTab)} currentTab={currentTab} />}
            titleColor={'primary'}
            extraClass={'-register-modal'}
            handleClose={() => {
                handleClose()
                setTimeout(() => {
                    setCurrentState('phone_number')
                }, 250)
            }}
        >
            <RegisterBoxStyled>
                {getModalBodyRenderComponent()}
            </RegisterBoxStyled>
        </DefaultModal>
    )
}

export default RegisterModal
