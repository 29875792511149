import React from "react";
import { styled } from "@mui/material/styles";
import { useTrans } from "@core/Hook/trans";
import { useHandleDialog } from "../../../Hook/modalDialogHook";
import LoginIntegration from "../../Modal/LoginIntegration";
import PlainButton from "../../Button/PlainButton";
import Stack from "@mui/material/Stack";

const HeaderAnonStyled = styled(Stack)(({ theme }) => ({
    '&.x-header-anon-container': {
        '.-btn': {
            minHeight: 0,
            padding: theme.spacing(.75, 2),
            // background: theme.baseVariables.headerAnon.btnLoginBg,
            borderRadius: 30,
            transition: 'all .2s',
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            fontWeight: theme.baseVariables.fonts.fontWeightMedium,
            color: theme.baseVariables.headerAnon.btnLoginColor,
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(.75, 1),
                fontSize: theme.baseVariables.fonts.fontSizeSmaller,
            },
            '&:active, &:hover': {
                filter: theme.baseVariables.headerAnon.btnLoginActiveFilter,
            },
            '&.-register-btn': {
                color: theme.palette.primary.main,
                border: '1px solid #9fc7ff',
                '&:active, &:hover': {
                    filter: theme.baseVariables.headerAnon.btnLoginActiveFilter,
                },
            }
        },
    }
}))

const HeaderAnon: React.FC = () => {
    const { trans } = useTrans();
    const loginModal = useHandleDialog();
    const registerModal = useHandleDialog();
    const registerEmailValidationModal = useHandleDialog()

    return (
        <HeaderAnonStyled className={'x-header-anon-container'} direction={"row"} spacing={1}>
            <PlainButton
                color={"secondary"}
                label={trans('header.register')}
                extraClass={'x-btn-header-base -btn -register-btn'}
                onClick={() => registerModal.handleClickOpen()}
            />
            <PlainButton
                label={trans('header.login')}
                extraClass={'x-btn-header-base -btn -login-btn'}
                onClick={() => loginModal.handleClickOpen()}
            />
            <LoginIntegration
                handleRegisterModal={registerModal}
                handleLoginModal={loginModal}
                handleEmailVerificationModal={registerEmailValidationModal}
            />
        </HeaderAnonStyled>
    )
}

export default HeaderAnon
