import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

interface IFormActionControl {
    children: React.ReactNode
    button: React.ReactNode
}

const FormActionControlStyled = styled(Box)(({ theme }) => ({
    '&.x-form-action-control': {
        background: theme.baseVariables.serviceProductForm.buttonActionContainerBg,
        position: 'fixed',
        bottom: 0,
        left: 0,
        margin: 0,
        zIndex: 3,
        transition: theme.baseVariables.constants.transition.normal,
        width: '100%',
        padding: theme.spacing(2, 3),
        boxShadow: theme.baseVariables.serviceProductForm.buttonActionContainerBoxShadow,
        [theme.breakpoints.between('xxs', 390)]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
            padding: theme.spacing(2, 0),
            boxShadow: 'none',
        },
        '.-button-wrapper': {
            [theme.breakpoints.up('sm')]: {
                width: '300px',
                margin: `${theme.spacing(5)} auto`,
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(2),
            },
        }
    }
}))

const FormActionControl: React.FC<IFormActionControl> = ({children,button}) => {
    return (
        <FormActionControlStyled className={'x-form-action-control'}>
            {children}
            <Box className={'-button-wrapper'}>
                {button}
            </Box>
        </FormActionControlStyled>
    )
}

export default FormActionControl
