import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Image } from '@core/Utils/image';
import { styled } from '@mui/material/styles';
import { useTrans } from '@core/Hook/trans';
import { useManageLocale } from '../Hook/locale';

const LocaleSwitcherStyled = styled(Box)(({ theme }) => ({
    '&.x-local-switcher': {
        marginRight: theme.spacing(1),
        '.-btn-switcher': {
            padding: theme.spacing(0.5),
            color: theme.baseVariables.localeSwitcher.btnColor,
            background: theme.baseVariables.localeSwitcher.btnBg,
            border: theme.baseVariables.localeSwitcher.btnBorder,
            borderRadius: 30,
            transition: 'filter .2s',
            '&:hover': {
                filter: theme.baseVariables.localeSwitcher.btnActiveFilter,
            },
            '&.-show': {
                filter: theme.baseVariables.localeSwitcher.btnActiveFilter,
                '.-ic-arrow': {
                    rotate: '180deg',
                },
            },
        },
        '.-img': {
            display: 'block',
            width: 22,
        },
        '.-ic-arrow': {
            position: 'relative',
            right: theme.spacing(-0.375),
            width: theme.spacing(2.75),
            height: 'auto',
            transition: 'rotate .2s',
        },
    },
}));

const MenuListStyled = styled(Menu)(({ theme }) => ({
    '&.x-locale-menu-list-container': {
        '.MuiMenu-paper': {
            marginTop: theme.spacing(1),
        },
        '.-list': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: theme.spacing(0),
        },
        '.-btn-list-item': {
            justifyContent: 'flex-start',
            padding: theme.spacing(1, 1.5),
            transition: 'background .2s',
            '.-img': {
                marginRight: theme.spacing(1),
            },
            '.-text': {
                fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                [theme.breakpoints.up('md')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeNormal,
                },
            },
            '&:active, &:hover': {
                background: theme.baseVariables.localeSwitcher.listItemActiveBg,
            },
        },
    },
}));

const LocaleSwitcher: React.FC = () => {
    const { trans } = useTrans();
    const { currentLocale, changeLocale } = useManageLocale();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const availableLanguageList = ['th', 'en'];

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeLocale = async (locale: string) => {
        if (currentLocale === locale) return handleClose();

        await changeLocale(locale).then(() => handleClose());
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <LocaleSwitcherStyled className={`x-local-switcher`}>
            <Button
                id="local-switcher-button"
                className={`x-btn-header-base -btn-switcher ${open ? '-show' : ''}`}
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Image src={`locale-flag-${currentLocale}.png`} width={25} height={25} />
                <ExpandMoreRoundedIcon className={'-ic-arrow'} />
            </Button>
            <MenuListStyled
                id="local-switcher-menu"
                className={'x-locale-menu-list-container'}
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ className: '-list' }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {availableLanguageList
                    .filter((val) => val !== currentLocale)
                    .map((lang, index) => {
                        return (
                            <Button
                                key={`btn-locale-item-${index}-${lang}`}
                                fullWidth
                                className={'-btn-list-item'}
                                onClick={() => handleChangeLocale(lang)}
                            >
                                <Image src={`locale-flag-${lang}.png`} width={25} height={25} />
                                <Typography className={'-text'}>{trans(`locale.${lang}`)}</Typography>
                            </Button>
                        );
                    })}
            </MenuListStyled>
        </LocaleSwitcherStyled>
    );
};

export default LocaleSwitcher;
