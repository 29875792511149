import React from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTrans } from "@core/Hook/trans";
import { Image } from "@core/Utils/image";

interface IToastMessage {
    type: string;
    title: string;
    message: string;
    icon: any;
}

const ToastMessageRenderer = ({type, title, message, icon}: IToastMessage) => {
    const { trans } = useTrans();
    const titleResolved = title ? title : trans(`alert.${type}.title`);
    const iconResolved = <Image
        src={icon}
        alt={`${trans('general.alt_prefix')} ${trans('alert.alt')}`}
        width={100}
        height={100}
        webp
    />

    return (
        <div className={`-toast-message-wrapper -${type}`}>
            {icon ? iconResolved : null}
            <div className="-inner-wrapper">
                <strong className={'-title'}>{titleResolved}</strong>
                <p className={'-message'}>{message}</p>
            </div>
        </div>
    )
}

export const ToastAlert = (type: 'success' | 'error' | 'default', message: any, topic: any = '') => {
    let icon = null;
    if ('error' === type) {
        icon = 'ic-alert-failed.png';
    } else if ('success' === type) {
        icon = 'ic-alert-success.png';
    }

    const msgSettings: IToastMessage = {
        type,
        message,
        icon,
        title: topic
    }

    switch (type) {
        case 'success':
            return toast.success(
                <ToastMessageRenderer {...msgSettings} />, {icon: false}
            )
        case 'error':
            return toast.error(
                <ToastMessageRenderer {...msgSettings} />, {icon: false}
            )
        default:
            return toast.info(
                <ToastMessageRenderer {...msgSettings} />
            )
    }
}

const useStyles = makeStyles(({typography, baseVariables}: Theme) => ({
    root: {
        '& .Toastify__toast': {
            fontFamily: typography.fontFamily,
            '&--success': {
                backgroundColor: baseVariables.alert.successBg,
            },
            '&--error': {
                backgroundColor: baseVariables.alert.errorBg,
            },
            '& .Toastify__close-button--light': {
                color: baseVariables.alert.color,
            },
        },
        '& .Toastify__progress-bar': {
            '&--success': {
                backgroundColor: baseVariables.alert.successProgressBg,
            },
            '&--error': {
                backgroundColor: baseVariables.alert.errorProgressBg,
            },
        },
        '& .-toast-message-wrapper': {
            display: 'flex',
            alignItems: 'center',
            '& .-inner-wrapper': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
            '& .-img': {
                width: '40px',
                height: 'auto',
                marginRight: '.75rem',
            },
            '& .-title': {
                fontSize: baseVariables.fonts.fontSizeSmall,
                fontWeight: baseVariables.fonts.fontWeightSemiBold,
                color: baseVariables.colors.black,
            },
            '& .-message': {
                marginTop: 0,
                marginBottom: 0,
                fontSize: baseVariables.fonts.fontSizeSmall,
                fontWeight: baseVariables.fonts.fontWeightLight,
            },
            '&.-success, &.-error': {
                '& .-title': {
                    color: baseVariables.alert.color,
                },
                '& .-message': {
                    color: baseVariables.alert.color,
                },
            },
        }
    },
}));

const AlertFadeInRight: React.FC = () => {
    const classes = useStyles();

    return (
        <ToastContainer
            position="top-right"
            autoClose={1000}
            className={classes.root}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={1}
        />
    )
};

export default AlertFadeInRight;
