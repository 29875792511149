import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface IFormLabelControl {
    label?: string;
    subLabel?: string;
    isRequired?: boolean;
}

const FormLabelControlStyled = styled(Box)(({ theme }) => ({
    '&.x-form-label-control': {
        '.-group-label-required': {
            marginRight: theme.spacing(1),
            color: theme.baseVariables.formLabelControl.requireColor,
        },
        '.-group-label': {
            fontWeight: theme.baseVariables.fonts.fontWeightMedium,
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            color: theme.baseVariables.formLabelControl.labelColor,
            [theme.breakpoints.between(350, 380)]: {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
            },
            [theme.breakpoints.between('xxs', 349)]: {
                fontSize: theme.baseVariables.fonts.fontSizeSmaller,
            },
        },
        '.-group-sub-label': {
            fontWeight: theme.baseVariables.fonts.fontWeightNormal,
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            color: theme.baseVariables.formLabelControl.subLabelColor,
            [theme.breakpoints.between(350, 380)]: {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
            },
            [theme.breakpoints.between('xxs', 349)]: {
                fontSize: theme.baseVariables.fonts.fontSizeSmaller,
            },
        },
    }
}))

const FormLabelControl: React.FC<IFormLabelControl> = ({label, subLabel, isRequired}) => {
    return (
        <FormLabelControlStyled className={'x-form-label-control'}>
            {isRequired && <Typography component={'span'} className={'-group-label-required'}>{'*'}</Typography>}
            {label && <Typography component={'span'} className={'-group-label'}>{label}</Typography>}
            {subLabel && <Typography component={'span'} className={'-group-sub-label'}>{subLabel}</Typography>}
        </FormLabelControlStyled>
    )
}

export default FormLabelControl
