import React, {useEffect, useState} from "react";
import DefaultModal, { IDefaultModal } from "./DefaultModal";
import LoginStepPhoneNumber from "./Login/LoginStepPhoneNumber";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import LoginStepPassword from "./Login/LoginStepPassword";
import { useTrans } from "@core/Hook/trans";
import FadeInUp from "../../Animate/FadeInUp";
import {IPhoneNumberFormData} from "./Form/PhoneNumberForm";
import {IInputPassCodeData} from "./Form/InputPasscodeForm";
import {handleResponseFormError, resolveClientApiState, useClientApiState} from "@core/Hook/api";
import {
    ACCOUNT_IS_NOT_VERIFIED,
    checkAccountExists, LOGIN_TYPE_CHECK_ACCOUNT_EMAIL,
    LOGIN_TYPE_CHECK_ACCOUNT_PHONE_NUMBER, loginByEmail,
    loginByPhone,
    requestOtpToForgetPasswordByPhone
} from "../../Api/auth";
import {useManageLocalStorage} from "@core/Utils/localStorage";
import {IForgetPasswordPhoneNumber} from "./ForgetPasswordModal";
import {LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE} from "../../Constants/common";
import {ToastAlert} from "@core/Component/AlertFadeInRight";
import client from "../../Utils/api_client";
import isUndefined from "lodash/isUndefined";
import {resetPasswordByValidateToken, userInfo} from "../../Api/user";
import {CustomerInfoAtom, ICustomerInfo} from "../../Recoil/Common/atom";
import {pusherAuthenticate} from "@core/Utils/pusherAuthentication";
import {useSetRecoilState} from "recoil";
import AuthModeSelectModalTabHeader from "./AuthModeSelectModalTabHeader";
import LoginByEmail from "./Login/LoginByEmail";
import {ILoginByEmailFormData} from "./Form/LoginByEmailForm";
import {IModalDialogHook} from "../../Hook/modalDialogHook";
import {ValidationStateProps} from "./RegisterEmailValidationModal";
import {IForgetPasswordEmailFormData} from "./Form/ForgetPasswordEmailForm";
import {useLocation, useNavigate} from "react-router-dom";
import {IForgetPasswordEmailSetNewPasswordFormData} from "./Form/ForgetPasswordEmailSetNewPasswordForm";
import ForgetPasswordEmail from "./ForgetPassword/ForgetPasswordEmail";
import ForgetPasswordEmailSetNewPassword from "./ForgetPassword/ForgetPasswordEmailSetNewPassword";
import { useFetchProducts } from "../../Recoil/Product/selector";

interface ILoginModal extends IDefaultModal {
    handleRegisterClick(): void;
    handleForgetPasswordClick: (data: IForgetPasswordPhoneNumber) => void;
    handleEmailVerificationModal?: IModalDialogHook;
    handleLoginModal?: IModalDialogHook;
    stateLogin?: string;
    setCurrentTab: (currentTab: string) => void;
    currentTab: string;
    setEmailVerify?: (email: string) => void;
    setEmailVerifyState?: (state: ValidationStateProps) => void;
}

const LoginBoxStyled = styled(Box)(() => ({
    '&.x-login-box-container': {
    },
}));

const LoginModal: React.FC<ILoginModal> = ({stateLogin = 'phone_number', setCurrentTab, currentTab, open, handleClose, handleLoginModal, setEmailVerify, setEmailVerifyState, handleEmailVerificationModal, handleRegisterClick, handleForgetPasswordClick, ...rest}) => {
    const { trans } = useTrans();
    const location = useLocation();
    const navigate = useNavigate();
    const fetchProducts = useFetchProducts();
    const [currentState, setCurrentState] = useState(stateLogin);
    const [resetPasswordToken, setResetPasswordToken] = useState<string | undefined>();
    const [loginPhoneNumber, setLoginPhoneNumber] = useState<string>('');
    const {setToLocalStorage} = useManageLocalStorage();
    const setCustomerInfo = useSetRecoilState(CustomerInfoAtom);
    const isSetNewPasswordEmailState = 'reset_password_email_set_new_password' === currentState;
    const [apiState, setApiState] = useClientApiState({isLoading: false});
    const [apiLoginState, setApiLoginState] = useClientApiState({
        isLoading: false,
    });

    const handleSubmitLoginPhoneNumber = async (state: string, data: IPhoneNumberFormData) => {
        setApiLoginState({ ...apiLoginState, isLoading: true });

        const loginData = {
            value: data.phoneNumber,
            loginType: LOGIN_TYPE_CHECK_ACCOUNT_PHONE_NUMBER,
        }

        const loginRes = resolveClientApiState(await checkAccountExists(loginData)
            , true, {
                errorMessageWithCode: {
                    404: trans('modal.login.error.not_found'),
                }
            });

        if (loginRes.isOk) {
            setApiLoginState({ ...apiLoginState, isLoading: false });
            setLoginPhoneNumber(data.phoneNumber)
            setCurrentState(state);

            return;
        }

        setCurrentState('phone_number');
        setApiLoginState({ ...apiLoginState, isLoading: false });
    }

    const handleSubmitLoginEmail = async (state: string, data: ILoginByEmailFormData) => {
        setApiLoginState({ ...apiLoginState, isLoading: true });

        const loginData = {
            email: data.email,
            password: data.password,
        }

        const loginRes = resolveClientApiState(await loginByEmail(loginData), true);

        if (loginRes.isOk) {
            const apiAccessToken = loginRes.data.accessToken;
            setToLocalStorage(LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE, apiAccessToken);
            client._accessToken = apiAccessToken;

            if (!isUndefined(loginRes.data.accessToken)) {
                const customerInfoRes = await userInfo();

                if (200 === customerInfoRes.status) {
                    setCustomerInfo({
                        ...customerInfoRes.data as ICustomerInfo
                    });

                    pusherAuthenticate(customerInfoRes.data.phoneNumber, apiAccessToken);
                }
            }

            ToastAlert('success', trans('alert.success.login'));
            handleClose();

            return;
        }

        if (ACCOUNT_IS_NOT_VERIFIED === loginRes.data.serverErrorCode) {
            handleClose();
            handleEmailVerificationModal?.handleClickOpen();
            setEmailVerify && setEmailVerify(data.email);
            setEmailVerifyState && setEmailVerifyState('in-complete');
        }

        handleResponseFormError(loginRes);
        setApiLoginState({ ...apiLoginState, isLoading: false });
    }

    const handleSubmitLogin = async (data: IInputPassCodeData) => {
        setApiLoginState({ ...apiLoginState, isLoading: true });

        const loginData = {
            phoneNumber: loginPhoneNumber,
            pin: data.password,
        }

        const loginRes = resolveClientApiState(await loginByPhone(loginData), true);

        if (loginRes.isOk) {
            const apiAccessToken = loginRes.data.accessToken;
            setToLocalStorage(LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE, apiAccessToken);
            client._accessToken = apiAccessToken;

            if (!isUndefined(loginRes.data.accessToken)) {
                const customerInfoRes = await userInfo();

                if (200 === customerInfoRes.status) {
                    setCustomerInfo({
                        ...customerInfoRes.data as ICustomerInfo
                    });

                    pusherAuthenticate(customerInfoRes.data.phoneNumber, apiAccessToken);
                }
            }

            ToastAlert('success', trans('alert.success.login'));
            fetchProducts(apiState, setApiState)
            handleClose();

            return;
        }

        handleResponseFormError(loginRes);
        setApiLoginState({ ...apiLoginState, isLoading: false });
    }

    const handleForgotPasswordPhoneNumber = async () => {
        setApiLoginState({ ...apiLoginState, isLoading: true });

        const loginData = {
            value: loginPhoneNumber,
            loginType: LOGIN_TYPE_CHECK_ACCOUNT_PHONE_NUMBER,
        }

        const loginRes = resolveClientApiState(await checkAccountExists(loginData)
            , true, {
                errorMessageWithCode: {
                    4: trans('modal.login.error.not_found'),
                }
            });

        if (loginRes.isOk) {
            const requestOtpRes = resolveClientApiState(await requestOtpToForgetPasswordByPhone({phoneNumber: loginPhoneNumber}), true, );

            if (requestOtpRes.isOk) {
                setApiLoginState({ ...apiLoginState, isLoading: false });

                const {refCode, otpToken} = requestOtpRes.data;
                const requestOtpData: IForgetPasswordPhoneNumber = {
                    refCode: refCode,
                    otpToken: otpToken,
                    phoneNumber: loginPhoneNumber
                }

                handleForgetPasswordClick(requestOtpData);

                return;
            }

            handleResponseFormError(requestOtpRes);

            return;
        }

        handleResponseFormError(loginRes);
        setApiLoginState({ ...apiLoginState, isLoading: false });
        setCurrentState('phone_number');
    }

    const handleForgotPasswordEmail = async (data: IForgetPasswordEmailFormData) => {
        setApiLoginState({ ...apiLoginState, isLoading: true });

        const loginData = {
            value: data.email,
            loginType: LOGIN_TYPE_CHECK_ACCOUNT_EMAIL,
        }

        const loginRes = resolveClientApiState(await checkAccountExists(loginData)
            , true, {
                errorMessageWithCode: {
                    404: trans('modal.login.error.not_found'),
                }
            });

        if (loginRes.isOk) {
            handleClose();
            handleEmailVerificationModal?.handleClickOpen();
            setEmailVerify && setEmailVerify(data.email);
            setEmailVerifyState && setEmailVerifyState('reset-password');
            setCurrentState('email');
            setApiLoginState({ ...apiLoginState, isLoading: false });

            return;
        }

        handleResponseFormError(loginRes);
        setApiLoginState({ ...apiLoginState, isLoading: false });
        setCurrentState('email');
    }

    const handleClickForgotPassword = async (type: string, data?: IForgetPasswordEmailFormData) => {
        if ('phoneNumber' === type) return handleForgotPasswordPhoneNumber();

        data && await handleForgotPasswordEmail(data);
    };

    const renderResetPasswordByEmail = () => {
        if (!location.state?.isValidResetPasswordToken || isUndefined(location.state.resetPasswordToken)) {
            setEmailVerifyState && setEmailVerifyState('invalid-reset-password')
            handleEmailVerificationModal?.handleClickOpen();
            navigate(location.pathname, { replace: true });

            return;
        }

        setResetPasswordToken(location.state.resetPasswordToken)
        navigate(location.pathname, { replace: true });
        setCurrentState('reset_password_email_set_new_password');
        handleLoginModal?.handleClickOpen();
    }

    const submitSetNewPassword = async (data: IForgetPasswordEmailSetNewPasswordFormData) => {
        setApiLoginState({ ...apiLoginState, isLoading: true });

        const setPasswordData = {
            password: data.newPassword,
            validateToken: resetPasswordToken,
        }

        const resetPasswordRes = resolveClientApiState(await resetPasswordByValidateToken(setPasswordData), true);

        if (resetPasswordRes.isOk) {
            handleClose();
            setEmailVerifyState && setEmailVerifyState('complete-reset-password')
            handleEmailVerificationModal?.handleClickOpen();
        }

        setResetPasswordToken(undefined);
        setCurrentState('email');
        setApiLoginState({ ...apiLoginState, isLoading: false });
    }

    useEffect(() => {
        if (isUndefined(location.state?.isValidResetPasswordToken)) return;

        renderResetPasswordByEmail();
    }, [])

    const getModalBodyRenderComponent = () => {
        if ('email' === currentTab) {
            if ('reset_password_email' === currentState) {
                return <ForgetPasswordEmail
                    onSubmit={(data) => handleClickForgotPassword('email', data)}
                    isLoading={apiLoginState.isLoading}
                />
            }

            if ('reset_password_email_set_new_password' === currentState) {
                return <ForgetPasswordEmailSetNewPassword
                    onSubmit={(data) => submitSetNewPassword(data)}
                    isLoading={apiLoginState.isLoading}
                />
            }

            return <LoginByEmail
                onSubmit={(data) => handleSubmitLoginEmail('password', data)}
                onClickRegister={handleRegisterClick}
                onClickForgetPassword={() => setCurrentState('reset_password_email')}
                isLoading={apiLoginState.isLoading}
            />
        }

        if ('phone-number' === currentTab) {
            if ('password' === currentState) {
                return <LoginStepPassword
                    onClickForgetPassword={() => handleClickForgotPassword('phoneNumber')}
                    onSubmit={(data) => handleSubmitLogin(data)}
                    isLoading={apiLoginState.isLoading}
                />
            }

            return <LoginStepPhoneNumber
                onSubmit={(data) => handleSubmitLoginPhoneNumber('password', data)}
                onClickRegister={handleRegisterClick}
                isLoading={apiLoginState.isLoading}
            />
        }
    }

    if (!open) return <></>

    return (
        <DefaultModal
            {...rest}
            showCloseIcon
            isHalfSize
            hasTabHeading
            open={open}
            titleCustom={!isSetNewPasswordEmailState && <AuthModeSelectModalTabHeader setCurrentTab={(currentTab) => setCurrentTab(currentTab)} currentTab={currentTab} />}
            titleColor={'primary'}
            extraClass={'-login-modal'}
            handleClose={() => {
                handleClose()
                setTimeout(() => {
                    setCurrentState('phone_number')
                }, 250)
            }}
        >
            <LoginBoxStyled>
                <FadeInUp>
                    {getModalBodyRenderComponent()}
                </FadeInUp>
            </LoginBoxStyled>
        </DefaultModal>
    )
}

export default LoginModal
