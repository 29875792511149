import React from "react";
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";
import {useTrans} from "@core/Hook/trans";
import {useResponsive} from "@core/Hook/responsiveHook";
import HomepageSectionLayout from "./HomepageSectionLayout";
import Title from "../../Component/Title";
import {Asset} from "@core/Utils/image";
import { Grid } from "@mui/material";
import FadeInUp from "../../Animate/FadeInUp";
import PlainButton from "../../Component/Button/PlainButton";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";

const InviteToUseStyled = styled(Container)(({theme}) => ({
    '&.x-homepage-invite-to-use-container': {
        '.-invite-to-use-wrapper': {
            width: '320px',
            margin: 'auto',
            marginTop: theme.spacing(2),
            [theme.breakpoints.between('xxs', 380)]: {
                width: 'auto',
            },
            [theme.breakpoints.up('sm')]: {
                width: '400px',
                marginTop: theme.spacing(3),
            },
        },
        '.-invite-to-use-button-wrapper': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: theme.spacing(4),
        }
    }
}))

const HomepageInviteToUseService: React.FC = () => {
    const {trans} = useTrans();
    const {isTabletOrMobile} = useResponsive();
    const navigate = useNavigate();
    const bg = isTabletOrMobile ? Asset('wow-boost-homepage-invite-to-use-bg-mobile.png') : Asset('wow-boost-homepage-invite-to-use-bg.png');

    return (
        <HomepageSectionLayout imageBg={bg}>
            <InviteToUseStyled className="x-homepage-invite-to-use-container">
                <FadeInUp>
                    <Title title={trans('homepage.section.invite_to_use.title')} subTitle={trans('homepage.section.invite_to_use.sub_title')} />
                </FadeInUp>
                <Grid container justifyContent="center" alignItems="center" className={'-invite-to-use'}>
                    <Grid item xs={6}>
                        <FadeInUp>
                            <Box className={'-invite-to-use-button-wrapper'}>
                                <PlainButton
                                    color={'primary'}
                                    label={trans('homepage.section.invite_to_use.button_detail')}
                                    onClick={() => navigate('/services')}
                                    extraClass={'-invite-to-use-button'}
                                />
                            </Box>
                        </FadeInUp>
                    </Grid>
                </Grid>
            </InviteToUseStyled>
        </HomepageSectionLayout>
    )
}

export default HomepageInviteToUseService
