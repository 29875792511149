import { useState } from 'react';
import { IApiState, resolveClientApiState } from '@core/Hook/api';
import { getUserCredit } from '../Api/credit';

const useCreditHook = () => {
    const [creditValue, setCreditValue] = useState(0);

    const useFetchUserCredit = (apiState: IApiState, setApiState: (...args: any[]) => void) => {
        const fetchUserCredit = async () => {
            setApiState({ ...apiState, isLoading: true });
            const res = resolveClientApiState(await getUserCredit(), true);
            setCreditValue(res.data.creditValue);
            setApiState({ ...apiState, isLoading: false });
        };

        return {
            fetchUserCredit,
        };
    };

    return {
        creditValue,
        useFetchUserCredit,
    };
};

export default useCreditHook;
