import { keyframes } from "@mui/system";

export const hamburgerWave = keyframes`
  0% {
    width: 70%;
  }
  100% {
    width: 100%;
  }
`

export default {
    hamburgerWave,
}
