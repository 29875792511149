import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Asset, Image } from "@core/Utils/image";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTrans } from "@core/Hook/trans";
import {useResponsive} from "@core/Hook/responsiveHook";

const CoverStyled = styled(Box)(({theme}) => ({
    '&.x-service-cover-container': {
        padding: theme.spacing(7, 0, 2),
        '.-service-cover-box': {
            margin: theme.spacing(2, 0),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: theme.baseVariables.serviceCover.boxBorder,
            borderRadius: theme.spacing(1),
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            transition: theme.baseVariables.constants.transition.normal,
        },
        '.-cover-column-left, .-cover-column-right': {
            [theme.breakpoints.up('sm')]: {
                width: '50%',
            },
        },
        '.-cover-column-right': {
            width: '55%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '.-img-banner': {
                display: 'flex'
            }
        },
        '.-cover-column-left': {
            width: '45%',
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(8),
            },
        },
        '.-cover-title-wrapper': {
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(4, 2),
            transition: theme.baseVariables.constants.transition.normal,
            [theme.breakpoints.between('xxs', 380)]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up('md')]: {
                width: 'auto',
                padding: 0,
            },
            '.-cover-title': {
                color: theme.baseVariables.serviceCover.titleColor,
                fontSize: theme.baseVariables.fonts.fontSizeNormal,
                fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                marginBottom: theme.spacing(1),
                transition: theme.baseVariables.constants.transition.normal,
                [theme.breakpoints.between('xxs', 330)]: {
                    fontSize: theme.baseVariables.fonts.fontSizeSmall,
                },
                [theme.breakpoints.up('sm')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeBigger,
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeBiggerLarge,
                },
            },
            '.-cover-sub-title': {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                transition: theme.baseVariables.constants.transition.normal,
                [theme.breakpoints.between('xxs', 330)]: {
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                },
                [theme.breakpoints.up('sm')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeNormal,
                },
            }
        }
    }
}))

const ServiceCover: React.FC = () => {
    const { trans } = useTrans();
    const { isTabletOrMobile } = useResponsive();
    const coverBgInner = isTabletOrMobile ? Asset('wow-boost-service-cover-bg-inner-mobile.png') : Asset('wow-boost-service-cover-bg-inner.png');

    const renderCoverTitle = () => {
        return (
            <Box className={'-cover-title-wrapper'}>
                <Typography variant={'h1'} className={'-cover-title'}>
                    {trans('service.cover.title')}
                </Typography>
                <Typography variant={'subtitle1'} variantMapping={{'subtitle1': 'p'}} className={'-cover-sub-title'}>
                    {trans('service.cover.sub_title')}
                </Typography>
            </Box>
        )
    }

    return (
        <CoverStyled className="x-service-cover-container">
            <Container className={'-cover-container-wrapper'}>
                <Box className="-service-cover-box" style={{backgroundImage: `url(${coverBgInner})`}}>
                    {isTabletOrMobile
                        ? renderCoverTitle()
                        : (
                            <>
                                <Box className={'-cover-column-left'}>
                                    {renderCoverTitle()}
                                </Box>
                                <Box className={'-cover-column-right'}>
                                    <Image
                                        extraClass={'-img-banner'}
                                        src={'wow-boost-service-cover-banner-inner.png'}
                                        width={626}
                                        height={626}
                                    />
                                </Box>
                            </>
                        )
                    }
                </Box>
            </Container>
        </CoverStyled>
    )
}

export default ServiceCover
