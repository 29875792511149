import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

interface IModalTypography extends TypographyProps {
    extraClass?: string;
    renderType?: 'title' | 'description';
}

const ModalTypographyStyled = styled(Typography)(({ theme }) => ({
    '&.x-modal-typography': {
        '&.-title': {
            marginBottom: theme.spacing(.25),
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            color: '#34406b',
        },
        '&.-description': {
            marginBottom: theme.spacing(1),
            fontSize: theme.baseVariables.fonts.fontSizeSmallLarge,
            color: '#646f9b',
        },
        '&.whitespace-break-spaces': {
            whiteSpace: 'break-spaces',
        }
    },
}));

const ModalTypography: React.FC<IModalTypography> = ({
    renderType = 'description',
    extraClass,
    children,
    ...rest
}) => {
    return (
        <ModalTypographyStyled {...rest} className={`x-modal-typography -${renderType} ${extraClass}`}>
            {children}
        </ModalTypographyStyled>
    )
}

export default ModalTypography
