import React, { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTrans } from '@core/Hook/trans';
import { useClientApiState } from '@core/Hook/api';
import useCreditHook from '../../Hook/creditHook';

const CreditBalanceStyled = styled(Box)(({ theme }) => ({
    '&.x-credit-balance-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1.25, 2),
        width: '100%',
        background: theme.baseVariables.summary.bg,
        borderRadius: 5,
        '.-title': {
            color: theme.baseVariables.summary.titleColor,
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
            },
        },
        '.-price': {
            color: theme.baseVariables.summary.priceColor,
            fontSize: theme.baseVariables.fonts.fontSizeNormal,
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.baseVariables.fonts.fontSizeNormalLarge,
            },
        },
        '.-decimal': {
            color: theme.baseVariables.summary.priceDecimalColor,
        },
    },
}));

const CreditBalance: React.FC = () => {
    const { trans } = useTrans();
    const { useFetchUserCredit, creditValue } = useCreditHook();
    const [apiState, setApiState] = useClientApiState({ isLoading: false });
    const { fetchUserCredit } = useFetchUserCredit(apiState, setApiState);

    useEffect(() => {
        fetchUserCredit();
    }, []);

    return (
        <CreditBalanceStyled className={'x-credit-balance-container'}>
            <Typography className={'-title'}>{trans('credit.balance')}</Typography>
            <div>
                <NumericFormat
                    thousandSeparator
                    fixedDecimalScale
                    decimalScale={2}
                    value={creditValue}
                    displayType={'text'}
                    className={'-price'}
                />
            </div>
        </CreditBalanceStyled>
    );
};

export default CreditBalance;
