import {useRecoilState} from "recoil";
import {IOrderPaymentPromptPay, OrderPaymentPromptPayAtom} from "../Recoil/Order/atom";

const usePaymentHook = () => {
    const [orderPaymentPromptPay, setOrderPaymentPromptPay] = useRecoilState(OrderPaymentPromptPayAtom);

    const saveOrderPaymentPromptPay = (data: IOrderPaymentPromptPay | undefined) => {
        setOrderPaymentPromptPay(data);
    }

    const clearOrderPaymentPromptPay = () => saveOrderPaymentPromptPay(undefined)

    return {
        orderPaymentPromptPay,
        saveOrderPaymentPromptPay,
        clearOrderPaymentPromptPay,
    }
}

export default usePaymentHook
