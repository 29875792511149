import { IServiceProductChoice } from 'Page/Product/ProductGroup';
import { atom } from 'recoil';

export interface IProductInfo {
    platform: IServiceProductChoice | undefined;
    service: IServiceProductChoice | undefined;
    target: IServiceProductChoice | undefined;
    packageInfo: IServiceProductChoice | undefined;
    serviceOptionType: IServiceProductChoice | undefined;
    serviceOptionTypeValue: IServiceProductChoice | undefined;
}

export interface IPackageData {
    platformServiceCode: string;
    platformServiceName: string;
    platformCode: string;
    targetAccountCode: string;
    targetAccountName: string;
    productItemCode: string;
    amount: number;
    price: number;
    isPromotion: boolean;
    isRecommend: boolean;
    isBestSeller: boolean;
    accountGroupTypeList: number[];
    isInternal?: boolean;
}

export interface ITargetData {
    platformServiceCode: string;
    platformServiceName: string;
    platformCode: string;
    targetAccountCode: string;
    targetAccountName: string;
}

export interface IServiceOptionDataSet {
    setName: string;
    setCode: string;
}

export interface IServiceOptionData {
    platformServiceCode: string;
    platformCode: string;
    name: string;
    dataSet: IServiceOptionDataSet[];
    typeCode: string;
}

export interface IServiceData {
    platformServiceCode: string;
    platformServiceName: string;
    platformServiceDescription: string;
    platformServiceOption?: IServiceOptionData[];
    platformCode: string;
}

export interface IPlatformData {
    platformCode: string;
    platformName: string;
}

export interface IProductData {
    platforms: IPlatformData[];
    services: IServiceData[];
    serviceOptions?: IServiceOptionData[];
    targets: ITargetData[];
    packagesInfo: IPackageData[];
}

export const ProductsAtom = atom({
    key: 'products',
    default: {} as IProductData,
});

export const ProductChooseInfo = atom({
    key: 'ProductChooseInfoAtom',
    default: {
        platform: undefined,
        service: undefined,
        target: undefined,
        packageInfo: undefined,
        serviceOptionType: undefined,
        serviceOptionTypeValue: undefined,
        // accountSocialName: undefined,
        // accountSocialLink: undefined,
    } as IProductInfo,
});
