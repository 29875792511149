import React, {ReactNode} from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import isUndefined from "lodash/isUndefined";

export interface IServiceProductIcon {
    isActive?: boolean;
    isRoundedBg?: boolean;
    extraClass?: string;
    children?: ReactNode;
}

export const getSvgServiceIconColor = (isActive: boolean): string => isActive ? '#5a80ee' : '#a1b8db';

const IconWrapper = styled(Box)(({ theme }) => ({
    '&.x-service-icon': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        transition: theme.baseVariables.constants.transition.normal,
        [theme.breakpoints.between('xxs', 380)]: {
            width: '18px',
            height: '18px',
        },
        '&.-rounded-bg': {
            background: theme.baseVariables.serviceProductIcon.bg,
            [theme.breakpoints.between('xxs', 380)]: {
                width: '22px',
                height: '22px',
            },
            '&.-active-icon': {
                background: theme.baseVariables.serviceProductIcon.bgHover,
            },
        },
        '&.-emoji-icon-set': {
            width: '100%',
            height: 'auto',
        }
    }
}))

const ServiceProductIcon: React.FC<IServiceProductIcon> = ({extraClass, isActive = false, isRoundedBg = false, children}) => {
    const activeClass = isActive ? '-active-icon' : '';
    const roundedBgClass = isRoundedBg ? '-rounded-bg' : '';
    const _extraClass = !isUndefined(extraClass) ? extraClass : '';

    return (
        <IconWrapper className={`x-service-icon ${activeClass} ${roundedBgClass} ${_extraClass}`}>
            {children}
        </IconWrapper>
    )
}

export default ServiceProductIcon
