import React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { useTrans } from "@core/Hook/trans";
import Box from "@mui/material/Box";
import ModalTypography from "../Partial/ModalTypography";
import FadeInUp from "../../../Animate/FadeInUp";
import {handleResponseFormError, resolveClientApiState, useClientApiState} from "@core/Hook/api";
import {requestOtp} from "../../../Api/auth";
import {IRegisterRequestOtp} from "../RegisterModal";
import PhoneNumberForm, {IPhoneNumberFormData} from "../Form/PhoneNumberForm";
import SSOButtonGroup from "../../SSOButtonGroup";

interface IRegisterStepPhoneNumber {
    onSubmit: (data: IRegisterRequestOtp) => void;
}

const RegisterStepPhoneNumberStyled = styled(Stack)(({ theme }) => ({
    '&.x-register-step-phone-number': {
        '.-icon-wrapper': {
            margin: theme.spacing(2, 0),
            textAlign: 'center',
            '.-img': {
                display: 'inline-block',
                width: 80,
            }
        },
        '.-description-wrapper': {
            marginTop: theme.spacing(1.5),
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(3),
            }
        },
        '.-form-wrapper': {
            margin: theme.spacing(3.5, 0, 1),
        },
    }
}))

const RegisterStepPhoneNumber: React.FC<IRegisterStepPhoneNumber> = ({ onSubmit }) => {
    const { trans } = useTrans();
    const [apiRequestOtpState, setApiRequestOtpState] = useClientApiState({
        isLoading: false,
    });

    const submitPhoneNumber = async (data: IPhoneNumberFormData) => {
        setApiRequestOtpState({ ...apiRequestOtpState, isLoading: true });

        const requestOtpRes = resolveClientApiState(await requestOtp(data), true);

        if (requestOtpRes.isOk) {
            const {otpToken, refCode} = requestOtpRes.data;
            const registerRequestOtpData: IRegisterRequestOtp = {
                phoneNumber: data.phoneNumber,
                otpToken: otpToken,
                refCode: refCode,
            }

            onSubmit(registerRequestOtpData);

            return;
        }

        handleResponseFormError(requestOtpRes);
        setApiRequestOtpState({ ...apiRequestOtpState, isLoading: false });
    }

    return (
        <RegisterStepPhoneNumberStyled className={'x-register-step-phone-number'}>
            <FadeInUp>
                {/*<Box className={'-icon-wrapper'}>*/}
                {/*    <Image*/}
                {/*        src={'ic-chat.png'}*/}
                {/*        width={60}*/}
                {/*        height={60}*/}
                {/*    />*/}
                {/*</Box>*/}
                <Box className={'-description-wrapper'}>
                    <ModalTypography renderType={'title'} align={'center'}>{trans('modal.register_phone_number.enter_phone_number')}</ModalTypography>
                    <ModalTypography align={'center'}>{trans('modal.register_phone_number.otp_to_you')}</ModalTypography>
                </Box>
                <PhoneNumberForm onSubmit={(data) => submitPhoneNumber(data)} isLoading={apiRequestOtpState.isLoading} />
                <SSOButtonGroup />
            </FadeInUp>
        </RegisterStepPhoneNumberStyled>
    )
}

export default RegisterStepPhoneNumber
