import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { useTrans } from "@core/Hook/trans";
import Box from "@mui/material/Box";
import ModalTypography from "../Partial/ModalTypography";
import FormInputPasscode from "@core/Form/FormInputPasscode";
import FormControlWrapper from "@core/Form/FormControlWrapper";
import FadeInUp from "../../../Animate/FadeInUp";
import { ISetPassword } from "../RegisterModal";
import InputPasscodeForm, { IInputPassCodeData } from "../Form/InputPasscodeForm";

interface IRegisterStepPassword {
    onSubmit: (data: ISetPassword) => void;
}

const RegisterStepPasswordStyled = styled(Stack)(({ theme }) => ({
    '&.x-register-step-password': {
        '.-description-wrapper': {
            marginTop: theme.spacing(3),
        },
        '.-form-wrapper': {
            margin: theme.spacing(3.5, 0, 1),
        },
    }
}))

const RegisterStepPassword: React.FC<IRegisterStepPassword> = ({ onSubmit }) => {
    const { trans } = useTrans();
    const [payload, setPayload] = useState('')

    const submitPassword = (data: IInputPassCodeData) => {
        onSubmit({
            password: data.password
        });
    }

    return (
        <RegisterStepPasswordStyled className={'x-register-step-password'}>
            <FadeInUp>
                <Box className={'-description-wrapper'}>
                    <ModalTypography renderType={'title'} align={'center'}>{trans('modal.register_password.enter_password')}</ModalTypography>
                </Box>
                <Box className={'-form-wrapper'}>
                    <InputPasscodeForm
                        labelSubmit={trans('modal.register_password.submit_password')}
                        inputName={'password'}
                        payload={payload}
                        onSubmit={(data) => submitPassword(data)}
                    >
                        <FormControlWrapper
                            required={true}
                            className={'-form-control-wrapper'}
                        >
                            <FormInputPasscode
                                passwordType
                                value={payload}
                                onChange={(val) => setPayload(val)}
                            />
                        </FormControlWrapper>
                    </InputPasscodeForm>
                </Box>
            </FadeInUp>
        </RegisterStepPasswordStyled>
    )
}

export default RegisterStepPassword
