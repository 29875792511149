import React from "react";
import PlainButton from "../../Button/PlainButton";
import { useTrans } from "@core/Hook/trans";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import FormInput from "@core/Form/FormInput";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Form from "@core/Form/Form";
import * as Yup from "yup";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from '@mui/icons-material/Lock';
import TextWithLink from "../../TextWithLink";

interface ILoginByEmailForm {
    onSubmit: (data: ILoginByEmailFormData) => void;
    isLoading?: boolean;
    onClickForgetPassword?(): void;
}

export interface ILoginByEmailFormData {
    email: string;
    password: string;
}

const LoginByEmailForm: React.FC<ILoginByEmailForm> = ({ onSubmit, onClickForgetPassword, isLoading = false }) => {
    const { trans } = useTrans();
    const validationSchema = Yup.object().shape({
        email: Yup.string().required(trans('modal.register_email.form.not_blank')),
        password: Yup.string().required(trans('modal.register_email.form.password.not_blank'))
    });
    const { register, handleSubmit, formState: { errors } } = useForm<ILoginByEmailFormData>({
        resolver: yupResolver(validationSchema)
    });

    const submitLoginByEmail = (data: ILoginByEmailFormData) => onSubmit(data);

    const renderButtonAction = () => {
        return (
            <PlainButton
                type={'submit'}
                fullWidth
                color={'primary'}
                label={trans('general.confirm')}
                isLoading={isLoading}
                disabled={isLoading}
            />
        )
    }

    const inputProps = {
        name: 'email',
        id: 'email',
        className: "-form-control",
        register,
        placeholder: trans('modal.login_email.form.email_placeholder'),
        errors: errors,
        type: 'text',
        fullWidth: true,
        autoFocus: true,
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <PersonIcon />
                </InputAdornment>
            ),
        },
    }

    const inputPasswordProps = {
        name: 'password',
        id: 'password',
        className: "-form-control",
        register,
        placeholder: trans('modal.login_email.form.password_placeholder'),
        errors: errors,
        type: 'password',
        fullWidth: true,
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <LockIcon />
                </InputAdornment>
            ),
        },
    }

    return (
        <Form register={register} onSubmit={handleSubmit(submitLoginByEmail)} buttonComponent={renderButtonAction()}>
            <Box className={'-input-wrapper'}>
                <FormInput {...inputProps} />
                <FormInput {...inputPasswordProps} />
                <TextWithLink
                    extraClass={'justify-end'}
                    linkTitle={trans('modal.login.forget_password')}
                    linkOnClick={onClickForgetPassword}
                />
            </Box>
        </Form>
    )
}

export default LoginByEmailForm
