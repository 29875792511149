import React from "react";
import { styled } from "@mui/material/styles";
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import LoggedMenu from "./LoggedMenu";
import {useCustomerContext} from "../../../Hook/auth";

interface ILoggedDrawer {
    open: boolean;
    handleClose: () => void;
}

const LoggedDrawerStyled = styled(Drawer)(({ theme }) => ({
    '&.x-header-logged-drawer': {
        '.MuiPaper-root': {
            width: '100%',
            maxWidth: 230
        },
        '.-btn-close': {
            display: 'block',
            margin: theme.spacing(0, 0, 0, 'auto'),
            '.-icon': {
                color: theme.baseVariables.loggedMenu.btnCloseColor,
                fontSize: theme.baseVariables.fonts.fontSizeBig
            }
        },
        '.-user-wrapper': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: theme.spacing(2, 0, 4),
            '.-user': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 40,
                height: 40,
                background: theme.baseVariables.loggedMenu.userBg,
                borderRadius: '50%',
                border: theme.baseVariables.loggedMenu.userBorder,
                '.-icon': {
                    width: 30,
                    height: 30,
                    color: theme.baseVariables.loggedMenu.userIconColor
                }
            },
            '.-username': {
                marginTop: theme.spacing(1.5),
                color: theme.baseVariables.loggedMenu.usernameColor,
                fontSize: theme.baseVariables.fonts.fontSizeSmallLarge,
                fontWeight: theme.baseVariables.fonts.fontWeightSemiBold
            }
        }
    }
}))

const LoggedDrawer: React.FC<ILoggedDrawer> = ({ open, handleClose }) => {
    const {customerInfo} = useCustomerContext();

    return (
        <LoggedDrawerStyled
            className={'x-header-logged-drawer'}
            anchor={'right'}
            open={open}
            onClose={handleClose}
        >
            <Button className={'-btn-close'} onClick={handleClose}>
                <ClearIcon className={'-icon'}/>
            </Button>
            <Box className={'-user-wrapper'}>
                <Box className={'-user'}>
                    <PersonIcon className={'-icon'} />
                </Box>
                <Typography className={'-username'}>{customerInfo?.displayName}</Typography>
            </Box>
            <LoggedMenu onClose={handleClose} />
        </LoggedDrawerStyled>
    )
}

export default LoggedDrawer
