import { useTranslation } from "react-i18next";

export type useTransType = (key: string, args?: object) => string

export const useTrans = () => {
    const { t } = useTranslation();

    const trans: useTransType = (key: string, args = {}): string => {
        return t(key, args);
    }

    return {
        trans,
    }
}
