import React from 'react';
import OrderSummary from '../../Component/Order/OrderSummary';
import OrderAction from '../../Component/Order/OrderAction';
import { IOrder } from '../../Recoil/Order/atom';
import { Skeleton } from '@mui/material';
import { getOrderStateLabelByCode } from '../../Utils/helper';
import { IServiceProductChoice } from 'Page/Product/ProductGroup';

interface IOrderDetailFooter {
    order: IOrder;
    isLoading?: boolean;
    orderType?: string;
}

const OrderDetailFooter: React.FC<IOrderDetailFooter> = ({ order, isLoading, orderType }) => {
    const purchaseOrderProduct: IServiceProductChoice = {
        code: order.purchaseOrderProduct.productItemFullCode || '',
        amount: order.purchaseOrderProduct.amount,
        price: order.purchaseOrderProduct.price,
    };
    return (
        <>
            {isLoading ? (
                <Skeleton height={47} variant="rounded" />
            ) : (
                <>
                    <OrderSummary price={order.purchaseOrderProduct.price} />
                    <OrderAction
                        currentState={getOrderStateLabelByCode(order.orderStatus)}
                        orderId={order.purchaseOrderRefId}
                        orderType={orderType}
                        purchaseOrderProduct={purchaseOrderProduct}
                    />
                </>
            )}
        </>
    );
};

export default OrderDetailFooter;
