import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTrans } from '@core/Hook/trans';
import OrderHistoryListItem from './OrderHistoryListItem';
import NoResult from '../../Component/NoResult';
import Stack from '@mui/material/Stack';
import { IOrder } from '../../Recoil/Order/atom';

interface IOrderHistoryList {
    lists: IOrder[];
}

const OrderHistoryListStyled = styled(Stack)(({ theme }) => ({
    '&.x-history-list-container': {
        width: '100%',
        marginTop: theme.spacing(1),
        '.-no-result': {
            padding: theme.spacing(5, 0),
        },
    },
}));

const OrderHistoryList: React.FC<IOrderHistoryList> = ({ lists }) => {
    const { trans } = useTrans();

    return (
        <OrderHistoryListStyled className={'x-history-list-container'}>
            {lists.length ? (
                lists.map((item, key) => {
                    return <OrderHistoryListItem key={`order-history-list-${key}`} order={item} />;
                })
            ) : (
                <Box className={'-no-result'}>
                    <NoResult title={trans('history.no_result')} />
                </Box>
            )}
        </OrderHistoryListStyled>
    );
};

export default OrderHistoryList;
