import React, { memo, useState } from 'react';
import { styled } from '@mui/material/styles';
import BasicButton, { IBasicButton } from '@core/Component/BasicButton';
import isUndefined from 'lodash/isUndefined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IServiceProductIcon } from '../../Component/Icon/ServiceIcon';
import { NumericFormat } from 'react-number-format';
import { alpha } from '@mui/material';

interface IProductButton extends IBasicButton {
    extraClass?: string;
    icon?: any;
    iconProps?: IServiceProductIcon;
    price?: number;
    isActive: boolean;
    isIconVertical: boolean;
    prefix?: string;
}

const ProductButtonWrapperStyled = styled(BasicButton)(({ theme }) => ({
    '&.x-service-product-button': {
        background: theme.baseVariables.serviceProductButton.bg,
        border: theme.baseVariables.serviceProductButton.border,
        borderRadius: theme.spacing(1.5),
        color: theme.baseVariables.serviceProductButton.color,
        transition: theme.baseVariables.constants.transition.normal,
        padding: theme.spacing(1.5, 1),
        width: '100%',
        [theme.breakpoints.between(360, 395)]: {
            padding: theme.spacing(1.25, 1.75),
        },
        [theme.breakpoints.between('xxs', 359)]: {
            padding: theme.spacing(1.25, 1),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1.5, 1),
        },
        '&:hover, &.-active': {
            background: theme.baseVariables.serviceProductButton.bgHover,
            border: theme.baseVariables.serviceProductButton.borderHover,
            '&.-choice-label-with-icon': {
                '.-product-choice-label-wrapper': {
                    '.-product-choice-label': {
                        color: theme.baseVariables.serviceProductButton.colorHover,
                    },
                },
            },
        },
        '&.-choice-label-with-icon': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'center',
            },
            '&.-serviceOptionTypeValue': {
                justifyContent: 'center',
                padding: theme.spacing(1),
                [theme.breakpoints.up('sm')]: {
                    padding: theme.spacing(1.5, 1),
                },
                '.-product-choice-label-wrapper': {
                    '.-product-choice-icon-wrapper': {
                        marginRight: theme.spacing(0),
                    },
                },
            },
            '&.-package': {
                justifyContent: 'center',
                padding: theme.spacing(1.5, 1),
                '.-product-choice-label-wrapper': {
                    '.-product-choice-label': {
                        color: theme.baseVariables.serviceProductButton.packageColor,
                    },
                },
            },
            '.-product-choice-label-wrapper': {
                '.-product-choice-label': {
                    textTransform: 'capitalize',
                    fontSize: theme.baseVariables.fonts.fontSizeSmall,
                    fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                    [theme.breakpoints.between('xxs', 380)]: {
                        fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    },
                },
                '.-product-choice-price': {
                    // marginTop: theme.spacing(1),
                    color: theme.baseVariables.serviceProductButton.prizeColor,
                    fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                },
                '&.-is-vertical': {
                    flexDirection: 'column',
                    '.-product-choice-icon-wrapper': {
                        marginRight: 0,
                        marginBottom: theme.spacing(1),
                    },
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                '.-product-choice-icon-wrapper': {
                    marginRight: theme.spacing(1),
                },
            },
            '&.-internal': {
                color: theme.baseVariables.serviceProductButton.internalColor,
                background: 'radial-gradient(173.75% 173.75% at 50% -0.00%, #ffffff 0%, #eae6fe 100%)',
                borderColor: alpha('#8b5cf6', 0.25),
                overflow: 'hidden',
                '&::before': {
                    content: '"ใช้ภายใน"',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: '.125rem .5rem',
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    color: theme.palette.common.white,
                    borderRadius: '0 0 0 8px',
                    background: alpha('#8b5cf6', 0.25),
                    transition: 'all .2s',
                },
                '&:hover, &.-active': {
                    background: alpha('#8b5cf6', 0.25),
                    borderColor: '#8b5cf6',
                    path: {
                        fill: '#8b5cf6',
                    },
                    '&::before': {
                        background: '#8b5cf6',
                    },
                },
            },
        },
    },
}));

const ProductButton = (props: IProductButton) => {
    const { extraClass, prefix = '฿', label, price, icon, iconProps, isActive, isIconVertical, ...serviceProductButtonProps } = props;
    const [isHovered, setIsHovered] = useState(false);
    const ServiceIcon = icon;
    const activeClass = isActive ? '-active' : '';

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const renderLabelWithIcon = () => {
        const iconVerticalClass = isIconVertical ? '-is-vertical' : '';

        return (
            <Box className={`-product-choice-label-wrapper ${iconVerticalClass}`}>
                {icon && (
                    <Box className={'-product-choice-icon-wrapper'}>
                        <ServiceIcon isActive={isHovered || isActive} {...iconProps} />
                    </Box>
                )}
                <Typography component={'span'} className={'-product-choice-label'}>
                    {label}
                </Typography>
                {price && (
                    <Typography component={'span'} className={'-product-choice-price'}>
                        <NumericFormat prefix={prefix} thousandSeparator displayType={'text'} className={'-number-text'} value={price} />
                    </Typography>
                )}
            </Box>
        );
    };

    return (
        <ProductButtonWrapperStyled
            className={`x-service-product-button ${activeClass} ${!isUndefined(extraClass) ? `-${extraClass}` : ''}`}
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
            labelElement={renderLabelWithIcon()}
            {...serviceProductButtonProps}
        />
    );
};

export default memo(ProductButton);
