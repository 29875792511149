import React, {Fragment} from "react";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {useTrans} from "@core/Hook/trans";
import Stack from "@mui/material/Stack";
import Title from "../../Component/Title";
import {List, ListItem} from "@mui/material";
import isUndefined from "lodash/isUndefined";
import Typography from "@mui/material/Typography";

const PolicyPrivacyStyled = styled(Box)(({theme}) => ({
    '&.x-policy-privacy-container': {
        '.-title-with-highlight': {
            textAlign: 'justify',
            '.-title': {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                color: theme.baseVariables.title.color,
                marginRight: theme.spacing(1),
                [theme.breakpoints.up('sm')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeNormal,
                },
            },
        },
        '.-personal-information-collect-wrapper': {
            '.-list-wrapper': {
                listStyleType: 'disc',
                listStylePosition: 'outside',
                padding: theme.spacing(0, 0, 0, 3),
                '.-list-item-wrapper': {
                    display: 'list-item',
                    '&::marker': {
                        color: theme.baseVariables.colors.textPrimary,
                        fontSize: theme.baseVariables.fonts.fontSizeSmallest,
                        [theme.breakpoints.up('sm')]: {
                            fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                        },
                    }
                }
            }
        },
        '.-privacy-contact-wrapper': {
            '.-list-wrapper': {
                listStyleType: 'disc',
                listStylePosition: 'outside',
                padding: theme.spacing(0, 0, 0, 3),
                '.-list-item-wrapper': {
                    display: 'list-item',
                    '&::marker': {
                        color: theme.baseVariables.colors.textPrimary,
                        fontSize: theme.baseVariables.fonts.fontSizeSmallest,
                        [theme.breakpoints.up('sm')]: {
                            fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                        },
                    }
                }
            }
        }
    }
}))

const PolicyPrivacy: React.FC = () => {
    const {trans} = useTrans();

    const renderTitleWithHighlight = (title: string, subTitle: string) => {
        return (
            <Box className={'-title-with-highlight'}>
                <Typography component={'span'} className={'-title'}>
                    {title}
                </Typography>
                <Typography component={'span'} className={'-sub-title'}>
                    {subTitle}
                </Typography>
            </Box>
        )
    }

    const renderPersonalInformationCollect = (title: string, subTitle: string, topic?: string, bulletList?: number, childrenList?: {parentIndex: number, bulletList: number, topic: string}) => {
        return (
            <Box className={'-personal-information-collect-wrapper'}>
                {renderTitleWithHighlight(title, subTitle)}
                {!isUndefined(topic) && <List className={'-list-wrapper'}>
                    {Array.from(Array(bulletList)).map((i, index) => {
                        const parentIndex = index + 1;

                        return (
                            <Fragment key={`personal_information_you_provide_${index}`}>
                                <ListItem dense disablePadding className={'-list-item-wrapper'}>
                                    <Title titlePosition={'left'} subTitlePosition={'justify'}
                                           subTitle={trans(`policy.privacy.personal_information_collect.${topic}.bullet_${index + 1}`)}
                                           extraClass={'-in-policy'}
                                    />
                                </ListItem>
                                {!isUndefined(childrenList) && parentIndex === childrenList.parentIndex && <List className={'-list-wrapper'}>
                                    {Array.from(Array(childrenList?.bulletList)).map((i, index) => {
                                        return (
                                            <ListItem className={'-list-item-wrapper'} dense disablePadding key={`personal_information_you_provide_${index}`}>
                                                <Title titlePosition={'left'} subTitlePosition={'justify'}
                                                       subTitle={trans(`policy.privacy.personal_information_collect.${topic}.${childrenList.topic}.bullet_${index + 1}`)}
                                                       extraClass={'-in-policy'}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>}
                            </Fragment>
                        )
                    })}
                </List>}
            </Box>
        )
    }

    const privacyTopics = [
        {title: 'privacy_policy_for', sub_title: 'privacy_policy_for'},
        {title: 'personal_information_collect'},
        {
            element: renderPersonalInformationCollect(
                trans('policy.privacy.personal_information_collect.personal_information_you_provide.title'),
                trans('policy.privacy.personal_information_collect.personal_information_you_provide.sub_title'),
                'personal_information_you_provide',
                4)
        },
        {
            element: renderPersonalInformationCollect(
                trans('policy.privacy.personal_information_collect.indirect_personal_information.title'),
                trans('policy.privacy.personal_information_collect.indirect_personal_information.sub_title'),
                'indirect_personal_information',
                1)
        },
        {
            element: renderPersonalInformationCollect(
                trans('policy.privacy.personal_information_collect.usage_personal_information.title'),
                trans('policy.privacy.personal_information_collect.usage_personal_information.sub_title'),
                'usage_personal_information',
                2,
                {parentIndex: 1, bulletList: 7, topic: "child_bullet_1"})
        },
        {
            element: renderPersonalInformationCollect(
                trans('policy.privacy.personal_information_collect.sensitive_personal_data.title'),
                trans('policy.privacy.personal_information_collect.sensitive_personal_data.sub_title'),
            )
        },
        {title: 'company_collect_data_for', sub_title: 'company_collect_data_for'},
        {title: 'company_disclose_data_to', sub_title: 'company_disclose_data_to'},
        {title: 'company_transfer_data_abroad', sub_title: 'company_transfer_data_abroad'},
        {title: 'how_long_to_store_data', sub_title: 'how_long_to_store_data'},
        {title: 'company_protect_data', sub_title: 'company_protect_data'},
        {title: 'rights_regarding_personal_information', sub_title: 'rights_regarding_personal_information'},
        {title: 'change_privacy', sub_title: 'change_privacy'},
    ]

    return (
        <PolicyPrivacyStyled className="x-policy-privacy-container">
            <Stack display={'flex'} flexDirection={'column'} spacing={1.5}>
                <Title titlePosition={'left'} subTitlePosition={'justify'}
                       subTitle={trans('policy.privacy.intro.sub_title')}
                       extraClass={'-in-policy'}
                />
                {privacyTopics.map((topic, index) => {
                    if (!isUndefined(topic.element)) return <Box key={`policy-privacy-${index}`}>{topic.element}</Box>;

                    return (
                        <Title key={`policy-privacy-${index}`} titlePosition={'left'} subTitlePosition={'justify'}
                               title={isUndefined(topic.title) ? undefined : trans(`policy.privacy.${topic.title}.title`)}
                               subTitle={isUndefined(topic.sub_title) ? undefined : trans(`policy.privacy.${topic.sub_title}.sub_title`)}
                               extraClass={'-in-policy'}
                        />
                    )
                })}
                <Box className={'-privacy-contact-wrapper'}>
                    <Title titlePosition={'left'} subTitlePosition={'justify'}
                           title={trans('policy.privacy.how_to_contact.title')}
                           subTitle={trans('policy.privacy.how_to_contact.sub_title')}
                           extraClass={'-in-policy'}
                    />
                    <List className={'-list-wrapper'}>
                        {Array.from(Array(3)).map((i, index) => {
                            return (
                                <ListItem className={'-list-item-wrapper'} dense disablePadding key={`privacy_contact_no_${index}`}>
                                    <Title titlePosition={'left'} subTitlePosition={'justify'}
                                           subTitle={trans(`policy.privacy.how_to_contact.bullet_${index + 1}`)}
                                           extraClass={'-in-policy'}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            </Stack>
        </PolicyPrivacyStyled>
    )
}

export default PolicyPrivacy
