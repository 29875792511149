import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const ReportIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 27 28" fill="none">
                <path d="M26.7624 22.9683L14.9167 3.32322C14.6173 2.82672 14.0798 2.52313 13.4999 2.52313C12.9201 2.52313 12.3826 2.82667 12.0832 3.32322L0.237618 22.9683C-0.0704557 23.4792 -0.0795788 24.1165 0.213835 24.636C0.507249 25.1556 1.05764 25.4768 1.65427 25.4768H25.3457C25.9423 25.4768 26.4927 25.1555 26.7861 24.636C27.0795 24.1165 27.0705 23.4792 26.7624 22.9683ZM13.5088 9.31859C14.1891 9.31859 14.7648 9.70239 14.7648 10.3827C14.7648 12.4584 14.5206 15.4414 14.5206 17.5172C14.5206 18.058 13.9275 18.2847 13.5088 18.2847C12.9507 18.2847 12.4796 18.0579 12.4796 17.5172C12.4796 15.4414 12.2355 12.4584 12.2355 10.3827C12.2355 9.70239 12.7936 9.31859 13.5088 9.31859ZM13.5263 22.1748C12.7588 22.1748 12.1831 21.5468 12.1831 20.8316C12.1831 20.099 12.7587 19.4885 13.5263 19.4885C14.2414 19.4885 14.852 20.099 14.852 20.8316C14.852 21.5468 14.2414 22.1748 13.5263 22.1748Z" fill={color}/>
            </svg>
        </ServiceProductIcon>
    )
}

export default ReportIcon
