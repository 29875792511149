import ApiClient from '../Utils/api_client';
import {AxiosResponse} from "axios";

export const LOGIN_TYPE_CHECK_ACCOUNT_GUEST = 0;
export const LOGIN_TYPE_CHECK_ACCOUNT_USERNAME = 1;
export const LOGIN_TYPE_CHECK_ACCOUNT_EMAIL = 2;
export const LOGIN_TYPE_CHECK_ACCOUNT_LINE = 3;
export const LOGIN_TYPE_CHECK_ACCOUNT_FACEBOOK = 4;
export const LOGIN_TYPE_CHECK_ACCOUNT_PHONE_NUMBER = 5;
export const LOGIN_TYPE_CHECK_ACCOUNT_GOOGLE = 6;

export const NOT_ASSIGNED_ERROR_CODE = 0;
export const ACCOUNT_IS_NOT_VERIFIED = 1;
export const TOKEN_IS_EXPIRE = 2;
export const DUPLICATE_ACCOUNT = 3;
export const ACCOUNT_NOT_FOUND = 4;
export const TOKEN_IS_INVALID = 4;

export async function checkAccountExists(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/auth/checkAccount', formData);
}

export async function requestOtp(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/auth/requestOtpToRegisterByPhone', formData);
}

export async function requestOtpToForgetPasswordByPhone(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/auth/requestOtpToForgetPasswordByPhone', formData);
}

export async function validateOtp(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/auth/validateOTP', formData);
}

export async function loginByPhone(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/auth/loginByPhone', formData);
}

export async function loginByLoginToken(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/auth/loginByLoginToken', formData);
}

export async function loginByEmail(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/auth/loginByEmailPass', formData);
}

export async function logout(): Promise<AxiosResponse> {
    return ApiClient.post('/auth/logout');
}
