import React, {ReactNode} from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

interface IPolicyLayout {
    children: ReactNode;
}

const PolicyLayoutStyled = styled(Box)(({ theme }) => ({
    '&.x-policy-body-layout': {
        padding: theme.spacing(4, 0),
        transition: theme.baseVariables.constants.transition.normal,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4),
        },
        '.-paper': {
            padding: theme.spacing(3),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(4, 6),
            },
            background: 'white',
            border: '1px solid #e2efff',
            borderRadius: 15,
        },
    }
}))

const PolicyBodyLayout: React.FC<IPolicyLayout> = ({children}) => {
    return (
        <PolicyLayoutStyled className={'x-policy-body-layout'}>
            <Container>
                <Paper className={'-paper'} elevation={0}>
                    <Stack className={'-stack'} alignItems={"center"}>
                        {children}
                    </Stack>
                </Paper>
            </Container>
        </PolicyLayoutStyled>
    )
}

export default PolicyBodyLayout
