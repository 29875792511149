import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { useTrans } from "@core/Hook/trans";

interface IMetaSeo {
    title?: string;
    description?: string;
}

const MetaSeo: React.FC<IMetaSeo> = ({title, description}) => {
    const { trans } = useTrans();

    const headTitle = (title || trans('meta.title')) + ` | ${trans('brand')}`
    const headDescription = description || trans('meta.description')

    return (
        <Helmet>
            <title>{headTitle}</title>
            <meta name="description" content={headDescription} />

            <meta name="application-name" content={headTitle} />
            <meta name="apple-mobile-web-app-title" content={headTitle} />
        </Helmet>
    )
}

const metaSeoPropsAreEqual = (prevProps: IMetaSeo, nextProps: IMetaSeo) => {
    return prevProps.title === nextProps.title && prevProps.description === nextProps.description;
}

export default memo(MetaSeo,  metaSeoPropsAreEqual);
