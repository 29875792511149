import React from 'react';
import { NumericFormat } from 'react-number-format';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTrans } from '@core/Hook/trans';
import { getDecimalNumber, hasDecimal } from '../../Utils/helper';

interface IOrderSummary {
    price?: number;
}

const OrderSummaryStyled = styled(Box)(({ theme }) => ({
    '&.x-order-summary-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1.25, 2),
        width: '100%',
        background: theme.baseVariables.summary.bg,
        borderRadius: 5,
        '.-title': {
            color: theme.baseVariables.summary.titleColor,
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
            },
        },
        '.-price': {
            color: theme.baseVariables.summary.priceColor,
            fontSize: theme.baseVariables.fonts.fontSizeNormal,
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.baseVariables.fonts.fontSizeNormalLarge,
            },
        },
        '.-decimal': {
            color: theme.baseVariables.summary.priceDecimalColor,
        },
    },
}));

const OrderSummary: React.FC<IOrderSummary> = ({ price = 0 }) => {
    const { trans } = useTrans();

    const hasDecimalChecker = hasDecimal(price);
    const decimalNumber = getDecimalNumber(price);

    return (
        <OrderSummaryStyled className={'x-order-summary-container'}>
            <Typography className={'-title'}>{trans('order.total')}</Typography>
            <div>
                <NumericFormat thousandSeparator prefix={'฿'} value={Math.floor(price)} displayType={'text'} className={'-price'} decimalScale={0} />
                {hasDecimalChecker && <span className={'-price -decimal'}>{`.${decimalNumber}`}</span>}
            </div>
        </OrderSummaryStyled>
    );
};

export default OrderSummary;
