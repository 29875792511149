import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import { useResponsive } from '@core/Hook/responsiveHook';
import Typography from '@mui/material/Typography';
import LoggedDrawer from './LoggedDrawer';
import LoggedDropdown from './LoggedDropdown';
import { ICustomerInfo } from '../../../Recoil/Common/atom';

interface IHeaderLogged {
    customerInfo: ICustomerInfo;
}

const HeaderLoggedStyled = styled(Box)(({ theme }) => ({
    '&.x-header-logged-container': {
        '.-btn-icon': {
            padding: 0,
            background: theme.baseVariables.headerLogged.btnLoggedBg,
            borderRadius: '50%',
            border: theme.baseVariables.headerLogged.btnLoggedBorder,
            transition: 'background .2s',
            [theme.breakpoints.down('md')]: {
                width: 32,
                height: 32,
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0, 1.5, 0, 1),
                borderRadius: 30,
            },
            '.-ic': {
                width: 24,
                height: 24,
                color: theme.baseVariables.headerLogged.btnLoggedColor,
                [theme.breakpoints.up('md')]: {
                    width: 26,
                    height: 26,
                    marginRight: theme.spacing(0.5),
                },
                [theme.breakpoints.up('lg')]: {
                    marginRight: theme.spacing(0.75),
                },
            },
            '.-text': {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                color: theme.baseVariables.headerLogged.btnLoggedColor,
                [theme.breakpoints.up('lg')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeSmallLarge,
                },
            },
            '&:active, &:hover': {
                background: theme.baseVariables.headerLogged.btnLoggedActiveBg,
            },
        },
    },
}));

const HeaderLogged: React.FC<IHeaderLogged> = ({ customerInfo }) => {
    const { isDesktop } = useResponsive();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openLogged, setOpenLogged] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenLogged(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenLogged(false);
    };

    return (
        <HeaderLoggedStyled className={'x-header-logged-container'}>
            <IconButton className={'x-btn-header-base -btn-icon'} onClick={handleClick}>
                <PersonIcon className={'-ic'} />
                {isDesktop && <Typography className={'-text'}>{customerInfo.displayName}</Typography>}
            </IconButton>
            {isDesktop ? <LoggedDropdown open={openLogged} anchorEl={anchorEl} handleClose={handleClose} /> : <LoggedDrawer open={openLogged} handleClose={handleClose} />}
        </HeaderLoggedStyled>
    );
};

export default HeaderLogged;
