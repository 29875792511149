import { selector } from 'recoil';
import {LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE} from "../../Constants/common";
import {CustomerInfoAtom, ICustomerInfo} from "./atom";

export const ApiTokenSelector = selector({
    key: 'ApiTokenSelector',
    get: (): string|undefined => {
        const t = window.localStorage.getItem(LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE);

        return t ? t : undefined;
    },
});

export const CustomerContextSelector = selector({
    key: 'CustomerContextSelector',
    get: ({get}) => {
        const customerInfo: ICustomerInfo | undefined = get(CustomerInfoAtom);

        if (customerInfo) return customerInfo;

        return undefined
    },
});
