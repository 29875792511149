import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { hamburgerWave } from "../../../Theme/Partial/keyframes";

interface IHamburgerButton {
    onClick(): void;
}

const HamburgerButtonStyled = styled(Button)(({ theme }) => ({
    '&.x-hamburger-button': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: 22,
        marginRight: theme.spacing(1.5),
        padding: theme.spacing(0),
        '.-line': {
            width: '100%',
            height: 3,
            background: theme.baseVariables.headerHamburger.color,
            borderRadius: 5,
            transformOrigin: 'left center',
            '&:not(:first-of-type)': {
                marginTop: theme.spacing(.625),
            },
            '&:nth-of-type(1)': {
                animation: `${hamburgerWave} .4s ease-out infinite alternate`,
            },
            '&:nth-of-type(2)': {
                width: '70%',
                animation: `${hamburgerWave} .4s ease-out .3s infinite alternate`,
            },
            '&:nth-of-type(3)': {
                animation: `${hamburgerWave} .4s ease-out .6s infinite alternate`,
            },
        }
    }
}))

const HamburgerButton: React.FC<IHamburgerButton> = ({onClick}) => {
    return (
        <HamburgerButtonStyled className={'x-hamburger-button'} onClick={onClick}>
            {Array.from(Array(3)).map((_i, index) => {
                return (
                    <Box key={`hamburger-button-item-${index}`} className={'-line'} />
                )
            })}
        </HamburgerButtonStyled>
    )
}

export default HamburgerButton
