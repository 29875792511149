import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

interface IHistoryProgress {
    value: number;
    count: number;
    total: number;
}

const OrderProgressStyled = styled(Box)(({ theme }) => ({
    '&.x-order-progress-container': {
        position: 'relative',
        margin: theme.spacing(1, 0),
        padding: 3,
        width: '100%',
        background: theme.baseVariables.progressBar.bg,
        border: theme.baseVariables.progressBar.border,
        borderRadius: 10,
        '.-progress-bar': {
            height: 14,
            borderRadius: 16,
            [`&.${linearProgressClasses.colorPrimary}`]: {
                background: theme.baseVariables.progressBar.trackBg
            },
            [`& .${linearProgressClasses.bar}`]: {
                background: theme.baseVariables.progressBar.progressBg,
                borderRadius: 14
            },
        },
        '.-progress-label': {
            position: 'absolute',
            top: '50%',
            left: 0,
            width: '100%',
            color: theme.baseVariables.progressBar.labelColor,
            fontSize: theme.baseVariables.fonts.fontSizeSmaller,
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            textAlign: 'center',
            translate: '0 -50%',
        }
    }
}))

const OrderProgress: React.FC<IHistoryProgress> = ({ value, count, total }) => {
    return (
        <OrderProgressStyled className={'x-order-progress-container'}>
            <LinearProgress className={'-progress-bar'} variant="determinate" value={value}/>
            <Typography className={'-progress-label'}>{`${count}/${total}`}</Typography>
        </OrderProgressStyled>
    )
}

export default OrderProgress
