import React from "react";
import { Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

interface ILoadingList {
    amount?: number;
}

const LoadingListStyled = styled(Stack)(({theme}) => ({
    '.MuiSkeleton-root': {
        width: '100%',
        height: 100,
        '&:first-of-type': {
            marginTop: theme.spacing(2),
        },
        '&:not(:last-of-type)': {
            marginBottom: theme.spacing(1),
        }
    }
}));

const LoadingList: React.FC<ILoadingList> = ({amount = 10}) => {
    return (
        <LoadingListStyled>
            {Array.from(Array(amount)).map((i, index) => {
                return (
                    <Skeleton key={`skeleton-loading-${index}`} variant="rounded" />
                )
            })}
        </LoadingListStyled>
    )
}

export default LoadingList
