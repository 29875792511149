import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useTrans } from "@core/Hook/trans";
import Stack from "@mui/material/Stack";
import Title from "../../Component/Title";
import {List, ListItem} from "@mui/material";

const PolicyTermStyled = styled(Box)(({theme}) => ({
    '&.x-policy-term-and-condition-container': {
        '.-term-and-condition-wrapper': {
            '.-list-wrapper': {
                listStyleType: 'decimal',
                listStylePosition: 'outside',
                padding: theme.spacing(0, 0, 0, 1.75),
                '.-list-item-wrapper': {
                    display: 'list-item',
                    '&::marker': {
                        color: theme.baseVariables.colors.textPrimary,
                        fontSize: theme.baseVariables.fonts.fontSizeSmall,
                        [theme.breakpoints.up('sm')]: {
                            fontSize: theme.baseVariables.fonts.fontSizeNormal,
                        },
                    }
                }
            }
        }
    }
}))

const PolicyTerm: React.FC = () => {
    const { trans } = useTrans();
    const termAndConditionTopics = [
        'use_of_the_website',
        'about_website',
        'submission_on_website',
        'link_to_other_website',
        'disclaimer_and_limitation_of_liability',
        'changes_to_terms_and_conditions',
    ]

    return (
        <PolicyTermStyled className="x-policy-term-and-condition-container">
            <Stack display={'flex'} flexDirection={'column'} spacing={1}>
                <Title titlePosition={'left'} subTitlePosition={'justify'}
                       subTitle={trans('policy.term_and_condition.intro.sub_title')}
                       extraClass={'-in-policy'}
                />
                {termAndConditionTopics.map((topic) => {
                    return (
                        <Title key={`term-and-condition-${topic}`} titlePosition={'left'} subTitlePosition={'justify'}
                               title={trans(`policy.term_and_condition.${topic}.title`)}
                               subTitle={trans(`policy.term_and_condition.${topic}.sub_title`)}
                               extraClass={'-in-policy'}
                        />
                    )
                })}
                <Box className={'-term-and-condition-wrapper'}>
                    <Title titlePosition={'left'} subTitlePosition={'justify'}
                           title={trans('policy.term_and_condition.terms_of_service.title')}
                           extraClass={'-in-policy'}
                    />
                    <List className={'-list-wrapper'}>
                        {Array.from(Array(5)).map((i, index) => {
                            return (
                                <ListItem className={'-list-item-wrapper'} dense disablePadding key={`terms_of_service_no_${index}`}>
                                    <Title key={`term-and-condition-${index}`} titlePosition={'left'} subTitlePosition={'justify'}
                                           subTitle={trans(`policy.term_and_condition.terms_of_service.no_${index + 1}`)}
                                           extraClass={'-in-policy'}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            </Stack>
        </PolicyTermStyled>
    )
}

export default PolicyTerm
