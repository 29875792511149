import set from "lodash/set";

export const addOrReplace = (objArr: Array<any>, obj: object, identifier = 'id'): Array<any>  => {
    let isReplace = false;

    const newArr = objArr.map(function(a: object) {
        // @ts-ignore
        if (a[identifier] === obj[identifier]) {
            isReplace = true;
            return {...obj};
        }

        return a;
    });

    if (isReplace) {
        return newArr;
    }

    newArr.push(obj);

    return [
        ...newArr
    ]
};

export const replaceByIdentifier = (objArr: Array<any>, obj: object, identifier = 'id'): Array<any>  => {
    let isReplace = false;

    const newArr = objArr.map(function(a: object) {
        // @ts-ignore
        if (a[identifier] === obj[identifier]) {
            isReplace = true;
            return {...a, ...obj};
        }

        return a;
    });

    if (isReplace) {
        return newArr;
    }

    newArr.push(obj);

    return [
        ...newArr
    ]
};

/*
 Better performance than `_.get()`
 see: https://stackoverflow.com/questions/2631001/javascript-test-for-existence-of-nested-object-key
 */
export const ref = function ref(obj: object, s: string): any {
    const str: Array<string> = s.split(".");

    for (let i = 0; i < str.length; i++) {
        // eslint-disable-next-line no-prototype-builtins
        if (typeof obj === 'undefined' || !obj.hasOwnProperty(str[i])) {
            return undefined;
        }

        // @ts-ignore
        obj = obj[str[i]];
    }

    return obj;
}

export const setRef = function (obj: object, str: string, value: any) {
    set(obj, str, value);
}
