import {alpha, ThemeOptions} from "@mui/material";
import variableEntry from "./Partial/variableEntry";

declare module '@mui/material/styles' {
    interface CustomTheme {
        baseVariables: {
            [key: string]: { [key: string]: any };
        },
    }

    // fix the type error when referencing the Theme object in your styled component
    interface Theme extends CustomTheme {
        fix?: any;
    }

    // fix the type error when calling `createTheme()` with a custom theme option
    interface ThemeOptions extends CustomTheme {
        fix?: any;
    }

    interface BreakpointOverrides {
        xxs: true;
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
    }
}

export const CreateThemeOptions = (): ThemeOptions => {
    const variables = variableEntry()
    const { colors, fonts, input } = variables;

    return {
        breakpoints: {
            keys: ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'],
            values: {
                xxs: 0,
                xs: 375,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            }
        },
        palette: {
            primary: {
                main: colors.primary,
            },
            secondary: {
                main: colors.secondary,
            },
            info: {
                main: colors.info,
            },
            success: {
                main: colors.success,
            },
            warning: {
                main: colors.warning,
            },
            error: {
                main: colors.error,
            },
            text: {
                primary: colors.bodyColor,
            },
        },
        typography: {
            fontFamily: fonts.fontFamily,
            fontSize: fonts.fontSizeBase,
            h1: {
                fontSize: fonts.fontSizeH1,
            },
            subtitle1: {
                fontSize: fonts.fontSizeNormal,
                fontWeight: fonts.fontWeightMedium,
                color: colors.textPrimary,
                "@media (max-width: 430px)": {
                    fontSize: fonts.fontSizeSmallLarge,
                },
                "@media (max-width: 350px)": {
                    fontSize: fonts.fontSizeSmall,
                }
            },
            body1: {
                fontSize: fonts.fontSizeNormal,
                fontWeight: fonts.fontWeightNormal,
                color: colors.textPrimary,
                "@media (max-width: 430px)": {
                    fontSize: fonts.fontSizeSmallLarge,
                },
                "@media (max-width: 350px)": {
                    fontSize: fonts.fontSizeSmall,
                }
            },
            body2: {
                fontSize: fonts.fontSizeSmall,
                color: colors.textPrimary,
                "@media (max-width: 380px)": {
                    fontSize: fonts.fontSizeSmaller,
                }
            },
        },
        components: {
            MuiContainer: {
                defaultProps: {
                    maxWidth: 'lg',
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        minWidth: 'auto',
                    }
                },
                defaultProps: {
                    disableRipple: true,
                    disableFocusRipple: true,
                },
            },
            MuiSkeleton: {
                styleOverrides: {
                    root: {
                        backgroundColor: alpha('#e9e9e9', .5),
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        lineHeight: 1.5,
                    }
                }
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        background: variables.modal.backdropColor,
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        border: '1px solid #e2efff',
                        background: alpha('#ffffff', .5),
                        borderRadius: '8px',
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        width: '24px',
                        height: '24px',
                    }
                }
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '.MuiOutlinedInput-root': {
                            fontSize: fonts.fontSizeSmallLarge,
                            background: input.bg,
                            borderRadius: 30,
                            '&.MuiInputBase-multiline': {
                                borderRadius: 20,
                            },
                            '&:hover': {
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: input.borderFocusColor,
                                },
                            },
                            '&.Mui-focused': {
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 1,
                                },
                            }
                        },
                        '.MuiOutlinedInput-input': {
                            height: input.height,
                            paddingTop: 0,
                            paddingBottom: 0,
                            color: input.color,
                            '&::placeholder': {
                                color: input.placeholderColor,
                                fontSize: fonts.fontSizeSmall,
                                "@media (max-width: 430px)": {
                                    fontSize: fonts.fontSizeSmallLarge,
                                },
                                "@media (max-width: 350px)": {
                                    fontSize: fonts.fontSizeSmall,
                                }
                            }
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: input.borderColor,
                        },
                        '.MuiInputAdornment-positionStart': {
                            height: '100%',
                            marginRight: '1rem',
                            paddingRight: '.75rem',
                            paddingLeft: '.5rem',
                            color: input.adornmentColor,
                            borderRight: '1px solid #a1b8db',
                            'svg': {
                                fontSize: fonts.fontSizeBig,
                            }
                        },
                        '.MuiInputAdornment-positionEnd': {
                            color: input.adornmentColor,
                            '.MuiIconButton-root': {
                                color: input.adornmentColor,
                            }
                        },
                    }
                }
            },
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        height: '100%',
                        margin: 0,
                        overflowX: "hidden",
                    },
                    body: {
                        minHeight: '100dvh',
                        margin: 0,
                        fontFamily: fonts.fontFamily,
                        fontSize: fonts.fontSizeBase,
                        color: colors.bodyColor,
                        background: colors.bodyBg,
                        overflowX: "hidden",
                    },
                    "strong, b": {
                        fontWeight: fonts.fontWeightSemiBold,
                    },
                    "#root": {
                        height: '100%',
                        whiteSpace: 'pre-line'
                    },
                    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: input.color,
                        boxShadow: `inset 0 0 20px 20px ${input.bg}`,
                        transition: 'background-color 5000s ease-in-out 0s',
                    }
                },
            },
        },
        baseVariables: variables
    }
};
