import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTrans } from "@core/Hook/trans";
import { Image } from "@core/Utils/image";
import Menus from "./Menus";
import Copyright from "../Copyright";
import TagSeo from "../TagSeo";
import ContactUsFooter from "../ContactUsFooter";

const FooterStyled = styled(Box)(({ theme }) => ({
    '&.x-footer': {
        background: theme.baseVariables.footer.bg,
        '.-footer-wrapper': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: theme.spacing(4, 'auto'),
            padding: theme.spacing(0, 4),
            [theme.breakpoints.up('md')]: {
                padding: 0
            }
        },
        '.-logo': {
            marginBottom: theme.spacing(2),
            width: 140,
            height: 'auto',
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(3),
                width: 160
            }
        },
        '.-description': {
            margin: theme.spacing(2, 0),
            maxWidth: 720,
            color: theme.baseVariables.footer.descriptionColor,
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            textAlign: 'center'
        }
    }
}))

const Footer: React.FC = () => {
    const { trans } = useTrans();
    const tags = [
        trans('footer_tags.boost_like'),
        trans('footer_tags.boost_like_page'),
        trans('footer_tags.boost_like_thai'),
        trans('footer_tags.boost_follower_1'),
        trans('footer_tags.boost_follower_2'),
        trans('footer_tags.boost_view'),
        trans('footer_tags.tiktok'),
    ];

    return (
        <FooterStyled className={'x-footer'}>
            <Container className={'-footer-wrapper'}>
                <Image
                    extraClass={'-logo'}
                    src={'wow-boost-logo.png'}
                    width={150}
                    height={100}
                />
                <Menus renderLocation={'footer'} />
                <Typography variant={'body1'} className={'-description'}>
                    {trans('footer.description')}
                </Typography>
                <TagSeo tags={tags} />
                <ContactUsFooter />
            </Container>
            <Copyright />
        </FooterStyled>
    )
}

export default Footer
