import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";
import {useTrans} from "@core/Hook/trans";
import HomepageSectionLayout from "./HomepageSectionLayout";
import Title from "../../Component/Title";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PlainButton from "../../Component/Button/PlainButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import FadeInUp from "../../Animate/FadeInUp";

const FAQStyled = styled(Container)(({theme}) => ({
    '&.x-homepage-faq-container': {
        '.-faq-wrapper': {
            padding: theme.spacing(3, 0),
            '.-card-wrapper': {
                padding: theme.spacing(0),
                '.-card-content-wrapper': {
                    padding: theme.spacing( 0),
                }
            },
            '.-question-wrapper': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: 1,
                paddingLeft: theme.spacing(1),
                [theme.breakpoints.up('md')]: {
                    paddingLeft: theme.spacing(2),
                },
                '.-question-inner-wrapper': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flex: 1,
                    '.-question-symbol': {
                        color: theme.baseVariables.faq.questionSymbolColor,
                        fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                        marginRight: theme.spacing(1.5),
                        fontSize: theme.baseVariables.fonts.fontSizeBig,
                        [theme.breakpoints.up('md')]: {
                            fontSize: theme.baseVariables.fonts.fontSizeBigger,
                            marginRight: theme.spacing(4),
                        },
                    },
                    '.-question-text': {
                        paddingRight: theme.spacing(2),
                        textAlign: 'left',
                        color: theme.baseVariables.faq.questionColor,
                        fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                        textTransform: 'capitalize',
                        [theme.breakpoints.up(390)]: {
                            fontSize: theme.baseVariables.fonts.fontSizeSmall,
                        },
                        [theme.breakpoints.up('sm')]: {
                            fontSize: theme.baseVariables.fonts.fontSizeNormal,
                        },
                    },
                },
                '.-question-icon': {
                    color: theme.baseVariables.faq.questionIconColor,
                    fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                    fontSize: theme.baseVariables.fonts.fontSizeBig,
                    [theme.breakpoints.up('md')]: {
                        fontSize: theme.baseVariables.fonts.fontSizeBigger,
                    },
                }
            },
            '.-answer-wrapper': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: theme.spacing(1.5),
                margin: theme.spacing(1.5),
                background: theme.baseVariables.faq.answerBg,
                borderRadius: theme.spacing(1),
                [theme.breakpoints.up('md')]: {
                    padding: theme.spacing(2),
                    margin: theme.spacing(2),
                },
                '.-answer-symbol': {
                    color: theme.baseVariables.faq.answerSymbolColor,
                    fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                    marginRight: theme.spacing(3),
                    fontSize: theme.baseVariables.fonts.fontSizeBig,
                    [theme.breakpoints.up('md')]: {
                        marginRight: theme.spacing(4),
                        fontSize: theme.baseVariables.fonts.fontSizeBigger,
                    },
                },
                '.-answer-text': {
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    [theme.breakpoints.up(390)]: {
                        fontSize: theme.baseVariables.fonts.fontSizeSmall,
                    },
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.baseVariables.fonts.fontSizeNormal,
                    },
                }
            }
        }
    }
}))

const HomepageFAQ: React.FC = () => {
    const {trans} = useTrans();
    const [collapsedQuestionTarget, setCollapsedQuestionTarget] = useState<string | undefined>();
    const contents = [
        {
            question: trans('homepage.section.faq.question.how_to_use'),
            answer: trans('homepage.section.faq.answer.how_to_use'),
        },
        {
            question: trans('homepage.section.faq.question.guarantee'),
            answer: trans('homepage.section.faq.answer.guarantee'),
        },
        {
            question: trans('homepage.section.faq.question.account_safety'),
            answer: trans('homepage.section.faq.answer.account_safety'),
        },
        {
            question: trans('homepage.section.faq.question.payment_method'),
            answer: trans('homepage.section.faq.answer.payment_method'),
        },
        {
            question: trans('homepage.section.faq.question.when_to_process'),
            answer: trans('homepage.section.faq.answer.when_to_process'),
        },
        {
            question: trans('homepage.section.faq.question.how_long_to_complete'),
            answer: trans('homepage.section.faq.answer.how_long_to_complete'),
        },
        {
            question: trans('homepage.section.faq.question.type_of_account_target'),
            answer: trans('homepage.section.faq.answer.type_of_account_target'),
        },
        {
            question: trans('homepage.section.faq.question.how_to_refund'),
            answer: trans('homepage.section.faq.answer.how_to_refund'),
        },
    ]

    const handleClickCollapse = (focusQuestionTarget: string) => {
        setCollapsedQuestionTarget(focusQuestionTarget);

        if (focusQuestionTarget === collapsedQuestionTarget) setCollapsedQuestionTarget(undefined);
    }

    const renderQuestion = (question: string, isCollapsed: boolean) => {
        return (
            <Box className={'-question-wrapper'}>
                <Box className={'-question-inner-wrapper'}>
                    <Typography variant={'h6'} className={'-question-symbol'}>{'Q'}</Typography>
                    <Typography variant={'body1'} className={'-question-text'}>{question}</Typography>
                </Box>
                <Typography variant={'h6'} className={'-question-icon'}>{isCollapsed ? '-' : '+'}</Typography>
            </Box>
        )
    }

    const renderAnswer = (answer: string) => {
        return (
            <Box className={'-answer-wrapper'}>
                <Typography variant={'h6'} className={'-answer-symbol'}>{'A'}</Typography>
                <Typography variant={'body1'} className={'-answer-text'}>{answer}</Typography>
            </Box>
        )
    }

    return (
        <HomepageSectionLayout sectionId={'homepageSectionFAQ'}>
            <FAQStyled className="x-homepage-faq-container">
                <FadeInUp>
                    <Title title={trans('homepage.section.faq.title')} subTitle={trans('homepage.section.faq.sub_title')} />
                </FadeInUp>
                <Grid container className={'-faq-wrapper'} spacing={2}>
                    {contents.map((item, index) => {
                        const questionTarget = `${item.question}-${index}`;
                        const isCollapsed = questionTarget === collapsedQuestionTarget;

                        return (
                            <Grid item xs={12} key={questionTarget} className={'-faq-item-wrapper'}>
                                <FadeInUp>
                                    <Card variant={'outlined'} key={`${item.question}-${index}`} className={'-card-wrapper'}>
                                        <CardContent className={'-card-content-wrapper'}>
                                            <PlainButton
                                                fullWidth
                                                onClick={() => handleClickCollapse(questionTarget)}
                                                labelElement={renderQuestion(item.question, isCollapsed)}
                                                extraClass={'-question'}
                                            />
                                            <Collapse in={isCollapsed} className={'-body-collapse'} timeout="auto" unmountOnExit>
                                                {renderAnswer(item.answer)}
                                            </Collapse>
                                        </CardContent>
                                    </Card>
                                </FadeInUp>
                            </Grid>
                        )
                    })}
                </Grid>
            </FAQStyled>
        </HomepageSectionLayout>
    )
}

export default HomepageFAQ
