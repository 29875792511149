import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTrans } from '@core/Hook/trans';
import DefaultModal, { IDefaultModal } from '../../Component/Modal/DefaultModal';
import { BeatLoader } from 'react-spinners';
import Typography from '@mui/material/Typography';

const PaymentProcessModalStyled = styled(Box)(({ theme }) => ({
    '&.x-payment-process-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        height: '180px',
        whiteSpace: 'break-spaces',
        '.-loader-wrapper': {
            margin: theme.spacing(2, 0),
        },
        '.-description-text': {
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
        },
    },
}));

const PaymentProcessModal: React.FC<IDefaultModal> = ({ open, handleClose, ...rest }) => {
    const { trans } = useTrans();

    if (!open) return <></>;

    return (
        <DefaultModal
            {...rest}
            isHalfSize
            open={open}
            title={trans('order_summary.payment_modal.title')}
            titleColor={'primary'}
            handleClose={handleClose}
        >
            <PaymentProcessModalStyled className={'x-payment-process-container'}>
                <Box className={'-loader-wrapper'}>
                    <BeatLoader color={'#5a80ee'} />
                </Box>
                <Typography variant={'body1'} className={'-description-text'}>
                    {trans('order_summary.payment_modal.description')}
                </Typography>
            </PaymentProcessModalStyled>
        </DefaultModal>
    );
};

export default PaymentProcessModal;
