import React, { ReactNode } from 'react';
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import PlainButton from "./Button/PlainButton";
import Typography from "@mui/material/Typography";
import { FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import Stack from "@mui/material/Stack";
import { useTrans } from "@core/Hook/trans";

type SSOButtonsProps = {
    key: string,
    color: 'ssoFacebook' | 'ssoGmail',
    title: string,
    icon: ReactNode,
    path: string;
}

const SSOButtonGroupStyled = styled(Box)(({ theme }) => ({
    '&.x-sso-button-group': {
        '.-separate-text': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: theme.spacing(2, 0),
            [theme.breakpoints.up("sm")]: {
                margin: theme.spacing(4, 0),
            },
            '.-text': {
                padding: theme.spacing(0, 1.5),
                color: '#a9a9a9'
            },
            '&::before, &::after': {
                content: '""',
                flex: 1,
                display: 'block',
                height: 1,
                background: '#c3c3c3'
            }
        }
    }
}))

const SSOButtonGroup: React.FC = () => {
    const { trans } = useTrans();
    const baseUrl = `${process.env['REACT_APP_API_BASE_URL']}/${process.env['REACT_APP_API_VERSION']}`

    const buttons = [
        // {
        //     key: 'facebook',
        //     color: 'ssoFacebook',
        //     title: trans('button.login_with_facebook'),
        //     icon: <FaFacebook size={24} className={'-ic'} />,
        //     path: `${baseUrl}/auth/facebookLogin`
        // },
        {
            key: 'gmail',
            color: 'ssoGmail',
            title: trans('button.login_with_gmail'),
            icon: <FcGoogle size={24} className={'-ic'} />,
            path: `${baseUrl}/auth/googleLogin`
        },
    ] as SSOButtonsProps[]

    return (
        <SSOButtonGroupStyled className={'x-sso-button-group'}>
            <Box className={'-separate-text'}>
                <Typography variant={"body2"} className={'-text'}>{'หรือ'}</Typography>
            </Box>
            <Stack spacing={1.25}>
                {buttons.map(item => (
                    <PlainButton
                        key={item.key}
                        color={item.color}
                        extraClass={'-btn -btn-sso'}
                        onClick={() => window.location.href=item.path}
                        labelElement={(
                            <>
                                {item.icon}
                                <Typography
                                    variant={"body2"}
                                    className={"-text"}
                                    textAlign={"left"}
                                >
                                    {item.title}
                                </Typography>
                            </>
                        )}
                    />
                ))}
            </Stack>
        </SSOButtonGroupStyled>
    );
};

export default SSOButtonGroup;
