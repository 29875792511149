import React from 'react';
import { useTrans } from '@core/Hook/trans';
import ConfirmModal from '../../Component/Modal/ConfirmModal';
import PlainButton from '../../Component/Button/PlainButton';
import { resolveClientApiState, useClientApiState } from '@core/Hook/api';
import { cancelOrderByPurchaseOrderRefId, cancelOrderCreditByPurchaseOrderRefId } from '../../Api/purchase';
import { ToastAlert } from '@core/Component/AlertFadeInRight';
import { useNavigate } from 'react-router-dom';
import { OrderTypeEnum } from '../../Constants/order';

interface ICancelOrderModal {
    purchaseOrderRefId: string;
    open: boolean;
    handleClose: () => void;
    orderType: string;
}

const CancelOrderModal: React.FC<ICancelOrderModal> = ({ purchaseOrderRefId, open, handleClose, orderType }) => {
    const { trans } = useTrans();
    const [apiState, setApiState] = useClientApiState({ isLoading: false });
    const navigate = useNavigate();

    const handleCancelOrder = async () => {
        setApiState({ ...apiState, isLoading: true });

        let res;
        if (orderType === OrderTypeEnum.CREDIT) {
            res = resolveClientApiState(await cancelOrderCreditByPurchaseOrderRefId({ purchaseOrderRefId: purchaseOrderRefId }));
        } else {
            res = resolveClientApiState(await cancelOrderByPurchaseOrderRefId({ purchaseOrderRefId: purchaseOrderRefId }));
        }

        if (res.isOk) {
            handleClose();

            if (orderType === OrderTypeEnum.CREDIT) {
                navigate('/account/credit/credit-history', {
                    state: {
                        alertMessage: trans('modal.cancel_order.success'),
                    },
                });
            } else {
                navigate('/account/order-history/cancel?noti=success', {
                    state: {
                        alertMessage: trans('modal.cancel_order.success'),
                    },
                });
            }

            return;
        }

        handleClose();
        ToastAlert('error', trans('modal.cancel_order.fail'));
        setApiState({ ...apiState, isLoading: false });
    };

    const confirmModalActions = () => {
        return (
            <>
                <PlainButton color={'secondary'} label={trans('general.close')} onClick={handleClose} disabled={apiState.isLoading} />
                <PlainButton
                    color={'primary'}
                    label={trans('order_detail.cancel_order_modal.confirm')}
                    onClick={() => handleCancelOrder()}
                    isLoading={apiState.isLoading}
                    disabled={apiState.isLoading}
                />
            </>
        );
    };

    return (
        <ConfirmModal
            showCloseIcon
            open={open}
            title={trans('modal.confirm.title')}
            subTitle={trans('order_detail.cancel_order_modal.sub_title')}
            handleClose={handleClose}
            actions={confirmModalActions()}
        />
    );
};

export default CancelOrderModal;
