import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import classNames from "classnames";

interface ITextWithLink {
    title?: string;
    linkTitle?: string;
    linkOnClick?(): void;
    extraClass?: string;
}

const TextWithLinkStyled = styled(Box)(({ theme }) => ({
    '&.x-text-with-link': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        '&.justify-end': {
            justifyContent: 'end',
        },
        '.-text': {
            fontSize: theme.baseVariables.fonts.fontSizeSmallLarge,
            color: '#4f4f4f',
            [theme.breakpoints.between('xxs', 425)]: {
                fontSize: theme.baseVariables.fonts.fontSizeSmaller,
            },
        },
        '.-link': {
            marginLeft: theme.spacing(.5),
            fontSize: theme.baseVariables.fonts.fontSizeSmallLarge,
            fontWeight: theme.baseVariables.fonts.fontWeightMedium,
            color: '#5a80ee',
            textDecoration: 'underline',
            [theme.breakpoints.between('xxs', 425)]: {
                fontSize: theme.baseVariables.fonts.fontSizeSmaller,
            },
            '&:hover': {
                opacity: .8
            }
        },
    },
}));

const TextWithLink: React.FC<ITextWithLink> = ({title, linkTitle, linkOnClick, extraClass}) => {
    const shouldRenderLink = (linkTitle && linkOnClick);
    const classes = classNames(
        'x-text-with-link',
        extraClass
    )

    return (
        <TextWithLinkStyled className={classes}>
            {title && <Typography className={'-text'}>{title}</Typography>}
            {shouldRenderLink && (
                <Button
                    variant={"text"}
                    onClick={linkOnClick}
                    className={'-link'}
                >
                    {linkTitle}
                </Button>
            )}
        </TextWithLinkStyled>
    )
}

export default TextWithLink
