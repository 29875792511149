import React from 'react';
import { FormControlProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';

const FormControlWrapper: React.FC<FormControlProps> = ({ children, ...otherProps }) => {
    return (
        <FormControl fullWidth sx={{ marginBottom: '.75rem' }} {...otherProps}>
            {children}
        </FormControl>
    );
};

export default FormControlWrapper;
