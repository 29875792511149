import ApiClient from '../Utils/api_client';
import { AxiosResponse } from 'axios';
import { IUploadSlipFormData } from '../Page/Payment/Modal/UploadSlipForm';

export async function getPurchaseReceipt(purchaseOrderReceiptRefId: string): Promise<AxiosResponse> {
    return ApiClient.get(
        `/purchase-order/purchaseReceipt/${purchaseOrderReceiptRefId}`,
        {},
        {
            responseType: 'blob',
        },
    );
}

export async function cancelOrderByPurchaseOrderRefId(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/purchase-order/cancelOrder', formData);
}

export async function uploadSlip(formData: IUploadSlipFormData): Promise<AxiosResponse> {
    return ApiClient.postForm('/purchase-order/uploadSlip', formData, {
        headers: {
            'Content-Type': `multipart/form-data`,
        },
    });
}

export async function getPurchaseReceiptCredit(purchaseOrderReceiptRefId: string): Promise<AxiosResponse> {
    return ApiClient.get(
        `/purchase-order-credit/purchaseReceipt/${purchaseOrderReceiptRefId}`,
        {},
        {
            responseType: 'blob',
        },
    );
}

export async function cancelOrderCreditByPurchaseOrderRefId(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/purchase-order-credit/cancelOrder', formData);
}

export async function uploadSlipCredit(formData: IUploadSlipFormData): Promise<AxiosResponse> {
    return ApiClient.postForm('/purchase-order-credit/uploadSlip', formData, {
        headers: {
            'Content-Type': `multipart/form-data`,
        },
    });
}
