import { atom } from 'recoil';

export interface IAlert {
    show: boolean;
    title: string;
    content: string;
    onOk?: () => void;
}

export const AlertAtom = atom({
    key: 'UiAlertAtom',
    default: {
        show: false,
        title: 'Alert!',
        content: '',
        onOk: () => {
            // do things on OK
        },
    } as IAlert,
});
