import React from 'react';
import LoadingFullPage from "../Component/Loading/LoadingFullPage";
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";

const SplashStyled = styled(Container)(({ theme }) => ({
    '&.x-splash-container': {
        height: '100dvh',
    }
}))

const Splash: React.FC = () => {
    return (
        <SplashStyled className="x-splash-container">
            <LoadingFullPage />
        </SplashStyled>
    )
}

export default Splash;
