import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import StateTitle from '../StateTitle';

interface IOrderState {
    state: string;
    isIndexPage?: boolean;
}

const OrderStateStyled = styled(Box)(({ theme }) => ({
    '&.x-order-state-container': {
        padding: theme.spacing(1),
        width: '100%',
        textAlign: 'center',
        borderRadius: 5,
        '&.-pending': {
            background: theme.baseVariables.stateTitle.stateWaitingBg,
        },
        '&.-process': {
            background: theme.baseVariables.stateTitle.stateProcessBg,
        },
        '&.-success': {
            background: theme.baseVariables.stateTitle.stateSuccessBg,
        },
        '&.-cancel': {
            background: theme.baseVariables.stateTitle.stateCancelBg,
        },
        '&.-customerStrictInfo': {
            background: theme.baseVariables.stateTitle.stateCustomerStrictInfoBg,
        },
    },
}));

const OrderState: React.FC<IOrderState> = ({ state, isIndexPage }) => {
    return (
        <OrderStateStyled className={`x-order-state-container -${state}`}>
            <StateTitle state={state} isIndexPage={isIndexPage} />
        </OrderStateStyled>
    );
};

export default OrderState;
