import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTrans } from '@core/Hook/trans';
import FormControlWrapper from '@core/Form/FormControlWrapper';
import FormSelect from '@core/Form/FormSelect';
import FormInput, { basicInputPropsFloat, basicInputPropsNumber, onInputAcceptOnlyNumber } from '@core/Form/FormInput';
import FadeInUp from '../../../Animate/FadeInUp';
import PlainButton from '../../../Component/Button/PlainButton';
import FileUpload from '../../../Component/FileUpload';
import Form from '@core/Form/Form';
import isUndefined from 'lodash/isUndefined';
import usePaymentHook from '../../../Hook/paymentHook';
import { resolveClientApiState, useClientApiState } from '@core/Hook/api';
import { uploadSlip, uploadSlipCredit } from '../../../Api/purchase';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { ToastAlert } from '@core/Component/AlertFadeInRight';
import { OrderTypeEnum } from '../../../Constants/order';

interface IUploadSlipForm {
    handleClose: () => void;
    orderType?: string;
}

interface IUploadSlipFormInput {
    amount: string | undefined;
    file: File | undefined;
    dateTime: moment.Moment;
    purchaseOrderRefId: string;
    hour: string;
    minute: string;
}

export interface IUploadSlipFormData {
    amount: string;
    file: File;
    dateTime: string;
    purchaseOrderRefId: string;
}

const UploadSlipFormStyled = styled(Stack)(({ theme }) => ({
    '&.x-upload-slip-form': {
        '.-form-wrapper': {
            margin: theme.spacing(2.5, 0, 1.5),
        },
        '.-form-control-wrapper': {
            width: '100%',
            '.-date-picker': {
                width: '100%',
            },
            '.-text-field': {
                '.MuiInputBase-input::placeholder': {
                    color: '#a1b8db',
                    opacity: 1,
                },
            },
        },
    },
}));

const UploadSlipForm: React.FC<IUploadSlipForm> = ({ handleClose, orderType }) => {
    const [file, setFile] = useState<File | null>(null);
    const [dateTime, setDateTime] = useState<moment.Moment>(moment());
    const [open, setOpen] = useState<boolean>(false);
    const { trans } = useTrans();
    const { orderPaymentPromptPay } = usePaymentHook();
    const [apiState, setApiState] = useClientApiState({
        isLoading: false,
    });
    const navigate = useNavigate();
    const hours = Array(24)
        .fill(0)
        .map((_item, index) => String(index).padStart(2, '0'));
    const minutes = Array(60)
        .fill(0)
        .map((_item, index) => String(index).padStart(2, '0'));
    const validationSchema = Yup.object().shape({
        amount: Yup.string().required(trans('payment.form.amount.not_blank')),
        purchaseOrderRefId: Yup.string().required(trans('payment.form.purchase_order_ref_id.not_blank')),
        hour: Yup.string().required(trans('payment.form.hour.not_blank')),
        minute: Yup.string().required(trans('payment.form.minute.not_blank')),
        file: Yup.mixed().required(trans('payment.form.file.not_blank')),
        dateTime: Yup.mixed().required(trans('payment.form.file.not_blank')),
    });
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(validationSchema),
    });

    const submitUploadSlip = async (data: any) => {
        const dateFormData = moment(data.dateTime.format('YYYY-MM-DD'));
        const timeFormData = moment(`${data.hour}:${data.minute}`, 'HH:mm');
        dateFormData.set({
            hour: timeFormData.get('hour'),
            minute: timeFormData.get('minute'),
            second: timeFormData.get('second'),
        });

        const formData: IUploadSlipFormData = {
            dateTime: dateFormData.format(),
            file: data.file,
            amount: data.amount,
            purchaseOrderRefId: data.purchaseOrderRefId,
        };

        setApiState({ ...apiState, isLoading: true });

        let uploadSlipRes;
        if (orderType === OrderTypeEnum.CREDIT) {
            uploadSlipRes = resolveClientApiState(await uploadSlipCredit(formData), true);
        } else {
            uploadSlipRes = resolveClientApiState(await uploadSlip(formData), true);
        }

        if (uploadSlipRes?.isOk) {
            setApiState({ ...apiState, isLoading: false });
            ToastAlert('success', trans('alert.success.upload_slip'));
            handleClose();

            setTimeout(() => {
                if (orderType === OrderTypeEnum.CREDIT) {
                    navigate('/account/credit/credit-history');
                } else {
                    navigate('/account/order-history/process');
                }
            }, 500);

            return;
        }

        setApiState({ ...apiState, isLoading: false });
    };

    const renderButtonAction = () => {
        return (
            <PlainButton
                type={'submit'}
                fullWidth
                color={'primary'}
                label={trans('payment.modal.button')}
                isLoading={apiState.isLoading}
                disabled={apiState.isLoading}
            />
        );
    };

    const inputProps = {
        hiddenLabel: true,
        name: 'amount',
        id: 'amount',
        className: '-form-control -text-field',
        register,
        placeholder: trans('payment.modal.amount'),
        errors: errors,
        type: 'text',
        inputProps: basicInputPropsFloat,
    };

    const formData: IUploadSlipFormInput = {
        dateTime: dateTime,
        amount: undefined,
        file: undefined,
        purchaseOrderRefId: orderPaymentPromptPay?.refId as string,
        hour: String(dateTime.format('HH')),
        minute: String(dateTime.format('mm')),
    };

    useEffect(() => {
        Object.keys(formData).map((key) => {
            // @ts-ignore
            setValue(key, formData[key]);
        });
    }, []);

    return (
        <UploadSlipFormStyled className={'x-upload-slip-form'}>
            <FadeInUp>
                <Form
                    encType="multipart/form-data"
                    register={register}
                    onSubmit={handleSubmit(submitUploadSlip)}
                    buttonComponent={renderButtonAction()}
                >
                    <Stack className={'-form-wrapper'}>
                        <FormControlWrapper className={'-form-control-wrapper'} required={true}>
                            <Controller
                                key={'file'}
                                name={'file'}
                                control={control}
                                // @ts-ignore
                                render={({ field: { onChange } }) => {
                                    return (
                                        <FileUpload
                                            maxFileSize={1000}
                                            onChange={(val) => {
                                                onChange(val);
                                            }}
                                            setFile={setFile}
                                            title={trans('payment.modal.btn_upload')}
                                            titleClear={trans('payment.modal.btn_clear')}
                                            textError={!isUndefined(errors.file) ? String(errors.file.message) : null}
                                        />
                                    );
                                }}
                            />

                            <Stack
                                direction={'column'}
                                spacing={1}
                                justifyContent={'center'}
                                alignItems={'center'}
                                className={'-form-control-wrapper'}
                            >
                                <Controller
                                    key={'dateTime'}
                                    name={'dateTime'}
                                    control={control}
                                    // @ts-ignore
                                    render={({ field: { onChange } }) => {
                                        return (
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    className={'-date-picker'}
                                                    disableFuture
                                                    value={dateTime}
                                                    defaultValue={dateTime}
                                                    format="DD/MM/YYYY"
                                                    maxDate={moment()}
                                                    open={open}
                                                    onClose={() => setOpen(false)}
                                                    onChange={(val) => {
                                                        onChange(val);
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            onClick: () => setOpen(true),
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        );
                                    }}
                                />
                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    className={'-form-control-wrapper'}
                                >
                                    <FormSelect
                                        required
                                        isHideLabel
                                        extraClass={'-select-form'}
                                        name={'hour'}
                                        placeholder={trans('payment.modal.hour')}
                                        control={control}
                                        options={hours.map((num: string) => ({ label: num, value: num }))}
                                    />
                                    <Typography className={'-colon'} component={'span'}>
                                        {':'}
                                    </Typography>
                                    <FormSelect
                                        required
                                        isHideLabel
                                        extraClass={'-select-form'}
                                        name={'minute'}
                                        placeholder={trans('payment.modal.minute')}
                                        control={control}
                                        options={minutes.map((num: string) => ({ label: num, value: num }))}
                                    />
                                </Stack>
                                <FormInput
                                    {...inputProps}
                                    // onInput={onInputAcceptOnlyNumber}
                                />
                            </Stack>
                        </FormControlWrapper>
                    </Stack>
                </Form>
            </FadeInUp>
        </UploadSlipFormStyled>
    );
};

export default UploadSlipForm;
