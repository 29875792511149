import React, { useEffect } from 'react';
import Layout from '../Component/Common/Layout';
import HomepageCover from './Homepage/HomepageCover';
import HomepageOurService from './Homepage/HomepageOurService';
import HomepageWhyChooseUs from './Homepage/HomepageWhyChooseUs';
import HomepageReview from './Homepage/HomepageReview';
import HomepageFAQ from './Homepage/HomepageFAQ';
import HomepageHowToUse from './Homepage/HomepageHowToUse';
import HomepageInviteToUseService from './Homepage/HomepageInviteToUseService';
import MetaSeo from '../Component/Common/MetaSeo';
import { useTrans } from '@core/Hook/trans';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import trim from 'lodash/trim';
import isUndefined from 'lodash/isUndefined';
import { resolveClientApiState, useClientApiState } from '@core/Hook/api';
import { loginByLoginToken } from '../Api/auth';
import { LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE } from '../Constants/common';
import client from '../Utils/api_client';
import { userInfo } from '../Api/user';
import { CustomerInfoAtom, ICustomerInfo } from '../Recoil/Common/atom';
import { pusherAuthenticate } from '@core/Utils/pusherAuthentication';
import { ToastAlert } from '@core/Component/AlertFadeInRight';
import { useManageLocalStorage } from '@core/Utils/localStorage';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ApiTokenSelector } from '../Recoil/Common/selector';
import { useCustomerContext } from '../Hook/auth';

const Homepage: React.FC = () => {
    const { trans } = useTrans();
    const location = useLocation();
    const qs = parse(trim(location.search, '?'));
    const [apiState, setApiState] = useClientApiState({
        isLoading: false,
    });
    const { setToLocalStorage } = useManageLocalStorage();
    const setCustomerInfo = useSetRecoilState(CustomerInfoAtom);
    const apiAccessToken = useRecoilValue(ApiTokenSelector);
    const { isLogged } = useCustomerContext();

    const login = async () => {
        setApiState({ ...apiState, isLoading: true });

        const loginRes = resolveClientApiState(await loginByLoginToken({ loginToken: qs.loginToken }), true);
        if (loginRes.isOk) {
            const apiAccessToken = loginRes.data.accessToken;
            setToLocalStorage(LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE, apiAccessToken);
            client._accessToken = apiAccessToken;

            if (!isUndefined(loginRes.data.accessToken)) {
                const customerInfoRes = await userInfo();

                if (200 === customerInfoRes.status) {
                    setCustomerInfo({
                        ...(customerInfoRes.data as ICustomerInfo),
                    });

                    pusherAuthenticate(customerInfoRes.data.phoneNumber, apiAccessToken);
                }
            }

            ToastAlert('success', trans('alert.success.login'));
        }

        clearHistoryState();
        setApiState({ ...apiState, isLoading: false });
    };

    const clearHistoryState = () => {
        window.history.pushState({ path: location.pathname }, '', location.pathname);
        window.history.replaceState(null, '');
    };

    const renderErrorToast = (serverErrorCode: number) => {
        ToastAlert('error', trans(`alert.error.server_error_code.${serverErrorCode}`));
        clearHistoryState();
    };

    useEffect(() => {
        if (!isUndefined(qs.serverErrorCode)) return renderErrorToast(parseInt(qs?.serverErrorCode as string));
        if (isUndefined(qs.loginToken) || isLogged) return;

        isUndefined(apiAccessToken) && login();
    }, []);

    return (
        <Layout>
            <MetaSeo title={trans('meta_seo.homepage.title')} description={trans('meta_seo.homepage.description')} />
            <HomepageCover />
            <HomepageOurService />
            <HomepageWhyChooseUs />
            <HomepageHowToUse />
            <HomepageReview />
            <HomepageFAQ />
            {/*<HomepageContactUs />*/}
            <HomepageInviteToUseService />
        </Layout>
    );
};

export default Homepage;
