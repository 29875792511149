import ApiClient from '../Utils/api_client';
import { AxiosResponse } from 'axios';

export async function getProducts(): Promise<AxiosResponse> {
    return ApiClient.get('/product/getAllPlatformAndService');
}

export async function getLastestSelectPurchaseOrder(): Promise<AxiosResponse> {
    return ApiClient.get('/purchase-order/getLastestSelectPurchaseOrder');
}
