import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface ITagSeo {
    tags?: string[]
}

const TagSeoStyled = styled(Box)(({ theme }) => ({
    '&.x-tag-seo': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: '100%',
        '.-tag-item': {
            margin: theme.spacing(1, .5, 0),
            padding: theme.spacing(.5, 2),
            background: theme.baseVariables.tagSeo.bg,
            borderRadius: 15,
            [theme.breakpoints.up('md')]: {
                margin: theme.spacing(1, .75, 0)
            },
            '.-tag': {
                color: theme.baseVariables.tagSeo.color,
                fontSize: theme.baseVariables.fonts.fontSizeSmaller
            }
        }
    }
}))

const TagSeo: React.FC<ITagSeo> = ({ tags }) => {
    return (
        <TagSeoStyled className={'x-tag-seo'}>
            {tags && tags.map((tag, index) => {
                return (
                    <Box key={`tag-item-${index}`} className={'-tag-item'}>
                        <Typography variant={'h6'} className={'-tag'}>{tag}</Typography>
                    </Box>
                )
            })}
        </TagSeoStyled>
    )
}

export default TagSeo
