import React, { ReactNode, useState } from "react";
import isUndefined from "lodash/isUndefined";
import { useTrans } from "@core/Hook/trans";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

interface ICopyToClipboard {
    copyMessage: string | number;
    label?: string | ReactNode;
    labelCopied?: string | ReactNode;
    extraClass?: string;
    textSize?: 'normal' | 'small' | 'smaller';
}

const CopyToClipboardStyled = styled(Button)(({theme}) => ({
    '&.x-copy-to-clipboard': {
        padding: 0,
        minWidth: 'unset',
        minHeight: 'unset',
        fontSize: '1em',
        "&.-normal": {
            '.MuiTypography-root': {
                fontSize: theme.baseVariables.fonts.fontSizeNormal,
            },
        },
        "&.-small": {
            '.MuiTypography-root': {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
            },
        },
        "&.-smaller": {
            '.MuiTypography-root': {
                fontSize: theme.baseVariables.fonts.fontSizeSmaller,
            },
        },
    }
}));

const CopyToClipboard: React.FC<ICopyToClipboard> = ({
    copyMessage,
    label,
    labelCopied,
    extraClass= '',
    textSize
}) => {
    const { trans } = useTrans();
    const labelResolved = label ? label : trans('copy.label');
    const labelCopiedResolved = labelCopied ? labelCopied : trans('copy.labelCopied');

    const [isCopying, setIsCopying] = useState(false);
    const [currentLabel, setCurrentLabel] = useState(labelResolved);
    const textSizeClass = isUndefined(textSize) ? 'smaller' : textSize;

    const setCopy = async (text: string | number) => {
        if (isCopying) {
            return;
        }

        const copyTextResolved = text.toString();

        try {
            await navigator.clipboard.writeText(copyTextResolved);

            setIsCopying(true);
            setCurrentLabel(labelCopiedResolved);

            setTimeout(() => {
                setIsCopying(false);
                setCurrentLabel(labelResolved);
            }, 2500)
        } catch (e) {
            console.log('Copy to clipboard failed cause of HTTPS Secured Context');
        }
    };

    return (
        <CopyToClipboardStyled className={`x-copy-to-clipboard ${extraClass} -${textSizeClass}`} onClick={(e) => {
            e.stopPropagation();
            setCopy(copyMessage)
        }}>
            <Typography component={'span'}>{currentLabel}</Typography>
        </CopyToClipboardStyled>
    )
}

export default CopyToClipboard;
