import React, {Fragment, ReactNode, useEffect} from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import PlainButton from "../../Button/PlainButton";
import { useTrans } from "@core/Hook/trans";
import { useForm } from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Form from "@core/Form/Form";
import * as Yup from "yup";
import FormInput from "@core/Form/FormInput";
import { ObjectSchema } from "yup";
import isUndefined from "lodash/isUndefined";

interface IInputPasscodeForm {
    onSubmit: (data: IInputPassCodeData) => void;
    isLoading?: boolean;
    payload: string;
    inputName: string;
    children?: ReactNode;
    extraFormData?: object;
    isHiddenForm?: boolean;
    objectSchema?: ObjectSchema<any>;
    labelSubmit?: string;
}

export interface IInputPassCodeData {
    [key: string]: string;
}

const InputPasscodeFormStyled = styled(Stack)(() => ({
    '&.x-input-passcode-form': {
        '.-form-control.-is-hide': {
            '.MuiInputBase-root': {
                display: 'none',
            },
        },
    }
}))

const InputPasscodeForm: React.FC<IInputPasscodeForm> = ({
    children,
    onSubmit,
    payload,
    inputName,
    objectSchema,
    labelSubmit,
    extraFormData,
    isLoading = false,
    isHiddenForm = true
}) => {
    const { trans } = useTrans();
    const validationSchemaDefault = Yup.object().shape({
        [inputName]: Yup.string().required(trans('modal.login_password.form.not_blank')).min(6, trans('modal.login_password.form.min', {min: 6})),
    });
    const validationSchema = isUndefined(objectSchema) ? validationSchemaDefault : objectSchema;
    const hiddenClass = isHiddenForm ? '-is-hide': '';

    const { register, setValue, handleSubmit, formState: { errors } } = useForm<IInputPassCodeData>({
        // @ts-ignore
        resolver: yupResolver(validationSchema)
    });

    const submitInputPasscode = (data: IInputPassCodeData) => onSubmit(data)

    const renderButtonAction = () => {
        const _labelSubmit = !isUndefined(labelSubmit) ? labelSubmit : trans('general.confirm');

        return (
            <PlainButton
                type={'submit'}
                fullWidth
                color={'primary'}
                label={_labelSubmit}
                isLoading={isLoading}
                disabled={isLoading}
            />
        )
    }

    const renderOtpInput = () => {
        const inputProps = {
            hiddenLabel: true,
            name: inputName,
            id: inputName,
            className: `-form-control ${hiddenClass}`,
            register,
            type: 'hidden',
            value: payload,
            errors
        }

        return (
            <FormInput {...inputProps} />
        )
    }

    let initFormData = {
        [inputName]: payload,
    }

    if (!isUndefined(extraFormData)) {
        initFormData = {
            ...initFormData,
            ...extraFormData
        }
    }

    useEffect(() => {
        Object.keys(initFormData).map((key) => {
            // @ts-ignore
            setValue(key, initFormData[key]);
        });
    }, [payload]);

    return (
        <InputPasscodeFormStyled className={'x-input-passcode-form'}>
            <Form register={register} onSubmit={handleSubmit(submitInputPasscode)} buttonComponent={renderButtonAction()}>
                <Fragment>
                    {children}
                    {renderOtpInput()}
                </Fragment>
            </Form>
        </InputPasscodeFormStyled>
    )
}

export default InputPasscodeForm
