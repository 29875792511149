import {
    ORDER_STATUS_CANCEL,
    ORDER_STATUS_PENDING,
    ORDER_STATUS_PROGRESS,
    ORDER_STATUS_SUCCESS
} from "../Constants/order";
import split from "lodash/split";
import { padEnd } from "lodash";

export const getOrderStateLabelByCode = (code: number) => {
    switch (code) {
        case 0:
            return ORDER_STATUS_PENDING;
        case 1:
            return ORDER_STATUS_PROGRESS;
        case 2:
            return ORDER_STATUS_SUCCESS;
        case 3:
            return ORDER_STATUS_CANCEL;
        default:
            return 'unknown'
    }
}

export const isOrderPendingStatus = (orderStatus: number) => getOrderStateLabelByCode(orderStatus) === ORDER_STATUS_PENDING
export const isOrderProcessStatus = (orderStatus: number) => getOrderStateLabelByCode(orderStatus) === ORDER_STATUS_PROGRESS

export const formatBytes = (bytes: number, size?: string, decimals = 2,) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const hasDecimal = (number: number) => {
    return number - Math.floor(number) !== 0
}

export const getDecimalNumber = (number: number, separator = '.') => {
    const decimal = split(number.toString(), separator)[1] || undefined

    if (!decimal) return

    return padEnd(decimal, 2, '0')
}
