import { atom } from 'recoil';

export const InitializedAtom = atom({
    key: 'initialized',
    default: false,
});

export interface ICustomerInfo {
    displayName: string;
    phoneNumber: string;
    creditValue: number;
    accountGroupType: number;
}

export interface ISiteInfo {
    totalCustomer: number;
    totalSold: number;
}

export interface IPersonalInvoiceInfo {
    infoName: string;
    isCorperate: boolean;
}

export interface ICorporateInvoiceInfo {
    infoName: string;
    address: string;
    email: string;
    phoneNumber: string;
    isCorperate: boolean;
    taxpayerId: string;
}

export interface ICustomerInvoiceInfo {
    isCorporate: boolean;
    personalInfo: IPersonalInvoiceInfo;
    corporateInfo: ICorporateInvoiceInfo;
}

export const CustomerInvoiceInfoAtom = atom({
    key: 'customer_invoice_info',
    default: undefined as undefined | ICustomerInvoiceInfo,
});

export const CustomerInfoAtom = atom({
    key: 'customer_info',
    default: undefined as undefined | ICustomerInfo,
});

export const SiteInfoAtom = atom({
    key: 'site_info',
    default: undefined as undefined | ISiteInfo,
});
