import React from "react";
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";
import {useTrans} from "@core/Hook/trans";
import {useResponsive} from "@core/Hook/responsiveHook";
import HomepageSectionLayout from "./HomepageSectionLayout";
import Title from "../../Component/Title";
import {Asset, Image} from "@core/Utils/image";
import {Card, CardContent, CardMedia, Grid} from "@mui/material";
import FadeInUp from "../../Animate/FadeInUp";

const WhyChooseUsStyled = styled(Container)(({theme}) => ({
    '&.x-homepage-why-choose-us-container': {
        '.-why-choose-us-wrapper': {
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                justifyContent: 'center',
                marginTop: theme.spacing(3),
            },
            '.-why-choose-us-item-wrapper': {
                display: 'grid'
            },
            '.-card-wrapper': {
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.up('sm')]: {
                    justifyContent: 'space-between',
                    flexDirection: 'row-reverse',
                    padding: theme.spacing(0, 2.5),
                },
                '.-card-media-wrapper': {
                    maxWidth: 116,
                    [theme.breakpoints.up('sm')]: {
                        width: '130px',
                    },
                }
            },
        }
    }
}))

const HomepageWhyChooseUs: React.FC = () => {
    const {trans} = useTrans();
    const {isTabletOrMobile} = useResponsive();
    const bg = isTabletOrMobile ? Asset('wow-boost-homepage-why-choose-us-bg-mobile.png') : Asset('wow-boost-homepage-why-choose-us-bg.png');
    const contents = [
        {
            title: trans('homepage.section.why_choose_us.reason_1.title'),
            description: trans('homepage.section.why_choose_us.reason_1.description'),
            banner: 'wow-boost-homepage-why-choose-us-banner-1.png',
        },
        {
            title: trans('homepage.section.why_choose_us.reason_2.title'),
            description: trans('homepage.section.why_choose_us.reason_2.description'),
            banner: 'wow-boost-homepage-why-choose-us-banner-2.png',
        },
        {
            title: trans('homepage.section.why_choose_us.reason_3.title'),
            description: trans('homepage.section.why_choose_us.reason_3.description'),
            banner: 'wow-boost-homepage-why-choose-us-banner-3.png',
        },
        {
            title: trans('homepage.section.why_choose_us.reason_4.title'),
            description: trans('homepage.section.why_choose_us.reason_4.description'),
            banner: 'wow-boost-homepage-why-choose-us-banner-4.png',
        },
    ]

    return (
        <HomepageSectionLayout sectionId={'homepageSectionWhyChooseUs'} imageBg={bg}>
            <WhyChooseUsStyled className="x-homepage-why-choose-us-container">
                <FadeInUp>
                    <Title title={trans('homepage.section.why_choose_us.title')} subTitle={trans('homepage.section.why_choose_us.sub_title')} />
                </FadeInUp>
                <Grid container className={'-why-choose-us-wrapper'} spacing={2}>
                    {contents.map((item, index) => {
                        return (
                            <Grid item xs={12} sm={5} key={`${index}-${item.title}`} className={'-why-choose-us-item-wrapper'}>
                                <FadeInUp>
                                    <Card variant="outlined" className={'-card-wrapper'}>
                                        <CardMedia className={'-card-media-wrapper'}>
                                            <Image
                                                extraClass={'-why-choose-us-banner-img'}
                                                src={item.banner}
                                                width={746}
                                                height={424}
                                            />
                                        </CardMedia>
                                        <CardContent>
                                            <Title
                                                title={item.title}
                                                subTitle={item.description}
                                                variantTitle={'h3'}
                                                titlePosition={'left'}
                                                subTitlePosition={'left'}
                                            />
                                        </CardContent>
                                    </Card>
                                </FadeInUp>
                            </Grid>
                        )
                    })}
                </Grid>
            </WhyChooseUsStyled>
        </HomepageSectionLayout>
    )
}

export default HomepageWhyChooseUs
