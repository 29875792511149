import React from 'react';
import { styled } from '@mui/material/styles';
import HistoryIcon from '@mui/icons-material/History';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSetRecoilState } from 'recoil';
import { CustomerInfoAtom } from '../../../Recoil/Common/atom';
import { useNavigate } from 'react-router-dom';
import { useTrans } from '@core/Hook/trans';
import { useHandleDialog } from '../../../Hook/modalDialogHook';
import ConfirmModal from '../../Modal/ConfirmModal';
import PlainButton from '../../Button/PlainButton';
import { logout } from '../../../Api/auth';
import { useManageLocalStorage } from '@core/Utils/localStorage';
import { LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE } from '../../../Constants/common';
import { ToastAlert } from '@core/Component/AlertFadeInRight';
import { useFetchProducts } from '../../../Recoil/Product/selector';
import { useClientApiState } from '@core/Hook/api';
import client from '../../../Utils/api_client';
import { title } from 'process';

interface ILoggedMenu {
    renderLocation?: 'drawer' | 'dropdown';
    onClose(): void;
}

const LoggedMenuStyled = styled(Box)(({ theme }) => ({
    '&.x-btn-logged-menu': {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: "center",
        '&.-drawer': {
            width: '80%',
            margin: '0 auto',
            '.-btn': {
                justifyContent: 'flex-start',
                width: '100%',
                marginTop: theme.spacing(1.5),
                padding: theme.spacing(1, 2),
                background: theme.baseVariables.loggedMenu.drawerBtnBg,
                border: theme.baseVariables.loggedMenu.drawerBtnBorder,
                borderRadius: 6,
                '.-icon': {
                    color: theme.baseVariables.loggedMenu.drawerBtnIconColor,
                    fontSize: theme.baseVariables.fonts.fontSizeBig,
                    marginRight: theme.spacing(1),
                },
                '.-text': {
                    color: theme.baseVariables.loggedMenu.drawerBtnTextColor,
                    fontSize: theme.baseVariables.fonts.fontSizeSmaller,
                    fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                },
            },
        },
        '&.-dropdown': {
            '.-btn': {
                justifyContent: 'flex-start',
                padding: theme.spacing(1, 1.5),
                minWidth: 160,
                background: theme.baseVariables.loggedMenu.dropdownBtnBg,
                transition: 'background .2s ease',
                '&:active, &:hover': {
                    background: theme.baseVariables.loggedMenu.dropdownBtnActiveBg,
                },
                '.-icon': {
                    color: theme.baseVariables.loggedMenu.dropdownIconColor,
                    fontSize: theme.baseVariables.fonts.fontSizeBig,
                    marginRight: theme.spacing(1),
                },
                '.-text': {
                    color: theme.baseVariables.loggedMenu.dropdownTextColor,
                    fontSize: theme.baseVariables.fonts.fontSizeSmall,
                    fontWeight: theme.baseVariables.fonts.fontWeightMedium,
                },
            },
        },
    },
}));

const LoggedMenu: React.FC<ILoggedMenu> = ({ renderLocation = 'drawer', onClose }) => {
    const navigate = useNavigate();
    const { trans } = useTrans();
    const { open, handleClickOpen, handleClose } = useHandleDialog();
    const setCustomerInfo = useSetRecoilState(CustomerInfoAtom);
    const [apiState, setApiState] = useClientApiState({ isLoading: false });
    const { removeItemLocalStorage } = useManageLocalStorage();
    const fetchProducts = useFetchProducts();

    const menus = [
        {
            title: trans('menu_logged.credit'),
            icon: <MonetizationOnIcon className={'-icon'} />,
            onClick: () => navigate('/account/credit/amount'),
        },
        {
            title: trans('menu_logged.credit_history'),
            icon: <HistoryIcon className={'-icon'} />,
            onClick: () => navigate('/account/credit/credit-history'),
        },
        {
            title: trans('menu_logged.history'),
            icon: <HistoryIcon className={'-icon'} />,
            onClick: () => navigate('/account/order-history/pending'),
        },
        {
            title: trans('menu_logged.logout'),
            icon: <LogoutIcon className={'-icon'} />,
            onClick: () => handleClickOpen(),
        },
    ];

    const handleLogout = async () => {
        const logoutRes = await logout();

        if (200 === logoutRes.status) {
            ToastAlert('success', trans('alert.success.logout'));
        }

        client._accessToken = undefined;
        removeItemLocalStorage(LOCAL_STORAGE_CUSTOMER_ACCESS_TOKEN_CODE);
        setCustomerInfo(undefined);

        fetchProducts(apiState, setApiState);
    };

    const readerLogoutConfirmModalActions = () => {
        return (
            <>
                <PlainButton
                    color={'secondary'}
                    label={trans('general.cancel')}
                    onClick={() => {
                        handleClose();
                        onClose();
                    }}
                />
                <PlainButton color={'primary'} label={trans('general.confirm')} onClick={() => handleLogout()} />
            </>
        );
    };

    return (
        <LoggedMenuStyled className={`x-btn-logged-menu -${renderLocation}`}>
            {menus.map((menu, index) => {
                return (
                    <Button key={`btn-menu-logged-${index}-${menu}`} className={'-btn'} onClick={menu.onClick}>
                        {menu.icon}
                        <Typography className={'-text'}>{menu.title}</Typography>
                    </Button>
                );
            })}

            <ConfirmModal
                showCloseIcon
                open={open}
                title={trans('modal.logout.title')}
                subTitle={trans('modal.logout.sub_title')}
                handleClose={() => {
                    handleClose();
                    onClose();
                }}
                actions={readerLogoutConfirmModalActions()}
            />
        </LoggedMenuStyled>
    );
};

export default LoggedMenu;
