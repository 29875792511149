import Pusher from "pusher-js";
import {ChannelWrapper, wrappedChannelFactory} from "./pusher";

declare global {
    interface Window {
        ws_channel: ChannelWrapper;
    }
}

let initialized = false;
export const pusherAuthenticate = (username: string, apiAccessToken: string | undefined) => {
    if (initialized) return;

    const headers = {};

    if (apiAccessToken) {
        headers['Authorization'] = 'Bearer ' + apiAccessToken;
        headers['api-key'] = process.env['REACT_APP_API_KEY'];
    }

    const pusher = new Pusher(process.env['REACT_APP_PUSHER_AUTH_KEY'] as string, {
        cluster: 'ap1',
        // @ts-ignore
        channelAuthorization: {
            endpoint: `${process.env['REACT_APP_API_BASE_URL']}/${process.env['REACT_APP_API_VERSION']}/pusher/auth`,
            headers: headers,
        }
    });

    initialized = true;

    window['ws_channel'] = wrappedChannelFactory(pusher, pusher.subscribe(`private-wb-${username}`));
}
