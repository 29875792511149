import React from "react";
import ServiceProductIcon, {IServiceProductIcon} from "./ServiceIcon";
import {Image} from "@core/Utils/image";

const BoostEmojiAngrySetIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const icon = isActive ? 'emoji-angry-set-active.png' : 'emoji-angry-set.png';

    return (
        <ServiceProductIcon {...props}>
            <Image src={icon} width={265} height={65} extraClass={'-img'} />
        </ServiceProductIcon>
    )
}

export default BoostEmojiAngrySetIcon
