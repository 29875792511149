import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import PlainButton from "../Button/PlainButton";
import classNames from "classnames";

type DefaultModalTabHeaderButtonProps = {
    key: string
    title: string
    onClick(): void
}

interface IDefaultModalTabHeader {
    activeKey: string
    buttons: DefaultModalTabHeaderButtonProps[]
}

const DefaultModalTabHeaderStyled = styled(Box)(({ theme }) => ({
    '&.x-modal-tab-header-container': {
        borderTopRightRadius: '1rem',
        borderTopLeftRadius: '1rem',
        overflow: 'hidden',
        '.-btn.-primary': {
            padding: theme.spacing(1.5),
            fontSize: theme.baseVariables.fonts.fontSizeSmallLarge,
            color: theme.palette.primary.main,
            background: theme.baseVariables.modal.borderColor,
            borderRadius: 0,
            textTransform: 'none',
            transition: 'all .2s',
            [theme.breakpoints.up('lg')]: {
                fontSize: theme.baseVariables.fonts.fontSizeNormal,
            },
            '&:hover': {
                background: theme.baseVariables.modal.borderColor,
                filter: 'brightness(.95)',
            },
            '&.-active': {
                color: theme.palette.primary.main,
                background: 'transparent',
            }
        }
    },
}));

const DefaultModalTabHeader: React.FC<IDefaultModalTabHeader> = (props) => {
    const { activeKey, buttons } = props

    return (
        <DefaultModalTabHeaderStyled className={'x-modal-tab-header-container'}>
            <Grid container>
                {buttons.map(item => {
                    return (
                        <Grid key={item.key} item xxs={6}>
                            <PlainButton
                                fullWidth
                                label={item.title}
                                onClick={item.onClick}
                                extraClass={classNames(
                                    '-btn',
                                    item.key === activeKey && '-active'
                                )}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </DefaultModalTabHeaderStyled>
    )
}

export default DefaultModalTabHeader
