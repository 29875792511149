import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Variant} from "@mui/material/styles/createTypography";
import isUndefined from "lodash/isUndefined";

interface ITitle {
    title?: string;
    titleElement?: React.ReactElement;
    subTitle?: string;
    extraClass?: string;
    titlePosition?: 'center' | 'left';
    subTitlePosition?: 'center' | 'left' | 'justify';
    variantTitle?: Variant;
    variantSubTitle?: Variant;
}

const TitleStyled = styled(Box)(({ theme }) => ({
    '&.x-title': {
        '&.-facebook-text': {
            '.-title': {
                color: theme.baseVariables.title.facebookColor,
            },
        },
        '&.-tiktok-text': {
            '.-title': {
                color: theme.baseVariables.title.tiktokColor,
            },
        },
        '&.-in-policy': {
            '.-title': {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                [theme.breakpoints.up('sm')]: {
                    fontSize: theme.baseVariables.fonts.fontSizeNormal,
                },
            },
        },
        '.-title': {
            color: theme.baseVariables.title.color,
            fontSize: theme.baseVariables.fonts.fontSizeNormal,
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            marginBottom: theme.spacing(1.5),
            [theme.breakpoints.between('xxs', 380)]: {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.baseVariables.fonts.fontSizeBig,
            },
        },
        '.-sub-title': {
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.baseVariables.fonts.fontSizeNormal,
            },
        },
        'h3': {
            '&.-title': {
                marginBottom: theme.spacing(.75),
            },
        }
    }
}))

const Title: React.FC<ITitle> = ({
    title,
    titleElement,
    subTitle,
    variantTitle = 'h2',
    variantSubTitle = 'body1',
    titlePosition = "center",
    subTitlePosition = "center",
    extraClass
}) => {
    return (
        <TitleStyled className={`x-title ${!isUndefined(extraClass) ? extraClass : ''}`}>
            {isUndefined(titleElement)
                ? title && <Typography align={titlePosition} variant={variantTitle} className={'-title'}>{title}</Typography>
                : titleElement
            }
            {subTitle && <Typography align={subTitlePosition} variant={variantSubTitle} className={'-sub-title'}>{subTitle}</Typography>}
        </TitleStyled>
    )
}

export default Title
