import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Asset, Image } from "@core/Utils/image";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTrans } from "@core/Hook/trans";
import {Card, CardContent, Skeleton} from "@mui/material";
import {useResponsive} from "@core/Hook/responsiveHook";
import FadeInUp from "../../Animate/FadeInUp";
import CountingUp from "../../Component/CountingUp";
import {useRecoilValue} from "recoil";
import {SiteInfoAtom} from "../../Recoil/Common/atom";
import isUndefined from "lodash/isUndefined";

const CoverStyled = styled(Box)(({theme}) => ({
    '&.x-homepage-cover-container': {
        backgroundSize: 'cover',
        backgroundPosition: '100% 100%',
        backgroundRepeat: 'no-repeat',
        padding: theme.spacing(8, 0, 3),
        [theme.breakpoints.up('md')]: {
            backgroundPosition: 'center',
        },
        '.-cover-container-wrapper': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column-reverse',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
            },
            '.-cover-column-left': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column-reverse',
                [theme.breakpoints.up('md')]: {
                    width: '55%',
                    flexDirection: 'column',
                },
                '.-cover-content-wrapper': {
                    textAlign: 'center',
                    [theme.breakpoints.up('md')]: {
                        textAlign: 'left',
                    },
                    '.-cover-title': {
                        color: theme.baseVariables.cover.titleColor,
                        fontSize: theme.baseVariables.fonts.fontSizeBig,
                        fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                        marginBottom: theme.spacing(1),
                        [theme.breakpoints.up('sm')]: {
                            fontSize: theme.baseVariables.fonts.fontSizeBiggest,
                        },
                    },
                    '.-cover-sub-title': {
                        fontSize: theme.baseVariables.fonts.fontSizeSmall,
                        [theme.breakpoints.up('sm')]: {
                            fontSize: theme.baseVariables.fonts.fontSizeNormal,
                        },
                    },
                },
                '.-cover-card-info': {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    margin: theme.spacing(2, 0),
                    [theme.breakpoints.up('sm')]: {
                        justifyContent: 'center',
                        marginTop: theme.spacing(2),
                    },
                    [theme.breakpoints.up('md')]: {
                        justifyContent: 'flex-start',
                    },
                    '.-card-wrapper': {
                        width: '50%',
                        [theme.breakpoints.up('sm')]: {
                            width: '240px',
                        },
                        '&:first-of-type': {
                            marginRight: theme.spacing(2),
                        },
                        '.-card-content': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            padding: theme.spacing(2),
                            [theme.breakpoints.up('sm')]: {
                                padding: theme.spacing(3),
                            },
                            '.-loading-skeleton': {
                                marginBottom: theme.spacing(.5),
                            },
                            '.-icon-wrapper': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minWidth: '50px',
                                height: '50px',
                                borderRadius: theme.spacing(1),
                                background: theme.baseVariables.cover.iconBg,
                                color: theme.baseVariables.cover.iconColor,
                                marginRight: theme.spacing(2.5),
                                [theme.breakpoints.between('xxs', 380)]: {
                                    marginRight: theme.spacing(2),
                                },
                                [theme.breakpoints.up('sm')]: {
                                    marginRight: theme.spacing(4),
                                },
                                '.fa-user, .fa-cart-shopping': {
                                    fontSize: theme.baseVariables.fonts.fontSizeBig
                                }
                            },
                            '.-number-text': {
                                fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                                fontSize: theme.baseVariables.fonts.fontSizeBig,
                                color: theme.baseVariables.cover.numberColor,
                                [theme.breakpoints.between('xxs', 380)]: {
                                    fontSize: theme.baseVariables.fonts.fontSizeNormal,
                                },
                            }
                        }
                    }
                }
            },
            '.-cover-column-right': {
                width: '100%',
                textAlign: 'center',
                [theme.breakpoints.up('md')]: {
                    width: '45%'
                },
                '.-img-banner': {
                    width: '90%',
                    margin: 'auto',
                    [theme.breakpoints.up('sm')]: {
                        width: '350px'
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '500px'
                    },
                }
            }
        }
    }
}))

const HomepageCover: React.FC = () => {
    const { trans } = useTrans();
    const { isTabletOrMobile } = useResponsive();
    const siteInfo = useRecoilValue(SiteInfoAtom);
    const coverBg = isTabletOrMobile ? Asset('wow-boost-homepage-cover-bg-mobile.png') : Asset('wow-boost-homepage-cover-bg.png');

    return (
        <CoverStyled className="x-homepage-cover-container" style={{backgroundImage: `url(${coverBg})`}}>
            <Container className={'-cover-container-wrapper'}>
                <Box className={'-cover-column-left'}>
                    <FadeInUp>
                        <Box className={'-cover-content-wrapper'}>
                            <Typography variant={'h1'} className={'-cover-title'}>
                                {trans('homepage.cover.title')}
                            </Typography>
                            <Typography variant={'subtitle1'} variantMapping={{'subtitle1': 'p'}} className={'-cover-sub-title'}>
                                {trans('homepage.cover.sub_title')}
                            </Typography>
                        </Box>
                    </FadeInUp>
                    <FadeInUp>
                        <Box className={'-cover-card-info'}>
                            <Card variant="outlined" className={'-card-wrapper'}>
                                <CardContent className={'-card-content'}>
                                    <Box className={'-icon-wrapper'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 70 71" fill="none">
                                            <rect y="0.5" width="70" height="70" rx="10" fill="#E2EFFF"/>
                                            <path d="M35.1696 35.2153C37.8873 35.2153 40.2406 34.2406 42.1634 32.3175C44.0862 30.3947 45.061 28.042 45.061 25.324C45.061 22.607 44.0862 20.254 42.1631 18.3306C40.24 16.4081 37.887 15.4333 35.1696 15.4333C32.4516 15.4333 30.099 16.4081 28.1762 18.3309C26.2534 20.2537 25.2783 22.6066 25.2783 25.324C25.2783 28.042 26.2534 30.395 28.1765 32.3178C30.0996 34.2403 32.4526 35.2153 35.1696 35.2153Z" fill="#9FC7FF"/>
                                            <path d="M52.477 47.0115C52.4215 46.2113 52.3093 45.3384 52.1442 44.4166C51.9775 43.488 51.7629 42.6101 51.506 41.8077C51.2406 40.9783 50.8797 40.1593 50.4335 39.3745C49.9704 38.5599 49.4265 37.8505 48.8162 37.2668C48.178 36.6562 47.3966 36.1652 46.493 35.8071C45.5925 35.4509 44.5946 35.2704 43.5271 35.2704C43.1079 35.2704 42.7025 35.4424 41.9195 35.9522C41.4377 36.2664 40.874 36.6299 40.2449 37.0318C39.7069 37.3746 38.9781 37.6957 38.078 37.9865C37.1998 38.2707 36.3081 38.4148 35.428 38.4148C34.5479 38.4148 33.6565 38.2707 32.7773 37.9865C31.8781 37.6961 31.1494 37.3749 30.612 37.0322C29.9888 36.6339 29.4249 36.2705 28.9358 35.9518C28.1538 35.4421 27.748 35.2701 27.3288 35.2701C26.261 35.2701 25.2634 35.4509 24.3633 35.8074C23.4603 36.1649 22.6786 36.6559 22.0398 37.2671C21.4297 37.8512 20.8855 38.5602 20.4231 39.3745C19.9772 40.1593 19.6163 40.978 19.3506 41.808C19.094 42.6104 18.8794 43.488 18.7127 44.4166C18.5476 45.3371 18.4354 46.2103 18.3799 47.0124C18.3254 47.7982 18.2979 48.6138 18.2979 49.4375C18.2979 51.5811 18.9793 53.3166 20.3231 54.5965C21.6503 55.8594 23.4064 56.5001 25.542 56.5001H45.3159C47.4514 56.5001 49.2069 55.8597 50.5344 54.5965C51.8785 53.3175 52.56 51.5818 52.56 49.4371C52.5597 48.6097 52.5318 47.7935 52.477 47.0115Z" fill="#9FC7FF"/>
                                        </svg>
                                    </Box>
                                    <Typography component={'div'}>
                                        {isUndefined(siteInfo)
                                            ? <Skeleton height={26} className={'-loading-skeleton'} variant="rounded" />
                                            : <CountingUp number={Number(siteInfo?.totalCustomer)} extraClass={'-number-text'} />
                                        }
                                        <Typography variant={'body2'}>{trans('homepage.cover.card.customer')}</Typography>
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" className={'-card-wrapper'}>
                                <CardContent className={'-card-content'}>
                                    <Box className={'-icon-wrapper'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 42 42" fill="none">
                                            <g clipPath="url(#clip0_802_5366)">
                                                <path d="M41.2816 14.6631C40.6809 13.9622 39.7798 13.5117 38.8788 13.5117H36.3758L26.7143 1.59751C26.1136 0.846616 25.0623 0.696437 24.2614 1.24709C23.5105 1.84781 23.3603 2.89906 23.9109 3.70001C23.9109 3.75007 23.961 3.75007 23.961 3.80013L31.9205 13.5117H10.495L18.4044 3.80013C19.0552 3.0993 19.0051 1.99799 18.3043 1.34721C17.6034 0.696437 16.5021 0.746497 15.8514 1.44733C15.8013 1.49739 15.7512 1.54745 15.7012 1.59751L6.03967 13.5618H4.03729C2.33526 13.5618 0.933594 14.9634 0.933594 16.6654C0.933594 16.8657 0.933594 17.0659 0.983653 17.2662L4.98842 37.4402C5.3889 39.5427 7.2411 41.0445 9.39367 41.0445H33.5224C35.675 41.0445 37.5272 39.5427 37.9276 37.4402L41.9324 17.2662C42.1326 16.3651 41.8823 15.414 41.2816 14.6631ZM16.2518 31.0326C16.2518 31.9837 15.4509 32.7846 14.4997 32.7846C13.5486 32.7846 12.7477 31.9837 12.7477 31.0326V23.9241C12.7477 22.973 13.5486 22.172 14.4997 22.172C15.4509 22.172 16.2518 22.973 16.2518 23.9241V31.0326ZM23.2101 31.0326C23.2101 31.9837 22.4092 32.7846 21.458 32.7846C20.5069 32.7846 19.7059 31.9837 19.7059 31.0326V23.9241C19.7059 22.973 20.5069 22.172 21.458 22.172C22.4092 22.172 23.2101 22.973 23.2101 23.9241V31.0326ZM30.1183 31.0326C30.1183 31.9837 29.3174 32.7846 28.3663 32.7846C27.4151 32.7846 26.6142 31.9837 26.6142 31.0326V23.9241C26.6142 22.973 27.4151 22.172 28.3663 22.172C29.3174 22.172 30.1183 22.973 30.1183 23.9241V31.0326Z" fill="#9FC7FF"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_802_5366">
                                                    <rect width="41.0667" height="41.0667" fill="white" transform="translate(0.933594 0.43335)"/>
                                                </clipPath>
                                            </defs>totalSold
                                        </svg>
                                    </Box>
                                    <Typography component={'div'}>
                                        {isUndefined(siteInfo)
                                            ? <Skeleton height={26} className={'-loading-skeleton'} variant="rounded" />
                                            : <CountingUp number={Number(siteInfo?.totalSold)} extraClass={'-number-text'} />
                                        }
                                        <Typography variant={'body2'}>{trans('homepage.cover.card.sold')}</Typography>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </FadeInUp>
                </Box>
                <Box className={'-cover-column-right'}>
                    <FadeInUp delay={350}>
                        <Image
                            extraClass={'-img-banner'}
                            src={'wow-boost-homepage-cover-banner.png'}
                            width={626}
                            height={626}
                        />
                    </FadeInUp>
                </Box>
            </Container>
        </CoverStyled>
    )
}

export default HomepageCover
