import React, { useRef, useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { styled } from '@mui/material/styles';

export type TimePatternType = 'hour' | 'minute' | 'second';

export interface ITimeRemaining {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    unixSeconds: number;
    diffInSeconds: number;
}

interface ICountdownTimer {
    prefix?: string;
    endedAt?: Date | string;
    isCountingUp?: boolean;
    pattern?: TimePatternType;
    extraClass?: string;
    onStart?: () => void;
    onTrigger?: (timeRemaining: ITimeRemaining) => void;
    onEnd?: (setTimer: any) => void;
}

export const countdownPromptPay = 15 * 60; // 15 min
export const countdownCallStaff = 60; // 1 min

const CountdownTimerStyled = styled('span')(({ theme }) => ({
    '&.x-countdown-timer-wrapper': {
        fontSize: theme.baseVariables.fonts.fontSizeNormal,
        '&.-small': {
            fontSize: theme.baseVariables.fonts.fontSizeSmall,
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.baseVariables.fonts.fontSizeNormal,
            },
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.baseVariables.fonts.fontSizeBig,
        },
    },
}));

const CountdownTimer: React.FC<ICountdownTimer> = ({ endedAt, isCountingUp = false, pattern = 'hour', prefix, extraClass, onStart, onTrigger, onEnd }) => {
    const Ref: { current: NodeJS.Timeout | null } = useRef(null);
    const [timer, setTimer] = useState('00:00:00');
    const endedAtAsString = moment(endedAt).toISOString();

    const getTimeRemaining = (dateTime: Moment) => {
        let unixSeconds = dateTime.valueOf() - moment().valueOf();
        if (isCountingUp) {
            unixSeconds = moment().valueOf() - dateTime.valueOf();
        }
        const diffInSeconds = Math.floor(unixSeconds / 1000);
        const seconds = Math.floor((unixSeconds / 1000) % 60);
        const minutes = Math.floor((unixSeconds / 1000 / 60) % 60);
        const hours = Math.floor((unixSeconds / 1000 / 60 / 60) % 24);
        const days = Math.floor(unixSeconds / 1000 / 60 / 60 / 24);

        return { unixSeconds, diffInSeconds, days, hours, minutes, seconds };
    };

    const timerDisplayResolver = (timeRemaining: ITimeRemaining) => {
        const { hours, minutes, seconds } = timeRemaining;
        let timerDisplay = '';

        const hPad = hours.toString().padStart(2, '0');
        const mPad = minutes.toString().padStart(2, '0');
        const sPad = seconds.toString().padStart(pattern === 'second' ? 1 : 2, '0');

        if ('hour' === pattern) {
            timerDisplay = `${hPad}:${mPad}:${sPad}`;
        }

        if ('minute' === pattern) {
            timerDisplay = `${mPad}:${sPad}`;
        }

        if ('second' === pattern) {
            timerDisplay = sPad;
        }

        return timerDisplay;
    };

    const setup = (dateTime: Moment) => {
        const timeRemaining = getTimeRemaining(dateTime);
        const timerDisplayResolved = timerDisplayResolver(getTimeRemaining(dateTime));
        const diffInSeconds = Math.floor(timeRemaining.unixSeconds / 1000);

        if (diffInSeconds > 0) {
            setTimer(timerDisplayResolved);
            onTrigger?.call(null, timeRemaining);

            return;
        }

        setTimer(timerDisplayResolved);
        onTrigger?.call(null, timeRemaining);
        onEnd?.call(null, setTimer);

        if (Ref.current) clearInterval(Ref.current);
    };

    useEffect(() => {
        const dateTime = moment(endedAt);
        const timerDisplayResolved = timerDisplayResolver(getTimeRemaining(dateTime));

        setTimer(timerDisplayResolved);
        onStart?.call(null);
        onTrigger?.call(null, getTimeRemaining(dateTime));

        if (Ref.current) clearInterval(Ref.current);

        Ref.current = setInterval(() => {
            setup(dateTime);
        }, 1000);

        return () => {
            if (Ref.current) clearInterval(Ref.current);
        };
    }, [endedAtAsString]);

    return (
        <CountdownTimerStyled className={`x-countdown-timer-wrapper ${extraClass && extraClass}`}>
            <span className={`-time`}>
                {prefix && prefix} {timer}
            </span>
        </CountdownTimerStyled>
    );
};

export default CountdownTimer;
