import {AxiosResponse} from "axios";
import {useState} from "react";
import { ref } from "@core/Utils/obj";
import { ToastAlert } from "@core/Component/AlertFadeInRight";
import uniq from "lodash/uniq";
import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";

export interface IApiState {
    isOk: boolean;
    isBadRequest: boolean;
    isError: boolean;
    isFormError: boolean;
    isLoading: boolean;
    loadingKeyActive: undefined | string;
    data: any;
    paginate: IApiPagination | object;
}

export interface IApiPagination {
    page: number;
    count: number;
    limit: number;
    items: Array<any>;
}

interface IApiPaginationResponse {
    currentPage: number;
    totalItem: number;
    maxPage: number;
    limit: number;
}

export interface IFormItemError {
    errors: Array<string>
}

export interface IFormError {
    [key: string]: IFormItemError
}

export interface IClientApiState {
    isOk: boolean,
    isBadRequest: boolean,
    isError: boolean,
    isFormError: boolean,
    isLoading: boolean,
    loadingKeyActive: string | undefined,
    data: object | undefined | any,
    paginate: object,
}

export const useClientApiState = (initState = {}) => {
    return useState({
        isOk: false,
        isBadRequest: false,
        isError: false,
        isFormError: false,
        isLoading: true,
        data: undefined,
        loadingKeyActive: undefined,
        paginate: {},
        ...initState,
    } as IClientApiState);
}

export const resolveClientApiState = (
    response: AxiosResponse,
    alertOnError = false,
    options: {
        paginateKey?: string,
        itemKey?: string,
        errorMessageWithCode?: object,
        alertOnFormError?: boolean,
    } = {}
): IApiState => {
    // @ts-ignore
    let resolved: IApiState = {};
    resolved = {
        isError: !isOk(response),
        isFormError: 400 === response.status && (response.data.field_errors || response.data.error),
        isBadRequest: 400 === response.status,
        isLoading: false,
        data: response.data,
        loadingKeyActive: undefined,
        isOk: false,
        paginate: {},
    }

    // paginate
    const paginateData: IApiPaginationResponse | undefined = ref(response, !isEmpty(options.paginateKey) ? `data.${options.paginateKey}` : 'data');
    const itemData: Array<object> | undefined = ref(response, `data.${options.itemKey}`);
    if (paginateData && itemData) {
        resolved.paginate = {
            page: paginateData.currentPage,
            count: paginateData.totalItem,
            limit: paginateData.limit,
            items: itemData,
        }
    }

    resolved.isOk = !resolved.isError && !resolved.isFormError && !resolved.isBadRequest;

    if (alertOnError && (resolved.isError || !resolved.isOk)) {
        let message = !isUndefined(response.data?.message) ? response.data.message : 'Something went wrong.';
        if (401 === response.status) {
            message = 'Pin ไม่ถูกต้อง\nกรุณาเข้าสู่ระบบใหม่อีกครั้ง';

            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000)
        }

        if (options?.errorMessageWithCode) {
            if (options.errorMessageWithCode[response.status]) {
                message = options.errorMessageWithCode[response.status];
            } else if (options.errorMessageWithCode[response.data.serverErrorCode]) {
                message = options.errorMessageWithCode[response.data.serverErrorCode];
            }
        }

        ToastAlert('error', message);
    }

    if (options.alertOnFormError && resolved.isFormError) {
        ToastAlert('error', serializeFormError(response.data).global.errors.join(','));
    }

    return resolved;
};

export const serializeFormError = (responseData: { [key: string]: any }): IFormError => {
    const serialized: IFormError = {
        global: {
            errors: []
        }
    };

    function _exposeErrorMessage(fieldErrors: Array<any>, inputDeepSuffix: string | null) {
        for (const i in fieldErrors) {
            if (fieldErrors[i].children) {
                _exposeErrorMessage(fieldErrors[i].children, i);
            }

            if (!fieldErrors[i].errors) {
                continue;
            }

            const inputPrefix = inputDeepSuffix ? `${i}_${inputDeepSuffix}` : i.toString();

            if (typeof serialized[inputPrefix] === 'undefined') {
                serialized[inputPrefix] = {
                    errors: [],
                }
            }

            serialized['global'].errors.push(...fieldErrors[i].errors);
            serialized[inputPrefix].errors.push(...fieldErrors[i].errors);
        }
    }

    if (!isUndefined(responseData.field_errors)) {
        _exposeErrorMessage(responseData.field_errors.children, '');

        if (responseData.field_errors.errors) {
            serialized['global'].errors.push(...responseData.field_errors.errors);
        }
    }

    if (responseData.message) {
        serialized['global'].errors.push(...responseData.message);
    }

    serialized['global'].errors = uniq(serialized['global'].errors);

    return serialized;
};

export const isOk = (response: AxiosResponse): boolean => {
    return typeof response.data === 'object' && [200, 400].includes(response.status);
}

export const handleResponseFormError = (res: IApiState) => {
    if (!res.isFormError) {
        return;
    }

    const formErrors = serializeFormError(res.data);

    ToastAlert('error', formErrors.global.errors.join(','));
}
