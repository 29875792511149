import React, { useEffect } from "react";
import {matchPath, useLocation} from "react-router-dom";
import includes from "lodash/includes";

export const ScrollToTop: React.FC = () => {
    const { pathname } = useLocation();
    const isPolicyPage = matchPath('/policy/:topic', pathname)

    useEffect(() => {
        const excludePathName: string[] = [];

        if (includes(excludePathName, pathname)) return;

        window.scrollTo({
            top: 0,
            behavior: isPolicyPage ? 'auto' : 'smooth',
        });
    }, [pathname]);

    return null;
}
