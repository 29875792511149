import { useMediaQuery } from "react-responsive";

export const muiBreakpoints = {
    xxs: 0,
    xs: 375,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
}

export const useResponsive = () => {
    const isTabletOrMobile = useMediaQuery({query: `(max-width: ${muiBreakpoints.md - 1}px)`})
    const isDesktop = useMediaQuery({query: `(min-width: ${muiBreakpoints.md}px)`})

    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })

    return {
        isDesktop,
        isTabletOrMobile,
        isPortrait,
        isLandscape,
    }
}
