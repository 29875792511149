import ApiClient from '../Utils/api_client';
import {AxiosResponse} from "axios";

export async function createCustomerByPhoneWithValidateToken(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/user/createCustomerByPhoneWithValidateToken', formData);
}

export async function createCustomerByEmail(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/user/createCustomerByEmail', formData);
}

export async function validateEmailWithValidateToken(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/user/validateEmailWithValidateToken', formData);
}

export async function checkValidateTokenIsExpire(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/user/checkValidateTokenIsExpire', formData);
}

export async function resentValidateEmail(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/user/resendValidateEmail', formData);
}

export async function sendForgetPasswordLinkViaEmail(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/user/forgetpassword', formData);
}


export async function resetPasswordByValidateToken(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/user/resetPasswordByValidateToken', formData);
}

export async function userInfo(): Promise<AxiosResponse> {
    return ApiClient.get('/user');
}

export async function getInvoiceInfo(): Promise<AxiosResponse> {
    return ApiClient.get('/user/customerInfo');
}
