import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import MetaSeo from '../Component/Common/MetaSeo';
import OrderCardLayout from '../Component/Order/OrderCardLayout';
import Layout from '../Component/Common/Layout';
import { useTrans } from '@core/Hook/trans';
import { matchPath, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useResponsive } from '@core/Hook/responsiveHook';
import { Asset } from '@core/Utils/image';
import OrderDetailHeading from './OrderDetail/OrderDetailHeading';
import OrderDetailContent from './OrderDetail/OrderDetailContent';
import OrderDetailFooter from './OrderDetail/OrderDetailFooter';
import { IOrder, OrderSingleAtom } from '../Recoil/Order/atom';
import isUndefined from 'lodash/isUndefined';
import useOrderHook from '../Hook/orderHook';
import { IApiState, resolveClientApiState, useClientApiState } from '@core/Hook/api';
import { getOrderByPurchaseOrderId, getOrderCreditByPurchaseOrderId } from '../Api/order';
import { useRecoilState } from 'recoil';
import { isOrderPendingStatus } from '../Utils/helper';
import PlainButton from '../Component/Button/PlainButton';
import { useHandleDialog } from '../Hook/modalDialogHook';
import Stack from '@mui/material/Stack';
import CancelOrderModal from './OrderDetail/CancelOrderModal';
import { useAlert } from '../Hook/alertHook';
import { parse } from 'qs';
import trim from 'lodash/trim';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrderTypeEnum } from '../Constants/order';

const OrderDetailStyled = styled(Box)(({ theme }) => ({
    '&.x-order-detail': {
        '.-loading-wrapper': {
            '.-loading-skeleton': {
                marginBottom: theme.spacing(2),
            },
        },
    },
}));

const OrderHistoryDetail: React.FC = () => {
    const location = useLocation();
    const qs = parse(trim(location.search, '?'));
    const navigate = useNavigate();
    const { trans } = useTrans();
    const { orderId } = useParams();
    const { isTabletOrMobile } = useResponsive();
    const { findOrderFromState } = useOrderHook();
    const [apiState, setApiState] = useClientApiState({ isLoading: false });
    const [orderSingle, setOrderSingle] = useRecoilState(OrderSingleAtom);
    const cancelOrderModal = useHandleDialog();
    const { renderStateAlert } = useAlert();
    const [searchParams, setSearchParams] = useSearchParams();
    const orderType = location.state?.orderType;

    const bgImage = isTabletOrMobile ? Asset('wow-boost-history-purchase-bg-mobile.jpg') : Asset('wow-boost-history-purchase-bg.jpg');

    const fetchOrderSingle = async () => {
        setApiState((prev: IApiState) => {
            return { ...prev, isLoading: true };
        });

        let res;
        if (orderType === OrderTypeEnum.CREDIT) {
            res = resolveClientApiState(await getOrderCreditByPurchaseOrderId(orderId as string), true);
        } else {
            res = resolveClientApiState(await getOrderByPurchaseOrderId(orderId as string), true);
        }

        setApiState({ ...res, isLoading: false });

        if (res.isOk) {
            setOrderSingle(res.data);

            return;
        }
    };

    useEffect(() => {
        fetchOrderSingle();
    }, []);

    useEffect(() => {
        if (isUndefined(orderSingle)) {
            fetchOrderSingle();

            return;
        }

        const orderFromState = findOrderFromState(orderId as string);

        if (orderSingle?.purchaseOrderRefId === orderId) return;

        setOrderSingle((prev) => {
            return {
                ...(prev as IOrder),
                ...orderFromState,
            };
        });
    }, [orderId, navigate]);

    const isOrderDetailPage = matchPath('/account/order-detail/:orderId', location.pathname);

    const orderDetailHeaderComponent = () => {
        return (
            <>
                {isUndefined(orderSingle) ? (
                    <Box className={'-loading-wrapper'}>
                        <Skeleton height={37} className={'-loading-skeleton'} variant="rounded" />
                        <Skeleton height={24} className={'-loading-skeleton'} variant="rounded" />
                        <Skeleton height={60} className={'-loading-skeleton'} variant="rounded" />
                    </Box>
                ) : (
                    <>
                        <OrderDetailHeading order={orderSingle as IOrder} isLoading={apiState.isLoading} isIndexPage={false} />
                        <Divider />
                    </>
                )}
            </>
        );
    };

    const renderOrderDetailContent = () => {
        return (
            <>
                {isUndefined(orderSingle) ? (
                    <Box className={'-loading-wrapper'}>
                        <Skeleton height={190} className={'-loading-skeleton'} variant="rounded" />
                    </Box>
                ) : (
                    <OrderDetailContent order={orderSingle as IOrder} isLoading={apiState.isLoading} orderType={orderType} />
                )}
            </>
        );
    };

    const renderOrderDetailFooter = () => {
        return (
            <>
                {isUndefined(orderSingle) ? (
                    <Box className={'-loading-wrapper'}>
                        <Skeleton height={47} className={'-loading-skeleton'} variant="rounded" />
                        <Skeleton height={44} className={'-loading-skeleton'} variant="rounded" />
                    </Box>
                ) : (
                    <OrderDetailFooter order={orderSingle as IOrder} isLoading={apiState.isLoading} orderType={orderType} />
                )}
            </>
        );
    };

    const panelOrderTitle = isOrderDetailPage ? trans('history.title') : trans('order.title');
    const panelCreditTitle = isOrderDetailPage ? trans('credit.credit_history_title') : trans('order.title');
    const metaTitle = isOrderDetailPage ? trans('meta_seo.history.title') : trans('meta_seo.order.title');
    const metaDescription = isOrderDetailPage ? trans('meta_seo.history.description') : trans('meta_seo.order.description');
    const isOrderPending = isOrderPendingStatus(Number(orderSingle?.orderStatus));

    return (
        <Layout contentAvoidHeader contentBg={bgImage}>
            <MetaSeo title={metaTitle} description={metaDescription} />
            <OrderDetailStyled className={'x-order-detail'}>
                <OrderCardLayout title={orderType === OrderTypeEnum.CREDIT ? panelCreditTitle : panelOrderTitle}>
                    <>
                        {isOrderDetailPage && orderDetailHeaderComponent()}
                        {renderOrderDetailContent()}
                        <Divider />
                        {renderOrderDetailFooter()}
                        {isOrderPending && (
                            <>
                                <Stack display={'flex'} alignItems={'center'} justifyContent={'center'} spacing={1}>
                                    <PlainButton
                                        color={'secondary'}
                                        label={trans('order_detail.cancel_order_title')}
                                        extraClass={'-text-underline -cancel-order'}
                                        onClick={cancelOrderModal.handleClickOpen}
                                    />
                                </Stack>
                                <CancelOrderModal
                                    open={cancelOrderModal.open}
                                    purchaseOrderRefId={orderSingle?.purchaseOrderRefId as string}
                                    handleClose={cancelOrderModal.handleClose}
                                    orderType={orderType}
                                />
                            </>
                        )}
                        {(!isUndefined(location.state?.isPaymentSuccess) || !isUndefined(qs.noti)) &&
                            renderStateAlert(searchParams, setSearchParams)}
                    </>
                </OrderCardLayout>
            </OrderDetailStyled>
        </Layout>
    );
};

export default OrderHistoryDetail;
