import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const BoostFollowerIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 23 24" fill="none">
                <g clipPath="url(#clip0_1338_2137)">
                    <path d="M1.04841 10.6355C0.680806 10.6355 0.382812 10.9335 0.382812 11.3011V21.0771C0.382812 21.4447 0.68085 21.7427 1.04841 21.7427H5.05856V10.6355H1.04841Z" fill={color}/>
                    <path d="M22.8666 14.311C22.8666 13.466 22.4126 12.7251 21.7357 12.3193C21.9951 11.9449 22.1472 11.4906 22.1472 11.0016C22.1472 9.72225 21.1063 8.68136 19.8269 8.68136H14.9288C15.0789 8.00108 15.2843 6.98027 15.4272 5.91165C15.7992 3.13075 15.545 1.58848 14.6271 1.0581C14.0548 0.727503 13.4378 0.660442 12.8898 0.869095C12.4663 1.03037 11.8945 1.42689 11.5687 2.40006L10.2813 5.77072C9.6287 7.38251 7.63011 9.07463 6.39062 10.0103V22.0372C8.68783 22.8421 11.085 23.25 13.5305 23.25H18.8675C20.1469 23.25 21.1878 22.2092 21.1878 20.9298C21.1878 20.4818 21.0601 20.063 20.8393 19.7079C21.6128 19.3312 22.1471 18.5369 22.1471 17.6204C22.1471 17.1314 21.995 16.6772 21.7357 16.3027C22.4126 15.8969 22.8666 15.1559 22.8666 14.311Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_1338_2137">
                        <rect width="22.5" height="22.5" fill="white" transform="translate(0.375 0.75)"/>
                    </clipPath>
                </defs>
            </svg>
        </ServiceProductIcon>
    )
}

export default BoostFollowerIcon
