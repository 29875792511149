import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTrans } from '@core/Hook/trans';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PlainButton from '../../Component/Button/PlainButton';
import Form from '@core/Form/Form';
import { FormControlLabel, Grid, Radio, RadioGroup, Skeleton } from '@mui/material';
import FormInput, { onInputAcceptOnlyNumber } from '@core/Form/FormInput';
import OrderSummary from '../../Component/Order/OrderSummary';
import FormLabelControl from '../../Component/Form/FormLabelControl';
import FormActionControl from '../../Component/Form/FormActionControl';
import isUndefined from 'lodash/isUndefined';
import { wrappedOmiseFactory } from '../../Utils/omise';
import { resolveClientApiState, useClientApiState } from '@core/Hook/api';
import { checkout, checkoutCredit } from '../../Api/payment';
import { useManageProductInfo } from '../../Hook/product';
import { useNavigate } from 'react-router-dom';
import usePaymentHook from '../../Hook/paymentHook';
import PaymentProcessModal from './PaymentProcessModal';
import { useHandleDialog } from '../../Hook/modalDialogHook';
import { getInvoiceInfo } from '../../Api/user';
import { CustomerInvoiceInfoAtom } from '../../Recoil/Common/atom';
import isEmpty from 'lodash/isEmpty';
import PaymentCreditFailModal from './PaymentCreditFailModal';
import { useRecoilState } from 'recoil';
import RefundPolicyModal from './RefundPolicyModal';
import TextWithLink from '../../Component/TextWithLink';
import Stack from '@mui/material/Stack';
import { PaymentMethodEnum } from '../../Constants/common';
import { OrderTypeEnum } from '../../Constants/order';
import OrderState from '../../Component/Order/OrderState';

const ReceiptFormStyled = styled(Box)(({ theme }) => ({
    '&.x-receipt-form-container': {
        '.-form-control': {
            paddingTop: theme.spacing(2),
            '&.-hide': {
                display: 'none',
            },
            '.-radio-group': {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                '.-radio-label': {
                    width: 'calc(50% - 8px)',
                    '.MuiTypography-root': {
                        fontSize: theme.baseVariables.fonts.fontSizeSmall,
                    },
                },
            },
        },
        '.-btn-refund-policy-wrapper': {
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(5),
            },
        },
    },
}));

interface IReceiptForm {
    socialAccountLink: string;
    socialAccountLinks?: string[];
    socialAccountName: string;
    productItemFullCode: string;
    price: number;
    metadata?: IOrderMetadata;
    purchaseOrderRefId?: string;
    orderType?: string;
    creditCode?: string;
    isRepayment?: boolean;
    paymentMethod?: PaymentMethodEnum;
}

export interface IReceiptFormData {
    type: string;
    personalName?: string | undefined;
    corporateName?: string | undefined;
    phoneNumber?: string | undefined;
    address?: string | undefined;
    taxNumber?: string | undefined;
}

export interface IOrderInfo {
    data: string;
    customerInfo: string;
}

export interface IOrderMetadata {
    [key: string]: string;
}

export interface IOrderCheckOut {
    productItemFullCode: string;
    link: string | string[];
    socialAccountName: string;
    metadata?: IOrderMetadata;
    purchaseOrderRefId?: string;
}

export interface IOrderCheckOutCredit {
    creditCode?: string;
    purchaseOrderCreditRefId?: string;
}

export interface IOrderCustomerInfo {
    infoName: string;
    isCorporate: boolean;
    address?: string;
    phoneNumber?: string;
    taxpayerId?: string;
}

const ReceiptForm: React.FC<IReceiptForm> = ({
    productItemFullCode,
    price,
    socialAccountLink,
    socialAccountLinks,
    socialAccountName,
    metadata,
    purchaseOrderRefId,
    orderType,
    creditCode,
    isRepayment,
}) => {
    const { trans } = useTrans();
    const [currentType, setCurrentType] = useState<string>('personal');
    const [lastPurchaseOrderRefId, setLastPurchaseOrderRefId] = useState<string | undefined>(undefined);
    const isPersonalReceipt = 'personal' === currentType;
    const refundPolicyModal = useHandleDialog();

    const validationSchemaPersonal = Yup.object().shape({
        type: Yup.string().required(trans('order_summary.receipt.form.type.not_blank')).oneOf(['personal', 'corporate']),
        personalName: Yup.string().required(trans('order_summary.receipt.form.personalName.not_blank')),
    });

    const validationSchemaCorporate = Yup.object().shape({
        type: Yup.string().required(trans('order_summary.receipt.form.type.not_blank')).oneOf(['personal', 'corporate']),
        corporateName: Yup.string().required(trans('order_summary.receipt.form.corporateName.not_blank')),
        phoneNumber: Yup.string().required(trans('order_summary.receipt.form.phoneNumber.not_blank')),
        address: Yup.string().required(trans('order_summary.receipt.form.address.not_blank')),
        taxNumber: Yup.string().required(trans('order_summary.receipt.form.taxNumber.not_blank')),
    });

    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<any>({
        defaultValues: {
            type: 'personal',
        },
        resolver: yupResolver(isPersonalReceipt ? (validationSchemaPersonal as any) : (validationSchemaCorporate as any)),
    });
    const [apiCheckoutState, setApiCheckoutState] = useClientApiState({
        isLoading: false,
    });
    const [apiCheckoutCreditState, setApiCheckoutCreditState] = useClientApiState({
        isLoading: false,
    });
    const [apiInvoiceInfoState, setApiInvoiceInfoState] = useClientApiState({
        isLoading: false,
    });
    const { clearProductInfo } = useManageProductInfo();
    const navigate = useNavigate();
    const { saveOrderPaymentPromptPay } = usePaymentHook();
    const paymentProcessModal = useHandleDialog();
    const paymentCreditFailModal = useHandleDialog();
    const [customerInvoiceInfo, setCustomerInvoiceInfo] = useRecoilState(CustomerInvoiceInfoAtom);

    const fetchInvoiceInfo = async () => {
        setApiInvoiceInfoState({ ...apiInvoiceInfoState, isLoading: true });

        const customerInvoiceInfoRes = resolveClientApiState(await getInvoiceInfo(), true);

        if (customerInvoiceInfoRes?.isOk) {
            setCustomerInvoiceInfo(customerInvoiceInfoRes.data);
        }

        setApiInvoiceInfoState({ ...apiInvoiceInfoState, isLoading: false });
    };

    const createTokenSuccessHandler = async (token: string | undefined, data: IOrderInfo) => {
        setApiCheckoutState({ ...apiCheckoutState, isLoading: true });
        paymentProcessModal.handleClickOpen();

        const checkoutInfoRes = resolveClientApiState(await checkout({ ...data }), true);

        if (checkoutInfoRes?.isOk) {
            clearProductInfo();
            const { authorizeUri } = checkoutInfoRes.data;

            if (!isUndefined(authorizeUri)) {
                window.location.replace(authorizeUri);
                return;
            }

            saveOrderPaymentPromptPay(checkoutInfoRes.data);
            paymentProcessModal.handleClose();
            navigate(`/account/order-payment/${checkoutInfoRes.data.refId}`);
        }

        paymentProcessModal.handleClose();
        paymentCreditFailModal.handleClickOpen();
        setLastPurchaseOrderRefId(checkoutInfoRes.data?.purchaseOrderRefId);
        setApiCheckoutState({ ...apiCheckoutState, isLoading: false });
    };

    const createTokenCreditSuccessHandler = async (token: string | undefined, data: IOrderInfo) => {
        setApiCheckoutCreditState({ ...apiCheckoutCreditState, isLoading: true });
        paymentProcessModal.handleClickOpen();

        const checkoutInfoRes = resolveClientApiState(await checkoutCredit({ ...data }), true);

        if (checkoutInfoRes?.isOk) {
            const { authorizeUri } = checkoutInfoRes.data;

            if (!isUndefined(authorizeUri)) {
                window.location.replace(authorizeUri);
                return;
            }

            saveOrderPaymentPromptPay(checkoutInfoRes.data);
            paymentProcessModal.handleClose();
            navigate(`/account/order-payment/${checkoutInfoRes.data.refId}`, {
                state: {
                    orderType: OrderTypeEnum.CREDIT,
                },
            });
        }

        paymentProcessModal.handleClose();
        paymentCreditFailModal.handleClickOpen();
        setLastPurchaseOrderRefId(checkoutInfoRes.data?.purchaseOrderRefId);
        setApiCheckoutCreditState({ ...apiCheckoutCreditState, isLoading: false });
    };

    const submitOrder = async (data: IReceiptFormData) => {
        const isCorporate = 'corporate' === data.type;
        let customerInfo: IOrderCustomerInfo = {
            infoName: data.personalName as string,
            isCorporate: isCorporate,
        };

        if (isCorporate) {
            customerInfo = {
                ...customerInfo,
                infoName: data.corporateName as string,
                address: data?.address as string,
                taxpayerId: data?.taxNumber as string,
                phoneNumber: data?.phoneNumber as string,
            };
        }

        let order: IOrderCheckOut = {
            link: socialAccountLinks || socialAccountLink,
            productItemFullCode: productItemFullCode,
            socialAccountName: socialAccountName,
        };

        let orderCredit: IOrderCheckOutCredit = {
            creditCode: creditCode,
        };

        if (!isUndefined(purchaseOrderRefId)) {
            order = { ...order, purchaseOrderRefId: purchaseOrderRefId };
            orderCredit = { ...orderCredit, purchaseOrderCreditRefId: purchaseOrderRefId };
        } else if (!isUndefined(lastPurchaseOrderRefId)) {
            order = { ...order, purchaseOrderRefId: lastPurchaseOrderRefId };
            orderCredit = { ...orderCredit, purchaseOrderCreditRefId: lastPurchaseOrderRefId };
        }

        if (!isUndefined(metadata)) {
            order = { ...order, metadata: metadata };
        }

        const orderInfo: IOrderInfo = {
            data: JSON.stringify(order),
            customerInfo: JSON.stringify(customerInfo),
        };
        const orderCreditInfo: IOrderInfo = {
            data: JSON.stringify(orderCredit),
            customerInfo: JSON.stringify(customerInfo),
        };

        if (isRepayment) {
            if (orderType === OrderTypeEnum.CREDIT) {
                return createTokenCreditSuccessHandler(undefined, orderCreditInfo);
            }

            return createTokenSuccessHandler(undefined, orderInfo);
        }

        return navigate(`/account/payment-method`, {
            state: {
                orderType: orderType,
                orderInfo: orderInfo,
                orderCreditInfo: orderCreditInfo,
                price: price,
                isMultipleLink: order.link.length > 1,
            },
        });
    };
    const renderButtonAction = () => {
        const buttonAction = (
            <PlainButton
                fullWidth
                type={'submit'}
                color={'primary'}
                label={trans('general.next')}
                isLoading={apiCheckoutState.isLoading}
                disabled={apiCheckoutState.isLoading}
            />
        );

        return (
            <FormActionControl button={buttonAction}>
                {/*<span>{'Test'}</span>*/}
                {/*<Box className={'-coupon-wrapper'} padding={2} bgcolor={'red'} borderRadius={1}>*/}
                {/*    <FormLabelControl label={'คูปองส่วนลด'} />*/}
                {/*    <Stack direction={"row"} alignItems={"center"} gap={1}>*/}
                {/*        <TextField name={'coupon'} placeholder={'กรุณากรอกรหัสคูปอง'} />*/}
                {/*        <Button>{'ใช้คูปอง'}</Button>*/}
                {/*    </Stack>*/}
                {/*</Box>*/}

                <OrderSummary price={price} />
                <Box className={'-btn-refund-policy-wrapper'}>
                    <TextWithLink
                        title={trans('order_summary.refund_policy.title')}
                        linkTitle={trans('order_summary.refund_policy.button_text')}
                        linkOnClick={refundPolicyModal.handleClickOpen}
                    />
                    <TextWithLink title={trans('order_summary.refund_policy.description')} />
                </Box>
            </FormActionControl>
        );
    };

    const handleTypeChange = (type: string) => {
        const isCorporate = 'corporate' === type;
        const isEmptyCorporateInfo = isUndefined(customerInvoiceInfo) || (isCorporate && isEmpty(customerInvoiceInfo?.corporateInfo));
        const isEmptyPersonalInfo = isUndefined(customerInvoiceInfo) || (!isCorporate && isEmpty(customerInvoiceInfo?.personalInfo));

        setCurrentType(type);

        reset({
            type: type,
            personalName: isEmptyPersonalInfo ? '' : customerInvoiceInfo?.personalInfo?.infoName,
            corporateName: isEmptyCorporateInfo ? '' : customerInvoiceInfo?.corporateInfo?.infoName,
            phoneNumber: isEmptyCorporateInfo ? '' : customerInvoiceInfo?.corporateInfo?.phoneNumber,
            address: isEmptyCorporateInfo ? '' : customerInvoiceInfo?.corporateInfo?.address,
            taxNumber: isEmptyCorporateInfo ? '' : customerInvoiceInfo?.corporateInfo?.taxpayerId,
        });
    };

    const formData: IReceiptFormData = {
        type: currentType,
        personalName: '',
        corporateName: '',
        phoneNumber: '',
        address: '',
        taxNumber: '',
    };

    const initReceiptForm = () => {
        if (isUndefined(customerInvoiceInfo)) return handleTypeChange('personal');

        const type = customerInvoiceInfo?.isCorporate ? 'corporate' : 'personal';

        handleTypeChange(type);
    };

    useEffect(() => {
        fetchInvoiceInfo();
    }, []);

    useEffect(() => {
        initReceiptForm();
    }, [customerInvoiceInfo]);

    return (
        <ReceiptFormStyled className="x-receipt-form-container">
            <OrderState state={'customerStrictInfo'} />
            <Form register={register} onSubmit={handleSubmit(submitOrder)} buttonComponent={renderButtonAction()}>
                {apiInvoiceInfoState.isLoading ? (
                    <Stack display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                        <Skeleton height={66} width={'100%'} className={'-loading-skeleton'} variant="rounded" />
                        <Skeleton height={66} width={'100%'} className={'-loading-skeleton'} variant="rounded" />
                        <Skeleton height={66} width={'100%'} className={'-loading-skeleton'} variant="rounded" />
                        <Skeleton height={66} width={'100%'} className={'-loading-skeleton'} variant="rounded" />
                    </Stack>
                ) : (
                    <Grid container spacing={{ xxs: 1, sm: 1.25 }} columns={12}>
                        {Object.keys(formData)
                            .filter((key) => (isPersonalReceipt ? ['type', 'personalName'].includes(key) : !['personalName'].includes(key)))
                            .map((key, index) => {
                                const shouldRenderForm = 'type' !== key;
                                const hideFormClass = !shouldRenderForm ? '-hide' : '';
                                const inputProps = {
                                    hiddenLabel: true,
                                    name: key,
                                    id: key,
                                    className: '-form-control',
                                    register,
                                    placeholder: trans(`order_summary.receipt.form.${key}.placeholder`),
                                    errors: errors,
                                    type: shouldRenderForm ? 'text' : 'hidden',
                                };

                                if ('type' === key) {
                                    return (
                                        <Grid item xxs={12} className={'-form-control'} key={`${key}-${index}`}>
                                            <FormLabelControl label={trans(`order_summary.receipt.form.${key}.label`)} isRequired />
                                            <Controller
                                                key={key}
                                                name={key}
                                                control={control}
                                                defaultValue={currentType}
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                        <RadioGroup
                                                            onChange={(e, value: string) => {
                                                                onChange(value);
                                                                handleTypeChange(value);
                                                            }}
                                                            value={value}
                                                            className={'-radio-group'}
                                                            name="radio-buttons-group"
                                                        >
                                                            <FormControlLabel
                                                                className={'-radio-label'}
                                                                value="personal"
                                                                label={trans(`order_summary.receipt.form.${key}.personal`)}
                                                                control={<Radio />}
                                                            />
                                                            <FormControlLabel
                                                                className={'-radio-label'}
                                                                value="corporate"
                                                                label={trans(`order_summary.receipt.form.${key}.corporate`)}
                                                                control={<Radio />}
                                                            />
                                                        </RadioGroup>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    );
                                }

                                let extraInputProps = {};
                                if ('address' === key) {
                                    extraInputProps = {
                                        rows: 4,
                                        multiline: true,
                                    };
                                }

                                if ('phoneNumber' === key) {
                                    extraInputProps = {
                                        onInput: onInputAcceptOnlyNumber,
                                        inputProps: {
                                            inputMode: 'numeric',
                                            maxLength: 10,
                                        },
                                    };
                                }

                                if ('taxNumber' === key) {
                                    extraInputProps = {
                                        onInput: onInputAcceptOnlyNumber,
                                        inputProps: {
                                            inputMode: 'numeric',
                                            maxLength: 13,
                                        },
                                    };
                                }

                                return (
                                    <Grid item xxs={12} className={`-form-control ${hideFormClass}`} key={`${key}-${index}`}>
                                        <FormLabelControl label={trans(`order_summary.receipt.form.${key}.label`)} isRequired />
                                        <FormInput {...inputProps} {...extraInputProps} />
                                    </Grid>
                                );
                            })}
                    </Grid>
                )}
            </Form>
            <PaymentProcessModal open={paymentProcessModal.open} handleClose={() => {}} />
            <PaymentCreditFailModal open={paymentCreditFailModal.open} handleClose={paymentCreditFailModal.handleClose} />
            <RefundPolicyModal open={refundPolicyModal.open} handleClose={refundPolicyModal.handleClose} />
        </ReceiptFormStyled>
    );
};

export default ReceiptForm;
