import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import {RecoilRoot, useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import { RecoilExternalStatePortal } from "./Recoil/RecoilExternalStatePartial";
import moment from "moment";
import i18n from "i18next";
import './Trans/i18n';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CreateThemeOptions } from "./Theme/baseTheme";
import {CustomerInfoAtom, ICustomerInfo, InitializedAtom, ISiteInfo, SiteInfoAtom} from "./Recoil/Common/atom";
import { BrowserRouter } from "react-router-dom";
import Routers from "./Routes";
import { CssBaseline, responsiveFontSizes } from "@mui/material";
import { useManageLocale } from "./Hook/locale";
import { ScrollToTop } from "@core/Utils/scrollToTop";
import AlertFadeInRight from "@core/Component/AlertFadeInRight";
import {ApiTokenSelector} from "./Recoil/Common/selector";
import client from "./Utils/api_client";
import {userInfo} from "./Api/user";
import isUndefined from "lodash/isUndefined";
import Pages from './Page';
import LoadingFullPage from "./Component/Loading/LoadingFullPage";
import {pusherAuthenticate} from "@core/Utils/pusherAuthentication";
import {siteInfo} from "./Api/common";

const container = document.getElementById('root');

if (!container) {
    throw new Error("The element #portal wasn't found");
}

const root = createRoot(container);

const App: React.FC = () => {
    moment().locale(i18n.language);

    let themeResolved = createTheme(CreateThemeOptions());
    themeResolved = responsiveFontSizes(themeResolved);
    const {currentLocale, changeLocale} = useManageLocale();
    const [initialized, setInitialized] = useRecoilState(InitializedAtom)
    const setCustomerInfo = useSetRecoilState(CustomerInfoAtom);
    const [websiteInfo, setWebsiteInfo] = useRecoilState(SiteInfoAtom);
    const apiAccessToken = useRecoilValue(ApiTokenSelector);
    client._accessToken = apiAccessToken;

    useEffect(() => {
        if (initialized) return;

        (async () => {
            if (isUndefined(websiteInfo)) {
                const siteInfoRes = await siteInfo();

                if (200 === siteInfoRes.status) {
                    setWebsiteInfo({
                        ...siteInfoRes.data as ISiteInfo
                    });
                }
            }

            if (!isUndefined(apiAccessToken)) {
                const customerInfoRes = await userInfo();

                if (200 === customerInfoRes.status) {
                    setCustomerInfo({
                        ...customerInfoRes.data as ICustomerInfo
                    });

                    pusherAuthenticate(customerInfoRes.data.phoneNumber, apiAccessToken);
                }
            }

            setInitialized(true);
            await changeLocale(currentLocale as string);
            moment.locale(currentLocale as string);
        })()
    }, []);

    if (!initialized) {
        return (
            <ThemeProvider theme={themeResolved}>
                <Pages.Splash />
                <CssBaseline />
                <AlertFadeInRight />
            </ThemeProvider>
        )
    }

    return (
        <ThemeProvider theme={themeResolved}>
            <CssBaseline />
            <BrowserRouter>
                <ScrollToTop />
                <Routers />
            </BrowserRouter>
            <AlertFadeInRight />
        </ThemeProvider>
    )
}

root.render(
    <React.Suspense fallback={<LoadingFullPage />}>
        <RecoilRoot>
            <App />
            <RecoilExternalStatePortal />
        </RecoilRoot>
    </React.Suspense>
);
