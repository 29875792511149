import { useRecoilState } from "recoil";
import { CurrentLocaleCode } from "../Recoil/Locale/atom";
import { LOCAL_STORAGE_CURRENT_LOCALE_CODE } from "../Constants/common";
import { useManageLocalStorage } from "./ui";
import i18n from "i18next";

export const useManageLocale = () => {
    const [currentLocale, setCurrentLocale] = useRecoilState(CurrentLocaleCode);
    const {setToLocalStorage} = useManageLocalStorage();
    const { changeLanguage, options } = i18n;

    const saveCurrentLocaleClient = (locale: string) => {
        setToLocalStorage(LOCAL_STORAGE_CURRENT_LOCALE_CODE, locale);
        setCurrentLocale(locale);
    }

    const changeLocale = async (locale: string) => {
        let supportLocale = locale;
        if (options.supportedLngs && !options.supportedLngs.includes(locale)) {
            supportLocale = options.fallbackLng as string;
        }

        await changeLanguage(supportLocale, () => saveCurrentLocaleClient(supportLocale));
    }

    return {
        currentLocale,
        changeLocale,
        saveCurrentLocaleClient
    }
};
