import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTrans } from '@core/Hook/trans';
import UploadSlipForm from './Modal/UploadSlipForm';
import DefaultModal, { IDefaultModal } from '../../Component/Modal/DefaultModal';

const UploadSlipBoxStyled = styled(Box)(() => ({
    '&.x-upload-slip-box-container': {},
}));

interface IUploadSlipModal extends IDefaultModal {
    open: boolean;
    handleClose: () => void;
    orderType?: string;
}

const UploadSlipModal: React.FC<IUploadSlipModal> = ({ open, handleClose, orderType, ...rest }) => {
    const { trans } = useTrans();

    if (!open) return <></>;

    return (
        <DefaultModal
            {...rest}
            isHalfSize
            showCloseIcon
            open={open}
            title={trans('payment.modal.title')}
            titleColor={'primary'}
            extraClass={'-upload-slip-modal'}
            handleClose={handleClose}
        >
            <UploadSlipBoxStyled className={'x-upload-slip-box-container'}>
                <UploadSlipForm handleClose={handleClose} orderType={orderType} />
            </UploadSlipBoxStyled>
        </DefaultModal>
    );
};

export default UploadSlipModal;
