import ApiClient from '../Utils/api_client';
import { AxiosResponse } from 'axios';

export async function checkout(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/payment/checkout', formData);
}

export async function checkoutCredit(formData: object): Promise<AxiosResponse> {
    return ApiClient.post('/payment/checkoutCredit', formData);
}
