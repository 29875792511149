import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const BoostCommentIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 25 24" fill="none">
                <g clipPath="url(#clip0_1338_2171)">
                    <path d="M17.2994 0H7.70199C3.73304 0 0.503906 3.22914 0.503906 7.19808V12.7966C0.503906 16.6316 3.5183 19.7755 7.30209 19.9839V23.3206C7.30209 23.6953 7.60761 24 7.98311 24C8.16266 24 8.33262 23.9288 8.46098 23.8005L9.21118 23.0503C11.1811 21.08 13.8008 19.9947 16.5872 19.9947H17.2994C21.2684 19.9947 24.4975 16.7655 24.4975 12.7966V7.19808C24.4975 3.22914 21.2684 0 17.2994 0ZM7.30209 11.5969C6.41993 11.5969 5.70252 10.8795 5.70252 9.99733C5.70252 9.11517 6.41993 8.39776 7.30209 8.39776C8.18426 8.39776 8.90167 9.11517 8.90167 9.99733C8.90167 10.8795 8.18386 11.5969 7.30209 11.5969ZM12.5007 11.5969C11.6185 11.5969 10.9011 10.8795 10.9011 9.99733C10.9011 9.11517 11.6185 8.39776 12.5007 8.39776C13.3829 8.39776 14.1003 9.11517 14.1003 9.99733C14.1003 10.8795 13.3825 11.5969 12.5007 11.5969ZM17.6993 11.5969C16.8172 11.5969 16.0997 10.8795 16.0997 9.99733C16.0997 9.11517 16.8172 8.39776 17.6993 8.39776C18.5815 8.39776 19.2989 9.11517 19.2989 9.99733C19.2989 10.8795 18.5811 11.5969 17.6993 11.5969Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_1338_2171">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                    </clipPath>
                </defs>
            </svg>
        </ServiceProductIcon>
    )
}

export default BoostCommentIcon
