import ApiClient from '../Utils/api_client';
import { AxiosResponse } from 'axios';

export async function getUserCredit(): Promise<AxiosResponse> {
    return ApiClient.get('/credits/getUserCredit');
}

export async function loadCreditPrice(): Promise<AxiosResponse> {
    return ApiClient.get('/credits/loadCreditPrice');
}
