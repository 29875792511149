import React from "react";
import PlainButton from "../../Button/PlainButton";
import { useTrans } from "@core/Hook/trans";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import FormInput, { onInputAcceptOnlyNumber } from "@core/Form/FormInput";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Form from "@core/Form/Form";
import * as Yup from "yup";

interface IPhoneNumberForm {
    onSubmit: (data: IPhoneNumberFormData) => void;
    isLoading?: boolean;
}

export interface IPhoneNumberFormData {
    phoneNumber: string;
}

const PhoneNumberForm: React.FC<IPhoneNumberForm> = ({ onSubmit, isLoading = false }) => {
    const { trans } = useTrans();
    const validationSchema = Yup.object().shape({
        phoneNumber: Yup.string().required(trans('modal.login_phone_number.form.not_blank')).min(10, trans('modal.login_phone_number.form.min', {min: 10})),
    });
    const { register, handleSubmit, formState: { errors } } = useForm<IPhoneNumberFormData>({
        resolver: yupResolver(validationSchema)
    });

    const submitPhoneNumber = (data: IPhoneNumberFormData) => onSubmit(data);

    const renderButtonAction = () => {
        return (
            <PlainButton
                type={'submit'}
                fullWidth
                color={'primary'}
                label={trans('general.confirm')}
                isLoading={isLoading}
                disabled={isLoading}
            />
        )
    }

    const inputProps = {
        name: 'phoneNumber',
        id: 'phoneNumber',
        className: "-form-control",
        register,
        placeholder: trans('modal.register_phone_number.form.phone_number_placeholder'),
        errors: errors,
        type: 'text',
        fullWidth: true,
        autoFocus: true,
        onInput: onInputAcceptOnlyNumber,
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <PhoneAndroidIcon />
                </InputAdornment>
            ),
        },
    }

    return (
        <Form register={register} onSubmit={handleSubmit(submitPhoneNumber)} buttonComponent={renderButtonAction()}>
            <Box className={'-input-wrapper'}>
                <FormInput {...inputProps} inputProps={{
                    inputMode: 'numeric',
                    maxLength: 10,
                }} />
            </Box>
        </Form>
    )
}

export default PhoneNumberForm
