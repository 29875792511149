import React, {useEffect, useState} from 'react';
import moment from "moment";
import { Telegram } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTrans } from "@core/Hook/trans";
import { Image } from "@core/Utils/image";
import CountdownTimer from "../../CountdownTimer";
import PlainButton from "../../Button/PlainButton";
import type { ValidationStateProps } from "../RegisterEmailValidationModal";
import {resolveClientApiState, useClientApiState} from "@core/Hook/api";
import {
    resentValidateEmail,
    sendForgetPasswordLinkViaEmail
} from "../../../Api/user";
import {ToastAlert} from "@core/Component/AlertFadeInRight";
import isUndefined from "lodash/isUndefined";

interface IEmailValidationSent {
    email?: string
    validationState: ValidationStateProps;
    initialCanSentState: boolean;
    isAutoSentFirstTime?: boolean;
    onClick?: () => void;
}

const EmailValidationSentStyled = styled(Stack)(({ theme }) => ({
    '&.x-email-validation': {
        '.-img': {
            width: 120,
        },
        '.-time': {
            fontSize: theme.baseVariables.fonts.fontSizeNormal,
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            textAlign: 'center',
            color: theme.palette.primary.main,
        },
        '.-btn': {
            minWidth: 220,
        }
    },
}));

const EmailValidationResend: React.FC<IEmailValidationSent> = ({ email, validationState, initialCanSentState, isAutoSentFirstTime, onClick  }) => {
    const { trans } = useTrans()
    const [canSend, setCanSend] = useState(initialCanSentState);
    const [initialAutoSent, setInitialAutoSent] = useState(false);
    const isStateSent = validationState === 'sent'
    const isStateResetPassword = validationState === 'reset-password'
    let title = isStateSent
        ? trans('modal.register_email_sent', { email })
        : trans('modal.register_email_in_complete')
    let icon = isStateSent ? 'sent' : 'incomplete';
    if (isStateResetPassword) {
        title = trans('modal.reset_password_email');
        icon = trans('reset-password');
    }

    const [apiState, setApiState] = useClientApiState({isLoading: false});
    const onClickResentEmail = async () => {
        setCanSend(false);
        setApiState({ ...apiState, isLoading: true });

        const method = isStateResetPassword ? sendForgetPasswordLinkViaEmail({email: email}) : resentValidateEmail({email: email})
        const res = resolveClientApiState(await method, true);

        if (res.isOk) {
            ToastAlert('success', trans('alert.success.resent_email'));
            onClick && onClick();
            setApiState({ ...apiState, isLoading: false });

            return;
        }

        ToastAlert('error', trans('alert.fail.resent_email'));
        setApiState({ ...apiState, isLoading: false });
    }

    useEffect(() => {
        if (isUndefined(isAutoSentFirstTime)) return;
        if (initialAutoSent) return;

        onClickResentEmail();
        setInitialAutoSent(true);
    }, [])

    return (
        <EmailValidationSentStyled className={'x-email-validation-sent'}>
            <Box className={'-icon-wrapper'} textAlign={"center"}>
                <Image
                    src={`ic-email-validation-${icon}.png`}
                    width={60}
                    height={60}
                />
            </Box>
            <Box textAlign={"center"} marginTop={2}>
                <Typography variant={"subtitle2"} whiteSpace={"pre-wrap"}>{title}</Typography>
            </Box>
            <Stack
                justifyContent={"center"}
                alignItems={"center"}
                marginTop={3}
                textAlign={"center"}
                spacing={3}
            >
                {!canSend && (
                    <CountdownTimer
                        pattern={"minute"}
                        prefix={trans('modal.register_email_can_send_in')}
                        endedAt={moment().add('1', 'minute').toDate()}
                        onEnd={() => setCanSend(true)}
                    />
                )}
                <PlainButton
                    disabled={!canSend}
                    color={'primary'}
                    extraClass={'-btn'}
                    onClick={() => onClickResentEmail()}
                    labelElement={(
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <Telegram />
                            <Box>{trans('modal.register_email_send_again')}</Box>
                        </Stack>
                    )}
                />
            </Stack>
        </EmailValidationSentStyled>
    );
};

export default EmailValidationResend;
