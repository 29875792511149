import React from "react";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import LoggedMenu from "./LoggedMenu";

interface ILoggedDropdown {
    open: boolean;
    anchorEl: HTMLElement | null;
    handleClose: () => void;
}

const LoggedDropdownStyled = styled(Menu)(({ theme }) => ({
    '&.x-header-logged-dropdown': {
        '.MuiMenu-paper': {
            marginTop: theme.spacing(1)
        },
        '.-list': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: theme.spacing(0)
        }
    }
}))

const LoggedDropdown: React.FC<ILoggedDropdown> = ({ open, anchorEl, handleClose }) => {
    return (
        <LoggedDropdownStyled
            id="header-menu-logged"
            className={'x-header-logged-dropdown'}
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{className: '-list'}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <LoggedMenu renderLocation={'dropdown'} onClose={handleClose} />
        </LoggedDropdownStyled>
    )
}

export default LoggedDropdown
