import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localeTh from "./locales/th/translation.json";
import localeEn from "./locales/en/translation.json";
import localeZh from "./locales/zh/translation.json";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    th: localeTh,
    en: localeEn,
    zh: localeZh,
}

const languageDetectorOptions = {
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
    lookupQuerystring: 'locale',
    lookupCookie: 'current_locale_code',
    lookupLocalStorage: 'current_locale_code',
    lookupSessionStorage: 'current_locale_code',

    // cache user language
    caches: ['cookie'],
    excludeCacheFor: ['cimode'],
}

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: languageDetectorOptions,
        debug: false,
        fallbackLng: 'th',
        supportedLngs: Object.keys(resources),
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources
    });

export default i18n;
