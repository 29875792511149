import React from "react";
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";
import {useTrans} from "@core/Hook/trans";
import {useResponsive} from "@core/Hook/responsiveHook";
import HomepageSectionLayout from "./HomepageSectionLayout";
import Title from "../../Component/Title";
import {Image} from "@core/Utils/image";
import Box from "@mui/material/Box";
import {Swiper, SwiperSlide} from "swiper/react";
import {Mousewheel, Pagination} from "swiper";
import Typography from "@mui/material/Typography";
import FadeInUp from "../../Animate/FadeInUp";

const HowToUseStyled = styled(Container)(({theme}) => ({
    '&.x-homepage-how-to-use-container': {
        [theme.breakpoints.up('md')]: {
            height: '500px',
            overflow: 'hidden',
        },
        '.-swiper-slide': {
            marginTop: theme.spacing(3),
            '.-swiper-slide-item': {
                width: '100%',
                height: 'auto',
            },
            '.swiper-pagination': {
                marginTop: theme.spacing(2),
                position: 'relative',
                bottom: 0,
                '.swiper-pagination-bullet': {
                    width: '10px',
                    height: '10px',
                    borderRadius: '8px',
                    transition: theme.baseVariables.constants.transition.normal,
                    background: theme.baseVariables.howToUse.swiperBulletBg,
                    opacity: 1,
                    '&.swiper-pagination-bullet-active': {
                        width: '28px',
                        background: theme.baseVariables.howToUse.swiperBulletBgActive,
                    }
                }
            },
            '&.-desktop': {
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex',
                '.swiper-wrapper': {
                    width: '50%',
                },
                '.swiper-pagination': {
                    width: '50%',
                    height: '100%',
                    position: 'relative',
                    top: 'unset',
                    marginTop: 0,
                    transform: 'none',
                    '&:before, &:after': {
                        content: '""',
                        position: 'absolute',
                        width: '2px',
                        height: '65%',
                        left: '22px',
                        top: '40px',
                        zIndex: 1,
                    },
                    '&:before': {
                        background: theme.baseVariables.howToUse.numberLinerBgDesktop,
                        transform: 'translate(0, 0)',
                    },
                    '&:after': {
                        background: theme.baseVariables.howToUse.numberLinerBgDesktopActive,
                        transform: 'translate3d(0px, 0px, 0px) scaleX(1) scaleY(0.2)',
                        transformOrigin: 'left top',
                        transition: theme.baseVariables.constants.transition.normal,
                    },
                    '&.-index-1': {
                        '&:after': {
                            transform: 'translate3d(0px, 0px, 0px) scaleX(1) scaleY(0.5)',
                        },
                    },
                    '&.-index-2': {
                        '&:after': {
                            transform: 'translate3d(0px, 0px, 0px) scaleX(1) scaleY(0.8)',
                        },
                    },
                    '&.-index-3': {
                        '&:after': {
                            transform: 'translate3d(0px, 0px, 0px) scaleX(1) scaleY(1)',
                        },
                    },
                    '.swiper-pagination-bullet': {
                        width: '100%',
                        height: 'auto',
                        background: 'transparent',
                        position: 'relative',
                        '.-bullet-content-wrapper': {
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            minHeight: '95px',
                            zIndex: 2,
                            '.-number': {
                                width: '46px',
                                height: '46px',
                                background: theme.baseVariables.howToUse.numberBgDesktop,
                                color: theme.baseVariables.howToUse.numberColorDesktop,
                                fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                                fontSize: theme.baseVariables.fonts.fontSizeBig,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%',
                                marginRight: theme.spacing(3),
                                transition: theme.baseVariables.constants.transition.normal,
                            },
                            '.-content-wrapper': {
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'left',
                                flex: 1,
                                background: theme.baseVariables.howToUse.swiperBulletBgDesktop,
                                color: theme.baseVariables.howToUse.bulletColorDesktop,
                                transition: theme.baseVariables.constants.transition.normal,
                                padding: theme.spacing(2),
                                borderRadius: theme.spacing(1),
                                position: 'relative',
                                '&:before': {
                                    content: 'none',
                                    position: 'absolute',
                                    width: 0,
                                    height: 0,
                                    borderStyle: 'solid',
                                    borderWidth: '8px 16px 8px 0',
                                    borderColor: 'transparent #646f9b transparent transparent',
                                    top: '50%',
                                    left: '-16px',
                                    transform: 'translate(0,-50%)',
                                    opacity: 0,
                                    visibility: 'hidden',
                                },
                                '.-bullet-title': {
                                    fontSize: theme.baseVariables.fonts.fontSizeSmall,
                                    fontWeight: theme.baseVariables.fonts.fontWeightSemiBold
                                },
                                '.-bullet-description': {
                                    display: 'none',
                                    opacity: 0,
                                    visibility: 'hidden',
                                    fontSize: theme.baseVariables.fonts.fontSizeSmall
                                }
                            }
                        },
                        '&.-is-seen': {
                            '.-number': {
                                background: theme.baseVariables.howToUse.numberBgDesktopActive,
                                color: theme.baseVariables.howToUse.numberColorDesktopActive,
                            },
                        },
                        '&.swiper-pagination-bullet-active': {
                            background: 'transparent',
                            '.-number': {
                                background: theme.baseVariables.howToUse.numberBgDesktopActive,
                                color: theme.baseVariables.howToUse.numberColorDesktopActive,
                            },
                            '.-content-wrapper': {
                                color: theme.baseVariables.howToUse.bulletColorDesktopActive,
                                background: theme.baseVariables.howToUse.swiperBulletBgDesktopActive,
                                '&:before': {
                                    content: '""',
                                    opacity: 1,
                                    visibility: 'visible',
                                },
                                '.-bullet-description': {
                                    opacity: 1,
                                    visibility: 'visible',
                                    display: 'block',
                                }
                            }
                        },
                    }
                },
            }
        },
        '.-how-to-us-content-wrapper': {
            '.-how-to-use-banner-wrapper': {
                textAlign: 'center',
                '.-how-to-use-banner-image': {
                    width: '90%',
                    margin: 'auto',
                    [theme.breakpoints.up('sm')]: {
                        width: '380px',
                    },
                }
            },
            '.-how-to-use-description-inner-wrapper': {
                width: '80%',
                margin: 'auto',
                [theme.breakpoints.between('xxs', 330)]: {
                    width: '90%',
                },
                [theme.breakpoints.up('sm')]: {
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            },
            '.-how-to-use-description-wrapper': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: theme.spacing(2),
                '.-title-wrapper': {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginBottom: theme.spacing(1.5),
                    '.-number-wrapper': {
                        display: 'flex',
                        width: '26px',
                        height: '26px',
                        borderRadius: '50%',
                        background: theme.baseVariables.howToUse.numberBg,
                        marginRight: theme.spacing(1),
                        '.-number-text': {
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: theme.baseVariables.howToUse.numberColor,
                        }
                    },
                    '.-title': {
                        fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                    }
                },
                '.-description': {
                    fontSize: theme.baseVariables.fonts.fontSizeSmall,
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.baseVariables.fonts.fontSizeNormal,
                    },
                }
            },
        }
    }
}))

const HomepageHowToUse: React.FC = () => {
    const {trans} = useTrans();
    const { isDesktop, isTabletOrMobile } = useResponsive();
    const contents = [
        {
            title: trans('homepage.section.how_to_use.create_account.title'),
            description: trans('homepage.section.how_to_use.create_account.description'),
            banner: 'wow-boost-homepage-how-to-use-create-account.png',
        },
        {
            title: trans('homepage.section.how_to_use.choose_service.title'),
            description: trans('homepage.section.how_to_use.choose_service.description'),
            banner: 'wow-boost-homepage-how-to-use-choose-service.png',
        },
        {
            title: trans('homepage.section.how_to_use.payment.title'),
            description: trans('homepage.section.how_to_use.payment.description'),
            banner: 'wow-boost-homepage-how-to-use-payment.png',
        },
        {
            title: trans('homepage.section.how_to_use.processing.title'),
            description: trans('homepage.section.how_to_use.processing.description'),
            banner: 'wow-boost-homepage-how-to-use-processing.png',
        },
    ]

    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
            return '<span class="' + className + '">' + '</span>';
        },
    };

    const _onSlideChange = (swiper: any) => {
        const pagination = swiper.pagination;

        if (swiper.activeIndex < swiper.previousIndex) {
            Array.from(Array(swiper.previousIndex)).map((_i, index) => {
                const prevActiveBullet: HTMLElement | null = document.getElementById(`how-to-use-bullet-${index}`);
                prevActiveBullet && prevActiveBullet.classList.remove("-is-seen");
            })
        }

        pagination.el.classList.remove(`-index-${swiper.previousIndex}`)
        pagination.el.classList.add(`-index-${swiper.activeIndex}`)

        Array.from(Array(swiper.activeIndex)).map((_i, index) => {
            const activeBullet: HTMLElement | null = document.getElementById(`how-to-use-bullet-${index}`);
            activeBullet && activeBullet.classList.add("-is-seen");
        })
    }

    const paginationDesktop = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
            return '<div id="how-to-use-bullet-' + index + '" class="'+ className +'">' +
                    '<div class="-bullet-content-wrapper">' +
                        '<div class="-number">' + (index + 1) + '</div>' +
                        '<div class="-content-wrapper">' +
                            '<div class="-bullet-title">' + contents[index].title + '</div>' +
                            '<div class="-bullet-description">' + contents[index].description + '</div>' +
                        '</div>' +
                    '</div>' +
                '</div>';
        },
    };

    const renderSlideContent = (item: {title: string, description: string, banner: string}, index: number) => {
        return (
            <Box className={'-how-to-us-content-wrapper'}>
                <Box className={'-how-to-use-banner-wrapper'}>
                    <Image
                        src={item.banner}
                        width={393}
                        height={393}
                        extraClass={'-how-to-use-banner-image'}
                    />
                </Box>
                {isTabletOrMobile && (
                    <Box className={'-how-to-use-description-wrapper'}>
                        <Box className={'-how-to-use-description-inner-wrapper'}>
                            <Box className={'-title-wrapper'}>
                                <Box className={'-number-wrapper'}>
                                    <Typography component={"span"} className={'-number-text'}>{index + 1}</Typography>
                                </Box>
                                <Typography className={'-title'}>{item.title}</Typography>
                            </Box>
                            <Typography className={'-description'}>{item.description}</Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        )
    }

    const renderSwiperDesktop = () => {
        return (
            <Swiper
                direction={'vertical'}
                slidesPerView={1}
                centeredSlides={true}
                spaceBetween={0}
                mousewheel={true}
                onSlideChange={(swiper) => _onSlideChange(swiper)}
                pagination={paginationDesktop}
                modules={[Mousewheel, Pagination]}
                className={'-swiper-slide -desktop'}
            >
                {contents.map((item, index) => {
                    return (
                        <SwiperSlide key={`how-to-use-desktop-${item.title}-${index}`} className={'-swiper-slide-item'}>
                            {renderSlideContent(item, index)}
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        )
    }

    const renderSwiperMobile = () => {
        return (
            <Swiper
                direction={'horizontal'}
                slidesOffsetAfter={0}
                slidesOffsetBefore={0}
                spaceBetween={16}
                slidesPerView={'auto'}
                pagination={pagination}
                modules={[Pagination]}
                className={'-swiper-slide'}
            >
                {contents.map((item, index) => {
                    return (
                        <SwiperSlide key={`how-to-use-mobile-${item.title}-${index}`} className={'-swiper-slide-item'}>
                            {renderSlideContent(item, index)}
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        )
    }

    return (
        <FadeInUp>
            <HomepageSectionLayout sectionId={'homepageSectionHowToUse'}>
                <HowToUseStyled className="x-homepage-how-to-use-container">
                    <Title title={trans('homepage.section.how_to_use.title')} subTitle={trans('homepage.section.how_to_use.sub_title')}/>
                    {isDesktop && renderSwiperDesktop()}
                    {isTabletOrMobile && renderSwiperMobile()}
                </HowToUseStyled>
            </HomepageSectionLayout>
        </FadeInUp>
    )
}

export default HomepageHowToUse
