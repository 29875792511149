import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import BasicButton, { IBasicButton } from "@core/Component/BasicButton";

interface IImageButton extends IBasicButton {
    extraClass?: string;
    target?: string;
}

const ImageButtonWrapperStyled = styled(BasicButton)(({ theme }) => ({
    '&.x-image-button': {
        '.-contact-button-img': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: theme.baseVariables.constants.transition.normal,
            width: '180px',
        },
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                background: 'none',
                '.-contact-button-img': {
                    filter: 'saturate(1.5)'
                },
            }
        },
    },
}));

const ImageButton = (props: IImageButton) => {
    const {extraClass, ...imageButtonProps} = props;

    return (
        <ImageButtonWrapperStyled
            className={`x-image-button -${extraClass}`}
            {...imageButtonProps}
        />
    )
}

export default memo(ImageButton);
