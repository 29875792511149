import React, { useState } from 'react';
import snakeCase from "lodash/snakeCase";
import { useTrans } from "@core/Hook/trans";
import DefaultModalTabHeader from "./DefaultModalTabHeader";
import { AUTH_MODE_AVAILABLE } from "../../Constants/common";

interface IAuthSelectModalTabHeader {
    setCurrentTab: (currentTab: string) => void;
    currentTab: string;
}

const AuthModeSelectModalTabHeader: React.FC<IAuthSelectModalTabHeader> = (props) => {
    const { trans } = useTrans();
    const {
        setCurrentTab,
        currentTab
    } = props
    const [activeKey, setActiveKey] = useState<string>(currentTab);

    const buttons = AUTH_MODE_AVAILABLE.map(val => {
        return {
            key: val,
            title: trans(`general.${snakeCase(val)}`),
            onClick: () => {
                setCurrentTab(val);
                setActiveKey(val)
            }
        }
    })

    return (
        <DefaultModalTabHeader
            activeKey={activeKey}
            buttons={buttons}
        />
    );
};

export default AuthModeSelectModalTabHeader;
