import { useState } from "react";
export interface IModalDialogHook {
    open: boolean;
    handleClickOpen: () => void;
    handleClose: () => void;
}
export const useHandleDialog = (): IModalDialogHook => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return {
        open,
        handleClickOpen,
        handleClose,
    }
}
