import React, { useState } from "react";
import Animate, { IAnimateTransition } from "./animate";

const FadeInUp: React.FC<IAnimateTransition> = ({
    children,
    ...rest
}) => {
    const [show, setShow] = useState(false);

    const transitionTo = {
        y: !show ? 24 : 0,
        opacity: !show ? 0 : 1,
    }

    const onEnter = () => {
        if (show) {
            return;
        }

        setShow(true);
    }

    return (
        <Animate
            onEnter={onEnter}
            transitionTo={transitionTo}
            {...rest}
        >
            {children}
        </Animate>
    )
}

export default FadeInUp;
