import isUndefined from "lodash/isUndefined";
import {useTrans} from "@core/Hook/trans";
import {isNull} from "lodash";
import {ToastAlert} from "@core/Component/AlertFadeInRight";
import {useLocation, useNavigate} from "react-router-dom";
import {parse} from "qs";
import trim from "lodash/trim";

export const useAlert = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const qs = parse(trim(location.search, '?'));
    const { trans } = useTrans();

    const renderStateAlert = (searchParams: URLSearchParams, setSearchParams: (...args: any[]) => void) => {
        if ((isUndefined(location.state?.isPaymentSuccess) || isNull(window.history.state)) && !searchParams.has('noti')) return;

        const isSuccess = !isUndefined(qs.noti) ? 'success' === qs.noti : location.state.isPaymentSuccess;
        const message = isSuccess ? trans('general.payment.success') : trans('general.payment.fail');
        const alertMessage = !isUndefined(location.state?.alertMessage) ? location.state.alertMessage : message;

        ToastAlert(isSuccess ? 'success' : 'error', alertMessage)

        searchParams.delete('noti');
        setSearchParams(searchParams);
        navigate(location.pathname, { replace: true });
        window.history.pushState({ path: location.pathname },'', location.pathname);
        window.history.replaceState(null, '');
    }

    return {
        renderStateAlert
    }
};
