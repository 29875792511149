import React, { useEffect, useRef, useState } from 'react';
import { Link as LinkRouter, matchPath, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Image } from '@core/Utils/image';
import { useResponsive } from '@core/Hook/responsiveHook';
import Menus from './Menus';
import HamburgerButton from './Header/HamburgerButton';
import HeaderLogged from './Header/HeaderLogged';
import HeaderAnon from './Header/HeaderAnon';
import MenuMobileDialog from './Header/MenuMobileDialog';
import LocaleSwitcher from '../LocaleSwitcher';
import { useCustomerContext } from '../../Hook/auth';
import Credit from './Header/Credit';

const HeaderStyled = styled(AppBar)(({ theme }) => ({
    '&.x-header': {
        top: 0,
        left: 0,
        height: theme.baseVariables.header.height,
        background: theme.baseVariables.header.bg,
        boxShadow: theme.baseVariables.header.boxShadow,
        transition: 'background .2s, boxShadow .2s',
        [theme.breakpoints.up('md')]: {
            background: theme.baseVariables.header.desktopBg,
            borderBottom: theme.baseVariables.header.desktopBorder,
        },
        '&.-sticky': {
            background: theme.baseVariables.header.activeBg,
            boxShadow: theme.baseVariables.header.activeBoxShadow,
            backdropFilter: theme.baseVariables.header.activeBackdropFilter,
            [theme.breakpoints.up('md')]: {
                background: theme.baseVariables.header.activeDesktopBg,
                boxShadow: theme.baseVariables.header.activeDesktopBoxShadow,
            },
        },
        '.-outer-container': {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
        },
        '.-left-content-wrapper': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
        '.-right-content-wrapper': {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
        },
        '.-logo-btn': {
            marginRight: theme.spacing(1),
            [theme.breakpoints.up('lg')]: {
                marginRight: theme.spacing(2),
            },
            '&:active': {
                background: 'none',
            },
        },
        '.-logo': {
            display: 'block',
            width: 34,
            [theme.breakpoints.up('lg')]: {
                width: 100,
            },
            [theme.breakpoints.up('md')]: {
                width: 70,
            },
        },
    },
    '.x-btn-header-base': {
        height: 32,
        [theme.breakpoints.up('md')]: {
            height: 34,
        },
    },
}));

const Header: React.FC = () => {
    const { isDesktop, isTabletOrMobile } = useResponsive();
    const location = useLocation();
    const headerRef = useRef<HTMLElement>(null);
    const [isSticky, setIsSticky] = useState(false);
    const [open, setOpen] = useState(false);
    const { customerInfo } = useCustomerContext();
    const isHomepage = matchPath('/', location.pathname);

    useEffect(() => {
        const headerEl = headerRef?.current;

        if (!headerEl) return;

        const _headerStickyHandler = () => {
            const scrollTop = window.scrollY;
            const headerHeight = headerEl.offsetHeight;

            if (scrollTop <= headerHeight) {
                setIsSticky(false);

                return;
            }

            setIsSticky(true);
        };

        window.addEventListener('scroll', _headerStickyHandler);

        return () => {
            window.removeEventListener('scroll', _headerStickyHandler);
        };
    }, []);

    const logoProps = isDesktop ? { src: 'logo.png', width: 387, height: 133 } : { src: 'logo-no-text.png', width: 34, height: 34 };

    return (
        <HeaderStyled className={`x-header ${isHomepage ? (isSticky ? '-sticky' : '') : '-sticky'}`} position={'fixed'} ref={headerRef}>
            <Container className={'-outer-container'}>
                <Box className={'-left-content-wrapper'}>
                    {isTabletOrMobile && <HamburgerButton onClick={() => setOpen(true)} />}
                    <MenuMobileDialog open={open} onClose={() => setOpen(false)} />
                    <Link component={LinkRouter} to={'/'} className={'-logo-btn'}>
                        <Image extraClass={'-logo'} {...logoProps} />
                    </Link>
                    {isDesktop && <Menus renderLocation={'header'} />}
                </Box>

                <Box className={'-right-content-wrapper'}>
                    {customerInfo && <Credit creditValue={customerInfo.creditValue}/>}
                    <LocaleSwitcher />
                    {customerInfo ? <HeaderLogged customerInfo={customerInfo} /> : <HeaderAnon />}
                </Box>
            </Container>
        </HeaderStyled>
    );
};

export default Header;
