import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const BoostFollowerIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="21" viewBox="0 0 20 24" fill="none">
                <path d="M9.43512 11.9015C10.9671 11.9015 12.2936 11.352 13.3775 10.2679C14.4614 9.184 15.0109 7.85775 15.0109 6.32556C15.0109 4.79391 14.4614 3.46749 13.3773 2.38321C12.2933 1.29947 10.9669 0.75 9.43512 0.75C7.90298 0.75 6.57678 1.29947 5.49289 2.38339C4.409 3.46731 3.85938 4.79373 3.85938 6.32556C3.85938 7.85775 4.409 9.18417 5.49307 10.2681C6.57713 11.3518 7.90351 11.9015 9.43512 11.9015Z" fill={color}/>
                <path d="M19.1475 18.4545C19.1171 18.0079 19.0555 17.5207 18.9648 17.0063C18.8732 16.488 18.7553 15.998 18.6142 15.5502C18.4685 15.0874 18.2702 14.6303 18.0252 14.1923C17.7708 13.7376 17.4721 13.3418 17.1369 13.016C16.7864 12.6752 16.3572 12.4012 15.8609 12.2013C15.3663 12.0025 14.8182 11.9018 14.2319 11.9018C14.0017 11.9018 13.779 11.9978 13.349 12.2823C13.0843 12.4577 12.7747 12.6605 12.4292 12.8848C12.1337 13.0761 11.7334 13.2554 11.239 13.4176C10.7567 13.5762 10.2669 13.6567 9.78355 13.6567C9.30016 13.6567 8.81058 13.5762 8.32771 13.4176C7.83383 13.2555 7.43356 13.0763 7.13844 12.885C6.79616 12.6628 6.48641 12.4599 6.21778 12.2821C5.78826 11.9976 5.56541 11.9016 5.33516 11.9016C4.7487 11.9016 4.20078 12.0025 3.70638 12.2015C3.21044 12.401 2.78109 12.675 2.43021 13.0162C2.09516 13.3421 1.79625 13.7378 1.54225 14.1923C1.29737 14.6303 1.09913 15.0872 0.953204 15.5504C0.812267 15.9982 0.694389 16.488 0.60284 17.0063C0.512151 17.52 0.450545 18.0073 0.420086 18.455C0.390143 18.8935 0.375 19.3487 0.375 19.8084C0.375 21.0048 0.749284 21.9733 1.48735 22.6876C2.2163 23.3925 3.18084 23.7501 4.35377 23.7501H15.2144C16.3873 23.7501 17.3515 23.3926 18.0806 22.6876C18.8188 21.9738 19.1931 21.0051 19.1931 19.8082C19.193 19.3464 19.1776 18.8909 19.1475 18.4545Z" fill={color}/>
            </svg>
        </ServiceProductIcon>
    )
}

export default BoostFollowerIcon
