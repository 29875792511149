import React from 'react';
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Image } from "@core/Utils/image";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTrans } from "@core/Hook/trans";
import PlainButton from "../../Button/PlainButton";
import {ValidationStateProps} from "../RegisterEmailValidationModal";

interface IEmailValidationFail {
    onClick(): void;
    validationState: ValidationStateProps;
}

const EmailValidationFailStyled = styled(Stack)(({ theme }) => ({
    '&.x-email-validation-fail': {
        '.-img': {
            width: 120,
        },
        '.-time': {
            fontSize: theme.baseVariables.fonts.fontSizeNormal,
            fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
            textAlign: 'center',
            color: theme.palette.primary.main,
        },
        '.-btn': {
            minWidth: 220,
        }
    },
}));

const EmailValidationFail: React.FC<IEmailValidationFail> = ({ onClick, validationState }) => {
    const { trans } = useTrans();
    const isInvalidResetPassword = 'invalid-reset-password' === validationState;
    const description = isInvalidResetPassword ? trans('modal.email_validation.fail_reset_password_description') : trans('modal.email_validation.fail_verified_description');
    const textButton = isInvalidResetPassword ? trans('modal.email_validation.fail_reset_password_btn') : trans('modal.email_validation.fail_verified_btn');

    return (
        <EmailValidationFailStyled className={'x-email-validation-fail'}>
            <Box className={'-icon-wrapper'} textAlign={"center"}>
                <Image
                    src={'ic-email-validation-invalid.png'}
                    width={60}
                    height={60}
                />
            </Box>
            <Box textAlign={"center"} marginTop={2}>
                <Typography variant={"subtitle2"} whiteSpace={"pre-wrap"}>
                    {description}
                </Typography>
            </Box>
            <Stack
                justifyContent={"center"}
                alignItems={"center"}
                marginTop={2}
                textAlign={"center"}
                spacing={2}
            >
                <PlainButton
                    color={'primary'}
                    extraClass={'-btn -submit'}
                    label={textButton}
                    onClick={onClick}
                />
            </Stack>
        </EmailValidationFailStyled>
    );
};

export default EmailValidationFail;
