import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTrans } from '@core/Hook/trans';
import NoResult from '../../Component/NoResult';
import Stack from '@mui/material/Stack';
import CreditHistoryListItem from './CreditHistoryListItem';
import _ from 'lodash';
import { IOrder } from 'Recoil/Order/atom';

interface ICreditHistoryList {
    lists: IOrder[];
}

const CreditHistoryListStyled = styled(Stack)(({ theme }) => ({
    '&.x-history-list-container': {
        width: '100%',
        marginTop: theme.spacing(1),
        '.-no-result': {
            padding: theme.spacing(5, 0),
        },
    },
}));

const CreditHistoryList: React.FC<ICreditHistoryList> = ({ lists }) => {
    const { trans } = useTrans();
    return (
        <CreditHistoryListStyled className={'x-history-list-container'}>
            {lists.length ? (
                lists?.map((item, key) => {
                    return <CreditHistoryListItem key={`order-history-list-${key}`} order={item} />;
                })
            ) : (
                <Box className={'-no-result'}>
                    <NoResult title={trans('credit.history_no_result')} />
                </Box>
            )}
        </CreditHistoryListStyled>
    );
};

export default CreditHistoryList;
