import React, { useState } from "react";
import DefaultModal, { IDefaultModal } from "./DefaultModal";
import { useTrans } from "@core/Hook/trans";
import ForgetPasswordStepOtp from "./ForgetPassword/ForgetPasswordStepOtp";
import ForgetPasswordStepPassword from "./ForgetPassword/ForgetPasswordStepPassword";
import ForgetPasswordStepConfirmPassword from "./ForgetPassword/ForgetPasswordStepConfirmPassword";
import ForgetPasswordStepSuccess from "./ForgetPassword/ForgetPasswordStepSuccess";
import {IRequestOtp, ISetPassword} from "./RegisterModal";
import {IValidateOtpResponse} from "../../Hook/auth";
import {handleResponseFormError, resolveClientApiState, useClientApiState} from "@core/Hook/api";
import {resetPasswordByValidateToken} from "../../Api/user";

interface IForgetPasswordModal extends IDefaultModal {
    otpToken: string;
    phoneNumber?: string;
}

export interface IForgetPasswordPhoneNumber extends IRequestOtp {
    phoneNumber: string;
}

interface IForgetPasswordData {
    validateToken: string;
    password: string;
}

const ForgetPasswordModal: React.FC<IForgetPasswordModal> = ({phoneNumber, otpToken, open, handleClose, ...rest}) => {
    const { trans } = useTrans();
    const [currentState, setCurrentState] = useState('otp');
    const [forgetPasswordData, setForgetPasswordData] = useState<IForgetPasswordData>({validateToken: '', password: ''});
    const [apiResetPasswordState, setApiResetPasswordState] = useClientApiState({
        isLoading: false,
    });
    const resetPassword = async (data: IForgetPasswordData) => {
        setApiResetPasswordState({ ...apiResetPasswordState, isLoading: true });

        const resetPasswordRes = resolveClientApiState(await resetPasswordByValidateToken(data), true);

        if (resetPasswordRes.isOk) {
            setApiResetPasswordState({ ...apiResetPasswordState, isLoading: false });

            return {success: true};
        }

        handleResponseFormError(resetPasswordRes);
        setApiResetPasswordState({ ...apiResetPasswordState, isLoading: false });
    }
    const getModalTitle = () => {
        return trans('modal.forget_password.title')
    }

    const handleForgetPasswordStepOtp = (state: string, data: IValidateOtpResponse) => {
        setForgetPasswordData((prev) => {
            return {...prev, validateToken: data.validateToken};
        })
        setCurrentState(state);
    }

    const handleForgetPasswordStepPassword = (state: string, data: ISetPassword) => {
        setForgetPasswordData((prev) => {
            return {...prev, password: data.password};
        })
        setCurrentState(state);
    }

    const handleForgetPasswordConfirmPassword = (state: string, data: ISetPassword) => {
        const resetPasswordData = {
            ...forgetPasswordData,
            password: data.password,
        }

        resetPassword(resetPasswordData).then(res => {
            if (res && res.success) return setCurrentState('success');

            handleClose();
        })
    }

    const getModalRenderComponent = () => {
        switch (currentState) {
            case 'otp':
                return <ForgetPasswordStepOtp onSubmit={(data) => handleForgetPasswordStepOtp('password', data)} otpToken={otpToken} phoneNumber={phoneNumber} />
            case 'password':
                return <ForgetPasswordStepPassword onSubmit={(data) => handleForgetPasswordStepPassword('confirm_password', data)} />
            case 'confirm_password':
                return <ForgetPasswordStepConfirmPassword onSubmit={(data) => handleForgetPasswordConfirmPassword('success', data)} newPassword={forgetPasswordData.password} isLoading={apiResetPasswordState.isLoading} />
            case 'success':
                return <ForgetPasswordStepSuccess onEnd={handleClose} />
        }
    }

    if (!open) return <></>

    return (
        <DefaultModal
            {...rest}
            showCloseIcon
            open={open}
            isHalfSize={'success' !== currentState}
            title={getModalTitle()}
            titleColor={'primary'}
            extraClass={'-forget-password-modal'}
            handleClose={() => {
                handleClose()
                setTimeout(() => {
                    setCurrentState('otp')
                }, 250)
            }}
        >
            {getModalRenderComponent()}
        </DefaultModal>
    )
}

export default ForgetPasswordModal
