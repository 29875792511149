import React from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTrans } from "@core/Hook/trans";

interface ICopyright {
    title?: string,
    renderLocation?: 'menu-modal-dialog' | 'footer',
}

const CopyrightStyled = styled(Box)(({ theme }) => ({
    '&.x-copyright': {
        background: theme.baseVariables.copyright.bg,
        padding: theme.spacing(1.5),
        '.-title': {
            color: theme.baseVariables.copyright.color,
            fontSize: theme.baseVariables.fonts.fontSizeSmaller,
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                fontSize: theme.baseVariables.fonts.fontSizeSmall
            }
        },
        '&.-in-menu-modal-dialog': {
            position: 'fixed',
            left: '50%',
            bottom: theme.spacing(0),
            width: '100%',
            background: 'transparent',
            translate: '-50% 0',
            '.-title': {
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
            },
        },
    }
}))

const Copyright: React.FC<ICopyright> = ({ title , renderLocation = 'footer'}) => {
    const { trans } = useTrans();
    const year = moment().add(3, 'M').format('YYYY');

    return (
        <CopyrightStyled className={`x-copyright -in-${renderLocation}`}>
            <Typography variant={'h6'} className={'-title'}>
                {title || trans('copyright.title').replace('{{year}}', year)}
            </Typography>
        </CopyrightStyled>
    )
}

export default Copyright
