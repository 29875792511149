import {handleResponseFormError, resolveClientApiState, useClientApiState} from "@core/Hook/api";
import {IInputPassCodeData} from "../Component/Modal/Form/InputPasscodeForm";
import {validateOtp} from "../Api/auth";
import {useRecoilValue} from "recoil";
import {CustomerContextSelector} from "../Recoil/Common/selector";
import isUndefined from "lodash/isUndefined";

export interface IValidateOtpResponse {
    validateToken: string;
}

export const useManageOtp = () => {
    const [apiOtpState, setApiOtpState] = useClientApiState({
        isLoading: false,
    });

    const submitOtp = async (data: IInputPassCodeData, otpToken: string) => {
        const formData = {
            otpCode: data.otpCode,
            token: otpToken
        }

        setApiOtpState({ ...apiOtpState, isLoading: true });

        const validateOtpRes = resolveClientApiState(await validateOtp(formData), true);

        if (validateOtpRes.isOk) {
            return {
                validateToken: validateOtpRes.data.validateToken,
            };
        }

        handleResponseFormError(validateOtpRes);
        setApiOtpState({ ...apiOtpState, isLoading: false });
    }

    return {
        submitOtp,
        apiOtpState,
    }
};

export const useCustomerContext = () => {
    const customerInfo = useRecoilValue(CustomerContextSelector);
    const isLogged = !isUndefined(customerInfo);

    return {
        isLogged: isLogged,
        customerInfo: customerInfo
    }
}
