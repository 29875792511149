export const ORDER_STATUS_PENDING = 'pending';
export const ORDER_STATUS_PROGRESS = 'process';
export const ORDER_STATUS_SUCCESS = 'success';
export const ORDER_STATUS_CANCEL = 'cancel';

export const ORDER_STATUS_AVAILABLE_LIST = [
    { code: 0, state: ORDER_STATUS_PENDING },
    { code: 1, state: ORDER_STATUS_PROGRESS },
    { code: 2, state: ORDER_STATUS_SUCCESS },
    { code: 3, state: ORDER_STATUS_CANCEL },
];

export enum PaidStatusEnum {
    PENDING,
    SUCCESSFUL,
    CANCEL,
}

export enum OrderTypeEnum {
    CREDIT = 'credit',
    PRODUCT = 'product',
}
