import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useTrans } from "@core/Hook/trans";
import ModalTypography from "../Partial/ModalTypography";
import FadeInUp from "../../../Animate/FadeInUp";
import ForgetPasswordEmailForm, {IForgetPasswordEmailFormData} from "../Form/ForgetPasswordEmailForm";

interface IForgetPasswordEmail {
    onSubmit: (data: IForgetPasswordEmailFormData) => void;
    isLoading?: boolean;
}

const LoginByEmailStyled = styled(Stack)(({ theme }) => ({
    '&.x-forget-password-email': {
        '.-icon-wrapper': {
            margin: theme.spacing(2, 0),
            textAlign: 'center',
            '.-img': {
                display: 'inline-block',
                width: 100,
            }
        },
        '.-description-wrapper': {
            marginTop: theme.spacing(3),
        },
        '.-form-wrapper': {
            margin: theme.spacing(3.5, 0, 1),
        },
    }
}))

const ForgetPasswordEmail: React.FC<IForgetPasswordEmail> = ({ onSubmit, isLoading }) => {
    const { trans } = useTrans();
    const submitLogin = (data: IForgetPasswordEmailFormData) => onSubmit(data);

    return (
        <LoginByEmailStyled className={'x-forget-password-email'}>
            <FadeInUp>
                <Box className={'-description-wrapper'}>
                    <ModalTypography renderType={'title'} align={'center'}>
                        {trans('modal.forget_password_email.title')}
                    </ModalTypography>
                    <ModalTypography extraClass={'whitespace-break-spaces'} renderType={'description'} align={'center'}>
                        {trans('modal.forget_password_email.description')}
                    </ModalTypography>
                </Box>
                <Box className={'-form-wrapper'}>
                    <ForgetPasswordEmailForm onSubmit={(data) => submitLogin(data)} isLoading={isLoading} />
                </Box>
            </FadeInUp>
        </LoginByEmailStyled>
    )
}

export default ForgetPasswordEmail
