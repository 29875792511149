import React, { useState } from "react";
import ForgetPasswordModal, {IForgetPasswordPhoneNumber} from "./ForgetPasswordModal";
import {IModalDialogHook, useHandleDialog} from "../../Hook/modalDialogHook";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import RegisterEmailValidationModal, {ValidationStateProps} from "./RegisterEmailValidationModal";

interface ILoginIntegration {
    handleRegisterModal: IModalDialogHook;
    handleLoginModal: IModalDialogHook;
    handleEmailVerificationModal?: IModalDialogHook;
}

const LoginIntegration: React.FC<ILoginIntegration> = ({handleRegisterModal, handleLoginModal, handleEmailVerificationModal}) => {
    const forgetPasswordModal = useHandleDialog()
    const [currentTab, setCurrentTab] = useState<string>('email');
    const [otpToken, setOtpToken] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [emailVerify, setEmailVerify] = useState<string>('');
    const [emailVerifyState, setEmailVerifyState] = useState<ValidationStateProps | undefined>(undefined);

    const handleForgetPasswordClick = (data: IForgetPasswordPhoneNumber) => {
        setOtpToken(data.otpToken);
        setPhoneNumber(data.phoneNumber);

        handleRegisterModal.handleClose()
        handleLoginModal.handleClose()
        setTimeout(() => {
            forgetPasswordModal.handleClickOpen()
        }, 250);
    }

    return (
        <>
            <LoginModal
                open={handleLoginModal.open}
                handleClose={handleLoginModal.handleClose}
                handleForgetPasswordClick={(data: IForgetPasswordPhoneNumber) => handleForgetPasswordClick(data)}
                stateLogin={'phone_number'}
                setCurrentTab={(currentTab: string) => setCurrentTab(currentTab)}
                currentTab={currentTab}
                handleEmailVerificationModal={handleEmailVerificationModal}
                handleLoginModal={handleLoginModal}
                setEmailVerify={(email: string) => setEmailVerify(email)}
                setEmailVerifyState={(state: ValidationStateProps) => setEmailVerifyState(state)}
                handleRegisterClick={() => {
                    handleLoginModal.handleClose()

                    setTimeout(() => {
                        handleRegisterModal.handleClickOpen()
                    }, 250)
                }}
            />
            <RegisterModal
                open={handleRegisterModal.open}
                handleClose={handleRegisterModal.handleClose}
                setCurrentTab={(currentTab: string) => setCurrentTab(currentTab)}
                currentTab={currentTab}
                handleEmailVerificationModal={handleEmailVerificationModal}
                setEmailVerify={(email: string) => setEmailVerify(email)}
                setEmailVerifyState={(state: ValidationStateProps) => setEmailVerifyState(state)}
            />
            {otpToken && <ForgetPasswordModal
                open={forgetPasswordModal.open}
                handleClose={forgetPasswordModal.handleClose}
                otpToken={otpToken}
                phoneNumber={phoneNumber}
            />}
            {handleEmailVerificationModal && emailVerifyState && <RegisterEmailValidationModal
                email={emailVerify}
                open={handleEmailVerificationModal.open}
                validationState={emailVerifyState}
                handleClose={handleEmailVerificationModal.handleClose}
                handleRegisterModal={handleRegisterModal}
                handleLoginModal={handleLoginModal}
                handleEmailVerificationModal={handleEmailVerificationModal}
                setEmailVerifyState={(state: ValidationStateProps) => setEmailVerifyState(state)}
            />}
        </>
    )
}

export default LoginIntegration
