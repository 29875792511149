import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import MetaSeo from '../Component/Common/MetaSeo';
import OrderCardLayout from '../Component/Order/OrderCardLayout';
import Layout from '../Component/Common/Layout';
import { useTrans } from '@core/Hook/trans';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResponsive } from '@core/Hook/responsiveHook';
import { Asset } from '@core/Utils/image';
import isUndefined from 'lodash/isUndefined';
import { IProductInfoFormData } from './Service/ProductForm';
import ReceiptForm, { IOrderMetadata } from './OrderSummary/ReceiptForm';
import OrderDetailContent from './OrderDetail/OrderDetailContent';
import { IOrder, IOrderSummary, OrderSingleAtom } from '../Recoil/Order/atom';
import useOrderHook from '../Hook/orderHook';
import isEmpty from 'lodash/isEmpty';
import { useRecoilValue } from 'recoil';
import { useManageProductInfo } from '../Hook/product';
import { OrderTypeEnum } from '../Constants/order';

const OrderSummary: React.FC = () => {
    const location = useLocation();
    const { trans } = useTrans();
    const { isTabletOrMobile } = useResponsive();
    const navigate = useNavigate();
    const [productInfo] = useState<IProductInfoFormData | undefined>(location.state?.productInfoFormData);
    const orderType = location.state?.orderType;
    const { getOrderNationalityTargetLabelByClientTargetCode, getProductOptionsTranslation } = useOrderHook();
    const orderSingle = useRecoilValue(OrderSingleAtom);
    const { splitProductItemFullCode } = useManageProductInfo();
    const isRePayment = !isUndefined(location.state?.isRePayment) && !isUndefined(orderSingle);

    const initialOrderSummary = () => {
        if (isRePayment) {
            const purchaseOrderProduct = orderSingle?.purchaseOrderProduct;
            const purchaseOrderProgress = orderSingle?.purchaseOrderProgress;
            const productInfoObject: { [p: string]: string } | undefined = splitProductItemFullCode(
                purchaseOrderProduct?.productItemFullCode as string,
            );

            const order: IOrderSummary = {
                purchaseOrderProduct: {
                    accountType: Number(purchaseOrderProduct?.accountType),
                    amount: Number(purchaseOrderProduct?.amount),
                    platformName: trans(`service.product.platform.${productInfoObject?.platformCode}`),
                    price: Number(purchaseOrderProduct?.price),
                    serviceName: trans(`service.product.service.${productInfoObject?.serviceCode}`),
                    productItemFullCode: purchaseOrderProduct?.productItemFullCode as string,
                },
                purchaseOrderProgress: {
                    link: purchaseOrderProgress?.link as string,
                    socialAccountName: purchaseOrderProgress?.socialAccountName as string,
                    progressCount: 0,
                    progressTotal: 0,
                    serviceOption: getProductOptionsTranslation(orderSingle as IOrder),
                },
            };

            return order;
        }

        const serviceOptionLabel = !isEmpty(productInfo?.serviceOptionType)
            ? trans(`service.product.service_option_type.${productInfo?.serviceOptionType}`)
            : '';
        const serviceOptionValueLabel = !isEmpty(productInfo?.serviceOptionTypeValue)
            ? trans(`service.product.service_option_type_value.${productInfo?.serviceOptionTypeValue}`)
            : '';
        const serviceOption = !isEmpty(productInfo?.serviceOptionType) ? `${serviceOptionLabel} ${serviceOptionValueLabel}` : '';

        let order: IOrderSummary;
        if (orderType === OrderTypeEnum.CREDIT) {
            order = {
                purchaseOrderProduct: {
                    accountType: 0,
                    amount: Number(productInfo?.amount),
                    platformName: '',
                    price: Number(productInfo?.price),
                    serviceName: '',
                    productItemFullCode: productInfo?.code,
                },
                purchaseOrderProgress: {
                    link: '',
                    socialAccountName: '',
                    progressCount: 0,
                    progressTotal: 0,
                    serviceOption: '',
                },
            };
        } else {
            order = {
                purchaseOrderProduct: {
                    accountType: getOrderNationalityTargetLabelByClientTargetCode(productInfo?.target as string),
                    amount: Number(productInfo?.amount),
                    platformName: trans(`service.product.platform.${productInfo?.platform}`),
                    price: Number(productInfo?.price),
                    serviceName: trans(`service.product.service.${productInfo?.service}`),
                    productItemFullCode: productInfo?.productItemFullCode || '',
                },
                purchaseOrderProgress: {
                    link: productInfo?.socialAccountLink as string,
                    links: productInfo?.socialAccountLinks?.map((link) => link.url) || [],
                    socialAccountName: productInfo?.socialAccountName as string,
                    progressCount: 0,
                    progressTotal: 0,
                    serviceOption: serviceOption,
                },
            };
        }

        return order;
    };

    const getReceiptFormProps = () => {
        if (isRePayment) {
            const purchaseOrderProduct = orderSingle?.purchaseOrderProduct;
            const purchaseOrderProgress = orderSingle?.purchaseOrderProgress;

            const receiptFormProp = {
                productItemFullCode: purchaseOrderProduct?.productItemFullCode as string,
                price: Number(purchaseOrderProduct?.price),
                socialAccountName: purchaseOrderProgress?.socialAccountName as string,
                socialAccountLink: purchaseOrderProgress?.link as string,
            };

            return {
                receiptFormData: receiptFormProp,
                metadata: purchaseOrderProgress?.metadata,
            };
        }

        const receiptFormProp = {
            productItemFullCode: productInfo?.productItemFullCode as string,
            price: Number(productInfo?.price),
            socialAccountName: productInfo?.socialAccountName as string,
            socialAccountLink: productInfo?.socialAccountLink as string,
            socialAccountLinks: productInfo?.socialAccountLinks?.map((link) => link.url) || [],
        };

        const metadata: IOrderMetadata | undefined = isUndefined(productInfo?.serviceOptionType)
            ? undefined
            : {
                  selectCode: productInfo?.serviceOptionType as string,
                  data: productInfo?.serviceOptionTypeValue as string,
              };

        return {
            receiptFormData: receiptFormProp,
            metadata: metadata,
        };
    };

    useEffect(() => {
        if (isUndefined(productInfo) && !isRePayment) return navigate('/services');
    }, []);

    if (isUndefined(productInfo) && isUndefined(orderSingle)) return <></>;

    const bgImage = isTabletOrMobile ? Asset('wow-boost-history-purchase-bg-mobile.jpg') : Asset('wow-boost-history-purchase-bg.jpg');

    const panelTitle = trans('order.title');
    const metaTitle = trans('meta_seo.order.title');
    const metaDescription = trans('meta_seo.order.description');

    return (
        <Layout contentAvoidHeader contentBg={bgImage} isDisableFooter={isTabletOrMobile}>
            <MetaSeo title={metaTitle} description={metaDescription} />
            <OrderCardLayout title={panelTitle}>
                <OrderDetailContent order={initialOrderSummary()} orderType={orderType} />
                <Divider />
                <ReceiptForm
                    {...getReceiptFormProps().receiptFormData}
                    metadata={getReceiptFormProps().metadata}
                    purchaseOrderRefId={isRePayment ? orderSingle?.purchaseOrderRefId : undefined}
                    orderType={orderType}
                    creditCode={productInfo?.code}
                    isRepayment={isRePayment}
                    paymentMethod={isRePayment ? orderSingle?.purchaseOrderReceipt.paymentMethod : undefined}
                />
            </OrderCardLayout>
        </Layout>
    );
};

export default OrderSummary;
