import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const BoostLiveViewIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 27 26" fill="none">
                <g clipPath="url(#clip0_1338_2161)">
                    <path d="M14.6393 7.25048C14.0068 7.8816 12.9817 7.8816 12.3492 7.25048C11.7169 6.61936 11.7169 5.59603 12.3492 4.96492C12.9817 4.3338 14.0068 4.3338 14.6393 4.96492C15.2715 5.59603 15.2715 6.61936 14.6393 7.25048Z" fill={color}/>
                    <path d="M16.8478 9.69937C16.6505 9.69937 16.4533 9.62407 16.3031 9.4733C16.0027 9.17253 16.0033 8.68518 16.304 8.38499C16.9136 7.77664 17.2492 6.9679 17.2492 6.10779C17.2492 5.24769 16.9136 4.43895 16.304 3.8306C16.0033 3.53021 16.0027 3.04306 16.3031 2.74209C16.6033 2.44132 17.0906 2.44074 17.3914 2.74112C18.2923 3.64033 18.7884 4.83583 18.7884 6.10779C18.7884 7.37956 18.2923 8.57526 17.3914 9.47447C17.2412 9.62446 17.0445 9.69937 16.8478 9.69937Z" fill={color}/>
                    <path d="M18.59 11.9651C18.3927 11.9651 18.1955 11.8898 18.0453 11.7392C17.7449 11.4382 17.7455 10.9511 18.0462 10.6507C19.2622 9.43711 19.932 7.82371 19.932 6.1076C19.932 4.39148 19.2622 2.77808 18.0462 1.56448C17.7455 1.2641 17.7449 0.776949 18.0453 0.475982C18.3456 0.175209 18.8328 0.174625 19.1336 0.475009C20.6409 1.97946 21.4713 3.97981 21.4711 6.1076C21.4711 8.23538 20.6409 10.2359 19.1336 11.7404C18.9834 11.8902 18.7867 11.9651 18.59 11.9651Z" fill={color}/>
                    <path d="M10.1322 9.69944C9.93554 9.69944 9.73885 9.62454 9.58846 9.47454C8.68751 8.57533 8.19141 7.37964 8.19141 6.10787C8.19141 4.83591 8.68751 3.6404 9.58846 2.74119C9.88924 2.44081 10.3766 2.4412 10.6768 2.74217C10.9772 3.04294 10.9766 3.53029 10.6758 3.83067C10.0663 4.43903 9.73068 5.24777 9.73068 6.10787C9.73068 6.96797 10.0663 7.77671 10.6758 8.38507C10.9766 8.68526 10.9772 9.1726 10.6768 9.47337C10.5266 9.62395 10.3293 9.69944 10.1322 9.69944Z" fill={color}/>
                    <path d="M8.39299 11.9652C8.19611 11.9652 7.99942 11.8903 7.84923 11.7403C6.34186 10.2358 5.51172 8.23548 5.51172 6.10769C5.51172 3.97971 6.34186 1.97936 7.84923 0.474912C8.15 0.174722 8.63735 0.175111 8.93753 0.476079C9.23792 0.776852 9.23733 1.264 8.93656 1.56439C7.72063 2.77798 7.0508 4.39138 7.0508 6.1075C7.0508 7.82362 7.72063 9.43721 8.93656 10.6508C9.23733 10.9512 9.23792 11.4383 8.93753 11.7393C8.78734 11.8899 8.59007 11.9652 8.39299 11.9652Z" fill={color}/>
                    <path d="M23.6342 13.2957H3.34723C2.35289 13.2957 1.54688 14.1017 1.54688 15.0959V23.9496C1.54688 24.944 2.35289 25.75 3.34723 25.75H23.634C24.6284 25.75 25.4344 24.944 25.4344 23.9496V15.0959C25.4344 14.1017 24.6284 13.2957 23.6342 13.2957ZM7.4672 23.4401H4.63165C4.20675 23.4401 3.8622 23.0955 3.8622 22.6705V16.3752C3.8622 15.9503 4.20675 15.6056 4.63165 15.6056C5.05674 15.6056 5.40128 15.9501 5.40128 16.3752V21.9008H7.4672C7.89229 21.9008 8.23684 22.2454 8.23684 22.6705C8.23684 23.0955 7.89229 23.4401 7.4672 23.4401ZM10.6212 22.6705C10.6212 23.0955 10.2767 23.4401 9.85159 23.4401C9.4265 23.4401 9.08196 23.0955 9.08196 22.6705V16.3752C9.08196 15.9503 9.4265 15.6056 9.85159 15.6056C10.2767 15.6056 10.6212 15.9501 10.6212 16.3752V22.6705ZM17.0966 16.5978L15.1961 22.8928C15.1959 22.8934 15.1957 22.894 15.1955 22.8946C14.9891 23.599 13.9367 23.5817 13.7231 22.8946C13.7229 22.894 13.7227 22.8934 13.7225 22.8928L11.822 16.5978C11.6992 16.1908 11.9294 15.7614 12.3364 15.6385C12.743 15.5157 13.1727 15.7459 13.2955 16.1529L14.4593 20.0075L15.6231 16.1529C15.746 15.7459 16.1754 15.5155 16.5824 15.6385C16.9892 15.7614 17.2196 16.1908 17.0966 16.5978ZM21.8313 18.3569C22.2564 18.3569 22.601 18.7015 22.601 19.1265C22.601 19.5514 22.2564 19.8962 21.8313 19.8962H19.6473V21.9008H22.2459C22.671 21.9008 23.0156 22.2454 23.0156 22.6705C23.0156 23.0955 22.671 23.4401 22.2459 23.4401H18.8777C18.4526 23.4401 18.1081 23.0955 18.1081 22.6705V16.3752C18.1081 15.9503 18.4526 15.6056 18.8777 15.6056H22.3496C22.7745 15.6056 23.1193 15.9501 23.1193 16.3752C23.1193 16.8003 22.7745 17.1449 22.3496 17.1449H19.6471V18.3569H21.8313Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_1338_2161">
                        <rect width="25.5" height="25.5" fill="white" transform="translate(0.75 0.25)"/>
                    </clipPath>
                </defs>
            </svg>
        </ServiceProductIcon>
    )
}

export default BoostLiveViewIcon
